import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePageContext } from '~/hooks';
import { IState } from '~/store/modules/types';

import { ElementNotification } from '~/components';

import { INotification } from '~/store/modules/notification/types';
import { loadRequest } from '~/store/modules/notification/actions';
import NotificationsService from '~/services/NotificationsService';

import { Content, Header, Title, ViewMore } from './styles';

const SideNotification: React.FC = () => {
  const { collapseNotification, page, setPage } = usePageContext();
  const history = useHistory();
  const dispatch = useDispatch();

  const id = useSelector((state: IState) => state.auth.data.account.id);

  const notifications = useSelector(
    (state: IState) => state.notification.notifications,
  );

  const pages = useSelector((state: IState) => state.notification.pages);

  return (
    <Content state={collapseNotification} className="notification">
      <Header>
        <Title>Notificação</Title>
      </Header>
      {notifications.length > 0 ? (
        notifications.map((notification: INotification) => (
          <ElementNotification
            key={notification.id}
            id={notification.id}
            title={notification.template.descricao}
            status={!!notification.status}
            message={notification.template.texto}
            created_at={notification.data}
            acaoId={notification.acao_id}
            onClick={async () => {
              if (!notification.acao_id) return '';
              await NotificationsService.setView({ ids: [notification.id] });
              dispatch(loadRequest(id, page, notifications));
              const construction = [38, 39, 41, 42, 43, 45, 44, 40];
              if (construction.includes(notification.template.id))
                return history.push(
                  `/construction/view/${notification.acao_id}`,
                );
              return history.push(`/budget/edit/${notification.acao_id}`);
              switch (notification.acao_id) {
                case 1:
                  return history.push(`/budget/edit/${notification.acao_id}`);
                  break;
                case 2:
                  return history.push(`/budget/edit/${notification.acao_id}`);
                  break;
                case 3:
                  return history.push(`/budget/edit/${notification.acao_id}`);
                  break;

                default:
                  return history.push(`/`);
                  break;
              }
            }}
          />
        ))
      ) : (
        <>
          <hr />
          <div>Você não tem nenhuma notificação </div>
        </>
      )}
      <ViewMore display={pages > page}>
        <button
          onClick={() => {
            dispatch(loadRequest(id, page + 1, notifications));
            setPage(page + 1);
          }}
          type="button"
        >
          Ver Mais
        </button>
      </ViewMore>
    </Content>
  );
};

export default SideNotification;
