import { Reducer } from 'redux';
import { ReportTypes, IReportsState } from './types';

const INITIAL_STATE: IReportsState = {
  schedules: null,
  loading: false,
  dateSelected: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
  monthSelected: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
  schedule_period: [],
  resume: {
    clientsWithFinishingConstructions: [],
    finishedContructionWithoutSchedule: [],
    finishingConstructions: [],
  },
  users: [],
  schedule: [],
  report: [],
  condition: [],
  period: [],
  type_maintenance: [],
  reportOnly: null,
  error: false,
  resumeMonth: {
    totalAgendadosAguardando: 0,
    totalAgendadosConcluidos: 0,
    totalAgendadosIncompletos: 0,
    totalDiasNaoAgendados: 0,
  },
  logs: null,
};

const reducer: Reducer<IReportsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportTypes.LIST_REQUEST:
      return { ...state, loading: true };
    case ReportTypes.LIST_SUCCCES:
      return {
        ...state,
        schedules: action.payload.schedules,
        loading: false,
      };
    case ReportTypes.LIST_FAILURE:
      return {
        ...state,
        schedules: null,
        loading: false,
      };
    case ReportTypes.SELECT_DATE:
      return {
        ...state,
        dateSelected: action.payload.selectedDate,
      };
    case ReportTypes.SELECT_MONTH:
      return {
        ...state,
        monthSelected: action.payload.selectedMonth,
      };
    case ReportTypes.OPTIONS_REQUEST:
      return { ...state };
    case ReportTypes.OPTIONS_SUCCCES:
      return {
        ...state,
        ...action.payload,
      };
    case ReportTypes.OPTIONS_FAILURE:
      return {
        ...state,
      };
    case ReportTypes.REPORT_REQUEST:
      return { ...state, id: action.payload.id, loading: true, error: false };
    case ReportTypes.REPORT_SUCCCES:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case ReportTypes.REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ReportTypes.RESUME_REQUEST:
      return { ...state, resumeMonth: action.payload.resumeMonth };
    case ReportTypes.RESUME_SUCCCES:
      return {
        ...state,
        ...action.payload,
      };
    case ReportTypes.RESUME_FAILURE:
      return {
        ...state,
      };
    case ReportTypes.LOGS_REQUEST:
      return { ...state, loading: true };
    case ReportTypes.LOGS_SUCCCES:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case ReportTypes.LOGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
