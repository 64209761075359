/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { resumeRequest, selectMonth } from '~/store/modules/report/actions';

import * as St from './styles';
import Day from './Day';

interface IProps {
  onClick?: (date: Moment) => void;
  hideCircles?: boolean;
  selectedDate?: string;
}

const Calendar: React.FC<IProps> = props => {
  const dispatch = useDispatch();
  const [month, setMonth] = useState(
    (props.selectedDate && moment(props.selectedDate)) || moment(),
  );
  const [weekDays] = useState(['D', 'S', 'T', 'Q', 'Q', 'S', 'S']);
  const [days, setDays] = useState<Moment[]>([]);

  const { schedules } = useSelector((state: IState) => state.report);

  useEffect(() => {
    const currentMonth = moment(month);
    const firstDay = moment(currentMonth).startOf('month').get('weekday');
    const lastDay = moment(currentMonth).endOf('month').get('date');
    const lastMonth = moment(currentMonth).subtract(1, 'month');
    const nextMonth = moment(currentMonth).add(1, 'month');
    const arrInit = Array.from({ length: firstDay }, (v, k) =>
      moment(lastMonth).set(
        'date',
        lastMonth.daysInMonth() - (firstDay - k - 1),
      ),
    );
    const arrConcat = Array.from({ length: lastDay }, (v, k) =>
      moment(currentMonth)
        .set('date', k + 1)
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0),
    );
    let arr: Moment[] = [...arrInit, ...arrConcat];
    const arrFinal = Array.from(
      { length: arr.length % 7 === 0 ? 0 : 7 - (arr.length % 7) },
      (v, k) => moment(nextMonth).set('date', k + 1),
    );
    arr = [...arrInit, ...arrConcat, ...arrFinal];
    setDays(arr);
  }, [month]);

  useEffect(() => {
    handleMonth();
  }, [month]);

  const handleMonth = () => {
    const date = moment(month).toISOString();
    dispatch(resumeRequest(date));
  };

  const handleClickMonth = (value: number) => {
    const currentMonth = moment(month).get('month');
    const newMonth = moment(month.set('month', currentMonth + value));
    dispatch(selectMonth(newMonth.toISOString()));
    setMonth(newMonth);
  };

  const renderDays = useMemo(() => {
    return days.map(day => <Day day={day} month={month} {...props} />);
  }, [days, schedules, month, props]);

  const renderMonth = useMemo(() => {
    return (
      <St.Month>
        <St.Left onClick={() => handleClickMonth(-1)} />
        <St.Label>
          {format(moment(month).toDate(), 'MMMM yyyy', {
            locale: pt,
          })}
        </St.Label>
        <St.Right onClick={() => handleClickMonth(1)} />
      </St.Month>
    );
  }, [month]);

  return (
    <>
      {renderMonth}
      <St.Container>
        {weekDays.map(curr => (
          <St.Day>{curr}</St.Day>
        ))}
        {renderDays}
      </St.Container>
    </>
  );
};

Calendar.defaultProps = {
  hideCircles: false,
  onClick: undefined,
  selectedDate: undefined,
};

export default Calendar;
