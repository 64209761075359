/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import {
  IStock,
  StockTypes,
  IInfo,
  IProduct,
  ISaveProductRequest,
  IResponseOptions,
} from './types';

export const listStockRequest = (date: string | undefined) =>
  action(StockTypes.LIST_REQUEST, { date });

export const listStockSuccess = (stocks: IStock[]) =>
  action(StockTypes.LIST_SUCCCES, { stocks });

export const listStockFailure = () => action(StockTypes.LIST_FAILURE);

export const totalStockRequest = () => action(StockTypes.TOTAL_STOCK_REQUEST);

export const totalStockSuccess = (totalStock: IInfo) =>
  action(StockTypes.TOTAL_STOCK_SUCCCES, { totalStock });

export const totalStockFailure = () => action(StockTypes.TOTAL_STOCK_FAILURE);

export const productStockRequest = (id: number) =>
  action(StockTypes.PRODUCT_STOCK_REQUEST, { id });

export const productStockSuccess = (product: IProduct) =>
  action(StockTypes.PRODUCT_STOCK_SUCCCES, { product });

export const productStockFailure = () =>
  action(StockTypes.PRODUCT_STOCK_FAILURE);

export const saveProductStockRequest = (body: ISaveProductRequest) =>
  action(StockTypes.SAVE_PRODUCT_STOCK_REQUEST, { body });

export const saveProductStockSuccess = () =>
  action(StockTypes.SAVE_PRODUCT_STOCK_SUCCCES);

export const saveProductStockFailure = (errorSavingProduct: string) =>
  action(StockTypes.SAVE_PRODUCT_STOCK_FAILURE, { errorSavingProduct });

export const optionsRequest = (type: string[]) =>
  action(StockTypes.OPTIONS_REQUEST, { type });

export const optionsSuccess = (options: IResponseOptions) =>
  action(StockTypes.OPTIONS_SUCCCES, options);

export const optionsFailure = () => action(StockTypes.OPTIONS_FAILURE);

export const resetProductStock = () => action(StockTypes.RESET_PRODUCT_STOCK);

export const listSystemRequest = () => action(StockTypes.LIST_SYSTEM_REQUEST);

export const listSystemSuccess = (systems: IProduct[]) =>
  action(StockTypes.LIST_SYSTEM_SUCCCES, { systems });

export const listSystemFailure = () => action(StockTypes.LIST_SYSTEM_FAILURE);

export const listProductsRequest = () =>
  action(StockTypes.LIST_PRODUCTS_REQUEST);

export const listProductsSuccess = (products: IProduct[]) =>
  action(StockTypes.LIST_PRODUCTS_SUCCCES, { products });

export const listProductsFailure = () =>
  action(StockTypes.LIST_PRODUCTS_FAILURE);
