import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconDate, IconDelete, IconLogoWD } from '~/assets/Icons';
import { Button } from '~/components';
import ReportService from '~/services/ReportService';
import {
  listSchedulesRequest,
  optionsRequest,
} from '~/store/modules/report/actions';
import { Alert, Content, Img, LoadingCircle, Container } from './styles';

interface IItem {
  id: number;
  onClick: () => void;
  descricao: string;
  cliente: {
    id: number | null;
    nome: string | null;
  };
  setOpenModal: (value: boolean) => void;
  setClient: (value: number | null) => void;
}

const Item: FC<IItem> = ({
  cliente,
  descricao,
  id,
  setOpenModal,
  setClient,
  onClick,
}) => {
  const dispatch = useDispatch();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await ReportService.getOffClient(id);
      setLoading(false);
      dispatch(listSchedulesRequest());
      dispatch(optionsRequest(['report', 'resume']));
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Content>
      <div>
        <div
          style={{
            flexDirection: 'column',
            padding: 0,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <b>{cliente?.nome}</b>
          <p>{descricao}</p>
        </div>
        <span>
          <button
            type="button"
            onClick={() => {
              setOpenModal(true);
              onClick();
              if (cliente.id) setClient(cliente.id);
            }}
          >
            <IconDate />
          </button>
          <button type="button" onClick={() => setVisibleAlert(true)}>
            <IconDelete color="#323232" />
          </button>
        </span>
      </div>
      <Alert state={visibleAlert}>
        {loading ? (
          <Container>
            <LoadingCircle />
            <Img>
              <IconLogoWD />
            </Img>
          </Container>
        ) : (
          <>
            <span>
              <b>Deseja realmente deixar de acompanhar esse Cliente</b>
              <p>
                Cliente: {cliente?.nome} - {descricao}
              </p>
            </span>
            <div>
              <Button onClick={handleConfirm}>Confirmar</Button>
              <Button styles={1} onClick={() => setVisibleAlert(false)}>
                Cancelar
              </Button>
            </div>
          </>
        )}
      </Alert>
    </Content>
  );
};

export default Item;
