import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 65%;
  height: 100%;
  flex-direction: column;

  padding: 5px;

  // TablePage
  div {
    padding: 0;
  }
`;
