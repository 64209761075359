import { Modal, Button } from '~/components';
import { DeletDiv, ButtonStyle, Content } from './styles';

interface IProps {
  onClickDel: any;
  onClickCancel: any;
  item: any;
}

const ModalDelete: React.FC<IProps> = ({
  item,
  onClickDel,
  onClickCancel,
}: IProps) => {
  return (
    <Modal>
      <DeletDiv>
        <b>Tem certeza que deseja remover o {item}?</b>
        <p>Esta ação não poderá ser desfeita.</p>

        <Content>
          <ButtonStyle>
            <Button onClick={onClickCancel}>Cancelar</Button>
          </ButtonStyle>
          <Button onClick={onClickDel}>Deletar</Button>
        </Content>
      </DeletDiv>
    </Modal>
  );
};

export default ModalDelete;
