import { useHistory } from 'react-router';
import { IconAlert } from '~/assets/Icons';
import { Modal, Button } from '~/components';
import { ContainerModal, ContentModal } from './styles';

interface IProps {
  onClick: () => void;
}

const ModalErro: React.FC<IProps> = ({ onClick }: IProps) => {
  const history = useHistory();
  return (
    <Modal>
      <ContainerModal>
        <IconAlert />
        <b>Ops... Algo deu errado!</b>
        <p>Tem certeza que deseja continuar?</p>

        <ContentModal>
          <Button styles={1} onClick={onClick}>
            Continuar
          </Button>
          <Button onClick={() => history.goBack()}>Voltar</Button>
        </ContentModal>
      </ContainerModal>
    </Modal>
  );
};

export default ModalErro;
