import React, { useEffect, useState, useCallback } from 'react';
import { Badge, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  Page,
  Loading,
  TablePage,
  Modal,
  Button,
  ModalDefault,
  Input,
  Select,
  Return,
} from '~/components';

import isFormatCPF from '~/utils/format/isFormatCPF';
import isFormatPhone from '~/utils/format/isFormatPhone';
import ButtonCustomer from '~/components/ButtonCustomer';
import CustomersService from '~/services/CustomersService';
import isFormatCNPJ from '~/utils/format/isFormatCNPJ';
import {
  IconClose,
  IconDelete,
  IconEdit,
  IconFilter,
  IconSearch,
} from '~/assets/Icons';
import { BtEdit } from '~/components/TableActions/styles';
import { CheckPermission } from '~/utils/checkPermission';
import {
  BtStatus,
  BtTrash,
  ButtonHeaderTable,
  CleanFilter,
  ContainerActionTable,
  ContainerTable,
  ContentDelete,
  ContentModal,
  ContentModalFilter,
  DataNull,
  DeletDiv,
  DivList,
  Header,
  HeaderModalFilter,
  HeaderTable,
  ListContent,
  Row,
  Status,
  Table,
  Title,
  TitleModal,
} from './styles';

const Customer: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalResponse, setIsVisibleModalResponse] = useState(false);
  const [isVisibleModalFilters, setIsVisibleModalFilters] = useState(false);
  const [isVisibleFilters, setIsVisibleFilters] = useState(false);

  const [searchInput, setSearchInput] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [messageDelete, setMessageDelete] = useState('');
  const [customerType, setCustomerType] = useState(true);
  const [idCustomerEdit, setIdCustomerEdit] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [doc, setDoc] = useState<1 | 2>(1);
  const [time, setTime] = useState(0);

  const [inputName, setInputName] = useState('');
  const [inputDocument, setInputDocument] = useState('');
  const [inputCorporateName, setInputCorporateName] = useState('');
  const [inputCity, setInputCity] = useState('');
  const [currentInputStatus, setCurrentInputStatus] = useState('');
  const [inputStatus, setInputStatus] = useState<{
    id: 0 | 1 | undefined;
    descricao: string;
  }>({ id: undefined, descricao: '' });

  const document = customerType
    ? { cpf: inputDocument.replace(/[^\d]+/g, '') }
    : { cnpj: inputDocument.replace(/[^\d]+/g, '') };
  const status = inputStatus.id === undefined ? {} : { status: inputStatus.id };

  const cleanFilters = () => {
    setInputName('');
    setInputDocument('');
    setInputCorporateName('');
    setInputCity('');
    setInputStatus({ id: undefined, descricao: inputStatus.descricao });
    setCurrentInputStatus('');
    setIsVisibleFilters(false);
  };

  window.onclick = (e: any) => {
    console.log(searchInput);

    const { target } = e;
    const alvMenu = target.classList.contains('searchCustomer');
    const paiMenu = target.closest('.searchCustomer');
    if (!alvMenu && !paiMenu) {
      console.log(searchInput);
      setSearchList([]);
    }
    return null;
  };

  const loadRequest = async () => {
    const response = await CustomersService.listCustomersFilter({
      doc,
      nome: inputName,
      ...document,
      razaoSocial: inputCorporateName,
      cidade: inputCity,
      ...status,
    });
    setDataSource(response.result);
    setIsVisibleModalFilters(false);
    setIsVisibleFilters(true);
    setPage(response.info.page);
    setPages(response.info.pages);
  };

  const list = [
    {
      id: 1,
      descricao: 'Cadastro Completo',
    },
    {
      id: 0,
      descricao: 'Cadastro Incompleto',
    },
  ];

  const loadCustomers = async ({
    limit,
    page,
    doc,
  }: {
    limit: number;
    page: number;
    doc: 1 | 2;
  }) => {
    setLoading(true);
    const res = await CustomersService.listCustomers({
      limit,
      page,
      doc,
    });

    setDataSource(res.result);
    setIsVisibleFilters(false);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  const history = useHistory();

  useEffect(() => {
    loadCustomers({ limit, page, doc });
  }, [limit, page, doc]);

  const columnsCPF = [
    {
      title: 'Nome',
      dataIndex: 'nome',
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      render: (e: any) => isFormatCPF(e),
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      render: (e: any) => isFormatPhone(e),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Localização',
      dataIndex: 'bairro',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '5vw',
      render: (e: any) => {
        return (
          <ContainerActionTable>
            <Tooltip
              placement="top"
              title={<span>{!e ? 'Incompleto' : 'Completo'}</span>}
            >
              <BtStatus>
                <Status type={!e} />
              </BtStatus>
            </Tooltip>
          </ContainerActionTable>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      width: '10vw',
      render: (e: any) => {
        const { id } = e;
        return (
          <ContainerActionTable>
            <BtEdit
              disabled={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'VISUALIZAR',
                })
              }
              locked={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'VISUALIZAR',
                })
              }
              onClick={() => {
                history.push(`/customer/view/${id}`);
              }}
            >
              <IconEdit />
            </BtEdit>
            <BtTrash
              disabled={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'EXCLUIR',
                })
              }
              locked={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'EXCLUIR',
                })
              }
              onClick={() => {
                setIdCustomerEdit(id);
                setIsVisibleModalDelete(true);
              }}
            >
              <IconDelete />
            </BtTrash>
          </ContainerActionTable>
        );
      },
    },
  ];

  const columnsCNPJ = [
    {
      title: 'Nome',
      dataIndex: 'nome',
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      render: (e: any) => isFormatCNPJ(e),
    },
    {
      title: 'Telefone',
      dataIndex: 'telefone',
      render: (e: any) => isFormatPhone(e),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
    },
    {
      title: 'Localização',
      dataIndex: 'baiiro',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '5vw',
      render: (e: any) => {
        return (
          <ContainerActionTable>
            <Tooltip
              placement="top"
              title={<span>{!e ? 'Incompleto' : 'Completo'}</span>}
            >
              <BtStatus>
                <Status type={!e} />
              </BtStatus>
            </Tooltip>
          </ContainerActionTable>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      width: '10vw',
      render: (e: any) => {
        const { id } = e;
        return (
          <ContainerActionTable>
            <BtEdit
              disabled={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'VISUALIZAR',
                })
              }
              locked={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'VISUALIZAR',
                })
              }
              onClick={() => {
                history.push(`/customer/view/${id}`);
              }}
            >
              <IconEdit />
            </BtEdit>
            <BtTrash
              disabled={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'EXCLUIR',
                })
              }
              locked={
                !CheckPermission({
                  module: 'CLIENTES',
                  permission: 'EXCLUIR',
                })
              }
              onClick={() => {
                setIdCustomerEdit(id);
                setIsVisibleModalDelete(true);
              }}
            >
              <IconDelete />
            </BtTrash>
          </ContainerActionTable>
        );
      },
    },
  ];

  const deleteRequest = async () => {
    try {
      setLoading(true);
      setMessageDelete(
        await CustomersService.deleteCustomerById(idCustomerEdit),
      );
    } catch {
      setIsVisibleModalErro(true);
    }
    loadCustomers({ limit, page, doc });
    setLoading(false);
  };

  const searchRequest = useCallback(async () => {
    const response = await CustomersService.listCustomersFilter({
      nome: searchInput,
    });
    setSearchList(response.result);
  }, [searchInput]);

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  let typingTimer: any = null;
  const doneTypingInterval = 1000;

  const done = () => {
    searchRequest();
  };

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModalDelete && (
        <Modal>
          <DeletDiv>
            <TitleModal>
              <button
                type="button"
                onClick={() => setIsVisibleModalDelete(false)}
              >
                <IconClose />
              </button>
            </TitleModal>
            <b>Tem certeza que deseja excluir esse Contato?</b>
            <ContentDelete>
              <Button styles={1} onClick={() => setIsVisibleModalDelete(false)}>
                Não
              </Button>
              <Button
                onClick={async () => {
                  setIsVisibleModalDelete(false);
                  setIsVisibleModalResponse(true);
                  deleteRequest();
                }}
              >
                Sim
              </Button>
            </ContentDelete>
          </DeletDiv>
        </Modal>
      )}
      {isVisibleModalResponse && (
        <ModalDefault
          success={messageDelete === 'Deletado com sucesso'}
          onClick={() => setIsVisibleModalResponse(false)}
        >
          <ContentModal>{messageDelete}</ContentModal>
        </ModalDefault>
      )}
      {isVisibleModalFilters && (
        <Modal>
          <>
            <TitleModal>
              <button
                type="button"
                onClick={() => setIsVisibleModalFilters(false)}
              >
                <IconClose />
              </button>
            </TitleModal>
            <HeaderModalFilter>
              <span>
                <b>Filtros</b>
                <p>{customerType ? 'Pessoa' : 'Empresa'}</p>
              </span>
              <CleanFilter onClick={() => cleanFilters()}>
                Limpar Filtro
              </CleanFilter>
            </HeaderModalFilter>
            <ContentModalFilter>
              <Input
                label="Nome"
                value={inputName}
                onChange={e => setInputName(e.target.value)}
              />
              {customerType ? (
                <>
                  <Input
                    label="CPF"
                    value={inputDocument}
                    onChange={e =>
                      setInputDocument(isFormatCPF(e.target.value))
                    }
                  />
                </>
              ) : (
                <>
                  <Input
                    label="CNPJ"
                    value={inputDocument}
                    onChange={e =>
                      setInputDocument(isFormatCNPJ(e.target.value))
                    }
                  />
                  <Input
                    label="Razão Social"
                    value={inputCorporateName}
                    onChange={e => setInputCorporateName(e.target.value)}
                  />
                </>
              )}
              <Input
                label="Cidade"
                value={inputCity}
                onChange={e => setInputCity(e.target.value)}
              />

              <Select
                lista={list}
                onClickList={item => {
                  setInputStatus(item);
                  setCurrentInputStatus(item.descricao);
                }}
                label="Status"
                currentValue={currentInputStatus}
              />
              <span>
                <Button onClick={loadRequest}>Aplicar</Button>
              </span>
            </ContentModalFilter>
          </>
        </Modal>
      )}
      <Page>
        <Return />
        <Header>
          <Title>Clientes</Title>
          <Row>
            <div className="searchCustomer">
              <Input
                icon={<IconSearch />}
                onChange={e => {
                  setSearchInput(e.target.value);
                  if (time) clearTimeout(time);
                  if (e.target.value) {
                    typingTimer = setTimeout(done, doneTypingInterval);
                    setTime(typingTimer);
                  }
                }}
              />
              {searchInput.length > 2 && (
                <DivList>
                  {searchList.map((item: any) => (
                    <ListContent
                      key={item.id}
                      onClick={() => {
                        history.push(`/customer/view/${item.id}`);
                      }}
                    >
                      {item.nome}
                    </ListContent>
                  ))}
                </DivList>
              )}
            </div>
            <Badge dot={isVisibleFilters} color="#30B4E5">
              <button
                type="button"
                onClick={() => setIsVisibleModalFilters(true)}
              >
                <IconFilter />
              </button>
            </Badge>
            <ButtonCustomer
              permissions={{
                permission: 'INCLUIR',
                module: 'CLIENTES',
              }}
              onClickPf={() => history.push('/customer/pf')}
              onClickPj={() => history.push('/customer/pj')}
            />
          </Row>
        </Header>
        <HeaderTable>
          <ButtonHeaderTable
            active={customerType}
            onClick={() => {
              setCustomerType(true);
              setDoc(1);
              setPage(1);
              setPages(0);
            }}
          >
            Pessoa
          </ButtonHeaderTable>
          <ButtonHeaderTable
            active={!customerType}
            onClick={() => {
              setCustomerType(false);
              setDoc(2);
              setPage(1);
              setPages(0);
            }}
          >
            Empresa
          </ButtonHeaderTable>
        </HeaderTable>
        <ContainerTable>
          <Table>
            {dataSource.length === 0 ? (
              <DataNull>
                <b>
                  Ops... parece que você ainda não possui nenhum cadastro de
                  clientes {customerType ? 'pessoa' : 'empresa'}.
                </b>
              </DataNull>
            ) : (
              <TablePage
                columns={customerType ? columnsCPF : columnsCNPJ}
                dataSource={dataSource}
                pagination={{
                  pageSize: limit,
                  total: pages * limit,
                  onChange: e => {
                    setPage(e);
                  },
                }}
                rowKey="id"
              />
            )}
          </Table>
        </ContainerTable>
      </Page>
    </>
  );
};

export default Customer;
