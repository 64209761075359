/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconAlert,
  IconDownload2,
  IconSuccess,
  IconUpload,
} from '~/assets/Icons';
import { ModalDefault } from '~/components';
import {
  getConstructionRequest,
  getConstructionSuccess,
} from '~/store/modules/construnction/actions';
import ConstructionsService from '~/services/ConstructionsService';
import { CheckPermission } from '~/utils/checkPermission';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { IState } from '~/store/modules/types';
import ModalApproval from './ModalApproval ';
import {
  Actions,
  ApprovalContainer,
  AprroveButton,
  ReproveButton,
  BoxContainer,
  ContainerBox,
  HeaderBox,
  ListFile,
  NoData,
  UploadContainer,
  // NoData,
} from './styles';

interface IProps {
  dataSource: any;
  user: any;
}

// interface IUpdateFiles {
//   name: string;
//   path: string;
//   updateDate: string;
// }

const SwitchVisualDesing: React.FC<IProps> = ({ dataSource, user }: IProps) => {
  const [isVisibleModalApproval, setIsVisibleModalApproval] = useState(false);
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const [menssageModalSuccess, setMenssageModalSuccess] = useState('');
  const [handleImg, setHandleImg] = useState(0);
  const [permissionAction, setPermissionAction] = useState(false);

  const [imgArray, setImgArray] = useState<any>(
    dataSource?.projeto_visual?.files || [],
  );
  const [justification, setJustification] = useState('');

  const userId = useSelector((state: IState) => state.auth.data.account.id);

  const dispatch = useDispatch();

  const updateFiles = async (file: any[]) => {
    const formData = new FormData();
    formData.append('tab', '55');
    formData.append('obra', dataSource.id);
    formData.append('file', file[0]);

    try {
      const response = await ConstructionsService.uploadFile(formData);

      setImgArray((prevState: any) => {
        return [
          ...prevState,
          {
            status: null,
            ...response.upload,
            data_atualizacao: new Date(),
            justificativa: '',
          },
        ];
      });

      dispatch(
        getConstructionSuccess({
          ...dataSource,
          projeto_visual: {
            id: dataSource?.projeto_visual?.id,
            files: [
              ...dataSource?.projeto_visual?.files,
              {
                ...response.upload,
                status: null,
                data_atualizacao: new Date(),
                justificativa: '',
              },
            ],
          },
        }),
      );
    } catch {
      setIsVisibleModalError(true);
    }
  };

  // status: 0 | 1, justfication: string, index: number

  const approveFile = () => {
    dispatch(getConstructionRequest(dataSource.id));
    setImgArray(dataSource?.projeto_visual?.files);
  };

  const onDrop = useCallback(acceptedFiles => {
    updateFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  const getPermission = () => {
    const bodyTech = dataSource.corpo_tecnico;
    const keysTechs = Object.keys(bodyTech || {}).filter(curr => curr !== 'id');
    const ids = keysTechs.reduce(
      (acum: number[], curr) => {
        const tech = bodyTech[curr];
        if (tech.id && !acum.find(c => c === tech.id)) acum.push(tech.id);
        return acum;
      },
      [dataSource.responsavel.id],
    );
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    if (
      (!!DICT.find(key => key === (user || '').toUpperCase()) ||
        !!ids.find(id => userId === id)) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      })
    ) {
      setPermissionAction(true);
    } else {
      setPermissionAction(false);
    }
  };

  useEffect(() => {
    getPermission();
  }, []);

  const verifyPermissionActions = (): boolean => {
    if (dataSource.terminado) {
      return false;
    }
    if (!user) return false;
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    return (
      !!DICT.find(key => key === user.toUpperCase()) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      })
    );
  };

  const verifyPermission = (): boolean => {
    if (dataSource.terminado) {
      return false;
    }
    if (!user) return false;
    const bodyTech = dataSource.corpo_tecnico;
    const keysTechs = Object.keys(bodyTech || {}).filter(curr => curr !== 'id');
    const ids = keysTechs.reduce(
      (acum: number[], curr) => {
        const tech = bodyTech[curr];
        if (tech.id && !acum.find(c => c === tech.id)) acum.push(tech.id);
        return acum;
      },
      [dataSource.responsavel.id],
    );
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    if (
      (!!DICT.find(key => key === user.toUpperCase()) ||
        !!ids.find(id => userId === id)) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      })
    ) {
      return true;
    }

    return false;
  };

  return (
    <>
      {isVisibleModalError && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalError(false)}
        >
          Ops, algo deu errado
        </ModalDefault>
      )}
      {isVisibleModalApproval && (
        <ModalApproval
          typeProject="Visual"
          onChange={(e: any) => setJustification(e)}
          onClickConfirm={async () => {
            try {
              await ConstructionsService.updateProjectVisual({
                id: dataSource.projeto_visual.id,
                uploadId: handleImg,
                justification,
                status: 0,
              });
              // setMenssageModalSuccess('Reprovado com sucesso!');
              // setIsVisibleModalSuccess(true);
              approveFile();
            } catch {
              setIsVisibleModalError(true);
            }
            setIsVisibleModalApproval(false);
          }}
          onClickCancel={() => setIsVisibleModalApproval(false)}
        />
      )}
      {isVisibleModalSuccess && (
        <ModalDefault
          success
          onClick={() => {
            setIsVisibleModalSuccess(false);
            setMenssageModalSuccess('');
          }}
        >
          {menssageModalSuccess}
        </ModalDefault>
      )}
      {/* <NoData>
        <IconAlert />
        <span>
          <b>Ainda não há nenhum Projeto Visual publicado!</b>
        </span>
      </NoData> */}
      <ContainerBox>
        <BoxContainer>
          <HeaderBox>Upload de Arquivos</HeaderBox>
          {!verifyPermission() ? (
            <UploadContainer disabled>
              <>
                <IconUpload />
                <p>
                  Arraste seu arquivo aqui ou escolha selecionar dentro do seu
                  dispositivo.
                </p>
              </>
            </UploadContainer>
          ) : (
            <UploadContainer {...getRootProps()}>
              <>
                <input {...getInputProps()} />
                {isDragReject && (
                  <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                )}
                {isDragActive ? (
                  <p>Solte os arquivos aqui ... </p>
                ) : (
                  <>
                    <IconUpload />
                    <p>
                      Arraste seu arquivo aqui ou escolha selecionar dentro do
                      seu dispositivo.
                    </p>
                  </>
                )}
              </>
            </UploadContainer>
          )}
        </BoxContainer>
        <BoxContainer width>
          {imgArray.length > 0 ? (
            <>
              <HeaderBox>Arquivos</HeaderBox>
              {imgArray.map((element: any) => (
                <ListFile action>
                  <div>
                    <b>
                      <u>{element?.nome}</u>
                    </b>
                    <p>
                      Publicado
                      {format(
                        new Date(element?.data_atualizacao),
                        ` dd/MM/yyyy 'às' HH:mm`,
                        {
                          locale: pt,
                        },
                      )}
                    </p>
                  </div>
                  <Actions>
                    {element.status === null ? (
                      verifyPermissionActions() ? (
                        <>
                          <AprroveButton
                            onClick={async () => {
                              try {
                                await ConstructionsService.updateProjectVisual({
                                  id: dataSource.projeto_visual.id,
                                  uploadId: element.id,
                                  justification: 'aprovado',
                                  status: 1,
                                });
                                approveFile();
                              } catch {
                                setIsVisibleModalError(true);
                              }
                            }}
                          >
                            Aprovar
                          </AprroveButton>
                          <ReproveButton
                            onClick={() => {
                              setIsVisibleModalApproval(true);
                              setHandleImg(element.id);
                            }}
                          >
                            Reprovar
                          </ReproveButton>
                        </>
                      ) : null
                    ) : element.status === 1 ? (
                      <>
                        <ApprovalContainer>
                          <IconSuccess />
                          Aprovado
                        </ApprovalContainer>
                      </>
                    ) : (
                      <>
                        <ApprovalContainer>
                          <IconAlert />
                          <b>Reprovado</b>
                        </ApprovalContainer>
                      </>
                    )}
                    <span>
                      <a
                        target="_blank"
                        // download
                        href={element?.path || element?.url}
                        rel="noreferrer"
                      >
                        <IconDownload2 />
                      </a>
                    </span>
                  </Actions>
                </ListFile>
              ))}
            </>
          ) : (
            <NoData>
              <IconAlert />
              <span>
                <b>Ainda não há nenhum Projeto Visual publicado!</b>
              </span>
            </NoData>
          )}
        </BoxContainer>
      </ContainerBox>
    </>
  );
};

export default SwitchVisualDesing;
