/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { IconAlert, IconDownload2, IconUpload } from '~/assets/Icons';
import { getConstructionSuccess } from '~/store/modules/construnction/actions';
import ConstructionsService from '~/services/ConstructionsService';
import { ModalDefault } from '~/components';
import { CheckPermission } from '~/utils/checkPermission';
import { IState } from '~/store/modules/types';
import {
  BoxContainer,
  ContainerBox,
  HeaderBox,
  ListFile,
  NoData,
  ScrollDiv,
  UploadContainer,
} from './styles';

interface IProps {
  dataSource: any;
  user: any;
}

const SwitchQuantitative: React.FC<IProps> = ({ dataSource, user }: IProps) => {
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);

  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );
  const userId = useSelector((state: IState) => state.auth.data.account.id);

  const [permission, setPermission] = useState(false);
  const dispatch = useDispatch();

  const updateFiles = async (file: any) => {
    const formData = new FormData();
    formData.append('tab', '57');
    formData.append('obra', dataSource.id);
    formData.append('file', file[0]);

    try {
      const response = await ConstructionsService.uploadFile(formData);

      dispatch(
        getConstructionSuccess({
          ...dataSource,
          quantitativo: {
            ...dataSource?.quantitativo,
            files: [
              ...dataSource?.quantitativo?.files,
              {
                nome: file[0].name,
                path: response.upload.url,
                data_atualizacao: file[0].lastModified,
              },
            ],
          },
        }),
      );
    } catch {
      setIsVisibleModalErro(true);
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    updateFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  const verifyPermission = (): boolean => {
    if (dataSource.terminado) {
      return false;
    }
    if (!user) return false;
    const bodyTech = dataSource.corpo_tecnico;
    const keysTechs = Object.keys(bodyTech || {}).filter(curr => curr !== 'id');
    const ids = keysTechs.reduce(
      (acum: number[], curr) => {
        const tech = bodyTech[curr];
        if (tech.id && !acum.find(c => c === tech.id)) acum.push(tech.id);
        return acum;
      },
      [dataSource.responsavel.id],
    );
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    if (
      (!!DICT.find(key => key === user.toUpperCase()) ||
        !!ids.find(id => userId === id)) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      })
    ) {
      setPermission(true);
    }

    return false;
  };

  useEffect(() => {
    verifyPermission();
  }, []);

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          onClick={() => setIsVisibleModalErro(false)}
          success={false}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {/* <NoData>
        <IconAlert />
        <span>
          <b>Ainda não há nenhum Arquivo Quantitativo publicado!</b>
        </span>
      </NoData> */}
      <ContainerBox>
        <BoxContainer>
          <HeaderBox>Upload de Arquivos</HeaderBox>
          {!permission ? (
            <UploadContainer disabled>
              <>
                <IconUpload />
                <p>
                  Arraste seu arquivo aqui ou escolha selecionar dentro do seu
                  dispositivo.
                </p>
              </>
            </UploadContainer>
          ) : (
            <UploadContainer {...getRootProps()}>
              <>
                <input {...getInputProps()} />
                {isDragReject && (
                  <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                )}
                {isDragActive ? (
                  <p>Solte os arquivos aqui ... </p>
                ) : (
                  <>
                    <IconUpload />
                    <p>
                      Arraste seu arquivo aqui ou escolha selecionar dentro do
                      seu dispositivo.
                    </p>
                  </>
                )}
              </>
            </UploadContainer>
          )}
          {/* <UploadContainer {...getRootProps()}>
          <>
            <input {...getInputProps()} />
            {isDragReject && (
              <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
            )}
            {isDragActive ? (
              <p>Solte os arquivos aqui ... </p>
            ) : (
              <>
                <IconUpload />
                <p>
                  Arraste seu arquivo aqui ou escolha selecionar dentro do seu
                  dispositivo.
                </p>
              </>
            )}
          </>
        </UploadContainer> */}
        </BoxContainer>
        <BoxContainer>
          {dataSource.quantitativo.files.length > 0 ? (
            <>
              <HeaderBox>Orçamentos</HeaderBox>
              <ScrollDiv>
                {dataSource?.quantitativo?.files.map((item: any) => (
                  <ListFile action>
                    <div>
                      <b>
                        <u>{item.nome}</u>
                      </b>
                      <p>
                        Publicado
                        {format(
                          new Date(item.data_atualizacao),
                          ` dd/MM/yyyy 'às' HH:mm`,
                          {
                            locale: pt,
                          },
                        )}
                      </p>
                    </div>
                    <span>
                      <a
                        target="_blank"
                        // download
                        href={item?.path || item?.url}
                        rel="noreferrer"
                      >
                        <IconDownload2 />
                      </a>
                    </span>
                  </ListFile>
                ))}
              </ScrollDiv>
            </>
          ) : (
            <NoData>
              <IconAlert />
              <span>
                <b>Ainda não há nenhum Quantitativo publicado!</b>
              </span>
            </NoData>
          )}
        </BoxContainer>
        <div />
      </ContainerBox>
    </>
  );
};

export default SwitchQuantitative;
