/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { api } from '~/services/api';
import { getBudgetSuccess } from '~/store/modules/budget/actions';

interface IAprproveBudget {
  id: string | undefined;
  telefone: string | undefined;
}
interface IAprproveToken {
  id: string | undefined;
  token: string | undefined;
}

interface IUpdateBudget {
  idBudget?: string | number | undefined;
  budgetDescription?: string | undefined;
  budgetNote?: string | undefined;
  payment?: string | undefined;
  labor?: number | undefined;
  discount?: number | undefined;
  idResponsible?: number | undefined;
}

export function useBudgetRequest() {
  const history = useHistory();
  const dispatch = useDispatch();

  const createBudget = async () => {
    const result = await api.post('budget-api/budget', {
      descricao: '',
      situacaoId: 1,
    });
    const { id } = result.data;
    history.push(`/budget/edit/${id}`);
  };

  const insertQuotation = async (
    idBudget: string | undefined,
    idCurrancy: number | undefined,
    QuotationValue: number | undefined,
  ) => {
    await api.post(`budget-api/budget/${idBudget}/currency/${idCurrancy}`, {
      cotacao: QuotationValue,
    });
  };

  const deleteItem = async (
    idBudget: string | undefined,
    idItem: number | undefined,
  ) => {
    await api.delete(`budget-api/budget/${idBudget}/item/${idItem}`);
  };

  const insertItem = async (
    idBudget: string | undefined,
    idItem: string | undefined,
    idCurrancy: number | undefined,
    price: number | undefined,
    QuotationValue: number | undefined,
  ) => {
    await api.post(`/budget-api/budget/${idBudget}/item/${idItem}`, {
      preco: price,
      cotacao: QuotationValue,
      moedaId: idCurrancy,
    });
  };

  const insertCustomer = async (
    idBudget: string | undefined,
    idCustomer: number | undefined,
  ) => {
    await api.post(`/budget-api/budget/${idBudget}/customer/${idCustomer}`);
  };

  const putProduct = async (
    idBudget: string | undefined,
    productId: number | undefined,
    productType: string | undefined,
    productQuantity: any,
  ) => {
    await api.put(`budget-api/budget/${idBudget}/item/${productId}`, {
      tipo: productType,
      quantidade: parseInt(productQuantity, 10) || 1,
    });
  };

  const putBudget = async ({
    idBudget,
    budgetDescription,
    budgetNote,
    labor,
    discount,
    idResponsible,
    payment,
  }: IUpdateBudget) => {
    await api.patch(`budget-api/budget/${idBudget}`, {
      descricao: budgetDescription,
      maoDeObra: labor,
      observacao: budgetNote,
      responsavel: idResponsible,
      desconto: discount,
      pagamento: payment,
    });
  };

  const deleteBudget = async (idBudget: number | undefined) => {
    await api.delete(`budget-api/budget/${idBudget}`);
    history.push('/budget');
  };

  const handleConfirm = async (id: any) => {
    history.push(`/budget/confirm/${id}`);
  };

  const handleFinish = async (id: any, precoTotal: number) => {
    await api.post(`/budget-api/budget/${id}/finish`, {
      precoTotal,
    });
    history.push(`/budget/finish/${id}`);
  };

  const handleEdit = async (id: any) => {
    const request = await api.post(`/budget-api/budget/${id}/edit`);
    dispatch(getBudgetSuccess(request.data.result));
    history.push(`/budget/edit/${id}`);
  };

  const requestReview = async (id: string | undefined, Descricao: string) => {
    await api.post(`/budget-api/budget/${id}/review`, {
      descricao: Descricao,
    });
  };

  const approveBudget = async ({ id, telefone }: IAprproveBudget) => {
    await api.post(`/budget-api/proposeToken`, {
      id,
      telefone,
    });
  };

  const approveToken = async ({ id, token }: IAprproveToken) => {
    await api.patch(`/budget-api/confirmToken/${id}`, {
      token,
    });
  };

  // const approveBudget = async (id: string | undefined) => {
  //   await api.post(`/budget-api/budget/${id}/waiting`);
  // };

  const proposalViewed = async (id: string | undefined) => {
    await api.post(`/budget-api/budget/${id}/reading`);
  };

  const sendEmail = async (
    subject: string,
    message: string,
    destiny: string,
    idBudget: string,
  ) => {
    const bodyFormData = new FormData();
    bodyFormData.append('assunto', subject);
    bodyFormData.append('mensagem', message);
    bodyFormData.append('destino', destiny);
    bodyFormData.append('orcamento', idBudget);
    await api.post(`/notification-api/notification/email`, bodyFormData);
  };

  const sendPdfByEmail = async (id: string | undefined, Email: string) => {
    const response = await api.post(`budget-api/budget/proposal/${id}/email`, {
      email: Email,
    });

    return response;
  };

  const generatePdf = async (id: any) => {
    await api.post(`budget-api/budget/${id}/pdf`);
  };

  return {
    approveBudget,
    approveToken,
    createBudget,
    deleteBudget,
    insertItem,
    insertCustomer,
    insertQuotation,
    putBudget,
    putProduct,
    proposalViewed,
    deleteItem,
    handleConfirm,
    handleFinish,
    handleEdit,
    requestReview,
    sendEmail,
    sendPdfByEmail,
    generatePdf,
  };
}
