import styled from 'styled-components';

export const Child = styled.div`
  display: flex;
  width: 100%;
  height: auto;
`;

export const Container = styled.header`
  width: 100%;
  height: 10vh;
  padding: 1vh 2vh;
  background: ${({ theme }) => theme.Colors.Gray.Secondary};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const Logo = styled('img')<{ fix: boolean }>`
  width: auto;
  height: 7vh;

  @media (max-width: 480px) {
    width: 40vw;
    height: auto;
  }

  ${props => props.fix && 'margin-left: 5vw'}
`;

export const HeaderButton = styled('button')<{
  state: boolean;
  notification: boolean;
}>`
  display: flex;
  align-items: center;
  width: auto;
  height: 4vh;
  margin: 2vh;
  font-size: 4vh;
  color: ${({ theme }) => theme.Colors.White};

  ${props => props.state && 'opacity: 0'}
  ${props => props.notification && 'svg {color: #30b4e5;}'}

  sup {
    border: none;
    color: #30b4e5;
    box-shadow: none;
    span {
      margin: 1px;
      font-weight: 700;
    }
  }
`;

export const ButtonSair = styled.button`
  margin: 2vh;
  color: ${({ theme }) => theme.Colors.White};
`;

export const UserButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  width: 7vh;
  height: 7vh;
  margin: 2vh;
  font-size: 4vh;
  color: ${({ theme }) => theme.Colors.White};
  border: 2px solid;
  border-radius: 50%;
  @media (max-width: 480px) {
    height: 5vh;
    width: 5vh;
  }
`;

export const Mobile = styled.div`
  @media (max-width: 480px) {
    display: none;
  }
`;

export const MenuDropdown = styled.div`
  position: absolute;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 3px;
  padding: 9px;
  top: 9vh;
  right: 0px;
  width: max-content;
  button {
    width: 100%;
    color: #323232;
  }
`;
