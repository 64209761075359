import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LinkMenu = styled(NavLink)<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  height: 130px;
  width: 130px;
  padding: 20px 10px;
  margin: 15px;

  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.Colors.Gray.Main};
  text-decoration: none;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.Colors.Gray.Main};
    margin-bottom: 12px;
  }

  ${props =>
    props.disabled &&
    `
      background: #dcf2fb;
      color: rgba(0, 0, 0, 0.5);
      cursor: not-allowed;
  `}
`;
