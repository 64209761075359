/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import {
  ISchedule,
  ReportTypes,
  IResponseOptions,
  IReportResponse,
  IResumeMonth,
} from './types';

export const listSchedulesRequest = () => action(ReportTypes.LIST_REQUEST);

export const listSchedulesSuccess = (schedules: ISchedule) =>
  action(ReportTypes.LIST_SUCCCES, { schedules });

export const listSchedulesFailure = () => action(ReportTypes.LIST_FAILURE);

export const selectDate = (selectedDate: string) =>
  action(ReportTypes.SELECT_DATE, { selectedDate });

export const selectMonth = (selectedMonth: string) =>
  action(ReportTypes.SELECT_MONTH, { selectedMonth });

export const optionsRequest = (type: string[]) =>
  action(ReportTypes.OPTIONS_REQUEST, { type });

export const optionsSuccess = (options: IResponseOptions) =>
  action(ReportTypes.OPTIONS_SUCCCES, options);

export const optionsFailure = () => action(ReportTypes.OPTIONS_FAILURE);

export const reportRequest = (id: number) =>
  action(ReportTypes.REPORT_REQUEST, { id });

export const reportSuccess = (report: IReportResponse) =>
  action(ReportTypes.REPORT_SUCCCES, { reportOnly: report });

export const reportFailure = () => action(ReportTypes.REPORT_FAILURE);

export const resumeRequest = (month: string) =>
  action(ReportTypes.RESUME_REQUEST, { month });

export const resumeSuccess = (resumeMonth: IResumeMonth) =>
  action(ReportTypes.RESUME_SUCCCES, { resumeMonth });

export const resumeFailure = () => action(ReportTypes.RESUME_FAILURE);

export const logsRequest = (id: string | number) =>
  action(ReportTypes.LOGS_REQUEST, { id });

export const logsSuccess = (response: IResponseOptions) =>
  action(ReportTypes.LOGS_SUCCCES, response);

export const logsFailure = () => action(ReportTypes.LOGS_FAILURE);
