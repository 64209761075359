import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 330px;
  height: 100%;
  flex-direction: column;

  padding: 5px;
`;
