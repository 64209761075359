/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { IconClose, IconSuccess } from '~/assets/Icons';
import { Modal } from '~/components';
import { IAgendamentos } from '~/store/modules/report/types';

import { Header, Content, Scroll } from './styles';

interface IProps {
  onClick: () => void;
  schedules?: IAgendamentos[];
  selected?: IAgendamentos;
}

const ModalHistoric: React.FC<IProps> = ({
  onClick,
  schedules,
  selected,
}: IProps) => {
  const history = useHistory();
  const renderSchedules = useMemo(() => {
    return schedules?.map(curr => {
      const existReport = curr.status_relatorio.id === 1;
      return (
        <Content>
          <div>
            <IconSuccess />{' '}
            {moment(curr.periodo.data_inicio).format('DD/MM/YYYY')} -{' '}
            {curr.periodo.descricao}
          </div>
          {existReport ? (
            <button
              type="button"
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                history.push({
                  pathname: '/maintenance-report-exec',
                  state: {
                    id: curr.relatorio_id,
                  },
                });
              }}
            >
              Ver
            </button>
          ) : null}
        </Content>
      );
    });
  }, [history, schedules]);

  return (
    <Modal heigth="70%">
      <>
        <Header>
          <div>
            <b>{selected?.tecnico.nome}</b>
            <p>{selected?.obra.descricao}</p>
          </div>
          <button type="button" onClick={onClick}>
            <IconClose />
          </button>
        </Header>
        <Scroll>{renderSchedules}</Scroll>
      </>
    </Modal>
  );
};

ModalHistoric.defaultProps = {
  schedules: [],
  selected: undefined,
};

export default ModalHistoric;
