/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment';

export const useUrlParams = (): any => {
  const url = window.location.search;
  const urlSearch = new URLSearchParams(url);
  const params = Object.fromEntries(urlSearch.entries());

  return params;
};

export const getMonths = (start: any, end: any): string[] => {
  return Array.from({
    length: moment(end).diff(moment(start), 'month') + 1,
  }).map((_, index) => moment(start).add(index, 'month').format('MM/YYYY'));
};

export const getPercentDailyAwaiting = (
  constructionsStart: any,
  constructionsEnd: any,
  stageStart: any,
): string | number => {
  const daysTotal = moment(constructionsEnd).diff(
    moment(constructionsStart),
    'days',
  );

  const daysAwaiting = moment(stageStart).diff(
    moment(constructionsStart),
    'days',
  );

  if (daysAwaiting === 0) return 0;

  const result = (daysAwaiting / daysTotal) * 100;

  if (result > 100) return 100;
  if (result < 0) return 0;

  return result.toFixed(2);
};

export const getPercentDailyDuration = (
  constructionsStart: any,
  constructionsEnd: any,
  daysDuration: any,
): string | number => {
  const daysTotal = moment(constructionsEnd).diff(
    moment(constructionsStart),
    'days',
  );

  const result = (daysDuration / daysTotal) * 100;

  if (result > 100) return 100;
  if (result < 0) return 0;

  return result.toFixed(2);
};

export const getPercentMothly = (start: any, end: any): string | number => {
  const monthsTotal = moment(end).diff(moment(start), 'months');
  const monthsTraveled = moment().diff(moment(start), 'months');

  const result = (monthsTraveled / monthsTotal) * 100;

  if (result > 100) return 100;
  if (result < 0) return 0;

  return result.toFixed(2);
};
