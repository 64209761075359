import { call, put } from 'redux-saga/effects';
import jwt from 'jsonwebtoken';
import { api } from '~/services/api';
import ProfilesService from '~/services/ProfilesService';

import { loadSuccess, loadFailure } from './actions';
import { IPropsRequest } from './types';

interface IPropsLoad {
  email: string;
  password: string;
}

function loginRequest({ email, password }: IPropsLoad) {
  return new Promise((resolve, reject) => {
    try {
      const login: any = async () => {
        const request = await api.post('/user-api/user/login', {
          email,
          password,
        });

        const token = jwt.decode(request.data.token);
        api.defaults.headers['api-token'] = request.data.token;
        const name = request.data.user.nome.split(' ') || '';
        const listPemission = await ProfilesService.listMyPermission();
        const arrayPermission: any[] = [];
        listPemission.result.forEach((element: any, indexModule: number) => {
          Object.entries(element).forEach((permissions, index) => {
            if (
              permissions[0] !== 'modulo' &&
              permissions[0] !== 'moduloId' &&
              permissions[1] === 1
            ) {
              arrayPermission.push({
                id: indexModule + index,
                modulo: element.modulo.toUpperCase(),
                permissao: permissions[0].toUpperCase(),
              });
            }
          });
        });
        const result = {
          accessToken: request.data.token,
          account: {
            firstName: `${name[0]}`,
            lastName: `${name.length > 1 ? name[name.length - 1] : ''}`,
            email: email || '',
            photo: false,
            id: token?.sub,
          },
          permissions: arrayPermission,
        };

        sessionStorage.setItem('token', JSON.stringify(result));
        sessionStorage.setItem('accessToken', request.data.token);
        return result;
      };
      resolve(login());
    } catch (error) {
      reject(error);
    }
  });
}

export function* load(action: IPropsRequest): any {
  try {
    const { email, password } = action.payload;
    const token = { email, password };
    const request = yield call(loginRequest, token);
    yield put(loadSuccess(request, true));
  } catch (err) {
    yield put(loadFailure());
  }
}
