import React from 'react';
import { Switch } from 'react-router-dom';

import Login from '~/pages/Login';
import Home from '~/pages/Home';
import Stock from '~/pages/Stock';
import Product from '~/pages/Stock/Product';
import Vendor from '~/pages/Stock/Vendor';
import System from '~/pages/Stock/System';
import SaveSystem from '~/pages/Stock/SaveSystem';
import Budget from '~/pages/Budget';
import BudgetEdit from '~/pages/Budget/Edit';
import BudgetConfirm from '~/pages/Budget/Confirm';
import BudgetFinish from '~/pages/Budget/Finish';
import Revisores from '~/pages/Reviewers';
import Proposal from '~/pages/Proposal';
import Customer from '~/pages/Customer';
import CustomerCreate from '~/pages/Customer/Create';
import CustomerView from '~/pages/Customer/View';
import Users from '~/pages/Users';
import UserCreate from '~/pages/Users/Create';
import UserView from '~/pages/Users/View';
import Notification from '~/pages/Notifications';
import NotificationEdit from '~/pages/Notifications/Create';
import Recovery from '~/pages/Login/Recovery';
import RecoveryPassword from '~/pages/Login/RecoveryPassword';
import ConstructionDiary from '~/pages/ConstructionDiary';
import DiaryCreate from '~/pages/ConstructionDiary/Create';
import DiaryView from '~/pages/ConstructionDiary/View';
import MaintenanceReport from '~/pages/MaintenanceReport';
import MaintenanceReportExec from '~/pages/MaintenanceReportExec';
import MaintenanceReportLogs from '~/pages/MaintenanceReportLogs';
import RedefinePassword from '~/pages/RedefinePassword';

import Route from './Route';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <Route path="/recovery" exact component={Recovery} />
      <Route path="/recovery/password" exact component={RecoveryPassword} />
      <Route path="/home" exact component={Home} isPrivate />
      <Route path="/password" exact component={RedefinePassword} isPrivate />
      <Route
        idModule="ORÇAMENTO"
        path="/budget"
        exact
        component={Budget}
        isPrivate
      />
      <Route
        idModule="ORÇAMENTO"
        path="/budget"
        exact
        component={Budget}
        isPrivate
      />
      <Route
        idModule="ESTOQUE DE PRODUTOS"
        path="/stock"
        exact
        component={Stock}
        isPrivate
      />
      <Route
        idModule="ESTOQUE DE PRODUTOS"
        path="/stock/system"
        exact
        component={System}
        isPrivate
      />
      <Route
        idModule="ESTOQUE DE PRODUTOS"
        path="/stock/system/save"
        exact
        component={SaveSystem}
        isPrivate
      />
      <Route
        idModule="ESTOQUE DE PRODUTOS"
        path="/stock/product"
        exact
        component={Product}
        isPrivate
      />
      <Route
        idModule="ESTOQUE DE PRODUTOS"
        path="/stock/vendor"
        exact
        component={Vendor}
        isPrivate
      />
      <Route
        idModule="ORÇAMENTO"
        path="/budget/edit/:id"
        exact
        component={BudgetEdit}
        isPrivate
      />
      <Route
        idModule="ORÇAMENTO"
        path="/budget/confirm/:id"
        exact
        component={BudgetConfirm}
        isPrivate
      />
      <Route
        idModule="ORÇAMENTO"
        path="/budget/finish/:id"
        exact
        component={BudgetFinish}
        isPrivate
      />
      <Route
        idModule="REVISORES"
        path="/reviewers"
        exact
        component={Revisores}
        isPrivate
      />
      <Route path="/proposal/:uuid" exact component={Proposal} />
      <Route
        idModule="CLIENTES"
        path="/customer"
        exact
        component={Customer}
        isPrivate
      />
      <Route
        idModule="CLIENTES"
        path="/customer/:type"
        exact
        component={CustomerCreate}
        isPrivate
      />
      <Route
        idModule="CLIENTES"
        path="/customer/view/:id"
        exact
        component={CustomerView}
        isPrivate
      />
      <Route
        idModule="USUÁRIOS"
        path="/users"
        exact
        component={Users}
        isPrivate
      />
      <Route
        idModule="USUÁRIOS"
        path="/user/view/:id"
        exact
        component={UserView}
        isPrivate
      />
      <Route
        idModule="USUÁRIOS"
        path="/user/:type"
        exact
        component={UserCreate}
        isPrivate
      />
      <Route
        idModule="DIÁRIO DE OBRAS"
        path="/construction"
        exact
        component={ConstructionDiary}
        isPrivate
      />
      <Route
        idModule="DIÁRIO DE OBRAS"
        path="/construction/create"
        exact
        component={DiaryCreate}
        isPrivate
      />
      <Route
        idModule="DIÁRIO DE OBRAS"
        path="/construction/view/:type"
        exact
        component={DiaryView}
        isPrivate
      />
      <Route path="/notifications" exact component={Notification} isPrivate />
      <Route
        path="/notification/:type"
        exact
        component={NotificationEdit}
        isPrivate
      />
      <Route
        idModule="RELATÓRIO DE MANUTENÇÃO"
        path="/maintenance-report"
        exact
        component={MaintenanceReport}
        isPrivate
      />
      <Route
        idModule="RELATÓRIO DE MANUTENÇÃO"
        path="/maintenance-report-exec"
        exact
        component={MaintenanceReportExec}
        isPrivate
      />
      <Route
        idModule="RELATÓRIO DE MANUTENÇÃO"
        path="/maintenance-report-log"
        exact
        component={MaintenanceReportLogs}
        isPrivate
      />
    </Switch>
  );
};

export default Routes;
