/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, memo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { IResultByDateByTechnicians } from '~/store/modules/report/types';
import { selectDate } from '~/store/modules/report/actions';

import * as St from './styles';

interface IProps {
  day: Moment;
  month: Moment;
  onClick?: (date: Moment) => void;
  hideCircles?: boolean;
  selectedDate?: string;
}

const Day: React.FC<IProps> = ({
  day,
  month,
  onClick,
  hideCircles,
  selectedDate,
}) => {
  const dispatch = useDispatch();
  const [scheduledDay, setScheduledDay] =
    useState<IResultByDateByTechnicians>();
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState(false);

  const { schedules } = useSelector((state: IState) => state.report);

  useEffect(() => {
    handleDay();
  }, [day, schedules]);

  useEffect(() => {
    const disabled = day.format('MM/YYYY') !== month.format('MM/YYYY');
    setDisabled(disabled);
  }, [day, month]);

  useEffect(() => {
    if (selectedDate) {
      const selected =
        day.format('DD/MM/YYYY') === moment(selectedDate).format('DD/MM/YYYY');

      setSelected(selected);
    }
  }, [day, selectedDate]);

  const handleDay = () => {
    if (day && !hideCircles) {
      setScheduledDay(undefined);
      const scheduledDay = schedules?.result.resultByDateByTechnicians.find(
        ({ date }) => {
          return (
            moment(new Date(date)).format('DD/MM/YYYY') ===
            day.format('DD/MM/YYYY')
          );
        },
      );
      setScheduledDay(scheduledDay);
    }
  };

  const handleClick = () => {
    if (!disabled) {
      const dateSelected = day
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0);
      if (onClick) {
        onClick(dateSelected);
      } else {
        dispatch(selectDate(dateSelected.toISOString()));
      }
    }
  };

  return (
    <St.Day
      day
      hideCircles={hideCircles}
      key={day.format('DD/MM/YYYY')}
      onClick={handleClick}
      disabled={disabled}
    >
      {selected ? <St.Selected /> : null}
      <St.Label>{day ? day.format('D') : null}</St.Label>
      {scheduledDay && !hideCircles ? (
        <St.Circle color={scheduledDay.color} />
      ) : null}
    </St.Day>
  );
};

Day.defaultProps = {
  hideCircles: false,
  onClick: undefined,
  selectedDate: undefined,
};

export default memo(Day);
