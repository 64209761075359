import React, { FocusEvent } from 'react';

import { Container } from './styles';

interface IProps {
  checked?: any;
  disabled?: boolean;
  onChange?: ((e: FocusEvent<HTMLInputElement>) => void) | null;
}

const defaultProps = {
  checked: undefined,
  onChange: null,
  disabled: false,
};

const Checkbox: React.FC<IProps> = ({
  checked,
  onChange,
  disabled,
}: IProps) => {
  return (
    <Container disable={disabled}>
      <input
        disabled={disabled}
        checked={checked}
        onChange={(e: FocusEvent<HTMLInputElement>) => {
          if (onChange) onChange(e);
        }}
        type="checkbox"
      />
    </Container>
  );
};

export default Checkbox;

Checkbox.defaultProps = defaultProps;
