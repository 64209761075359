/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import isFormatCPF from '~/utils/format/isFormatCPF';
import isFormatPhone from '~/utils/format/isFormatPhone';
import isFormatCNPJ from '~/utils/format/isFormatCNPJ';
import CustomersService from '~/services/CustomersService';
import ImgDefault from '~/assets/Images/ImgDefault.svg';
import {
  Page,
  Loading,
  PageHeader,
  Avatar,
  TablePage,
  Button,
} from '~/components';
import {
  IconAddress,
  IconEdit,
  IconPeople,
  IconBudget,
  IconAdd,
  IconAddFinancial,
  IconDelete,
} from '~/assets/Icons';
import isFormatCEP from '~/utils/format/isFormatCEP';
import BudgetsService from '~/services/BudgetsService';
import { BtContainer, UserContainer, Table } from '~/pages/Budget/styles';
import { BtEdit } from '~/components/TableActions/styles';
import { CheckPermission } from '~/utils/checkPermission';
import { situacaoENUM } from '~/constants/situacao';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import {
  Content,
  Img,
  Container,
  Header,
  ButtonHeader,
  ContentAddFinancialResponsible,
  ButtonAddFinancialResponsible,
  ButtonTextAddFinancialResponsible,
} from './styles';
import { BtTrash } from '../styles';

const Customer: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [customerType, setCustomerType] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [dataSource, setDataSource] = useState({
    id: 0,
    nome: '',
    email: '',
    razaoSocial: '',
    cpf: '',
    cnpj: '',
    logradouro: '',
    bairro: '',
    numero: 0,
    estado: '',
    cidade: '',
    cep: '',
    telefone: '',
    complemento: '',
    nome_financeiro: '',
    telefone_financeiro: '',
    email_financeiro: '',
    segmento: {
      id: '',
      descricao: '',
    },
  });
  const [budgets, setBudgets] = useState([]);
  const [clientOrder, setClientOrder] = useState<1 | 2 | undefined>(undefined);
  const [updateDate, setUpdateDate] = useState<1 | 2 | undefined>(undefined);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const loadCustomers = useCallback(async () => {
    setLoading(true);
    const res = await CustomersService.getCustomerById(parseFloat(id));
    setDataSource(res);
    setImgUrl(res?.imagem?.fileUrl ? res?.imagem?.fileUrl : '');
    setLoading(false);
    if (res.cpf) {
      setCustomerType(true);
    }
  }, [id]);

  const loadBudgets = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    const resBudget = await BudgetsService.listBudgets({
      cpf: dataSource.cpf,
      cnpj: dataSource.cnpj,
      limit,
      page,
      clientOrder,
      updateDate,
    });
    setBudgets(resBudget.result);
    setPage(resBudget.info.page);
    setPages(resBudget.info.pages);
  };

  useEffect(() => {
    if (dataSource.cnpj || dataSource.cpf)
      loadBudgets({
        limit,
        page,
      });
  }, [limit, page, clientOrder, updateDate, dataSource]);

  useEffect(() => {
    loadCustomers();
  }, [id, loadCustomers]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Assunto',
      dataIndex: 'descricao',
      key: 'descricao',
      sorter: (a: any, b: any) => a.descricao.length - b.descricao.length,
    },
    {
      title: 'Status',
      dataIndex: 'situacao',
      key: 'situacao',
      render: (e: any) => situacaoENUM(e.nome),
    },
    {
      title: 'Ultima Alteração',
      dataIndex: 'dataAtualizacao',
      key: 'dataAtualizacao',
      render: (e: any) =>
        `${
          e && format(new Date(e), "dd 'de' MMMM 'às' HH:mm'", { locale: pt })
        }`,
      sorter: (a: any, b: any, c: any) => {
        if (c === 'ascend') {
          setUpdateDate(1);
        }
        if (c === 'descend') {
          setUpdateDate(2);
        }
        return a;
      },
    },
    {
      width: '10vh',
      key: 'avatar',
      sorter: (a: any, b: any, c: any) => {
        if (c === 'ascend') {
          setClientOrder(1);
        }
        if (c === 'descend') {
          setClientOrder(2);
        }
        return a;
      },
      render: (e: any) => (
        <UserContainer>
          <Avatar name={e!.cliente?.nome} />
        </UserContainer>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      width: '8vw',
      render: (e: any) => (
        <BtContainer>
          <BtEdit
            disabled={
              !CheckPermission({
                module: 'ORÇAMENTO',
                permission: 'GERENCIAR',
              })
            }
            locked={
              !CheckPermission({
                module: 'ORÇAMENTO',
                permission: 'GERENCIAR',
              })
            }
            onClick={() => {
              e.situacao.id !== 1
                ? history.push(`/budget/finish/${e.id}`)
                : history.push(`/budget/edit/${e.id}`);
            }}
          >
            <IconEdit />
          </BtEdit>
        </BtContainer>
      ),
    },
  ];

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }
  return (
    <>
      <Page>
        <PageHeader
          title={
            <Header>
              <b>{dataSource.nome}</b>
              <p>{customerType ? 'Pessoa' : 'Empresa'}</p>
            </Header>
          }
          button={
            <ButtonHeader>
              <IconEdit />
              Editar contato
            </ButtonHeader>
          }
          onClick={() => history.push(`/customer/${dataSource.id}`)}
          permission={{ module: 'CLIENTES', permission: 'GERENCIAR' }}
        />
        <Container>
          <Img src={imgUrl || ImgDefault} />
          <Content>
            <span>
              <IconPeople />
              <b>Detalhes do contato</b>
            </span>
            <p>
              Nome:<b>{dataSource.nome}</b>
            </p>
            {customerType ? (
              <p>
                CPF:<b>{isFormatCPF(dataSource.cpf)}</b>
              </p>
            ) : (
              <>
                <p>
                  CNPJ:<b>{isFormatCNPJ(dataSource.cnpj)}</b>
                </p>
                <p>
                  Razão Social: <b>{dataSource.razaoSocial}</b>
                </p>
                <p>
                  Segmento: <b>{dataSource.segmento.descricao}</b>
                </p>
              </>
            )}
            <p>
              E-mail: <b>{dataSource.email}</b>
            </p>
            <p>
              Telefone: <b>{isFormatPhone(dataSource.telefone)}</b>
            </p>
            {dataSource.cnpj && (
              <ContentAddFinancialResponsible>
                <ButtonAddFinancialResponsible
                  onClick={() => history.push(`/customer/${dataSource.id}`)}
                >
                  <IconAdd />
                  <ButtonTextAddFinancialResponsible>
                    Adicionar Responsável Financeiro
                  </ButtonTextAddFinancialResponsible>
                </ButtonAddFinancialResponsible>
              </ContentAddFinancialResponsible>
            )}
          </Content>

          <Content>
            <span>
              <IconAddress />
              <b>Endereço</b>
            </span>
            <p>
              CEP: <b>{isFormatCEP(dataSource.cep)}</b>
            </p>
            <p>
              Logradouro: <b>{dataSource.logradouro}</b>
            </p>
            <p>
              Número: <b>{dataSource.numero}</b>
            </p>
            <p>
              Complemento: <b>{dataSource.complemento}</b>
            </p>
            <p>
              Bairro: <b>{dataSource.bairro}</b>
            </p>
            <p>
              Cidade: <b>{dataSource.cidade}</b>
            </p>
            <p>
              Estado: <b>{dataSource.estado}</b>
            </p>
          </Content>
        </Container>

        {dataSource.nome_financeiro && (
          <>
            <Container>
              <Img src={imgUrl || ImgDefault} />

              <Content>
                <span>
                  <IconPeople />
                  <b>Responsável Financeiro</b>
                </span>
                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <span>
                    <IconPeople />
                    <b>Responsável Financeiro</b>
                  </span>
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <BtTrash>
                      <IconDelete />
                    </BtTrash>
                  </div>
                </div> */}
                <p>
                  Nome:<b>{dataSource.nome_financeiro}</b>
                </p>

                <p>
                  E-mail: <b>{dataSource.email_financeiro}</b>
                </p>
                <p>
                  Telefone:{' '}
                  <b>{isFormatPhone(dataSource.telefone_financeiro)}</b>
                </p>
              </Content>
            </Container>
          </>
        )}

        <Container>
          <Content>
            <span>
              <IconBudget />
              <b>Orçamentos</b>
            </span>

            {budgets.length === 0 ? (
              <h1>Sem budget</h1>
            ) : (
              <Table>
                <TablePage
                  columns={columns}
                  dataSource={budgets}
                  pagination={{
                    showSizeChanger: false,
                    pageSize: limit,
                    total: pages * limit,
                    onChange: e => {
                      setPage(e);
                    },
                  }}
                  rowKey="id"
                />
              </Table>
            )}
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default Customer;
