import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconEdit, IconSearch } from '~/assets/Icons';
import { Button, Input, Loading, Page, Return, TablePage } from '~/components';
import NotificationsService from '~/services/NotificationsService';
// import VisibleForPermissions from '~/components/VisibleForPermission';
import {
  BtEdit,
  Content,
  DivList,
  Header,
  ListContent,
  Row,
  Title,
} from './styles';

const Notification: React.FC = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [time, setTime] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [searchList, setSearchList] = useState([]);
  const history = useHistory();

  console.log(searchList);

  const columns = [
    {
      title: 'Identificação',
      dataIndex: 'descricao',
      key: 'descricao',
    },
    {
      title: 'Ações',
      key: 'action',
      width: '8vw',
      dataIndex: 'id',
      render: (e: number) => (
        <BtEdit
          onClick={() => {
            history.push(`/notification/${e}`);
          }}
        >
          <IconEdit />
        </BtEdit>
      ),
    },
  ];

  useEffect(() => {
    const loadNotication = async () => {
      setLoading(true);
      const response = await NotificationsService.listTemplate({
        limit,
        page,
      });
      setDataSource(response.result);
      setPages(response.info.pages);
      setPage(response.info.page);
      setLoading(false);
    };
    loadNotication();
  }, [limit, page]);

  const searchRequest = async () => {
    const response = await NotificationsService.listTemplate({
      descricao: searchInput,
    });
    setSearchList(response.result);
  };

  let typingTimer: any = null;
  const doneTypingInterval = 1000;

  const done = () => {
    searchRequest();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Page>
      <Return />
      <Header>
        <Title>Edição de Notificação</Title>
        <Row>
          <Input
            icon={<IconSearch />}
            onChange={e => {
              setSearchInput(e.target.value);
              if (time) clearTimeout(time);
              if (e.target.value) {
                typingTimer = setTimeout(done, doneTypingInterval);
                setTime(typingTimer);
              }
            }}
          />
          {searchInput.length > 2 && (
            <DivList>
              {searchList.map((item: any) => (
                <ListContent
                  key={item.id}
                  onClick={() => {
                    history.push(`/notification/${item.id}`);
                  }}
                >
                  {item.descricao}
                </ListContent>
              ))}
            </DivList>
          )}
          <span>
            <Button
              responsiveness
              // locked={
              //   !CheckPermission({
              //     module: 'USUARIOS',
              //     permission: 'INCLUSAO',
              //   })
              // }
              // disabled={
              //   !CheckPermission({
              //     module: 'USUARIOS',
              //     permission: 'INCLUSAO',
              //   })
              // }
              onClick={() => history.push('/notification/create')}
            >
              + Adicionar Notificação
            </Button>
          </span>
        </Row>
      </Header>
      {/* <PageHeader
        title="Edição de Notificação"
        button="+ Adicionar Notificação"
        onClick={() => history.push('/notification/create')}
        permission={{ module: 'CLIENTES', permission: 'INCLUSAO' }}
      /> */}
      {/* <VisibleForPermissions modules="Notification" permissions="VISUALIZACAO"> */}
      <Content>
        <TablePage
          columns={columns}
          dataSource={dataSource}
          pagination={{
            showSizeChanger: false,
            pageSize: limit,
            total: pages * limit,
            onChange: e => {
              setPage(e);
            },
          }}
          rowKey="id"
        />
      </Content>
      {/* </VisibleForPermissions> */}
    </Page>
  );
};

export default Notification;
