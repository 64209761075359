import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconReturn } from '~/assets/Icons';
import { Container } from './styles';

const Return: React.FC = () => {
  const history = useHistory();

  return (
    <Container
      onClick={() => {
        if (history.length > 1) {
          history.goBack();
        } else {
          history.replace('/home');
        }
      }}
      type="button"
    >
      <IconReturn />
    </Container>
  );
};

export default Return;
