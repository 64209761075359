import React from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { Container, Column } from './styles';

interface IProps {
  data: any;
}

const DropDown: React.FC<IProps> = ({ data }: IProps) => {
  return (
    <>
      <Container>
        <Column>
          <span>
            <p>
              Informações do Projeto: <b>{data.descricao}</b>
            </p>
          </span>
          <span>
            <p>Cliente:</p>
            <b>{data.cliente.nome}</b>
          </span>
          <span>
            <p>Endereço:</p>
            <b>
              {data.endereco.logradouro}, {data.endereco.numero}{' '}
              {data.endereco.complemento}
            </b>
            <b>
              {data.endereco.estado} - {data.endereco.cidade} -{' '}
              {data.endereco.bairro}
            </b>
          </span>
          <span>
            <p>Contato de Obra ({data.contato.empresa}):</p>
            <b>
              {data.contato.nome} - {data.contato.cargo} -{' '}
              {data.contato.telefone}
            </b>
            <b>{data.contato.email}</b>
          </span>
          <span>
            <p>
              Informações do Gerais: <b>{data.info}</b>
            </p>
          </span>
        </Column>
        <Column>
          <p>Previsão de execução:</p>
          <b>
            {format(new Date(data.inicio), 'dd/MM/yyyy', { locale: pt })} até
            {format(new Date(data.termino), ' dd/MM/yyyy', { locale: pt })}
          </b>
        </Column>
      </Container>
    </>
  );
};

export default DropDown;
