import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 25%;
  height: 100%;
  flex-direction: column;

  box-shadow: 0px 1px 4px 0px #00000047;

  padding: 5px;
`;

export const Content = styled.div`
  width: 98%;
  margin: 1%;
  box-shadow: 0px 1px 4px 0px #00000047;


  background: #fff;
  p {
    margin 0;
  }
  svg {
    height: 30px;
    margin-right: 10px;
  }
  div{
    padding: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Alert = styled('div')<{ state: boolean }>`
  background-color: rgba(255,153,0,0.25);

  padding: 0 10px !important;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => !props.state && `display: none !important;`}

  div {
    display: flex;
    flex-direction: column;

    button {
      max-height: 35px;
      margin 5px;
    }
  }
`;

export const Header = styled.div`
  /* height: 0; */
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    color: #30b4e5;
  }
  button {
    height: fit-content;
  }
`;
