import styled from 'styled-components';

export const Header = styled.div`
  margin: 3% 3% 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;

  span {
    height: fit-content;
    margin: 8px;

    sup {
      margin: 15px 10px;
    }
  }
  div {
    margin-right: 20px;
    button {
      width: fit-content;
    }
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
`;

export const DivList = styled.div`
  margin-top: 50px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  position: absolute;
  width: 22%;
  background: #ffffff;
  z-index: 900;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  a + a {
    border-top: 1px solid #30b4e5;
  }
`;

export const ListContent = styled('a')<{ disabled?: boolean }>`
  display: flex;
  padding: 10px;
  color: #323232;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;

  ${props =>
    props.disabled &&
    `pointer-events: none;
  cursor: not-allowed;`}
`;

export const StatusContainer = styled.div`
  display: flex;
`;

export const StatusContent = styled('div')<{ state?: boolean }>`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid #30b4e5;
  box-sizing: border-box;
  margin-right: 12px;

  ${props => props.state && 'background: #30b4e5 !important;'}
`;
