/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { loadSuccess } from '~/store/modules/auth/actions';
import { IState } from '~/store/modules/types';
import { api } from '~/services/api';
import { Loading } from '~/components';

interface IProps {
  component: any;
  path: string;
  isPrivate?: boolean;
  exact?: boolean;
  idModule?: string | null;
}

const defaultProps = {
  isPrivate: false,
  exact: false,
  idModule: null,
};

const RouteWrapper: React.FC<IProps> = ({
  component: Component,
  isPrivate,
  idModule,
  ...rest
}: IProps) => {
  const isLogged = useSelector((state: IState) => state.auth.isLogged);
  const listPermission = useSelector(
    (state: IState) => state.auth.data.permissions,
  );
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const result = sessionStorage.getItem('token');
  const token = sessionStorage.getItem('accessToken');
  useEffect(() => {
    const load = async () => {
      setLoading(true);
      if (result && token) {
        api.defaults.headers['api-token'] = token;
        dispatch(loadSuccess(JSON.parse(result), true));
      }
      setLoading(false);
    };
    load();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const findPermission = () => {
    if (idModule) {
      return listPermission?.find(element => element?.modulo === idModule);
    }
    return null;
  };

  if (idModule && !findPermission() && isLogged) {
    return <Redirect to="/home" />;
  }

  if (result) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  if (!isLogged && isPrivate) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

RouteWrapper.defaultProps = defaultProps;

export default RouteWrapper;
