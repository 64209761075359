/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { call, put } from 'redux-saga/effects';
import ReportService from '~/services/ReportService';

import {
  listSchedulesSuccess,
  listSchedulesFailure,
  optionsSuccess,
  optionsFailure,
  reportSuccess,
  reportFailure,
  resumeSuccess,
  resumeFailure,
  logsSuccess,
  logsFailure,
} from './actions';
import {
  IAtividade,
  IAtividadeResponse,
  IPropsLogRequest,
  IPropsOptionsRequest,
  IPropsReportRequest,
  IPropsResumeRequest,
} from './types';

export function* listSchedule(): any {
  try {
    const requestSchedule = async () => {
      const limit = 1000;
      const schedules = await ReportService.listSchedule({
        limit,
        page: 1,
      });

      return schedules;
    };

    const schedules = yield call(requestSchedule);
    yield put(listSchedulesSuccess(schedules));
  } catch (err) {
    yield put(listSchedulesFailure());
  }
}

export function* getOptionsReport(props: IPropsOptionsRequest): any {
  try {
    const types = props?.payload?.type || [];
    const productStock = async (type: string) => {
      const stock = await ReportService.listOptions({
        type,
      });
      return stock;
    };
    const promises = types.map(type => productStock(type));
    const data = yield Promise.all(promises);

    const dataReduced = data.reduce((acum: any, curr: any, index: number) => {
      acum[types[index]] = curr[types[index]];
      return acum;
    }, {});
    yield put(optionsSuccess(dataReduced));
  } catch (err) {
    yield put(optionsFailure());
  }
}

export function* getReport(props: IPropsReportRequest): any {
  try {
    const { id } = props?.payload || {};
    const reportApi = async () => {
      const stock = await ReportService.fetchReport(id);
      return stock;
    };
    const fetchActivity = async () => {
      const limit = 200;
      const activities = await ReportService.fetchActivity({
        limit,
        page: 1,
      });
      const promises = Array.from(
        { length: activities.info.pages - 1 },
        (v, k) => k + 2,
      ).reduce((acum: Promise<any>[], curr) => {
        acum.push(
          ReportService.fetchActivity({
            limit,
            page: curr,
          }),
        );
        return acum;
      }, []);
      const response = await Promise.all(promises);
      return [
        ...activities.result,
        ...response.reduce((acum, curr) => {
          acum = [...acum, ...curr.result];
          return acum;
        }, []),
      ];
    };

    const promises = [reportApi(), fetchActivity()];

    const [report, activities] = yield Promise.all(promises);

    const data = report?.atividades?.data || [];

    const activitiesReduced: IAtividade[] = activities.reduce(
      (acum: IAtividade[], curr: IAtividadeResponse) => {
        if (!acum.find(activity => activity.atividadeId === curr.id))
          acum.push({
            id: Math.floor(Math.random() * 9999999),
            atividadeDescricao: curr.descricao,
            atividadeId: curr.id,
            condicaoDescricao: '',
            condicaoId: '',
            periodoDescricao: '',
            periodoId: '',
            randomId: true,
          });
        return acum;
      },
      [...data],
    );

    report.atividades.data = activitiesReduced.sort(
      (prev, next) =>
        parseInt(prev.atividadeId, 10) - parseInt(next.atividadeId, 10),
    );

    yield put(reportSuccess(report));
  } catch (err) {
    yield put(reportFailure());
  }
}

export function* getResume(props: IPropsResumeRequest): any {
  try {
    const { month } = props?.payload || {};

    const mes = moment(month).get('month');

    const resumeApi = async () => {
      const resume = await ReportService.getResume({
        mes: `${mes + 1}`,
      });
      return resume;
    };

    const resume = yield call(resumeApi);
    yield put(
      resumeSuccess({
        totalAgendadosConcluidos: resume.info.totalAgendadosConcluidos,
        totalAgendadosIncompletos: resume.info.totalAgendadosIncompletos,
        totalAgendadosAguardando: resume.info.totalAgendadosAguardando,
        totalDiasNaoAgendados: resume.info.totalDiasNaoAgendados,
      }),
    );
  } catch (err) {
    yield put(resumeFailure());
  }
}

export function* getLogs(props: IPropsLogRequest): any {
  try {
    const logReport = async () => {
      const log = await ReportService.getLogs(props.payload.id);
      return log;
    };

    const log = yield call(logReport);
    yield put(logsSuccess(log));
  } catch (err) {
    yield put(logsFailure());
  }
}
