import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import isFormatCPF from '~/utils/format/isFormatCPF';
import isFormatPhone from '~/utils/format/isFormatPhone';
import UsersService from '~/services/UsersService';
import ImgDefault from '~/assets/Images/ImgDefault.svg';
import { Page, Loading, PageHeader } from '~/components';
import { IconDone, IconEdit, IconPeople } from '~/assets/Icons';

import VisibleForPermissions from '~/components/VisibleForPermission';
import {
  Content,
  Img,
  Container,
  Header,
  ButtonHeader,
  ContainerCheckbox,
} from './styles';
import ListCheckboxPermissions from './ListCheckbox';

const UserView: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [listModule, setListModule] = useState([]);
  const [dataSource, setDataSource] = useState({
    id: 0,
    nome: '',
    email: '',
    telefone: '',
    cpf: '',
    cargo: {
      descricao: '',
    },
  });
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const loadUser = useCallback(async () => {
    setLoading(true);
    const res = await UsersService.getUserById(parseFloat(id));
    setDataSource(res);
    setImgUrl(res.imagem?.fileUrl ? res.imagem?.fileUrl : '');
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadUser();
  }, [id, loadUser]);

  const loadPermissionForUser = useCallback(async () => {
    setLoading(true);
    const resModules = await UsersService.pemissionForUser(id);
    setListModule(resModules.result);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadPermissionForUser();
  }, [id, loadPermissionForUser]);

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }
  return (
    <>
      <Page>
        <PageHeader
          title={
            <Header>
              <b>{dataSource.nome}</b>
              <p>{dataSource?.cargo?.descricao}</p>
            </Header>
          }
          button={
            <ButtonHeader>
              <IconEdit />
              Editar contato
            </ButtonHeader>
          }
          onClick={() => history.push(`/user/${dataSource.id}`)}
          permission={{ module: 'USUÁRIOS', permission: 'GERENCIAR' }}
        />
        <Container>
          <Img src={imgUrl || ImgDefault} />
          <Content>
            <span>
              <IconPeople />
              <b>Detalhes do contato</b>
            </span>
            <p>
              Nome:<b>{dataSource.nome}</b>
            </p>
            <p>
              CPF:<b>{isFormatCPF(dataSource.cpf)}</b>
            </p>
            <p>
              E-mail: <b>{dataSource.email}</b>
            </p>
            <p>
              Telefone: <b>{isFormatPhone(dataSource.telefone)}</b>
            </p>
          </Content>
          <VisibleForPermissions modules="USUÁRIOS" permissions="GERENCIAR">
            <Content>
              <span>
                <IconDone />
                <b>Permissões </b>
              </span>
              {listModule.map((module: any) => (
                <ContainerCheckbox key={module.moduleId}>
                  <ListCheckboxPermissions module={module} />
                </ContainerCheckbox>
              ))}
            </Content>
          </VisibleForPermissions>
        </Container>
      </Page>
    </>
  );
};

export default UserView;
