import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  i {
    margin-top: 25px;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: -20px;
    color: ${({ theme }) => theme.Colors.Red};

    b {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  button {
    align-self: center;
    margin-top: 15px;
    width: 80%;
  }
`;
