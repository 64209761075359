import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  aling-items: center;
  justify-content: space-between;

  width: 98%;
  margin: 1%;
  padding: 5px;

  div {
    display: flex;
    flex-direction: column;
  }

  p {
    margin-bottom: 0;
  }
  b {
    font-size: 18px;
  }

  button {
    width: 30%;
    max-height: 100%;
    padding: 5px;
    white-space: break-spaces;
  }
`;
