/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { IconReturn } from '~/assets/Icons';

import { ButtonSelect, DivList, Icon, Label, ListContent } from './styles';

interface IProps {
  label?: string | null;
  disabled?: boolean;
  lista: { id: number; descricao?: string; nome?: string }[];
  onClickList: (item: any) => void;
  currentValue: string;
  fullWidth?: boolean;
}

const defaultProps = {
  label: null,
  disabled: false,
  fullWidth: false,
};

const Select: React.FC<IProps> = ({
  label,
  disabled,
  lista,
  onClickList,
  currentValue,
  fullWidth,
}: IProps) => {
  const [isVisibleData, setIsVisibleData] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const widthButton = ref.current?.offsetWidth;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  function addLabel() {
    if (label) {
      return <Label>{label}</Label>;
    }
    return null;
  }

  window.onclick = (e: any) => {
    const { target } = e;
    const alvMenu = target.classList.contains('select');
    const paiMenu = target.closest('.select');
    if (isVisibleData && !alvMenu && !paiMenu) {
      setIsVisibleData(false);
    }
    return null;
  };

  const handleClickOutside = (event: any) => {
    if (ref && !ref?.current?.contains(event.target)) {
      setIsVisibleData(false);
    }
  };

  return (
    <div
      ref={ref}
      className="select"
      style={{ position: 'relative', ...(fullWidth ? { width: '100%' } : {}) }}
    >
      {addLabel()}
      <ButtonSelect
        disabled={disabled}
        currentValue={!currentValue}
        onClick={() => setIsVisibleData(!isVisibleData)}
      >
        {currentValue}
        <Icon>
          <IconReturn />
        </Icon>
      </ButtonSelect>
      {isVisibleData && (
        <DivList width={widthButton}>
          {console.log(`lista`, lista)}
          {lista.map((item: any) => (
            <ListContent
              key={item.id}
              onClick={() => {
                onClickList(item);
                setIsVisibleData(false);
              }}
            >
              {item.descricao || item.nome}
            </ListContent>
          ))}
        </DivList>
      )}
    </div>
  );
};

Select.defaultProps = defaultProps;

export default Select;
