import { useState } from 'react';
import { Button } from '~/components';
import { CheckPermission } from '~/utils/checkPermission';
import { Container, MenuCustomer } from './styles';

interface IProps {
  onClickPf: () => void;
  onClickPj: () => void;
  permissions?: { permission: string; module: string } | undefined;
}

const defaultProps = {
  permissions: undefined,
};

const ButtonCustomer: React.FC<IProps> = ({
  onClickPf,
  onClickPj,
  permissions,
}: IProps) => {
  const [selectCustomer, setSelectCustomer] = useState(false);

  return (
    <Container>
      <Button
        onClick={() => setSelectCustomer(!selectCustomer)}
        disabled={permissions ? !CheckPermission(permissions) : false}
        locked={!CheckPermission(permissions!)}
      >
        Cadastrar novo cliente
      </Button>
      {selectCustomer && (
        <MenuCustomer>
          <button onClick={onClickPf} type="button">
            Pessoa (CPF)
          </button>
          <hr />
          <button onClick={onClickPj} type="button">
            Empresa (CNPJ)
          </button>
        </MenuCustomer>
      )}
    </Container>
  );
};

ButtonCustomer.defaultProps = defaultProps;

export default ButtonCustomer;
