/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';

import { Checkbox, TablePage, Modal, Button } from '~/components';
import { BtAlert, BtEdit, BtTrash } from '~/components/TableActions/styles';
import {
  IconDelete,
  IconEdit,
  IconRedAlert,
  IconYellowAlert,
} from '~/assets/Icons';
import { IAgendamentos, IResultByTech } from '~/store/modules/report/types';
import ReportService from '~/services/ReportService';

import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';

import { useDispatch, useSelector } from 'react-redux';
import {
  listSchedulesRequest,
  optionsRequest,
} from '~/store/modules/report/actions';
import { IState } from '~/store/modules/types';
import { useHistory } from 'react-router';

import ModalHistoric from '../ModalHistoric';
import * as St from './styles';

export interface ITechnical {
  id: number;
  name: string;
  schedules: ISchedule[];
}

export interface ISchedule {
  id: number;
  client: string;
  project: string;
  start: string;
  next: string;
  status: boolean;
}

export interface IProps {
  technical: IResultByTech;
  setId: (value: number | null) => void;
}

interface IModal extends ITypeModal {
  active: boolean;
}

interface ITypeModal {
  type?: 'success' | 'error';
}

const Colors = {
  red: '#DF0A24',
  yellow: '#F0D443',
};

const TechnicalList: React.FC<IProps> = ({ technical, setId }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [visibleTable, setVisibleTable] = useState(true);
  const [schedulesSize, setSchedulesSize] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [modal, setModal] = useState<IModal>({ active: false });
  const [schedulesModal, setSchedulesModal] = useState<IAgendamentos[]>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<IAgendamentos>();
  const userId = useSelector((state: IState) => state.auth.data.account.id);
  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  const getPermission = () => {
    return userPermissions.find(
      permission =>
        permission.permissao === 'TECNICODEMANUTENCAO' ||
        permission.permissao === 'COORDENADORDEMANUTENCAO',
    );
  };

  const [columns] = useState([
    {
      title: 'Cliente / Projeto',
      key: 'Piscina',
      render: (e: IAgendamentos) => (
        <p
          style={{
            color: `${
              (Number(e.tipo_manutencao.id) >= 3 && Colors.red) ||
              (Number(e.tipo_manutencao.id) === 2 && Colors.yellow)
            }`,
          }}
        >
          {e.endereco.rua} {e.endereco.numero} {e.endereco.complemento}{' '}
          {e.endereco.bairro} {e.endereco.cidade} {e.endereco.estado} /{' '}
          {e.cliente.nome}
        </p>
      ),
    },
    {
      title: 'Iniciou em',
      key: 'start',
      render: (e: IAgendamentos) =>
        e?.periodo?.data_inicio && (
          <p
            style={{
              color: `${
                (Number(e.tipo_manutencao.id) >= 3 && Colors.red) ||
                (Number(e.tipo_manutencao.id) === 2 && Colors.yellow)
              }`,
            }}
          >
            {format(
              new Date(e?.periodo?.data_inicio),
              "dd 'de' MMMM 'às' HH:mm'",
              {
                locale: pt,
              },
            )}
          </p>
        ),
    },
    {
      title: 'Próximo em',
      key: 'next',
      render: (e: IAgendamentos) =>
        e?.periodo?.proximo &&
        e?.periodo?.repetir_em > 0 && (
          <p
            style={{
              color: `${
                (Number(e.tipo_manutencao.id) >= 3 && Colors.red) ||
                (Number(e.tipo_manutencao.id) === 2 && Colors.yellow)
              }`,
            }}
          >
            {format(new Date(e?.periodo?.proximo), "dd 'de' MMMM 'às' HH:mm'", {
              locale: pt,
            })}
          </p>
        ),
    },
    {
      width: '10vh',
      title: 'Status',
      key: 'status',
      render: (e: IAgendamentos) => (
        <Checkbox
          onChange={async () => {
            if (!e.status_relatorio.id) {
              await ReportService.completeSchedule(e.id);
              dispatch(listSchedulesRequest());
              dispatch(optionsRequest(['report', 'resume']));
            }
          }}
          checked={!!e.status_relatorio.id}
        />
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      width: '10vw',
      render: (e: IAgendamentos) => (
        <>
          {(e.criadorId === userId ||
            e.tecnico.id === userId ||
            getPermission()) && (
            <>
              <BtTrash
                onClick={() => {
                  handleDelete(parseInt(e.id, 10));
                }}
              >
                <IconDelete />
              </BtTrash>

              <BtEdit
                onClick={() => {
                  handleUpdate(e.relatorio_id);
                }}
              >
                <IconEdit />
              </BtEdit>
            </>
          )}
        </>
      ),
    },
    {
      width: '10vh',
      title: 'Histórico',
      key: 'id',
      render: (e: IAgendamentos) => (
        <button
          type="button"
          onClick={() => handleGoToModal(e)}
          style={{
            color: `${
              (Number(e.tipo_manutencao.id) >= 3 && Colors.red) ||
              (Number(e.tipo_manutencao.id) === 2 && Colors.yellow)
            }`,
          }}
        >
          ver
        </button>
      ),
    },
    {
      width: '10vh',
      title: 'Logs',

      key: 'id',
      render: (e: IAgendamentos) => (
        <button
          type="button"
          onClick={() => handleGoToLogs(e)}
          style={{
            color: `${
              (Number(e.tipo_manutencao.id) >= 3 && Colors.red) ||
              (Number(e.tipo_manutencao.id) === 2 && Colors.yellow)
            }`,
          }}
        >
          ver
        </button>
      ),
    },
    {
      width: '10vh',
      title: '',

      key: 'id',
      render: (e: IAgendamentos) =>
        ((e.tipo_manutencao.id === '3' || e.tipo_manutencao.id === '4') && (
          <BtAlert>
            <IconRedAlert />
          </BtAlert>
        )) ||
        (e.tipo_manutencao.id === '2' && (
          <BtAlert>
            <IconYellowAlert />
          </BtAlert>
        )),
    },
  ]);

  const { schedules } = useSelector((state: IState) => state.report);

  useEffect(() => {
    setSchedulesSize(
      // eslint-disable-next-line prettier/prettier
      `${
        technical?.agendamentos.filter(curr => curr.status_relatorio.id).length
      } / ${technical?.agendamentos?.length}`,
    );
  }, [technical?.agendamentos]);

  const handleGoToModal = (e: IAgendamentos) => {
    if (schedules) {
      const {
        result: { resultByTech },
      } = schedules;
      const {
        cliente: { id: idClient },
        obra: { id: idObra },
        tecnico: { id: idTecnico },
      } = e;

      const works = resultByTech
        .filter(tech =>
          tech.agendamentos.find(schedule => schedule.obra.id === idObra),
        )
        .reduce((acum: IAgendamentos[], curr) => {
          acum = [...acum, ...curr.agendamentos];
          // acum.p
          return acum;
        }, [])
        .filter(schedule => schedule.obra.id === idObra);

      setSchedulesModal(works);
      setSelectedSchedule(e);
      return setVisibleModal(true);
      // }
    }
    setSchedulesModal([]);
    setSelectedSchedule(e);
    return setVisibleModal(true);
  };

  const handleGoToLogs = (e: IAgendamentos) => {
    history.push({
      pathname: '/maintenance-report-log',
      state: {
        id: e.relatorio_id,
        report: `${e.endereco.rua} ${e.endereco.numero} ${e.endereco.complemento} ${e.endereco.bairro} ${e.endereco.cidade} ${e.endereco.estado} / ${e.cliente.nome}`,
      },
    });
  };

  const handleDelete = async (id: number) => {
    try {
      await ReportService.deleteSchedule(id);
      handleClickModal({ type: 'success' });
    } catch (error) {
      handleClickModal({ type: 'error' });
    }
  };

  const handleUpdate = async (id: number) => {
    history.push({
      pathname: '/maintenance-report-exec',
      state: {
        id,
      },
    });
  };

  const handleClickModal = ({ type }: ITypeModal) => {
    setModal({ active: true, type });
  };

  const closeModal = () => {
    setModal({ active: false });
  };

  const renderModal = useMemo(() => {
    if (!modal.active || !modal.type) return;

    const MODAL_TITLE = {
      success: `Sucesso ao deletar agendamento`,
      error: `Erro ao deletar agendamento`,
    };

    // eslint-disable-next-line consistent-return
    return (
      <Modal>
        <St.ContentModal>
          <h3>{MODAL_TITLE[modal.type]}</h3>
          <St.ButtonStyle>
            <Button
              onClick={() => {
                if (modal.type === 'success') {
                  dispatch(listSchedulesRequest());
                  dispatch(optionsRequest(['report', 'resume']));
                } else {
                  closeModal();
                }
              }}
            >
              Entendi,{' '}
              {modal.type === 'success'
                ? 'voltar para relatórios'
                : 'tentar novamente'}
            </Button>
          </St.ButtonStyle>
        </St.ContentModal>
      </Modal>
    );
  }, [modal]);

  return (
    <>
      {renderModal}
      {visibleModal && (
        <ModalHistoric
          schedules={schedulesModal}
          selected={selectedSchedule}
          onClick={() => setVisibleModal(false)}
        />
      )}

      <St.Content onClick={() => setVisibleTable(!visibleTable)}>
        <div>
          <b>{technical.tecnico.nome}</b>
          <p>{technical.agendamentos.length} Agendamentos</p>
        </div>
        {schedulesSize}
      </St.Content>
      <St.Table state={!visibleTable}>
        <TablePage
          dataSource={technical.agendamentos}
          columns={columns}
          pagination={false}
        />
      </St.Table>
    </>
  );
};

export default TechnicalList;
