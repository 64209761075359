import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import store from '~/store';
import Routes from '~/routes';

import { defaultTheme } from '~/styles/Theme/styles';

import PageProvider from '~/context/PageContext';
import GlobalStyle from './styles/global';

const App: React.FC = () => {
  useEffect(() => {
    console.log('Versão');
  }, []);
  return (
    <Provider store={store}>
      <GlobalStyle />
      <BrowserRouter>
        <PageProvider>
          <ThemeProvider theme={defaultTheme}>
            <Routes />
          </ThemeProvider>
        </PageProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
