import { useEffect } from 'react';
import { IconAdd, IconClose, IconReturn, IconSearch } from '~/assets/Icons';
import { Avatar, Input, Modal, Page } from '~/components';
import { isFormatMoney } from '~/utils/format/isFormatMoney';
import {
  ModalAdd,
  ScrollDiv,
  Row,
  Client,
  Collum,
  Line,
  AlingBaseLine,
  SubTitle,
  CloseButton,
  ReturnButton,
  TitleModal,
} from './styles';

interface IProps {
  currencyOnClick: (index: any) => any;
  closeButton: () => void;
  inputSearchOnChange: (e: any) => void;
  inputSearchValue: string;
  inputQuotationOnChange: (e: any) => void;
  inputQuotationValue: any;
  dataCurrency: any[];
  pagination: () => void;
}

interface AddPageProps {
  size: number;
  children: React.ReactNode;
}

function AddPage({ size, children }: AddPageProps) {
  if (size > 768) {
    return <>{children}</>;
  }
  return <Page>{children}</Page>;
}

// const numberFormat = (e: any) => {
//   let number;

//   // eslint-disable-next-line no-unused-expressions
//   e
//     ? (number = `${e.toLocaleString('pt-br', {
//         style: 'currency',
//         currency: 'BRL',
//       })}`)
//     : (number = 'R$ 0,00');

//   // console.log(number, 'PRE SPLIT');

//   // console.log(number.split(','));
//   // const [, pos] = `${number}`.split(',');

//   // if (pos && pos.length === 1) number = `${number}0`;

//   return number.replaceAll(/[R$]/gm, '').trim();
// };

const ModalAddCurrency: React.FC<IProps> = ({
  currencyOnClick,
  closeButton,
  inputSearchOnChange,
  inputSearchValue,
  inputQuotationOnChange,
  inputQuotationValue,
  dataCurrency,
  pagination,
}: IProps) => {
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        pagination();
      }
    });
    const id = document!.querySelector('#finish');
    intersectionObserver.observe(id!);
    return () => intersectionObserver.disconnect();
  }, [pagination]);

  return (
    <Modal
      width={window.innerWidth > 768 ? '60vw' : '100%'}
      heigth={window.innerWidth > 768 ? '0' : '100%'}
      padding="0"
    >
      <AddPage size={window.innerWidth}>
        <ModalAdd>
          <ReturnButton onClick={closeButton}>
            <IconReturn />
          </ReturnButton>
          <TitleModal right>
            <CloseButton onClick={closeButton}>
              <IconClose />
            </CloseButton>
          </TitleModal>
          <AlingBaseLine>
            <Collum>
              <TitleModal>
                <b>Adicionar moeda:</b>
              </TitleModal>
              <Input
                icon={<IconSearch />}
                label="Pesquisar moeda:"
                id="SearchCliente"
                value={inputSearchValue}
                onChange={inputSearchOnChange}
              />
            </Collum>
            <Collum>
              <TitleModal marginB>
                <b>Cotação:</b>
              </TitleModal>
              <Input
                value={isFormatMoney(inputQuotationValue)}
                onChange={inputQuotationOnChange}
              />
            </Collum>
          </AlingBaseLine>
          <Line />
          <SubTitle>Lista de moedas:</SubTitle>
          <ScrollDiv>
            {dataCurrency
              .filter(
                (currancy: any) =>
                  currancy.moeda
                    .toLowerCase()
                    .indexOf(inputSearchValue.toLowerCase()) > -1,
              )
              .map((Currency: any) => {
                return (
                  <Client
                    key={Currency.id}
                    onClick={() => currencyOnClick(Currency)}
                  >
                    <Row aling>
                      <Avatar exact={Currency.sigla} />
                      <Collum>
                        {Currency.codigo} ({Currency.sigla})
                        <b>{Currency.moeda}</b>
                      </Collum>
                    </Row>
                    <IconAdd />
                  </Client>
                );
              })}
            <div id="finish" />
          </ScrollDiv>
        </ModalAdd>
      </AddPage>
    </Modal>
  );
};

export default ModalAddCurrency;
