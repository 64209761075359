import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 35%;
  max-height: 100vh;
  flex-direction: column;

  padding: 5px;

  // TablePage
  div {
    padding: 0;
  }
`;
