import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow: scroll;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 20px;
`;

export const Calendar = styled.div``;

export const Footer = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  button + button {
    margin-left: 10px;
  }
`;

export const Label = styled.p<{ margin?: string }>`
  margin: 0;
  padding: 0;
  ${({ margin }) => (margin ? `margin: ${margin}` : '')}
`;

export const InputContainer = styled.div<{ width?: string; margin?: string }>`
  width: ${({ width }) => width || '100%'};
  ${({ margin }) => (margin ? `margin: ${margin}` : '')}
`;

export const Days = styled.div`
  margin-top: 10px;
  display: flex;
  border: 1px solid #000;
  margin-left: 10px;
  div + div {
    border-left: 1px solid #000;
  }
`;

export const Day = styled.div<{ active: boolean }>`
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active }) => (active ? '#30b4e5' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#000')};
`;

export const RadioButton = styled.div<{ active: boolean }>`
  margin-left: 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 1px solid #000;
  border-radius: 50%;

  ${({ active }) =>
    active
      ? `
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: #000;
        border-radius: 50%;
      }
  `
      : ''}
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Row = styled.div<{
  dontAlign?: boolean;
  margin?: string;
  button?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: ${({ dontAlign }) => (dontAlign ? 'flex-start' : 'center')};
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ button }) => (button ? `cursor: pointer;` : '')}

  div + p {
    margin-left: 10px;
  }

  ${ContainerSelect} + ${ContainerSelect} {
    margin-left: 10px;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 40px;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    padding: 15px 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const RowModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ButtonStyle} + button {
    margin-left: 10px;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingCircle = styled.i`
  height: 10vh;
  width: 10vh;

  border: 2px solid #cccccc;
  border-top-color: #3cb4e5;
  border-radius: 50%;

  animation: linear rotation 2s infinite;

  @keyframes rotation {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Img = styled.span`
  position: absolute;
  svg {
    margin-top: 5px;
    width: 4vh;
    height: auto;
  }
`;
