/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FocusEvent, useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { IconAdd, IconClose } from '~/assets/Icons';
import ImgDefault from '~/assets/Images/ImgDefault.svg';
import {
  Button,
  Input,
  Loading,
  Modal,
  Page,
  PageHeader,
  Select,
  TableActions,
  TablePage,
} from '~/components';
import StockService from '~/services/StockService';
import {
  listStockRequest,
  optionsRequest,
  totalStockRequest,
} from '~/store/modules/stock/actions';
import { IStock } from '~/store/modules/stock/types';
import { IState } from '~/store/modules/types';
import { changeMoney } from '~/utils/format/isFormatMoney';

import { isFormatDate } from '~/utils/format/isFormatPhone';
import {
  BoxImage,
  ButtonAdd,
  ButtonStyle,
  CleanFilter,
  Container,
  Content,
  ContentModalFilter,
  HeaderModalFilter,
  Label,
  Row,
  RowInput,
  TitleModal,
} from './styles';

interface IModal extends ITypeModal {
  active: boolean;
  data?: IStock;
  action?: string;
}

interface ITypeModal {
  type?: 'buy' | 'delete' | 'success' | 'error';
}

interface IBuy {
  quantity: number;
  value: number;
  vendor: {
    id: number;
    nome: string;
  } | null;
}

const Colors = {
  red: '#DF0A24',
  yellow: '#F0D443',
};

const Stock: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const formatCurrency = (value: number | null) => {
    return (value || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const valueStored = sessionStorage.getItem('nameProductSearched') || '';

  const [date, setDate] = useState('');
  const [dateFilter, setDateFilter] = useState<string | undefined>(undefined);
  const [isVisibleModalFilters, setIsVisibleModalFilters] = useState(false);
  const [modal, setModal] = useState<IModal>({ active: false });
  const [data, setData] = useState<IStock[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [backToModal] = useState(history.location.state?.backToModal || false);
  const [item] = useState(history.location.state?.item || null);
  const [columns] = useState([
    {
      title: 'Produto',
      key: 'presentation',
      width: '22%',
      render: (data: IStock) => (
        <Row>
          <BoxImage>
            <img
              alt="Imagem do produto"
              src={data.produto.urlBucket ? data.produto.urlBucket : ImgDefault}
            />
          </BoxImage>
          <div>
            <Label
              color={
                data.estoqueMinimo
                  ? (data.quantidadeEmEstoque === data.estoqueMinimo &&
                      Colors.yellow) ||
                    (data.quantidadeEmEstoque < data.estoqueMinimo &&
                      Colors.red) ||
                    undefined
                  : Colors.red
              }
            >
              {data.produto.descricao}
            </Label>
          </div>
        </Row>
      ),
    },
    {
      title: 'Ult. Alteração',
      key: 'presentation',
      sorter: (a: IStock, b: IStock) =>
        new Date(a.dataDaUltimaEntrada).getTime() -
        new Date(b.dataDaUltimaEntrada).getTime(),
      ellipsis: true,
      render: (data: IStock) => (
        <Row>
          <div>
            <Label
              color={
                data.estoqueMinimo
                  ? (data.quantidadeEmEstoque === data.estoqueMinimo &&
                      Colors.yellow) ||
                    (data.quantidadeEmEstoque < data.estoqueMinimo &&
                      Colors.red) ||
                    undefined
                  : Colors.red
              }
            >
              {format(new Date(data.dataDaUltimaEntrada), `dd/MM/yyyy`, {
                locale: pt,
              })}
              <br />
              {format(new Date(data.dataDaUltimaEntrada), `'às' HH:mm `, {
                locale: pt,
              })}
            </Label>
          </div>
        </Row>
      ),
    },
    {
      title: 'Itens/estoque',
      key: 'quantidadeEmEstoque',
      sorter: (a: IStock, b: IStock) =>
        a.quantidadeEmEstoque - b.quantidadeEmEstoque,
      ellipsis: true,
      render: (data: IStock) => (
        <Label
          color={
            data.estoqueMinimo
              ? (data.quantidadeEmEstoque === data.estoqueMinimo &&
                  Colors.yellow) ||
                (data.quantidadeEmEstoque < data.estoqueMinimo && Colors.red) ||
                undefined
              : Colors.red
          }
        >
          {data.quantidadeEmEstoque || 0}{' '}
          {data.quantidadeEmEstoque === (1 || 0) ? `Item` : `Itens`}
        </Label>
      ),
    },
    {
      title: 'Estoque mínimo',
      key: 'estoqueMinimo',
      sorter: (a: IStock, b: IStock) => a.estoqueMinimo - b.estoqueMinimo,
      ellipsis: true,
      render: (data: IStock) => (
        <Label
          color={
            data.estoqueMinimo
              ? (data.quantidadeEmEstoque === data.estoqueMinimo &&
                  Colors.yellow) ||
                (data.quantidadeEmEstoque < data.estoqueMinimo && Colors.red) ||
                undefined
              : Colors.red
          }
        >
          {data.estoqueMinimo || 0} Itens
        </Label>
      ),
    },
    {
      title: 'Valor Unitário Médio',
      key: 'valorMedio',
      sorter: (a: IStock, b: IStock) => a.valorMedio - b.valorMedio,
      ellipsis: true,
      render: (data: IStock) => (
        <Label
          color={
            data.estoqueMinimo
              ? (data.quantidadeEmEstoque === data.estoqueMinimo &&
                  Colors.yellow) ||
                (data.quantidadeEmEstoque < data.estoqueMinimo && Colors.red) ||
                undefined
              : Colors.red
          }
        >
          {formatCurrency(data.valorMedio)}
        </Label>
      ),
    },
    {
      title: 'Valor Total',
      key: 'valorTotal',
      sorter: (a: IStock, b: IStock) => a.valorTotal - b.valorTotal,
      ellipsis: true,
      render: (data: IStock) => (
        <Label
          color={
            data.estoqueMinimo
              ? (data.quantidadeEmEstoque === data.estoqueMinimo &&
                  Colors.yellow) ||
                (data.quantidadeEmEstoque < data.estoqueMinimo && Colors.red) ||
                undefined
              : Colors.red
          }
        >
          {formatCurrency(data.valorTotal)}
        </Label>
      ),
    },
    {
      title: 'Categoria',
      key: 'categoria',
      width: '10%',
      sorter: (a: IStock, b: IStock) => a.produto.tipoId - b.produto.tipoId,
      ellipsis: true,
      render: (data: IStock) => (
        <Label
          color={
            data.estoqueMinimo
              ? (data.quantidadeEmEstoque === data.estoqueMinimo &&
                  Colors.yellow) ||
                (data.quantidadeEmEstoque < data.estoqueMinimo && Colors.red) ||
                undefined
              : Colors.red
          }
        >
          {data.produto.tipo.toUpperCase()}
        </Label>
      ),
    },
    {
      key: 'presentation',
      render: (data: IStock) => (
        <Row>
          <TableActions
            functionEdit={() => handleClickProduct(data.produto)}
            functionTrash={() => handleClickDelete(data)}
            functionBuy={() => handleClickBuy(data)}
          />
        </Row>
      ),
    },
  ]);

  const [buy, setBuy] = useState<IBuy>(
    history.location.state?.buy || {
      quantity: 0,
      value: 0,
      vendor: null,
    },
  );

  const { stocks, totalStock, loading, loadingTotal, producer } = useSelector(
    (state: IState) => state.stock,
  );

  useEffect(() => {
    dispatch(totalStockRequest());
    dispatch(listStockRequest(dateFilter));
    dispatch(optionsRequest(['producer']));
  }, []);

  useEffect(() => {
    if (backToModal) {
      handleClickBuy(item);
    }
  }, [backToModal]);

  useEffect(() => {
    const stockParsed = parserStocksUnduplicated(stocks);
    setData(stockParsed);
    if (valueStored && valueStored !== 'undefined') {
      handleSearch(valueStored);
    }
  }, [stocks]);

  const parserStocksUnduplicated = (stockDataParam: IStock[]) => {
    const dataArrayUnduplicated: IStock[] = [];

    stockDataParam.forEach(element => {
      const arrayWithProductsSameId = stockDataParam.filter(
        item => element.id === item.id,
      );

      if (arrayWithProductsSameId.length > 1) {
        let newestIdBucket = 0;
        arrayWithProductsSameId.forEach(element => {
          if (element.produto.idBucket > newestIdBucket) {
            newestIdBucket = element.produto.idBucket;
          }
        });

        if (
          dataArrayUnduplicated.filter(
            item => item.produto.idBucket === newestIdBucket,
          ).length === 0
        ) {
          dataArrayUnduplicated.push(
            ...stockDataParam.filter(
              element => element.produto.idBucket === newestIdBucket,
            ),
          );
        }
      } else {
        dataArrayUnduplicated.push(element);
      }
    });

    return dataArrayUnduplicated;
  };

  const handleClickBuy = (data: IStock) => {
    setModal({ active: true, data, type: 'buy' });
  };

  const handleClickDelete = (data: IStock) => {
    setModal({ active: true, data, type: 'delete' });
  };

  const handleAction = (type: 'success' | 'error', action: string) => {
    setModal({ active: true, type, action });
  };

  const closeModal = () => {
    setModal({
      active: false,
    });
  };

  const handleExcludeModal = async (id?: number) => {
    setLoading(true);
    try {
      await StockService.deleteStock(id || 0);
      closeModal();

      handleAction('success', 'deletar');
      setLoading(false);
    } catch (error) {
      closeModal();
      handleAction('error', 'deletar');
      setLoading(false);
    }
    dispatch(listStockRequest(dateFilter));
  };

  const handleClickBuyModal = async (id?: number) => {
    setLoading(true);
    try {
      await StockService.buyStock({
        produtoId: id,
        fornecedorId: buy.vendor?.id,
        quantidadeDeEntrada: buy.quantity,
        valorDeEntrada: buy.value,
      });
      closeModal();
      handleAction('success', 'comprar');
      setLoading(false);
    } catch (error) {
      closeModal();
      handleAction('error', 'comprar');
      setLoading(false);
    }
    dispatch(listStockRequest(dateFilter));
    setBuy({
      quantity: 0,
      value: 0,
      vendor: null,
    });
  };

  const handleClickVendor = (item: any) => {
    setBuy(props => ({ ...props, vendor: item }));
  };

  const handleChangeBuy = (
    name: string,
    event: FocusEvent<HTMLInputElement>,
  ) => {
    let { value }: { value: string | number } = event.target;

    value = value.replace(/\D/gi, '');

    if (name === 'value') {
      value = !Number.isNaN(changeMoney(value)) ? changeMoney(value) : value;
    }

    if (name === 'quantity' && typeof value === 'string') {
      value = parseInt(value, 10);
    }

    setBuy(props => ({ ...props, [name]: value }));
  };

  const handleClickProduct = (product: any) => {
    if (product === 'CRIAR')
      history.push({
        pathname: '/stock/product',
      });

    const { produtoId: id, tipoId } = product;
    if (tipoId === 1)
      history.push({
        pathname: '/stock/product',
        ...(id !== undefined && typeof id === 'number'
          ? { state: { id } }
          : {}),
      });

    if (tipoId === 2)
      history.push({
        pathname: '/stock/system/save',
        ...(id !== undefined && typeof id === 'number'
          ? { state: { id } }
          : {}),
      });
  };

  const handleClickSystem = () => {
    history.push({
      pathname: '/stock/system',
    });
  };

  const handleClickNewVendor = () => {
    history.push({
      pathname: '/stock/vendor',
      state: { buy, newVendor: true, item: modal.data },
    });
  };

  const handleSearch = (value: string) => {
    sessionStorage.setItem('nameProductSearched', value);

    const stockFiltered = stocks.filter(
      curr =>
        (curr?.produto?.descricao || '')
          .toLowerCase()
          .indexOf((value || '').toLowerCase()) !== -1,
    );

    const stockParsed = parserStocksUnduplicated(stockFiltered);
    setData(stockParsed);
  };

  const numberFormat = (e?: number) => {
    return (e || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const renderModal = useMemo(() => {
    if (
      !modal.active ||
      !modal.type ||
      !['success', 'error'].includes(modal.type)
    )
      return;

    const MODAL_TITLE = {
      success: `Sucesso ao ${modal.action}`,
      error: `Erro ao ${modal.action}`,
    };

    return (
      <Modal>
        <Content>
          <h3>{MODAL_TITLE[modal.type as 'success' | 'error']}</h3>

          <ButtonStyle>
            <Button
              onClick={() => {
                closeModal();
              }}
            >
              Entendido
            </Button>
          </ButtonStyle>
        </Content>
      </Modal>
    );
  }, [modal]);

  return (
    <>
      {modal.active && modal.type === 'buy' ? (
        <Modal>
          <Content>
            <h3>Comprar produto</h3>
            <p>{modal.data?.produto.descricao}</p>
            <Row>
              <RowInput>
                <p>Quantidade</p>
                <Input
                  onChange={event => handleChangeBuy('quantity', event)}
                  onBlur={event => handleChangeBuy('quantity', event)}
                  type="number"
                  value={buy.quantity}
                />
              </RowInput>
              <RowInput>
                <p>Valor</p>
                <Input
                  onChange={event => handleChangeBuy('value', event)}
                  onBlur={event => handleChangeBuy('value', event)}
                  value={numberFormat(buy.value)}
                />
              </RowInput>
            </Row>
            <RowInput>
              <p>Fornecedor</p>
              <Select
                fullWidth
                onClickList={handleClickVendor}
                currentValue={buy?.vendor?.nome || ''}
                lista={producer}
              />
              <ButtonAdd onClick={handleClickNewVendor}>
                <IconAdd />
              </ButtonAdd>
            </RowInput>
            <Row>
              <ButtonStyle>
                <Button onClick={closeModal}>Cancelar</Button>
              </ButtonStyle>
              <Button
                onClick={() =>
                  handleClickBuyModal(modal.data?.produto?.produtoId)
                }
              >
                Confirmar
              </Button>
            </Row>
          </Content>
        </Modal>
      ) : null}
      {modal.active && modal.type === 'delete' ? (
        <Modal>
          <Content>
            <h3>Confirma exclusão?</h3>
            {isLoading ? (
              <Loading />
            ) : (
              <Row>
                <ButtonStyle>
                  <Button onClick={closeModal}>Não</Button>
                </ButtonStyle>
                <Button onClick={() => handleExcludeModal(modal.data?.id)}>
                  Sim
                </Button>
              </Row>
            )}
          </Content>
        </Modal>
      ) : null}
      {isVisibleModalFilters && (
        <Modal>
          <>
            <TitleModal>
              <button
                type="button"
                onClick={() => setIsVisibleModalFilters(false)}
              >
                <IconClose />
              </button>
            </TitleModal>
            <HeaderModalFilter>
              <span>
                <b>Filtros</b>
              </span>
              <CleanFilter
                onClick={() => {
                  setDate('');
                  dispatch(listStockRequest(undefined));
                  setDateFilter(undefined);
                  setIsVisibleModalFilters(false);
                }}
              >
                Limpar Filtro
              </CleanFilter>
            </HeaderModalFilter>
            <ContentModalFilter>
              <Input
                label="Data"
                type="date"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
              <span>
                <Button
                  onClick={() => {
                    if (date !== '') {
                      dispatch(listStockRequest(isFormatDate(date)));
                      setDateFilter(date);
                      setIsVisibleModalFilters(false);
                    }
                  }}
                >
                  Aplicar
                </Button>
              </span>
            </ContentModalFilter>
          </>
        </Modal>
      )}
      {renderModal}
      <Page>
        <PageHeader
          textInputSearch={valueStored}
          title="Estoque de Produtos"
          button="+ Criar novo produto"
          onClick={() => handleClickProduct('CRIAR')}
          permission={{ module: 'ESTOQUE DE PRODUTOS', permission: 'INCLUIR' }}
          search="Pesquisar em Produtos"
          onSearch={handleSearch}
          buttonSecond="Sistemas"
          onClickSecond={handleClickSystem}
          onClickFilter={() => setIsVisibleModalFilters(true)}
          stateFilter={!!dateFilter}
        />
        {(loadingTotal || loading) && <Loading />}
        {(!loading && !loadingTotal && (
          <Container>
            <Row>
              <Content>
                <h3>
                  VALOR TOTAL DE
                  <br />
                  PRODUTOS EM ESTOQUE
                </h3>
                <h1>{formatCurrency(totalStock.valorTotalEmEstoque)}</h1>
              </Content>
              <Content>
                <h3>
                  ITENS INDISPONÍVEIS
                  <br />
                  NO ESTOQUE
                </h3>
                <h1>{totalStock.quantidadeFaltanteEmEstoque}</h1>
              </Content>
            </Row>
            <TablePage columns={columns} dataSource={data} />
          </Container>
        )) ||
          null}
      </Page>
    </>
  );
};

export default Stock;
