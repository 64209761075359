import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 30px);
  max-height: calc(100vh - 30px);
  margin-top: 1vh;
  margin-bottom: 30px;
  hr {
    border-top: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.Colors.Gray};
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 14vh;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: 10vh;
  }
  hr {
    position: absolute;
    top: 14vh;
    width: 100%;
    border-top: transparent;
    border-bottom: 1px solid ${({ theme }) => theme.Colors.Gray.Secondary};
  }
  @media (max-width: 480px) {
    height: 10vh;
    hr {
      top: 10vh;
    }
    img {
      height: 5vh;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin: 15px 0;
  }
  a {
    font-weight: 700;
    text-decoration: underline;
  }

  @media (max-width: 480px) {
    div {
      width: 80%;
    }
    p {
      font-size: 11px;
    }
  }
  @media (min-width: 481px) {
    div {
      width: 50%;
    }
  }
  @media (min-width: 769px) {
    div {
      width: 40%;
    }
  }
  @media (min-width: 1279px) {
    width: 100%;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 30px;
  color: ${({ theme }) => theme.Colors.Gray.Strong};
`;

export const Text = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.Colors.Gray};
`;

export const Auth = styled('span')<{ state?: boolean }>`
  input {
    ${props => props.state && 'border-color: #DF0A24; color: #DF0A24; '}
  }
`;

export const ModalAuth = styled.div`
  display: flex;
  flex-direction: column;

  i {
    cursor: pointer;
    align-self: flex-end;
    color: ${({ theme }) => theme.Colors.Blue.Main};
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: -20px;
    color: ${({ theme }) => theme.Colors.Red};

    b {
      margin-bottom: 10px;
    }
  }

  button {
    align-self: center;
    margin-top: 15px;
    width: 80%;
  }
`;
