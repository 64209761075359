import { Reducer } from 'redux';
import {
  IGetConstructionState,
  GetConstructionTypes,
  initialState,
} from './types';

export const INITIAL_STATE_GET_CONSTRUCTION: IGetConstructionState = {
  data: [initialState],
  error: false,
  loading: true,
};

const reducer: Reducer<IGetConstructionState> = (
  state = INITIAL_STATE_GET_CONSTRUCTION,
  action,
) => {
  switch (action.type) {
    case GetConstructionTypes.GETCONSTRUCTION_REQUEST:
      return { ...state, loading: true };
    case GetConstructionTypes.GETCONSTRUCTION_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case GetConstructionTypes.GETCONSTRUCTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default reducer;
