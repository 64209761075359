import { api } from './api';

interface IListCurrencies {
  limit?: number;
  page?: number;
}

class CurrenciesService {
  async listCurrencies({
    limit = 200,
    page = 1,
  }: IListCurrencies): Promise<any> {
    const response = await api.get(`/budget-api/currency`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async getCurrencyById(id: number): Promise<any> {
    const response = await api.get(`/budget-api/currency/${id}`);

    return response.data;
  }
}

export default new CurrenciesService();
