import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  margin-top: 25px;
  font-size: 19px;
  text-align: center;
  p {
    margin: 0;
  }
  svg {
    /* height: 8vh;
    min-height: 60px; */
    width: auto;
  }
  button {
    padding: 16px 45px;
    margin: 20px;
  }
`;
