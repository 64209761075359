import styled from 'styled-components';

export const Container = styled.div`
  padding: 3% 0;
  display: flex;
  flex-direction: column;
`;

export const ButtonAdd = styled.button.attrs({
  type: 'button',
})``;

export const RowInput = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  p + div {
    margin-left: 5px;
  }

  p + button {
    margin-left: 5px;
  }

  button {
    width: 100% !important;
  }

  ${ButtonAdd} {
    width: min-content !important;
  }
`;

export const Row = styled.div<{ justify?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) =>
    justify ? 'flex-start' : 'space-between'};
  width: 100%;
  ${RowInput} + ${RowInput} {
    margin-left: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
  }
  span + button {
    margin-left: 5px;
  }
  ${Row} + ${RowInput} {
    margin-top: 10px;
  }
  ${RowInput} + ${Row} {
    margin-top: 10px;
  }
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;
