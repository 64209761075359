/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import isValidEmail from '~/utils/validate/isValidEmail';
import isValidCPF from '~/utils/validate/isValidCPF';
import isFormatCPF from '~/utils/format/isFormatCPF';

import {
  Button,
  Input,
  Loading,
  Modal,
  ModalDefault,
  Page,
  PageHeader,
  InputPhone,
} from '~/components';

import imgDefault from '~/assets/Images/ImgDefault.svg';

import isFormatCEP from '~/utils/format/isFormatCEP';
import estadosENUM from '~/constants/estados';
import isFormatCNPJ from '~/utils/format/isFormatCNPJ';
import isValidCNPJ from '~/utils/validate/isValidCNPJ';
import CustomersService from '~/services/CustomersService';
import { IconAdd, IconClose } from '~/assets/Icons';
import { isEmpty } from '~/utils/validate/index';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  // setInputValue,
  validateForm,
} from '~/utils/forms/index';
import { api } from '~/services/api';
import { useUrlParams } from '~/utils';
import BudgetsService from '~/services/BudgetsService';

import {
  ButtonAddFinancialResponsible,
  ButtonTextAddFinancialResponsible,
  CloseModal,
  Container,
  ContainerBox,
  ContainerUpload,
  Content,
  ContentAddFinancialResponsible,
  ContentUpload,
  CustomerImg,
  DivList,
  InputBox,
  ListContent,
  SegmentContainer,
  SegmentModalContainer,
} from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const CustomerCreate: React.FC = () => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();
  const queryParams = useUrlParams();
  const [customerType, setCustomerType] = useState(true);
  const [pageTypeEdit, setPageTypeEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerForBudget, setCustomerForBudget] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [imgArray, setImgArray] = useState([]);
  const [listSegment, setListSegment] = useState([{ id: 0, descricao: '' }]);
  const [segment, setSegment] = useState({
    id: 0,
    descricao: '',
  });
  const [inputPhone, setInputPhone] = useState<any>(undefined);

  const [inputPhoneFinancialResponsible, setInputPhoneFinancialResponsible] =
    useState<any>(undefined);

  const [saveUser, setSaveUser] = useState(false);
  const [newSegment, setNewSegment] = useState('');
  const [messageModal, setMessageModal] = useState('');
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const [isVisibleModalSegment, setIsVisibleModalSegment] = useState(false);
  const [addResponsibleFinancial, setAddResponsibleFinancial] = useState(false);

  const stateSchemaCpf = {
    ...generateRequiredInputValues([
      'name',
      'document',
      'email',
      'address',
      'complement',
      'state',
      'city',
      'district',
      'numberAddress',
      'phone',
      'cep',
    ]),
    ...generateDefaultInputValues(['complement']),
  };

  const stateSchemaCnpj = {
    ...generateRequiredInputValues([
      'name',
      'document',
      'email',
      'address',
      'state',
      'city',
      'district',
      'numberAddress',
      'phone',
      'cep',
      'corporateName',
      'segment',
    ]),
    ...generateDefaultInputValues([
      'complement',
      'nameFinancial',
      'phoneFinancial',
      'emailFinancial',
    ]),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchemaCpf);

  useEffect(() => {
    if (addResponsibleFinancial) {
      setInputState(prevState => {
        return {
          ...prevState,
          ...generateRequiredInputValues([
            'nameFinancial',
            'phoneFinancial',
            'emailFinancial',
          ]),
        };
      });
    }
  }, [addResponsibleFinancial]);

  useEffect(() => {
    if (queryParams?.budget) {
      setCustomerForBudget(true);
    }
    if (Number.isNaN(parseFloat(type))) {
      if (type === 'pj') {
        setCustomerType(false);
        setInputState(stateSchemaCnpj);
      } else {
        setInputState(stateSchemaCpf);
      }
    } else {
      const LoadRequest = async () => {
        setLoading(true);
        const response = await CustomersService.getCustomerById(
          parseFloat(type),
        );
        if (response.cpf) {
          setInputState(stateSchemaCpf);
        } else {
          setInputState(stateSchemaCnpj);
        }
        setInputState((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.nome,
          },
          document: {
            ...prevState.document,
            value: response.cnpj
              ? isFormatCNPJ(response.cnpj)
              : isFormatCPF(response.cpf),
          },
          email: {
            ...prevState.email,
            value: response.email,
          },
          corporateName: {
            ...prevState.corporateName,
            value: response.razaoSocial,
          },
          segment: {
            ...prevState.segment,
            value: response.segmento.descricao,
          },
          address: {
            ...prevState.address,
            value: response.logradouro,
          },
          complement: {
            ...prevState.complement,
            value: response.complemento,
          },
          state: {
            ...prevState.state,
            value: response.estado,
          },
          city: {
            ...prevState.city,
            value: response.cidade,
          },
          district: {
            ...prevState.district,
            value: response.bairro,
          },
          numberAddress: {
            ...prevState.numberAddress,
            value: response.numero,
          },
          phone: {
            ...prevState.phone,
            value: response.telefone,
          },
          cep: {
            ...prevState.cep,
            value: response.cep,
          },
          nameFinancial: {
            ...prevState.nameFinancial,
            value: response.nome_financeiro,
          },
          phoneFinancial: {
            ...prevState.phoneFinancial,
            value: response.telefone_financeiro,
          },
          emailFinancial: {
            ...prevState.emailFinancial,
            value: response.email_financeiro,
          },
        }));
        setInputPhoneFinancialResponsible(response?.telefone_financeiro);
        setInputPhone(response?.telefone);
        setCustomerType(response.cpf);
        setSegment(response.segmento);
        setImgUrl(response?.imagem?.fileUrl || '');
        setLoading(false);
        setPageTypeEdit(true);
      };

      LoadRequest();
    }
  }, [type, customerType]);

  useEffect(() => {
    let page = 1;
    const ListSegments = async () => {
      const response = await CustomersService.listSegments({
        limit: 5,
        page,
      });
      const reload = async (res: any) => {
        if (res.info.page === 1) {
          setListSegment(res.result);
        } else {
          setListSegment(currentState => currentState.concat(res.result));
        }
        if (res.info.pages > page) {
          page += 1;
          ListSegments();
        }
      };
      reload(response);
    };
    ListSegments();
  }, [isVisibleModalSegment]);

  function getBase64(img: any, callback: any) {
    if (img.type === 'image/jpeg' || img.type === 'image/png') {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    if (
      acceptedFiles[0].type === 'image/jpeg' ||
      acceptedFiles[0].type === 'image/png'
    ) {
      setImgArray(acceptedFiles);
      getBase64(acceptedFiles[0], (imageUrl: any) => {
        setImgUrl(imageUrl);
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  function content() {
    if (imgUrl) {
      return <CustomerImg src={imgUrl} alt="Foto do cliente" />;
    }
    return (
      <ContentUpload>
        <img src={imgDefault} alt="sem imagem" />
        <p>
          Clique aqui para adicionar uma imagem do seu computador ou arraste-a
          para esse campo.
        </p>
      </ContentUpload>
    );
  }

  const handleInputChange = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (customerType && inputName === 'document' && value.length < 14) {
      error = 'Campo insuficiente, digite 11 caracteres ';
      isValid = false;
    }

    if (!customerType && inputName === 'document' && value.length < 18) {
      error = 'Campo insuficiente, digite 14 caracteres ';
      isValid = false;
    }

    if (inputName === 'cep' && value.length < 9) {
      error = 'Campo insuficiente, digite 8 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length < 10) {
      error = 'Campo insuficiente, digite no mínimo 10 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length > 20) {
      error = 'Digite no máximo 20 caracteres ';
      isValid = false;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const updateCep = (data: any) => {
    setInputState((prevState: any) => ({
      ...prevState,
      district: {
        value: data.bairro,
        isValid: inputState.district.isValid,
        required: inputState.district.required,
        error: inputState.district.error,
      },
      address: {
        value: data.logradouro,
        isValid: inputState.address.isValid,
        required: inputState.address.required,
        error: inputState.address.error,
      },
      city: {
        value: data.localidade,
        isValid: inputState.city.isValid,
        required: inputState.city.required,
        error: inputState.city.error,
      },
      state: {
        value: estadosENUM(data.uf),
        isValid: inputState.state.isValid,
        required: inputState.state.required,
        error: inputState.state.error,
      },
    }));
  };

  const requestCep = useCallback(async cep => {
    try {
      const response = await CustomersService.getCep(cep);
      updateCep(response);
      return { isValid: true, error: '' };
    } catch (e) {
      return { isValid: false, error: 'Cep não encontrado' };
    }
  }, []);

  const handleInputBlur = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (customerType && inputName === 'document') {
      if (value.length < 14) {
        error = 'Campo insuficiente, digite 11 caracteres ';
        isValid = false;
      } else if (!isValidCPF(value)) {
        error = 'CPF inválido';
      }
    }

    if (!customerType && inputName === 'document') {
      if (value.replace(/[^\d]+/g, '').length < 14) {
        error = 'Campo insuficiente, digite 14 caracteres ';
        isValid = false;
      }
      if (isValidCNPJ(value)) {
        error = 'CNPJ inválido';
        isValid = false;
      }
    }

    if (inputName === 'email' && !isValidEmail(value)) {
      error = 'E-mail inválido';
      isValid = false;
    }

    if (inputName === 'cep' && value.replace(/[^\d]+/g, '').length < 8) {
      error = 'Campo insuficiente, digite 8 caracteres ';
      isValid = false;
    }
    if (inputName === 'cep' && value.replace(/[^\d]+/g, '').length === 8) {
      const cep = value.replace(/[^\d]+/g, '');
      const response = await requestCep(cep);
      error = response.error;
      isValid = response.isValid;
    }

    if (inputName === 'phone' && value.length < 10) {
      error = 'Campo insuficiente, digite no mínimo 10 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length > 20) {
      error = 'Digite no máximo 20 caracteres ';
      isValid = false;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    if (!customerType && segment.id === 0) {
      hasError = true;
    }

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    Object.entries(inputState).forEach(allInput => {
      handleInputBlur(allInput[1].value, allInput[0]);
    });
    const create = async () => {
      if (isFormValid()) {
        try {
          const request = await api.post(`/customer-api/customer`, {
            nome: inputState.name.value,
            documento: inputState.document.value.replace(/[^\d]+/g, ''),
            cidade: inputState.city.value,
            estado: inputState.state.value,
            razaoSocial: inputState?.corporateName?.value,
            segmento: segment.id,
            logradouro: inputState.address.value,
            complemento: inputState.complement.value,
            email: inputState.email.value,
            telefone: inputState.phone.value.replace(/[^\d]+/g, ''),
            numero: inputState.numberAddress.value,
            bairro: inputState.district.value,
            cep: inputState.cep.value.replace(/[^\d]+/g, ''),
            nome_financeiro: inputState?.nameFinancial?.value
              ? inputState?.nameFinancial?.value
              : '',
            telefone_financeiro: inputState?.phoneFinancial?.value
              ? inputState?.phoneFinancial?.value.replace(/[^\d]+/g, '')
              : '',
            email_financeiro: inputState?.emailFinancial?.value || '',
          });

          if (imgArray.length > 0) {
            const formData = new FormData();
            formData.append('file', imgArray[0]);
            api.post(`customer-api/customer/${request.data.id}/file`, formData);
          }
          if (customerForBudget) {
            const budgetId = parseFloat(queryParams.budget);
            const linkCustomerToBudget = async () => {
              await BudgetsService.linkCustomerToBudget({
                budgetId,
                customerId: request.data.id,
              });
            };
            linkCustomerToBudget();
          }
          setSaveUser(true);
          setIsVisibleModalSuccess(true);
          setMessageModal('Cadastro de cliente criado com sucesso!');

          Object.entries(inputState).forEach(allInput => {
            setInputState((prevState: any) => ({
              ...prevState,
              [allInput[0]]: {
                required: allInput[1].required,
                isValid: true,
                error: '',
                value: '',
              },
            }));
          });
        } catch {
          setIsVisibleModalError(true);
          setMessageModal('Não foi possivel criar o cadastro de cliente');
        }
      } else {
        setIsVisibleModalError(true);
        setMessageModal('Não foi possivel criar o cadastro de cliente');
      }
    };

    const edit: any = async () => {
      try {
        if (customerType) {
          await CustomersService.updateCustomerById(type, {
            nome: inputState.name.value,
            cidade: inputState.city.value,
            estado: inputState.state.value,
            logradouro: inputState.address.value,
            complemento: inputState.complement.value,
            telefone: inputState.phone.value.replace(/[^\d]+/g, ''),
            numero: inputState.numberAddress.value,
            bairro: inputState.district.value,
            cep: inputState.cep.value.replace(/[^\d]+/g, ''),
            email: inputState.email.value,
            nome_financeiro: inputState.nameFinancial.value,
            telefone_financeiro: inputState.phoneFinancial.value.replace(
              /[^\d]+/g,
              '',
            ),
            email_financeiro: inputState.emailFinancial.value,
          });
        } else {
          await CustomersService.updateCustomerById(type, {
            nome: inputState.name.value,
            cidade: inputState.city.value,
            estado: inputState.state.value,
            razaoSocial: inputState.corporateName.value,
            segmento: segment.id,
            logradouro: inputState.address.value,
            complemento: inputState.complement.value,
            telefone: inputState.phone.value.replace(/[^\d]+/g, ''),
            numero: inputState.numberAddress.value,
            bairro: inputState.district.value,
            cep: inputState.cep.value.replace(/[^\d]+/g, ''),
            email: inputState.email.value,
            nome_financeiro: inputState.nameFinancial.value,
            telefone_financeiro: inputState.phoneFinancial.value.replace(
              /[^\d]+/g,
              '',
            ),
            email_financeiro: inputState.emailFinancial.value,
          });
        }
        setSaveUser(true);
        setIsVisibleModalSuccess(true);
        setMessageModal('Cadastro de cliente editado com sucesso!');
      } catch {
        setIsVisibleModalError(true);
        setMessageModal('Não foi possivel editar o cadastro de cliente');
      }
      if (imgArray.length > 0) {
        try {
          const formData = new FormData();
          formData.append('file', imgArray[0]);
          api.post(`customer-api/customer/${type}/file`, formData);
        } catch {
          setIsVisibleModalError(true);
          setMessageModal('Não foi possivel salvar foto');
        }
      }
      return null;
    };
    if (pageTypeEdit) {
      edit();
    } else {
      create();
    }
  };

  if (loading) {
    return <Loading />;
  }

  const typeTitle = pageTypeEdit ? 'Editar' : 'Novo';

  return (
    <>
      {isVisibleModalSuccess && (
        <ModalDefault
          success
          onClick={() => {
            setIsVisibleModalSuccess(false);
            setMessageModal('');
            if (saveUser) {
              setSaveUser(false);
              if (customerForBudget) {
                history.push(`/budget/edit/${queryParams.budget}`);
              } else {
                history.push('/customer');
              }
            }
          }}
        >
          {messageModal}
        </ModalDefault>
      )}
      {isVisibleModalError && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalError(false)}
        >
          {messageModal}
        </ModalDefault>
      )}
      {isVisibleModalSegment && (
        <>
          <Modal>
            <SegmentModalContainer>
              <CloseModal>
                <button
                  type="button"
                  onClick={() => setIsVisibleModalSegment(false)}
                >
                  <IconClose />
                </button>
              </CloseModal>
              <h1>Cadastro de novo Segmento</h1>
              <span>
                <Input
                  label="Título do novo Segmento"
                  onChange={e => setNewSegment(e.target.value)}
                  error={
                    newSegment.length < 5
                      ? '*Digite no mínimo 5 caracteres'
                      : undefined
                  }
                  required
                />
              </span>
              <Button
                disabled={newSegment.length < 5}
                onClick={async () => {
                  try {
                    await CustomersService.createSegment(newSegment);
                    setMessageModal(
                      'O cadastro de segmento foi concluído com sucesso.',
                    );
                    setIsVisibleModalSegment(false);
                    setIsVisibleModalSuccess(true);
                  } catch {
                    setMessageModal('Ocorreu um erro.');
                    setIsVisibleModalError(true);
                  }
                }}
              >
                Salvar novo segmento
              </Button>
            </SegmentModalContainer>
          </Modal>
        </>
      )}
      <Page>
        <PageHeader
          title={
            customerType
              ? `${typeTitle} Contato - Pessoa`
              : `${typeTitle} Contato - Empresa`
          }
          button="Salvar"
          disabled={!isFormValid()}
          onClick={() => validAll()}
          permission={{ module: 'CLIENTES', permission: 'INCLUIR' }}
        />
        <Container>
          <ContainerUpload>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragReject && (
                <ContentUpload>
                  <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                </ContentUpload>
              )}
              {isDragActive ? (
                <ContentUpload>
                  <p>Solte os arquivos aqui ... </p>
                </ContentUpload>
              ) : (
                content()
              )}
            </div>
          </ContainerUpload>
          <Content>
            <span>
              <b>Dados Gerais</b>
            </span>
            {customerType ? (
              <ContainerBox>
                <InputBox>
                  <Input
                    label="Nome"
                    maxLenght={70}
                    requiredLabel
                    error={inputState.name.error}
                    value={inputState.name.value}
                    onChange={e =>
                      handleInputChange(
                        e.target.value.replace(/[^a-zA-Zà-ūÀ-Ū ]/g, ''),
                        'name',
                      )
                    }
                    onBlur={e => handleInputBlur(e.target.value, 'name')}
                  />
                  <InputPhone
                    label="Telefone"
                    requiredLabel
                    value={inputPhone}
                    error={inputState.phone.error}
                    onChange={e => {
                      setInputPhone(e);
                      if (e !== undefined) {
                        handleInputChange(e, 'phone');
                      }
                    }}
                  />
                </InputBox>

                <InputBox>
                  <Input
                    label="CPF"
                    requiredLabel
                    value={isFormatCPF(inputState.document.value)}
                    error={inputState.document.error}
                    disabled={pageTypeEdit}
                    maxLenght={14}
                    onChange={e =>
                      handleInputChange(e.target.value, 'document')
                    }
                    onBlur={e => handleInputBlur(e.target.value, 'document')}
                  />
                  <Input
                    label="E-mail"
                    requiredLabel
                    value={inputState.email.value}
                    error={inputState.email.error}
                    onChange={e => handleInputChange(e.target.value, 'email')}
                    onBlur={e => handleInputBlur(e.target.value, 'email')}
                  />
                </InputBox>
              </ContainerBox>
            ) : (
              <>
                <ContainerBox>
                  <InputBox>
                    <Input
                      label="Nome"
                      requiredLabel
                      maxLenght={70}
                      value={inputState.name.value}
                      error={inputState.name.error}
                      onChange={e => handleInputChange(e.target.value, 'name')}
                      onBlur={e => handleInputBlur(e.target.value, 'name')}
                    />
                    <Input
                      label="CNPJ"
                      requiredLabel
                      maxLenght={18}
                      value={isFormatCNPJ(inputState.document.value)}
                      error={inputState.document.error}
                      disabled={pageTypeEdit}
                      onChange={e =>
                        handleInputChange(e.target.value, 'document')
                      }
                      onBlur={e => handleInputBlur(e.target.value, 'document')}
                    />
                  </InputBox>
                  <InputBox>
                    <Input
                      label="Razão Social"
                      requiredLabel
                      maxLenght={70}
                      value={inputState.corporateName.value}
                      error={inputState.corporateName.error}
                      onChange={e =>
                        handleInputChange(e.target.value, 'corporateName')
                      }
                      onBlur={e =>
                        handleInputBlur(e.target.value, 'corporateName')
                      }
                    />
                    <SegmentContainer>
                      <Input
                        label="Segmento"
                        requiredLabel
                        maxLenght={50}
                        value={inputState.segment.value}
                        error={inputState.segment.error}
                        onChange={e =>
                          handleInputChange(e.target.value, 'segment')
                        }
                        onBlur={e => handleInputBlur(e.target.value, 'segment')}
                      />
                      <button
                        type="button"
                        onClick={() => setIsVisibleModalSegment(true)}
                      >
                        <IconAdd />
                      </button>
                    </SegmentContainer>
                    {inputState.segment.value.length > 2 &&
                      inputState.segment.value !== segment.descricao && (
                        <DivList>
                          {listSegment
                            .filter(
                              (list: any) =>
                                list.descricao
                                  .toLowerCase()
                                  .indexOf(
                                    inputState.segment.value.toLowerCase(),
                                  ) > -1,
                            )
                            .map(list => (
                              <ListContent
                                key={list.id}
                                onClick={() => {
                                  setSegment({
                                    id: list.id,
                                    descricao: list.descricao,
                                  });
                                  setInputState((prevState: any) => ({
                                    ...prevState,
                                    segment: {
                                      value: list.descricao,
                                      isValid: true,
                                      required: inputState.segment.required,
                                      error: '',
                                    },
                                  }));
                                }}
                              >
                                {list.descricao}
                              </ListContent>
                            ))}
                        </DivList>
                      )}
                  </InputBox>
                </ContainerBox>
                <ContainerBox>
                  <InputBox>
                    <InputPhone
                      label="Telefone"
                      requiredLabel
                      value={inputPhone}
                      error={inputState.phone.error}
                      onChange={e => {
                        setInputPhone(e);
                        if (e !== undefined) {
                          handleInputChange(e, 'phone');
                        }
                      }}
                    />
                  </InputBox>
                  <InputBox>
                    <Input
                      label="E-mail"
                      requiredLabel
                      value={inputState.email.value}
                      error={inputState.email.error}
                      onChange={e => handleInputChange(e.target.value, 'email')}
                      onBlur={e => handleInputBlur(e.target.value, 'email')}
                    />
                  </InputBox>
                </ContainerBox>
                <ContentAddFinancialResponsible>
                  <ButtonAddFinancialResponsible
                    onClick={() => setAddResponsibleFinancial(true)}
                  >
                    <IconAdd />
                    <ButtonTextAddFinancialResponsible>
                      Adicionar Responsável Financeiro
                    </ButtonTextAddFinancialResponsible>
                  </ButtonAddFinancialResponsible>
                </ContentAddFinancialResponsible>
              </>
            )}

            {addResponsibleFinancial && (
              <>
                {' '}
                <ContainerBox>
                  <Input
                    label="Nome"
                    requiredLabel
                    maxLenght={70}
                    value={inputState.nameFinancial.value}
                    error={inputState.nameFinancial.error}
                    onChange={e =>
                      handleInputChange(e.target.value, 'nameFinancial')
                    }
                    onBlur={e =>
                      handleInputBlur(e.target.value, 'nameFinancial')
                    }
                  />
                </ContainerBox>
                <ContainerBox>
                  <InputBox>
                    <InputPhone
                      label="Telefone"
                      requiredLabel
                      value={inputPhoneFinancialResponsible}
                      error={inputState.phoneFinancial.error}
                      onChange={e => {
                        setInputPhoneFinancialResponsible(e);
                        if (e !== undefined) {
                          handleInputChange(e, 'phoneFinancial');
                        }
                      }}
                    />
                  </InputBox>
                  <InputBox>
                    <Input
                      label="E-mail"
                      requiredLabel
                      value={inputState.emailFinancial.value}
                      error={inputState.emailFinancial.error}
                      onChange={e =>
                        handleInputChange(e.target.value, 'emailFinancial')
                      }
                      onBlur={e =>
                        handleInputBlur(e.target.value, 'emailFinancial')
                      }
                    />
                  </InputBox>
                </ContainerBox>
              </>
            )}
            <span>
              <b>Endereços</b>
            </span>
            <ContainerBox>
              <InputBox>
                <Input
                  label="CEP"
                  requiredLabel
                  maxLenght={9}
                  value={isFormatCEP(inputState.cep.value)}
                  error={inputState.cep.error}
                  onChange={e => handleInputChange(e.target.value, 'cep')}
                  onBlur={async e => {
                    handleInputBlur(e.target.value, 'cep');
                  }}
                />
                <Input
                  label="Número"
                  requiredLabel
                  value={inputState.numberAddress.value}
                  error={inputState.numberAddress.error}
                  onChange={e =>
                    handleInputChange(
                      e.target.value.replace(/[^\d]+/g, ''),
                      'numberAddress',
                    )
                  }
                  onBlur={e => handleInputBlur(e.target.value, 'numberAddress')}
                />
              </InputBox>
              <InputBox>
                <Input
                  label="Logradouro"
                  requiredLabel
                  value={inputState.address.value}
                  error={inputState.address.error}
                  onChange={e => handleInputChange(e.target.value, 'address')}
                  onBlur={e => handleInputBlur(e.target.value, 'address')}
                />
                <Input
                  label="Complemento"
                  value={inputState.complement.value}
                  error={inputState.complement.error}
                  onChange={e =>
                    handleInputChange(e.target.value, 'complement')
                  }
                  onBlur={e => handleInputBlur(e.target.value, 'complement')}
                />
              </InputBox>
            </ContainerBox>
            <ContainerBox>
              <InputBox>
                <Input
                  label="Bairro"
                  requiredLabel
                  value={inputState.district.value}
                  error={inputState.district.error}
                  onChange={e => handleInputChange(e.target.value, 'district')}
                  onBlur={e => handleInputBlur(e.target.value, 'district')}
                />
                <Input
                  label="Estado"
                  requiredLabel
                  value={inputState.state.value}
                  error={inputState.state.error}
                  onChange={e => handleInputChange(e.target.value, 'state')}
                  onBlur={e => handleInputBlur(e.target.value, 'state')}
                />
              </InputBox>
              <InputBox>
                <Input
                  label="Cidade"
                  requiredLabel
                  value={inputState.city.value}
                  error={inputState.city.error}
                  onChange={e => handleInputChange(e.target.value, 'city')}
                  onBlur={e => handleInputBlur(e.target.value, 'city')}
                />
              </InputBox>
            </ContainerBox>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default CustomerCreate;
