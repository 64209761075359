import { Avatar as AvatarContainer } from 'antd';
import 'antd/dist/antd.css';
import { Container } from './styles';

interface IPeople {
  nome: string;
  url: string | null;
}

interface IProps {
  name?: string;
  url?: string;
  exact?: string;
  peoples?: IPeople[];
}

const Avatar: React.FC<IProps> = ({ name, url, exact, peoples }: IProps) => {
  if (peoples && peoples.length > 0) {
    return (
      <Container>
        <AvatarContainer.Group>
          {peoples?.map(people => {
            const { nome, url } = people;
            let nomeA: string[] = [''];
            if (nome) nomeA = nome.split(' ');

            const firstName = `${nomeA[0]}`;
            const lastName = `${
              nomeA.length > 1 ? nomeA[nomeA.length - 1] : ''
            }`;

            return (
              <AvatarContainer style={{ backgroundColor: '#54565a' }}>
                {url?.trim() ? (
                  <AvatarContainer src={url} />
                ) : (
                  <p>{firstName.substr(0, 1) + lastName.substr(0, 1)}</p>
                )}
              </AvatarContainer>
            );
          })}
        </AvatarContainer.Group>
      </Container>
    );
  }
  if (url?.trim()) {
    return (
      <Container>
        <AvatarContainer src={url} />
      </Container>
    );
  }

  if (name?.trim()) {
    let nome: string[] = [''];
    if (name) nome = name.split(' ');

    const firstName = `${nome[0]}`;
    const lastName = `${nome.length > 1 ? nome[nome.length - 1] : ''}`;

    return (
      <Container>
        <AvatarContainer style={{ backgroundColor: '#54565a' }}>
          <p>{firstName.substr(0, 1) + lastName.substr(0, 1)}</p>
        </AvatarContainer>
      </Container>
    );
  }

  return (
    <Container>
      <AvatarContainer style={{ backgroundColor: '#54565a' }}>
        <p>{exact || '?'}</p>
      </AvatarContainer>
    </Container>
  );
};

Avatar.defaultProps = {
  name: '',
  url: '',
  exact: '',
  peoples: [],
};

export default Avatar;
