import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }
`;

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 15px;
    margin-rigth: 15px;
  }
`;

export const Container = styled.div`
  display: flex;
  margin: 30px 30px 100px 30px;

  @media (max-width: 768px) {
    flex-direction: column;

    div {
      margin: 20px 0;
    }
  }
`;

export const Content = styled.div`
  padding: 16px 20px;
  margin: 0 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  width: 100%;
  height: fit-content;
  font-size: 17px;

  span {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }
  b {
    margin-left: 4px;
    font-weight: 700;
  }
  p {
    font-weight: 300;
  }
`;

export const Img = styled.img`
  border: 1px solid #000000;
  margin: 0 10px;

  border-radius: 6px;
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ListCheckbox = styled('div')<{ visible: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  flex-wrap: wrap;

  margin-bottom: 30px;
  /* padding: 0 10px; */
  div {
    display: flex;
    margin: 0 10px;
    b {
      margin: 5px 6px;
      font-size: 12px;
      text-transform: capitalize;
    }
  }

  ${props => props.visible && `display: none;`}

  @media (max-width: 570px) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
`;

export const TitleCheckbox = styled.b`
  font-size: 15px;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  svg {
    transform: rotate(-90deg);
  }
`;

export const ContainerCheckbox = styled.div`
  display: flex;
  flex-direction: column;
`;
