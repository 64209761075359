/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import Logo from '~/assets/Logotipo/water-design-logo-grande.png';
import { Button, Input, ModalDefault } from '~/components';
import isValidEmail from '~/utils/validate/isValidEmail';
import { generateRequiredInputValues, validateForm } from '~/utils/forms';
import { isEmpty } from '~/utils/validate';
import RecoveryService from '~/services/RecoveryService';
import { Container, Content, Header, Title, Text } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const Recovery: React.FC = () => {
  const history = useHistory();
  const stateSchema = {
    ...generateRequiredInputValues(['email']),
  };
  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && value.length === 0) {
      error = '*Campo obrigatorio';
      isValid = false;
    }

    if (inputName === 'email' && !isValidEmail(value)) {
      error = '*Email invalido';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.entries(inputState).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  function loger() {
    if (isFormValid()) {
      try {
        RecoveryService.sendTokenForEmail({ email: inputState.email.value });
        history.push('/recovery/password');
      } catch {
        setIsVisibleModalErro(true);
      }
    }
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          onClick={() => setIsVisibleModalErro(false)}
          success={false}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      <Header>
        <img src={Logo} alt="Logo-Waterdesing" />
        <hr />
      </Header>
      <Container>
        <Content>
          <Title>Redefinição de Senha</Title>
          <div>
            <Text>
              Verificação necessária. Acesse o link que enviamos ao seu e-mail
              para redefinição de senha.
            </Text>

            <Input
              name="email"
              label="Insira seu e-mail:"
              id="inputEmail"
              type="email"
              onChange={e => handleInput(e.target.value, 'email')}
              onBlur={e => handleInput(e.target.value, 'email')}
              error={inputState.email.error}
            />

            <Button onClick={loger}>Enviar link</Button>
          </div>
        </Content>
      </Container>
    </>
  );
};

export default Recovery;
