import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Input, Loading, ModalDefault, Page, PageHeader } from '~/components';

import { isEmpty } from '~/utils/validate/index';
import { generateRequiredInputValues, validateForm } from '~/utils/forms/index';
import NotificationsService from '~/services/NotificationsService';
import { Container, ErroAlert, Label, TextArea } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const NotificationEdit: React.FC = () => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [pageType, setPageType] = useState(true);

  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const [textModalError, setTextModalError] = useState('');

  const stateSchema = {
    ...generateRequiredInputValues(['descricao', 'texto']),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchema);

  useEffect(() => {
    const LoadRequest = async () => {
      if (type === 'create') {
        setPageType(false);
        setLoading(false);
      } else {
        setLoading(true);
        const response = await NotificationsService.getByIdTemplate(type);
        setInputState((prevState: any) => ({
          ...prevState,
          descricao: {
            ...prevState.descricao,
            value: response.descricao,
          },
          texto: {
            ...prevState.texto,
            value: response.texto,
          },
        }));
        setLoading(false);
      }
    };
    LoadRequest();
  }, [type]);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = async () => {
    Object.entries(inputState).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    if (pageType) {
      if (isFormValid()) {
        try {
          await NotificationsService.editByIdTemplate({
            id: type,
            texto: inputState.texto.value,
          });
          setIsVisibleModalSuccess(true);
        } catch (e: any) {
          if (e.error.message === 'Template já existe') {
            setTextModalError(e.error.message);
          }
          setIsVisibleModalError(true);
        }
      } else {
        setIsVisibleModalError(true);
      }
    } else if (isFormValid()) {
      try {
        await NotificationsService.createTemplate({
          descricao: inputState.descricao.value,
          texto: inputState.texto.value,
        });
        setIsVisibleModalSuccess(true);
      } catch (e: any) {
        if (e.error.message === 'Template já existe') {
          setTextModalError(e.error.message);
        }
        setIsVisibleModalError(true);
      }
    } else {
      setIsVisibleModalError(true);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalSuccess && (
        <ModalDefault
          success
          onClick={() => {
            setIsVisibleModalSuccess(false);
            history.push('/notifications');
          }}
        >
          A alteração de Notificação foi concluído com sucesso.
        </ModalDefault>
      )}
      {isVisibleModalError && (
        <ModalDefault
          success={false}
          onClick={() => {
            setIsVisibleModalError(false);
            setTextModalError('');
          }}
        >
          {textModalError || 'Ocorreu um erro.'}
        </ModalDefault>
      )}

      <Page>
        <PageHeader
          title={!pageType ? 'Criar Notificação' : 'Edição de Notificação'}
          button="Salvar"
          disabled={!isFormValid()}
          onClick={() => toSave()}
          permission={{
            module: 'withoutRestriction',
            permission: 'withoutRestriction',
          }}
        />
        <Container>
          <Input
            label="Identificador"
            requiredLabel
            disabled={pageType}
            value={inputState.descricao.value}
            error={inputState.descricao.error}
            onChange={e => handleInput(e.target.value, 'descricao')}
            onBlur={e => handleInput(e.target.value, 'descricao')}
          />
          <Label htmlFor="textarea">
            <div>
              <b>Texto</b> (Obrigatorio)
            </div>
          </Label>
          <TextArea
            id="textarea"
            value={inputState.texto.value}
            onChange={e => handleInput(e.target.value, 'texto')}
            onBlur={e => handleInput(e.target.value, 'texto')}
          />
          <ErroAlert erro={!!inputState.texto.error}>
            {inputState.texto.error}
          </ErroAlert>
        </Container>
      </Page>
    </>
  );
};

export default NotificationEdit;
