import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  min-height: 100%;
  /* background: blue; */
  flex-direction: column;
  padding: 10px;
`;

export const Panel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  padding: 10px 0px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  div + div {
    margin-left: 10px;
  }
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  margin-right: 10px;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 0 10px;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 40px;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    padding: 15px 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;
