import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IconAdd, IconClose, IconReturn, IconSearch } from '~/assets/Icons';
import { Avatar, Input, Modal, Page } from '~/components';
import ButtonCustomer from '~/components/ButtonCustomer';
import isFormatCNPJ from '~/utils/format/isFormatCNPJ';
import isFormatCPF from '~/utils/format/isFormatCPF';
import {
  Content,
  ModalAdd,
  ScrollDiv,
  Row,
  Client,
  Collum,
  Line,
  AlingBaseLine,
  SubTitle,
  CloseButton,
  ReturnButton,
} from './styles';

interface IProps {
  clientOnClick: (index: any) => any;
  closeButton: () => void;
  inputOnChange: (e: any) => void;
  inputValue: string;
  dataCustomer: any[];
  idBudget: string;
  pagination: () => void;
}

interface AddPageProps {
  size: number;
  children: React.ReactNode;
}

function AddPage({ size, children }: AddPageProps) {
  if (size > 768) {
    return <>{children}</>;
  }
  return <Page>{children}</Page>;
}

const ModalAddClient: React.FC<IProps> = ({
  clientOnClick,
  closeButton,
  inputOnChange,
  inputValue,
  dataCustomer,
  idBudget,
  pagination,
}: IProps) => {
  const history = useHistory();

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        pagination();
      }
    });
    const id = document!.querySelector('#finish');
    intersectionObserver.observe(id!);
    return () => intersectionObserver.disconnect();
  }, [pagination]);

  return (
    <Modal
      width={window.innerWidth > 768 ? '60vw' : '100%'}
      heigth={window.innerWidth > 768 ? '0' : '100%'}
      padding="0"
    >
      <AddPage size={window.innerWidth}>
        <ModalAdd>
          <ReturnButton onClick={closeButton}>
            <IconReturn />
          </ReturnButton>
          <Content>
            <b>Vincular cliente à proposta:</b>
            <CloseButton onClick={closeButton}>
              <IconClose />
            </CloseButton>
          </Content>
          <AlingBaseLine>
            <Collum>
              <Input
                icon={<IconSearch />}
                label="Pesquisar cliente:"
                id="SearchCliente"
                value={inputValue}
                onChange={inputOnChange}
              />
            </Collum>
            <p>ou</p>
            <div>
              <ButtonCustomer
                onClickPf={() =>
                  history.push(`/customer/pf?budget=${idBudget}`)
                }
                onClickPj={() =>
                  history.push(`/customer/pj?budget=${idBudget}`)
                }
                permissions={{ module: 'CLIENTES', permission: 'INCLUIR' }}
              />
            </div>
          </AlingBaseLine>
          <Line />
          <SubTitle>Lista de clientes já cadastrados:</SubTitle>
          <ScrollDiv id="scroll">
            {dataCustomer
              .filter(
                (customer: any) =>
                  customer.nome
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) > -1,
              )
              .map((infoCustomer: any) => {
                return (
                  <Client
                    key={infoCustomer.id}
                    onClick={() => clientOnClick(infoCustomer)}
                  >
                    <Row aling>
                      <Avatar name={infoCustomer.nome} />
                      <Collum>
                        Cliente:
                        <b>{infoCustomer.nome}</b>
                        <p>
                          {infoCustomer.cpf
                            ? `CPF: ${isFormatCPF(infoCustomer.cpf)}`
                            : `CNPJ: ${isFormatCNPJ(infoCustomer.cnpj)}`}
                        </p>
                      </Collum>
                    </Row>
                    <IconAdd />
                  </Client>
                );
              })}
            <div id="finish" />
          </ScrollDiv>
        </ModalAdd>
      </AddPage>
    </Modal>
  );
};

export default ModalAddClient;
