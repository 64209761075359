export const typeENUM = (type: string): string => {
  switch (type) {
    case 'no-schedule':
      return 'sem agendamento';
    case 'await-report':
      return 'aguardando relatório';
    case 'incomplete':
      return 'incompletos';
    case 'done':
      return 'totalmente realizados';
    default:
      return '-';
  }
};
