import styled from 'styled-components';

export const Container = styled('div')<{ state: boolean; fix: boolean }>`
  border: 1px solid #30b4e5;
  border-radius: 6px;
  margin: 3% 3% 0 0;
  min-width: 20%;
  height: min-content;

  @media (max-width: 768px) {
    border: none;
    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.22));
    position: fixed;
    display: flex;
    bottom: -460px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.7s;
    z-index: 998;

    ${props => props.state && ' bottom: 0;'}
    ${props => props.fix && 'width: 80vw;'}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 0 0 6px 6px;
  padding: 15px;
  width: 100%;

  p {
    font-size: 11px;
    color: #898a8d;
  }

  @media (max-width: 768px) {
    background: #eaf7fd;
    height: 480px;
    padding: 15px 20px;
  }
`;

export const Header = styled.button`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  background: #eaf7fd;
  width: 100%;
  border-radius: 6px 6px 0 0;
  padding: 10px 0;

  @media (max-width: 768px) {
    border-radius: 5px 5px 0 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const Valor = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin: 1vh 0;
`;

export const Desconto = styled.span`
  width: 100%;
  font-weight: 700;
  color: ${({ theme }) => theme.Colors.Gray};
  font-weight: 700;

  margin: 1vh 0;

  div {
    display: flex;
  }
  select {
    margin-right: 15px;
    border: 1px solid #30b4e5;
    border-radius: 3px;
    padding: 5px;
    background: #fff;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  margin-top: 10px;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const LabelSpan = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 17px !important;
  text-align: left;
  color: #000000;

  svg {
    margin-right: 5px;
  }
`;
