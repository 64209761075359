import { Reducer } from 'redux';
import { IListProductState, ListProductTypes } from './types';

const INITIAL_STATE: IListProductState = {
  data: [
    {
      id: 0,
      descricao: '',
      descricaoTecnica: '',
      descricaoComercial: '',
      especificacaoTecnica: '',
      preco: 0,
      imagens: [
        {
          default: 0,
          fileUrl: '',
        },
      ],
    },
  ],
  error: false,
  loading: false,
};

const reducer: Reducer<IListProductState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ListProductTypes.LISTPRODUCT_REQUEST:
      return { ...state, loading: true };
    case ListProductTypes.LISTPRODUCT_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case ListProductTypes.LISTPRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default reducer;
