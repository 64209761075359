import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

interface IProps {
  permissions: string | null;
  modules: string | null;
  children: any;
}

const VisibleForPermissions: React.FC<IProps> = ({
  permissions,
  modules,
  children,
}: IProps) => {
  const permission = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  const checkPermission = permission.find(element => {
    if (element.modulo === modules && element.permissao === permissions) {
      return true;
    }
    return null;
  });

  if (checkPermission) {
    return children;
  }
  return null;
};

export default VisibleForPermissions;
