import { api } from './api';

interface IListUsers {
  limit?: number;
  page?: number;
  nome?: string;
}

interface ICreateUsers {
  nome: string;
  email: string;
  password: string;
  cpf: string;
  telefone: string;
  cargo: number;
}

interface IUpdateUsersById {
  nome?: string;
  email?: string;
  cpf?: string;
  telefone?: string;
  cargo?: number;
}

interface IAddImgUsers {
  id: number;
  formData: FormData;
}

class UsersService {
  async listUsers({ limit = 200, page = 1, nome }: IListUsers): Promise<any> {
    const response = await api.get(`/user-api/user`, {
      params: {
        limit,
        page,
        nome,
      },
    });

    return response.data;
  }

  async listOffice({ limit = 200, page = 1 }: IListUsers): Promise<any> {
    const response = await api.get(`/user-api/title`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async createUsers(userInfo: ICreateUsers): Promise<any> {
    const response = await api.post(`/user-api/user`, {
      ...userInfo,
    });

    return response.data;
  }

  async addImgUser({ id, formData }: IAddImgUsers): Promise<any> {
    const response = await api.post(`user-api/user/${id}/file`, formData);

    return response.data;
  }

  async getUserById(id: number): Promise<any> {
    const response = await api.get(`/user-api/user/${id}`);

    return response.data;
  }

  async deleteUserById(id: number | string): Promise<any> {
    let response;
    try {
      const request = await api.delete(`/user-api/user/${id}`);
      response = request.data.message;
    } catch (e: any) {
      response = e.error.message;
    }
    return response;
  }

  async createOffice(descricao: string): Promise<any> {
    const response = await api.post(`/user-api/title`, {
      descricao,
    });

    return response.data;
  }

  async updateCustomerById(
    id: number | string,
    userEdit: IUpdateUsersById,
  ): Promise<any> {
    const response = await api.put(`/user-api/user/${id}`, {
      ...userEdit,
    });

    return response.data;
  }

  async listModule(): Promise<any> {
    const response = await api.get(`/profile-api/listpermissions/v2`);
    return response.data;
  }

  async listPemission(): Promise<any> {
    const response = await api.get(`/profile-api/permission`);
    return response.data;
  }

  async pemissionForUser(id: number | string): Promise<any> {
    const response = await api.get(`/profile-api/permission/user-v2/${id}`);
    return response.data;
  }

  async teste(id: number | string): Promise<any> {
    const response = await api.post(`/profile-api/permission/user/${id}`, {
      moduloId: 3,
      permissaoId: 4,
    });
    return response.data;
  }
}

export default new UsersService();
