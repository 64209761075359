import styled from 'styled-components';

export const ContentList = styled('div')<{ state: string }>`
  border: 1px solid #30b4e5;
  background: #fff;
  border-radius: 5px;
  margin: 10px 0px;

  ${props => {
    switch (props.state) {
      case 'Atividade Realizada':
        return `border: 1px solid #30B4E5;`;
      case 'Impedimento':
        return `border: 1px solid #DF0A24;`;
      case 'in progress':
        return `border: 1px solid #54565A;`;
      default:
        return `border: 1px solid #b2e0f2`;
    }
  }}
`;

export const BodyList = styled('div')<{ state: boolean }>`
  display: none;
  padding: 15px 10px;
  font-size: 11px;
  color: #898a8d;
  /* width: 100px; */
  p {
    width: 100%;
    margin: 0;
    word-wrap: break-word;
  }
  ${props =>
    props.state &&
    `
    display: flex;
    justify-content: space-between;
  `}
`;

export const HeaderList = styled('div')<{ state: string }>`
  cursor: pointer;
  border-radius: 5px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    font-weight: 700;
  }
  div {
    color: #000000;
    display: flex;
    align-items: center;
    span {
      font-size: clamp(11px, 1vw + 0.1rem, 16px);
      margin-right: 10px;
      border-radius: 5px;
      padding: 2px;
    }
  }

  ${props => {
    switch (props.state) {
      case 'Atividade Realizada':
        return `background: rgba(48, 180, 229, 0.17);
        span {
          background: rgba(48, 180, 229, 0.35);
        }
     `;
      case 'Impedimento':
        return `background: rgba(223, 10, 36, 0.17);
        span {
         background: rgba(223, 10, 36, 0.35);
       }
       `;
      case 'in progress':
        return `background: rgba(84, 86, 90, 0.17);
        color: #54565A !important;
        span {
         background: rgba(84, 86, 90, 0.35);
        }
     `;
      default:
        return `background: rgba(48, 180, 229, 0.17);
        color: #54565A !important;
        span {
         background: rgba(84, 86, 90, 0.35);
        }
     `;
    }
  }}
`;

export const Button = styled.a`
  padding: 8px;
  border-radius: 8px;
  background: #30b4e5;
  color: #fff !important;
  text-align: center;
`;
