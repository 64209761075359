import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useBudgetRequest } from '~/services/request';
import { IconMoney } from '~/assets/Icons';
import { Button, Input } from '~/components';

import { getBudgetSuccess } from '~/store/modules/budget/actions';
import { IResponseGet } from '~/store/modules/budget/types';
import {
  Container,
  Content,
  Header,
  Valor,
  Desconto,
  Buttons,
  LabelSpan,
} from './styles';

interface IProps {
  Itens?: any;
  Fix: boolean;
  Id: any;
  ButtonLabel?: string;
  ButtonOnClick: any;
  currency?: any;
  quotation?: any;
  disabled?: boolean;
  data: IResponseGet;
  labor?: number;
  // {
  //   descricao: string | undefined;
  //   observacao: string | undefined;
  //   maoDeObra: number | undefined;
  //   desconto: number | undefined;
  //   responsavel: {
  //     id: number;
  //   };
  // };
}

const defaultProps = {
  ButtonLabel: 'Confirmar proposta',
  currency: 'BRL',
  quotation: 1,
  disabled: false,
  labor: undefined,
  Itens: [
    {
      preco: 0,
      quantidade: 0,
    },
  ],
};

const Summary: React.FC<IProps> = ({
  Itens,
  Fix,
  Id,
  ButtonLabel,
  ButtonOnClick,
  currency,
  quotation,
  disabled,
  data,
  labor,
}: IProps) => {
  const [collapseProposal, SetCollapseProposal] = useState(false);
  const [valueDiscount, setValueDiscount] = useState(data.desconto);
  const { deleteBudget, putBudget } = useBudgetRequest();
  let valorTotal = 0;
  const dispatch = useDispatch();

  Itens.forEach((item: any) => {
    valorTotal += item.precoNovo * item.quantidade;
  });

  useEffect(() => {
    setValueDiscount(data.desconto);
  }, [data.desconto]);

  const discountedValue = valueDiscount
    ? valorTotal * (1 - valueDiscount / 100)
    : valorTotal;

  valorTotal /= quotation;
  const valorFinal = labor
    ? (discountedValue * (1 + labor / 100)) / quotation
    : discountedValue / quotation;

  return (
    <Container fix={Fix} state={collapseProposal}>
      <Header onClick={() => SetCollapseProposal(!collapseProposal)}>
        <span>Resumo da Proposta</span>
      </Header>
      <Content>
        <Valor>
          <LabelSpan>
            <IconMoney />
            Valor Total:
          </LabelSpan>
          <Input
            value={valorTotal.toLocaleString('pt-br', {
              style: 'currency',
              currency: currency || 'BRL',
            })}
          />
        </Valor>
        <Desconto>
          <LabelSpan>
            <IconMoney />
            Desconto
          </LabelSpan>
          <div>
            <select>
              <option value="%">%</option>
            </select>
            <Input
              type="text"
              placeholder="0"
              value={valueDiscount}
              onChange={e => {
                const desconto =
                  parseFloat(
                    e.target.value
                      .replace(/\D/g, '')
                      .replace(/(\d{2})(\d{5})/, '$2,$1'),
                  ) || parseFloat('0');
                setValueDiscount(desconto);
                dispatch(
                  getBudgetSuccess({
                    id: data.id,
                    uuid: data.uuid,
                    descricao: data.descricao,
                    preco: data.preco,
                    precoTotal: data.precoTotal,
                    valorDesconto: data.valorDesconto,
                    valorMaoDeObra: data.valorMaoDeObra,
                    desconto,
                    maoDeObra: data.maoDeObra,
                    observacao: data.observacao,
                    responsavel: data.responsavel,
                    criador: data.criador,
                    situacao: data.situacao,
                    cliente: data.cliente,
                    moeda: data.moeda,
                    dataValidade: data.dataValidade,
                    dataCriacao: data.dataCriacao,
                    dataAtualizacao: data.dataAtualizacao,
                    itens: data.itens,
                    justificativa: data.justificativa,
                    endereco: data.endereco,
                    emails: data.emails,
                    pagamento: data.pagamento,
                  }),
                );
              }}
              onBlur={async () => {
                putBudget({ idBudget: Id, discount: valueDiscount });
              }}
            />
          </div>
        </Desconto>
        <Valor>
          <LabelSpan>
            <IconMoney />
            Valor Final:
          </LabelSpan>
          <Input
            disabled
            value={valorFinal.toLocaleString('pt-br', {
              style: 'currency',
              currency: currency || 'BRL',
            })}
          />
        </Valor>
        <p>Você receberá um cópia da proposta por e-mail.</p>
        <Button disabled={disabled} onClick={ButtonOnClick}>
          {ButtonLabel}
        </Button>
        <Buttons>
          <Button
            onClick={() => {
              deleteBudget(Id);
            }}
          >
            Descartar Proposta
          </Button>
        </Buttons>
      </Content>
    </Container>
  );
};

Summary.defaultProps = defaultProps;

export default Summary;
