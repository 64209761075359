/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Input,
  Loading,
  Modal,
  Page,
  PageHeader,
  Select,
} from '~/components';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  validateForm,
} from '~/utils/forms';

import imgDefault from '~/assets/Images/ImgDefault.svg';

import { IconAdd } from '~/assets/Icons';

import TextArea from '~/components/TextArea';
import StockService from '~/services/StockService';
import {
  optionsRequest,
  productStockRequest,
  resetProductStock,
} from '~/store/modules/stock/actions';
import { IState } from '~/store/modules/types';
import { changeMoney } from '~/utils/format/isFormatMoney';
import { isEmpty } from '~/utils/validate';

import UsersService from '~/services/UsersService';
import {
  ButtonAdd,
  ButtonStyle,
  Container,
  ContainerSelect,
  ContainerUpload,
  Content,
  ContentUpload,
  CustomerImg,
  Row,
  RowInput,
} from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string | AnyObject;
  required: boolean;
  error: string;
}

interface IModal extends ITypeModal {
  active: boolean;
  data?: any;
}

interface ITypeModal {
  type?: 'manufacturer' | 'type' | 'subtype' | 'success' | 'error';
}

type typeInput = {
  [key: string]: IPropsInput;
};

interface AnyObject {
  [key: string]: any;
}

const DICT: any = {
  descricao: 'name',
  fabricante: 'producer',
  tipo: 'category',
  subTipo: 'subcategory',
  preco: 'sellValue',
  especificacaoTecnica: 'technicalSpecification',
  descricaoTecnica: 'technicalDescription',
  descricaoComercial: 'commercialDescription',
  codigo: 'code',
  estoque: {
    estoqueTotal: 'actualStock',
    estoqueMinimo: 'minimunStock',
    precoPago: 'payValue',
    estoqueAtual: 'totalStock',
    estoqueAbate: 'blockedStock',
  },
  observacoes: 'observation',
};

const DICT_SAVE: any = {
  name: 'descricao',
  producer: 'fabricanteId',
  category: 'tipoId',
  subcategory: 'subTipoId',
  sellValue: 'preco',
  payValue: 'precoPago',
  technicalSpecification: 'especificacaoTecnica',
  technicalDescription: 'descricaoTecnica',
  commercialDescription: 'descricaoComercial',
  code: 'codigo',
  minimunStock: 'estoqueMinimo',
  totalStock: 'quantidadeEmEstoque',
  observation: 'observacoes',
};

const Product: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [id] = useState(history.location.state?.id);
  const [modal, setModal] = useState<IModal>({ active: false });
  const [imgUrl, setImgUrl] = useState('');
  const [file, setFile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [inputModal, setInputModal] = useState('');
  const userId = useSelector((state: IState) => state.auth.data.account.id);
  const [permission, setPermission] = useState(false);
  const [inputState, setInputState] = useState<typeInput>({
    ...generateRequiredInputValues([
      'name',
      'producer',
      'category',
      'subcategory',
      'payValue',
      'minimunStock',
      'actualStock',
      'technicalSpecification',
      'technicalDescription',
      'commercialDescription',
      'code',
    ]),
    ...generateDefaultInputValues([
      'sellValue',
      'observation',
      'totalStock',
      'blockedStock',
    ]),
  });

  const { product, loadingProduct, type, subtype, manufacturer } = useSelector(
    (state: IState) => state.stock,
  );

  const verifyPermission = async () => {
    const responseUser = await UsersService.getUserById(userId);
    if (
      responseUser?.cargo?.descricao.toUpperCase() === 'DIRETOR' ||
      responseUser?.cargo?.descricao.toUpperCase() === 'GERENTE DE PROJETOS' ||
      responseUser?.cargo?.descricao.toUpperCase() === 'GERENTE GERAL'
    ) {
      setPermission(true);
      setInputState(previousState => {
        const inputState = { ...previousState };
        inputState.sellValue = {
          ...inputState.sellValue,
          required: true,
        };
        return inputState;
      });
    }
  };

  useEffect(() => {
    verifyPermission();
  }, []);

  useEffect(() => {
    dispatch(optionsRequest(['type', 'subtype', 'manufacturer']));
  }, []);

  useEffect(() => {
    dispatch(resetProductStock());
    if (id) dispatch(productStockRequest(id));
  }, [id]);

  useEffect(() => {
    handleProduct();
  }, [product]);

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState as any);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const handleProduct = () => {
    if (product && id) {
      const keys = Object.keys(product);
      const data = keys.reduce((acum: typeInput, curr) => {
        const valueDict = DICT[curr];

        if (valueDict)
          if (typeof valueDict === 'object') {
            const data = Object.keys(valueDict).reduce(
              (acum: typeInput, dict) => {
                acum[valueDict[dict]] = {
                  ...inputState[valueDict[dict]],
                  value: (product as any)[curr][dict],
                };
                return acum;
              },
              {},
            );
            acum = { ...acum, ...data };
          } else {
            acum[valueDict] = {
              ...inputState[valueDict],
              value: (product as any)[curr],
            };
          }

        return acum;
      }, {});
      if (product.imagens.length)
        setImgUrl(product.imagens[product.imagens.length - 1].fileUrl);

      setInputState(previousState => ({
        ...previousState,
        ...data,
      }));
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    if (
      acceptedFiles[0].type === 'image/jpeg' ||
      acceptedFiles[0].type === 'image/png'
    ) {
      setFile(acceptedFiles[0]);
      getBase64(acceptedFiles[0], (imageUrl: any) => {
        setImgUrl(imageUrl);
      });
    }
  }, []);

  const getBase64 = (img: any, callback: any) => {
    if (img.type === 'image/jpeg' || img.type === 'image/png') {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  const content = () => {
    if (imgUrl) {
      return <CustomerImg src={imgUrl} alt="Foto do cliente" />;
    }
    return (
      <ContentUpload>
        <img src={imgDefault} alt="sem imagem" />
        <p>
          Clique aqui para adicionar uma imagem do seu computador ou arraste-a
          para esse campo.
        </p>
      </ContentUpload>
    );
  };

  const handleInputChange = (value: any, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'actualStock') {
      const blockedStock = getValue('blockedStock');
      handleInputChange(
        parseInt(blockedStock || '0', 10) + (parseInt(value, 10) || 0),
        'totalStock',
      );
    }

    if (inputState[inputName].required && !value) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    if (inputName === 'actualStock') {
      value = parseInt(value, 10);
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleInputBlur = async (value: any, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && !value) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleClickModal = ({ type }: ITypeModal) => {
    setModal({ active: true, type });
  };

  const closeModal = () => {
    setModal({ active: false });
    setInputModal('');
  };

  const handleType = async (value: string, type: string) => {
    const data = await StockService.saveOption({ type, value });
    const DICT: AnyObject = {
      type: 'category',
      subtype: 'subcategory',
      manufacturer: 'producer',
    };
    handleInputChange(data, DICT[type]);
    closeModal();
  };

  const renderModal = useMemo(() => {
    if (!modal.active || !modal.type) return;

    const MODAL_TITLE = {
      manufacturer: 'Fabricante',
      type: 'Categoria',
      subtype: 'SubCategoria',
      success: `Sucesso ao ${product?.id ? 'editar' : 'criar'} produto`,
      error: `Erro ao ${product?.id ? 'editar' : 'criar'} produto`,
    };

    return (
      <Modal>
        <Content>
          <h3>{MODAL_TITLE[modal.type]}</h3>
          {!['success', 'error'].includes(modal.type) ? (
            <>
              <Row>
                <Input
                  value={inputModal}
                  onChange={e => setInputModal(e.target.value)}
                />
              </Row>
              <Row>
                <ButtonStyle>
                  <Button onClick={closeModal}>Cancelar</Button>
                </ButtonStyle>
                <Button
                  onClick={() => handleType(inputModal, modal.type || '')}
                >
                  Salvar
                </Button>
              </Row>
            </>
          ) : (
            <ButtonStyle>
              <Button
                onClick={() => {
                  if (modal.type === 'success') {
                    history.push({
                      pathname: '/stock',
                    });
                  } else {
                    closeModal();
                  }
                }}
              >
                Entendi,{' '}
                {modal.type === 'success'
                  ? 'voltar para produtos'
                  : 'tentar novamente'}
              </Button>
            </ButtonStyle>
          )}
        </Content>
      </Modal>
    );
  }, [modal, inputModal, product]);

  const getValue = (name: string, param = 'nome') => {
    const value = inputState[name].value;
    if (!value) return undefined;
    return typeof value !== 'object' ? value : value[param];
  };

  const numberFormat = (e: number | null) => {
    return (e || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const saveProduct = async () => {
    setLoading(true);
    const data = Object.keys(inputState).reduce((acum: any, curr) => {
      acum[DICT_SAVE[curr]] = getValue(curr, 'id');
      return acum;
    }, {});

    delete data.undefined;

    try {
      await StockService.saveProduct({
        ...data,
        ...(product ? { id: product.id } : {}),
        categoriaId: 1,
        ...(file ? { file } : {}),
      });
      handleClickModal({ type: 'success' });
      setLoading(false);
    } catch (error) {
      handleClickModal({ type: 'error' });
      setLoading(false);
    }
  };

  return (
    <Page>
      {renderModal}
      <PageHeader
        title={id ? 'Editar Produto' : 'Novo Produto'}
        button="Salvar"
        disabled={loading || !isFormValid()}
        onClick={saveProduct}
        permission={
          id
            ? { module: 'ESTOQUE DE PRODUTOS', permission: 'GERENCIAR' }
            : { module: 'ESTOQUE DE PRODUTOS', permission: 'INCLUIR' }
        }
      />
      {loadingProduct || loading ? (
        <Loading />
      ) : (
        <Container>
          <ContainerUpload>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragReject && (
                <ContentUpload>
                  <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                </ContentUpload>
              )}
              {isDragActive ? (
                <ContentUpload>
                  <p>Solte os arquivos aqui ... </p>
                </ContentUpload>
              ) : (
                content()
              )}
            </div>
          </ContainerUpload>
          <Content>
            <span>
              <b>Dados Gerais</b>
            </span>
            <RowInput>
              <Input
                label="Código Produto"
                maxLenght={70}
                error={inputState.code.error}
                value={getValue('code')}
                onChange={e => handleInputChange(e.target.value, 'code')}
                onBlur={e => handleInputBlur(e.target.value, 'code')}
              />
              <Input
                label="Nome Produto"
                maxLenght={70}
                error={inputState.name.error}
                value={getValue('name')}
                onChange={e => handleInputChange(e.target.value, 'name')}
                onBlur={e => handleInputBlur(e.target.value, 'name')}
              />
            </RowInput>
            <RowInput>
              <RowInput alignItems="flex-end">
                <ContainerSelect>
                  <Select
                    label="Fabricante"
                    onClickList={e => handleInputChange(e, 'producer')}
                    currentValue={getValue('producer')}
                    lista={manufacturer}
                  />
                </ContainerSelect>
                <ButtonAdd
                  onClick={() => handleClickModal({ type: 'manufacturer' })}
                >
                  <IconAdd />
                </ButtonAdd>
              </RowInput>
              <div style={{ flex: 1 }} />
            </RowInput>
            <RowInput>
              <RowInput alignItems="flex-end">
                <ContainerSelect>
                  <Select
                    label="Categoria"
                    onClickList={e => handleInputChange(e, 'category')}
                    currentValue={getValue('category')}
                    lista={type}
                  />
                </ContainerSelect>
                <ButtonAdd onClick={() => handleClickModal({ type: 'type' })}>
                  <IconAdd />
                </ButtonAdd>
              </RowInput>
              <RowInput alignItems="flex-end">
                <ContainerSelect>
                  <Select
                    label="SubCategoria"
                    onClickList={e => handleInputChange(e, 'subcategory')}
                    currentValue={getValue('subcategory')}
                    lista={subtype}
                  />
                </ContainerSelect>
                <ButtonAdd
                  onClick={() => handleClickModal({ type: 'subtype' })}
                >
                  <IconAdd />
                </ButtonAdd>
              </RowInput>
            </RowInput>
            <span>
              <b>Informações monetárias</b>
            </span>
            <RowInput>
              {permission ? (
                <>
                  <Input
                    label="Valor Pago"
                    maxLenght={70}
                    error={inputState.payValue.error}
                    value={numberFormat(getValue('payValue'))}
                    onChange={e =>
                      handleInputChange(
                        !Number.isNaN(changeMoney(e.target.value))
                          ? changeMoney(e.target.value)
                          : getValue('payValue'),
                        'payValue',
                      )
                    }
                    onBlur={e =>
                      handleInputBlur(
                        !Number.isNaN(changeMoney(e.target.value))
                          ? changeMoney(e.target.value)
                          : getValue('payValue'),
                        'payValue',
                      )
                    }
                  />
                  <Input
                    label="Valor Venda"
                    maxLenght={70}
                    error={inputState.sellValue.error}
                    value={numberFormat(getValue('sellValue'))}
                    onChange={e =>
                      handleInputChange(
                        !Number.isNaN(changeMoney(e.target.value))
                          ? changeMoney(e.target.value)
                          : getValue('sellValue'),

                        'sellValue',
                      )
                    }
                    onBlur={e =>
                      handleInputBlur(
                        !Number.isNaN(changeMoney(e.target.value))
                          ? changeMoney(e.target.value)
                          : getValue('sellValue'),

                        'sellValue',
                      )
                    }
                  />
                </>
              ) : (
                <div style={{ width: '50%' }}>
                  <Input
                    label="Valor Pago"
                    maxLenght={70}
                    error={inputState.payValue.error}
                    value={numberFormat(getValue('payValue'))}
                    onChange={e =>
                      handleInputChange(
                        !Number.isNaN(changeMoney(e.target.value))
                          ? changeMoney(e.target.value)
                          : getValue('payValue'),
                        'payValue',
                      )
                    }
                    onBlur={e =>
                      handleInputBlur(
                        !Number.isNaN(changeMoney(e.target.value))
                          ? changeMoney(e.target.value)
                          : getValue('payValue'),
                        'payValue',
                      )
                    }
                  />
                </div>
              )}
            </RowInput>
            <span>
              <b>Detalhes Produto</b>
            </span>
            <RowInput>
              <Input
                label="Estoque mínimo"
                maxLenght={70}
                error={inputState.minimunStock.error}
                value={getValue('minimunStock')}
                onChange={e =>
                  handleInputChange(
                    e.target.value.replace(/\D/gi, ''),
                    'minimunStock',
                  )
                }
                onBlur={e => handleInputBlur(e.target.value, 'minimunStock')}
              />
              <Input
                label="Estoque Atual"
                maxLenght={70}
                error={inputState.actualStock.error}
                value={getValue('actualStock') || 0}
                onChange={e =>
                  handleInputChange(
                    e.target.value.replace(/\D/gi, ''),
                    'actualStock',
                  )
                }
                onBlur={e => handleInputBlur(e.target.value, 'actualStock')}
              />
              <Input
                label="Estoque Total"
                disabled
                value={getValue('totalStock')}
              />
              <Input
                label="Estoque Reservado Orçamento"
                disabled
                value={getValue('blockedStock')}
              />
            </RowInput>
            <RowInput>
              <TextArea
                rows={3}
                maxLenght={250}
                label="Especificacão técnica"
                error={inputState.technicalSpecification.error}
                value={getValue('technicalSpecification')}
                onChange={e =>
                  handleInputChange(e.target.value, 'technicalSpecification')
                }
                onBlur={e =>
                  handleInputBlur(e.target.value, 'technicalSpecification')
                }
              />
              <TextArea
                rows={3}
                maxLenght={250}
                label="Descrição técnica"
                error={inputState.technicalDescription.error}
                value={getValue('technicalDescription')}
                onChange={e =>
                  handleInputChange(e.target.value, 'technicalDescription')
                }
                onBlur={e =>
                  handleInputBlur(e.target.value, 'technicalDescription')
                }
              />
            </RowInput>
            <RowInput>
              <TextArea
                rows={3}
                label="Descrição comercial"
                maxLenght={190}
                error={inputState.commercialDescription.error}
                value={getValue('commercialDescription')}
                onChange={e =>
                  handleInputChange(e.target.value, 'commercialDescription')
                }
                onBlur={e =>
                  handleInputBlur(e.target.value, 'commercialDescription')
                }
              />
              <TextArea
                rows={3}
                maxLenght={250}
                label="Observações"
                error={inputState.observation.error}
                value={getValue('observation')}
                onChange={e => handleInputChange(e.target.value, 'observation')}
                onBlur={e => handleInputBlur(e.target.value, 'observation')}
              />
            </RowInput>
          </Content>
        </Container>
      )}
    </Page>
  );
};

export default Product;
