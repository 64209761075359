import { api } from './api';

interface IPermissions {
  id: number | string;
  object: any[];
}

class UsersService {
  async listMyPermission(): Promise<any> {
    const response = await api.get(`/profile-api/listpermissions/v2`);
    return response.data;
  }

  async listModule(): Promise<any> {
    const response = await api.get(`/profile-api/module`);
    return response.data;
  }

  async listPemission(): Promise<any> {
    const response = await api.get(`/profile-api/permission`);
    return response.data;
  }

  async pemissionForUser(id: number | string): Promise<any> {
    const response = await api.get(`/profile-api/permission/user/${id}`);
    return response.data;
  }

  async attribute({ id, object }: IPermissions): Promise<any> {
    // object.shift();
    const response = await api.post(`/profile-api/permission/user/v2/${id}`, {
      permissoes: object,
    });
    return response.data;
  }

  async deletePermission({ id, object }: IPermissions): Promise<any> {
    object.shift();
    const response = await api.delete(`/profile-api/permission/user/${id}`, {
      data: {
        permissoes: object,
      },
    });
    return response.data;
  }
}

export default new UsersService();
