import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 25%;
  height: 100%;
  flex-direction: column;

  box-shadow: 0px 1px 4px 0px #00000047;

  padding: 5px;
`;

export const Content = styled.div`
  width: 98%;
  margin: 1%;
  padding: 2%;
  box-shadow: 0px 1px 4px 0px #00000047;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  p {
    margin 0
  }
  svg {
    height: 20px;
    margin-right: 10px;
  }
  div{
    display: flex;
    align-items: center;
  }
`;

export const Header = styled.div`
  /* height: 0; */
  display: flex;
  justify-content: space-between;

  svg {
    width: 20px;
    color: #30b4e5;
  }
  button {
    height: fit-content;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  div {
    margin: 0 10px;

    p {
      margin-botton: 0;
    }

    div {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InputBox = styled.div`
  width: 100%;

  button {
    div {
      margin-right: 10px;
    }
  }
`;

export const Display = styled.div`
  padding: 1%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  height: fit-content;
  margin: 10px 10px 10px 10px;
  font-size: 12px;

  display: flex;
  justify-content: space-between;
`;

export const ButtonStyles = styled.div`
  button {
    margin: 2% 1%;
    width: 98%;
  }
`;
