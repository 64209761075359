import { IconAlert } from '~/assets/Icons';
import { Button, Modal } from '~/components';
import { ModalContainer } from './styles';

interface IProps {
  title: string;
  content: string;
  onClick: () => void;
}

const ModalAlertDefault: React.FC<IProps> = ({
  title,
  content,
  onClick,
}: IProps) => {
  return (
    <Modal>
      <ModalContainer>
        <div>
          <i>
            <IconAlert />
          </i>

          <b>{title}</b>
          <span>{content}</span>
        </div>
        <Button onClick={onClick}>Entendi!</Button>
      </ModalContainer>
    </Modal>
  );
};

export default ModalAlertDefault;
