/* eslint-disable react/jsx-props-no-spreading */
import { useRef } from 'react';
import { Input } from '~/components';

import { IPropsInput } from '~/components/Input';
import { IconAdd, IconReturn } from '~/assets/Icons';
import {
  ButtonAdd,
  ButtonContainer,
  DivList,
  Icon,
  ListContent,
} from './styles';

interface IList {
  [key: string]: any;
  id: number;
  descricao: string;
}

interface IProps extends IPropsInput {
  lista: IList[];
  onClickList: (item: any) => void;
  onClickAdd?: (() => void) | null;
  valueInput?: string;
  currentValue: string;
  nameObject?: string;
  lengthShow?: number;
  visibleList?: boolean;
  setVisibleList?: (() => void) | null;
  setCleanInput?: (() => void) | null;
}

const defaultProps = {
  onClickAdd: null,
  nameObject: 'descricao',
  lengthShow: 2,
  visibleList: false,
  valueInput: '',
  setVisibleList: null,
  setCleanInput: null,
};

const InputSearch: React.FC<IProps> = ({
  lista,
  onClickList,
  onClickAdd,
  valueInput,
  currentValue,
  nameObject,
  lengthShow,
  visibleList,
  setVisibleList,
  setCleanInput,
  ...rest
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const widthInput = ref.current?.offsetWidth;

  window.onclick = (e: any) => {
    const { target } = e;
    const alvMenu = target.classList.contains('input');
    const paiMenu = target.closest('.input');
    if (visibleList && !alvMenu && !paiMenu && setVisibleList) {
      setVisibleList();
    }
    if (valueInput !== currentValue && !alvMenu && !paiMenu && setCleanInput) {
      setCleanInput();
    }
    return null;
  };

  return (
    <>
      <ButtonContainer className="input" ref={ref} button={!!onClickAdd}>
        <Input
          icon={
            <Icon>
              <IconReturn />
            </Icon>
          }
          {...rest}
        />
        <ButtonAdd
          type="button"
          button={!!onClickAdd}
          onClick={() => {
            if (onClickAdd) onClickAdd();
          }}
        >
          <IconAdd />
        </ButtonAdd>
      </ButtonContainer>
      {valueInput!.length > lengthShow! && valueInput !== currentValue && (
        <DivList className="input" width={widthInput}>
          {lista
            .filter((list: any) => {
              return (
                `${list[`${nameObject}`]}`
                  .toLowerCase()
                  .indexOf(valueInput!.toLowerCase()) > -1
              );
            })
            .map((item: any) => {
              return (
                <ListContent
                  key={item.id}
                  onClick={() => {
                    onClickList(item);
                  }}
                >
                  {item[nameObject!]}
                </ListContent>
              );
            })}
        </DivList>
      )}
      {visibleList && (
        <DivList className="input" width={widthInput}>
          {lista.map((item: any) => {
            return (
              <ListContent
                key={item.id}
                onClick={() => {
                  onClickList(item);
                }}
              >
                {item[nameObject!]}
              </ListContent>
            );
          })}
        </DivList>
      )}
    </>
  );
};

InputSearch.defaultProps = defaultProps;

export default InputSearch;
