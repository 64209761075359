import styled from 'styled-components';

export const BtEdit = styled('button')<{ locked?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue.Main};
    ${props => props.locked && 'color: #54565A; cursor: not-allowed;'}
  }
`;

export const BtAlert = styled('button')<{ locked?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
  }
`;

export const BtBuy = styled('button')<{ locked?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue.Main};
    ${props => props.locked && 'color: #54565A; cursor: not-allowed;'}
  }
`;

export const BtTrash = styled('a')<{ disabled?: boolean; locked?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
    ${props => props.locked && 'color: #54565A !important;'}
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
