/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  IconAttachment,
  IconFilter,
  IconReturn,
  IconSearch,
  IconTag,
} from '~/assets/Icons';
import { Input, Modal, TextArea, Button, ModalDefault } from '~/components';
import ConstructionsService from '~/services/ConstructionsService';
import { ErroAlert } from '~/components/TextArea/styles';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  validateForm,
} from '~/utils/forms';
import { isEmpty } from '~/utils/validate';
import { CheckPermission } from '~/utils/checkPermission';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import {
  FooterTextArea,
  HeaderTimeline,
  InputHeader,
  ListTag,
  Title,
} from './styles';
import ListJustification from './components/ListJustification';

interface IProps {
  permission: boolean;
  id: number | string;
  dataSource: any;
  user: any;
  stageWorkId: {
    workManagement: boolean;
    technicalProject: boolean;
    visualDesign: boolean;
    quantitative: boolean;
  };
}

interface IPropsInput {
  isValid: boolean;
  value: any;
  error: string;
  required: boolean;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const SwitchHistoric: React.FC<IProps> = ({
  id,
  stageWorkId,
  dataSource,
  user,
  permission,
}: IProps) => {
  const [dropDownToday, setDropDownToday] = useState(false);
  const [dropDownWeek, setDropDownWeek] = useState(false);
  const [dropDownMonth, setDropDownMonth] = useState(false);

  const [timelineToday, setTimelineToday] = useState<any[]>([]);
  const [timelineWeek, setTimelineWeek] = useState<any[]>([]);
  const [timelineMonth, setTimelineMonth] = useState<any[]>([]);

  const [tag, setTag] = useState([]);
  const [isVisibleModalTagSelect, setIsVisibleModalTagSelect] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [erro, setErro] = useState('');
  const stateSchema = {
    ...generateRequiredInputValues(['text', 'tag']),
    ...generateDefaultInputValues(['file']),
  };
  const userId = useSelector((state: IState) => state.auth.data.account.id);
  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  // const [permission, setPermission] = useState(false);
  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  const handleInputChange = (value: any, inputName: string) => {
    let isValid = true;
    let error = '';

    if (inputName === 'text' && value.length < 20) {
      error = 'Digite pelo menos 20 caracteres.';
      isValid = false;
    }
    if (inputName === 'tag' && (!value.id || value.id === 0)) {
      error = 'Selecione uma categoria.';
      isValid = false;
    }

    setErro(error);
    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        error,
        required: inputState[inputName].required,
      },
    }));
  };

  const stageWork = () => {
    if (stageWorkId.workManagement) {
      return 58;
    }
    if (stageWorkId.visualDesign) {
      return 55;
    }
    if (stageWorkId.technicalProject) {
      return 56;
    }
    if (stageWorkId.quantitative) {
      return 57;
    }
    return 0;
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        setErro(inputState[inputValue].error);
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  // const verifyPermission = (): boolean => {
  //   if (dataSource.terminado) {
  //     return false;
  //   }
  //   if (!user) return false;
  //   if (
  //     (user?.toUpperCase() === 'DIRETOR' ||
  //       user?.toUpperCase() === 'GERENTE DE PROJETOS' ||
  //       user?.toUpperCase() === 'GERENTE GERAL' ||
  //       userId === dataSource.corpo_tecnico?.gerente?.id ||
  //       userId === dataSource.corpo_tecnico?.engenheiro?.id ||
  //       userId === dataSource.corpo_tecnico?.arquiteto?.id) &&
  //     userPermissions.find(element => {
  //       if (
  //         element.modulo === 'DIÁRIO DE OBRAS' &&
  //         element.permissao === 'ATUALIZAR'
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     })
  //   ) {
  //     setPermission(true);
  //   }

  //   return false;
  // };

  // useEffect(() => {
  //   verifyPermission();
  // }, []);

  useEffect(() => {
    const load = async () => {
      try {
        const responseListTag = await ConstructionsService.listTag();
        setTag(responseListTag.result);
      } catch {
        setIsVisibleModalErro(true);
      }
    };
    load();
  }, []);

  const load = async () => {
    try {
      const response = await ConstructionsService.getTimelineById(id);
      const today: any = new Date();
      response.result.forEach((item: { data: string }) => {
        const date = new Date(item.data);
        const dateWeek: any = new Date();
        dateWeek.setDate(today.getDate() - 3);

        if (
          date.getDate() === today.getDate() &&
          date.getMonth() === today.getMonth() &&
          date.getFullYear() === today.getFullYear()
        ) {
          return setTimelineToday(prevState => [...prevState, item]);
        }
        if (
          date.getDate() >= dateWeek.getDate() &&
          date.getMonth() <= dateWeek.getMonth() &&
          date.getFullYear() <= dateWeek.getFullYear()
        ) {
          return setTimelineWeek(prevState => [...prevState, item]);
        }
        return setTimelineMonth(prevState => [...prevState, item]);
      });
    } catch {
      setIsVisibleModalErro(true);
    }
  };

  const createTimeline = async () => {
    Object.entries(inputState).forEach(allInput => {
      handleInputChange(allInput[1].value, allInput[0]);
    });
    if (isFormValid()) {
      try {
        await ConstructionsService.createTimeline({
          id,
          stageWorkId: stageWork(),
          tagId: inputState.tag.value.id,
          text: inputState.text.value,
        });
        if (inputState.file?.value[0]) {
          const formData = new FormData();
          formData.append('tab', stageWork().toString());
          formData.append('obra', id.toString());
          formData.append('file', inputState.file.value[0]);

          ConstructionsService.uploadFile(formData);
        }
        Object.entries(inputState).forEach(allInput => {
          setInputState((prevState: any) => ({
            ...prevState,
            [allInput[0]]: {
              required: allInput[1].required,
              isValid: true,
              error: '',
              value: '',
            },
          }));
        });
        setTimelineToday([]);
        setTimelineWeek([]);
        setTimelineMonth([]);
        load();
      } catch {
        setIsVisibleModalErro(true);
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  // const getPermission = () => {
  //   return (
  //     dataSource.terminado ||
  //     !(
  //       CheckPermission({
  //         permission: 'INCLUIR',
  //         module: 'DIÁRIO DE OBRAS',
  //       }) ||
  //       CheckPermission({
  //         permission: 'ATUALIZAR',
  //         module: 'DIÁRIO DE OBRAS',
  //       })
  //     )
  //   );
  // };

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          onClick={() => setIsVisibleModalErro(false)}
          success={false}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModalTagSelect && (
        <Modal>
          <>
            <b>Selecione a categoria</b>
            <ListTag>
              {tag.map((element: any) => (
                <button
                  type="button"
                  onClick={() => {
                    handleInputChange(
                      {
                        id: element.id,
                        description: element.descricao,
                      },
                      'tag',
                    );
                    setIsVisibleModalTagSelect(false);
                  }}
                >
                  {element.descricao}
                </button>
              ))}
            </ListTag>
            <Button
              styles={1}
              onClick={() => setIsVisibleModalTagSelect(false)}
            >
              Cancelar
            </Button>
          </>
        </Modal>
      )}
      <TextArea
        disabled={!permission}
        onChange={e => {
          handleInputChange(e.target.value, 'text');
        }}
        value={inputState.text.value}
        label={
          <div>
            <b>Inserir evento na obra</b> (Máx. 2000 caracteres)
          </div>
        }
        maxLenght={2000}
      />
      <FooterTextArea disabled={!permission}>
        <label htmlFor="inputFile">
          <input
            disabled={!permission}
            id="inputFile"
            type="file"
            onChange={e => {
              handleInputChange(e.target.files, 'file');
            }}
          />
          <span>
            <IconAttachment />
          </span>
          {inputState.file?.value[0]
            ? inputState.file?.value[0].name
            : 'Anexar um arquivo'}
        </label>
        <button
          disabled={!permission}
          type="button"
          onClick={() => setIsVisibleModalTagSelect(true)}
        >
          <span>
            <IconTag />
          </span>
          {inputState.tag.value?.description ||
            'Adicionar o tipo do comentário'}
        </button>
        <button
          disabled={!permission}
          type="button"
          onClick={() => createTimeline()}
        >
          <span>OK</span>
        </button>
      </FooterTextArea>
      <ErroAlert erro={!!erro}>{erro}</ErroAlert>
      <Title>{erro}Timeline de Eventos</Title>
      <InputHeader>
        <Input placeholder="Buscar por um evento" icon={<IconSearch />} />
        <span>
          <Input placeholder="Filtrar por..." icon={<IconFilter />} />
        </span>
      </InputHeader>
      <HeaderTimeline
        active={dropDownToday}
        onClick={() => setDropDownToday(!dropDownToday)}
      >
        Hoje
        <IconReturn />
      </HeaderTimeline>
      <>
        {dropDownToday && (
          <>
            {timelineToday.map(element => (
              <ListJustification key={element.id} element={element} />
            ))}
          </>
        )}
        <HeaderTimeline
          active={dropDownWeek}
          onClick={() => setDropDownWeek(!dropDownWeek)}
        >
          Últimos 3 dias
          <IconReturn />
        </HeaderTimeline>
        {dropDownWeek && (
          <>
            {timelineWeek.map((element: any) => (
              <ListJustification key={element.id} element={element} />
            ))}
          </>
        )}
        <HeaderTimeline
          active={dropDownMonth}
          onClick={() => setDropDownMonth(!dropDownMonth)}
        >
          Últimos mês
          <IconReturn />
        </HeaderTimeline>
        {dropDownMonth && (
          <>
            {timelineMonth.map(element => (
              <ListJustification key={element} element={element} />
            ))}
          </>
        )}
      </>
    </>
  );
};

export default SwitchHistoric;
