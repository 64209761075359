/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { INotification, NotificationTypes } from './types';

export const loadRequest = (id: number, page: number, state: any[]) =>
  action(NotificationTypes.LOAD_REQUEST, { user_id: id, page, state });

export const loadSuccess = (
  notifications: INotification[],
  unread: number,
  pages: number,
) => action(NotificationTypes.LOAD_SUCCCES, { notifications, unread, pages });

export const loadFailure = () => action(NotificationTypes.LOAD_FAILURE);
