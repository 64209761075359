/* eslint-disable react/jsx-no-bind */
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'antd';
import { Avatar, SideMenu, SideNotification } from '~/components';
import LogoWater from '~/assets/Logotipo/water-design-logo-grande.png';

import { usePageContext } from '~/hooks';
import { IconMenu, IconNotifications } from '~/assets/Icons';
import { logoutRequest } from '~/store/modules/auth/actions';
import { IState } from '~/store/modules/types';

import { useState } from 'react';
import { useHistory } from 'react-router';
import {
  Container,
  Content,
  Child,
  HeaderButton,
  Logo,
  ButtonSair,
  UserButton,
  Mobile,
  MenuDropdown,
} from './styles';

const Header: React.FC = () => {
  const {
    setCollapseMenu,
    setCollapseNotification,
    collapseMenu,
    collapseNotification,
    fixed,
    setFocus,
  } = usePageContext();

  const user = useSelector((state: any) => state.auth.data);
  const [menuProfile, setMenuProfile] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  function logout() {
    sessionStorage.clear();
    dispatch(logoutRequest());
  }

  window.onclick = (e: any) => {
    const { target } = e;
    const alvMenu = target.classList.contains('menu');
    const paiMenu = target.closest('.menu');
    const alvNotf = target.classList.contains('notification');
    const paiNotf = target.closest('.notification');
    const alvInputSearch = target.classList.contains('inputSearch');
    const paiInputSearch = target.closest('.inputSearch');
    if (!alvInputSearch && !paiInputSearch) {
      setFocus(false);
    }
    if (collapseMenu && !alvMenu && !paiMenu) {
      setCollapseMenu(false);
    }
    if (collapseNotification && !alvNotf && !paiNotf) {
      setCollapseNotification(false);
    }
    return null;
  };

  const notificationsUnread = useSelector(
    (state: IState) => state.notification.unread,
  );

  return (
    <Child>
      <SideMenu />
      <SideNotification />
      <Container>
        <Content>
          <HeaderButton
            state={collapseMenu}
            notification={false}
            className="menu"
            onClick={() => setCollapseMenu(true)}
          >
            <IconMenu />
          </HeaderButton>
        </Content>
        <Content>
          <Logo fix={fixed} src={LogoWater} alt="Logo-Waterdesing" />
        </Content>
        <Content>
          <Mobile>
            <HeaderButton
              state={false}
              notification
              className="notification"
              onClick={() => setCollapseNotification(!collapseNotification)}
            >
              <Badge
                count={notificationsUnread}
                color="white"
                offset={[0, 10]}
                size="small"
              >
                <IconNotifications />
              </Badge>
            </HeaderButton>
          </Mobile>
          <UserButton onClick={() => setMenuProfile(!menuProfile)}>
            <Avatar
              name={`${user.account.firstName} ${user.account.lastName}`}
            />
            {menuProfile && (
              <MenuDropdown>
                <button type="button" onClick={() => history.push('/password')}>
                  Redefinir Senha
                </button>
              </MenuDropdown>
            )}
          </UserButton>
          <Mobile>
            <ButtonSair onClick={logout}>Sair</ButtonSair>
          </Mobile>
        </Content>
      </Container>
    </Child>
  );
};

export default Header;
