import styled from 'styled-components';

export const Table = styled.div`
  @media (max-width: 1100px) {
    overflow: scroll;
    max-width: 1100px;
  }
`;

export const Header = styled.div`
  margin: 3%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin: 0 10px;
    width: 50%;
  }
`;

export const DataNull = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;

  b {
    font-size: 15px;
    height: fit-content;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
`;

export const ContainerTable = styled.div`
  padding: 10px 30px;
  margin: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;

  div {
    padding: 0;
  }

  @media (max-width: 570px) {
    padding: 10px;
  }
`;

export const HeaderTable = styled.div`
  display: flex;
  margin: 30px 0px -20px 20px;
`;

export const ButtonHeaderTable = styled('button')<{ active: boolean }>`
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.08);
  background: rgba(48, 180, 229, 0.35);
  border-radius: 3px 3px 0px 0px;
  padding: 8px 25px;
  margin: 0 10px;

  ${props =>
    props.active &&
    `
  background: #ffffff;

  `}
`;

export const ContainerActionTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BtStatus = styled.a`
  margin: 0 5px;
`;

export const BtTrash = styled('button')<{
  disabled?: boolean;
  locked?: boolean;
}>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
    ${props => props.locked && 'color: #54565A; cursor: not-allowed;'}
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Status = styled.div<{ type?: boolean }>`
  border-radius: 50%;
  width: 10px;
  height: 10px;

  background-color: #75b843 !important;

  ${props => props.type && `background-color: #F0D443 !important;`}
`;

export const DeletDiv = styled.div`
  button {
    width: 40%;
  }
`;

export const ContentDelete = styled.div`
  margin-top: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;
`;

export const TitleModal = styled.div`
  svg {
    color: #30b4e5;
  }
  button {
    width: fit-content !important;
  }
  display: flex;
  justify-content: right;
  margin: -10px -10px 0 0;
`;

export const ContentModal = styled.div`
  text-align: center;
`;

export const HeaderModalFilter = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: -30px;
  font-size: 20px;

  p {
    margin: 0 0 10px 0;
    font-weight: 300;
  }
`;

export const ContentModalFilter = styled.div`
  button {
    margin-top: 15px;
  }
`;

export const DivList = styled.div`
  margin-top: 50px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  position: absolute;
  width: 22%;
  background: #ffffff;
  z-index: 900;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  a + a {
    border-top: 1px solid #30b4e5;
  }
`;

export const ListContent = styled.a`
  display: flex;
  padding: 10px;
  color: #323232;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
`;

export const Row = styled.div`
  display: flex;
`;

export const CleanFilter = styled.button`
  border: 1px solid #30b4e5;
  border-radius: 7px;
  color: #30b4e5;
  font-size: clamp(8px, 1vw + 0.1rem, 13px);
  padding: 5px;
  margin-top: 40px;
  height: fit-content;
`;
// ------------------------------------

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  width: 5vh;
  height: 5vh;
  margin: 2vh;
  font-size: 1vh;
  color: #323232;
  background: #323232 !important;
  border: 2px solid;
  border-radius: 50%;
`;
