/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Input, Select } from '~/components';
import ConstructionsService from '~/services/ConstructionsService';
import isFormatPhone from '~/utils/format/isFormatPhone';
import isEmailValid from '~/utils/validate/isValidEmail';
import { IState } from '~/store/modules/types';
import { IconAdd } from '~/assets/Icons';
import { AddEmail, Card, ContentDiv, ContentWorkManagement } from './styles';

interface IContact {
  id: string | null;
  nome: string | null;
  email: string | null;
  telefone?: string | null;
}

interface IBodyTech {
  [key: string]: IContact[];
}

interface IProps {
  dataSource: any;
  user: any;
  users: any[];
}

const SwitchWorkManagement: React.FC<IProps> = ({
  dataSource,
  users,
  user,
}: IProps) => {
  const [permission, setPermission] = useState(false);

  const [architect, setArchitect] = useState<IContact[]>([]);
  const [engineer, setEngineer] = useState<IContact[]>([]);
  const [responsible, setResponsible] = useState<IContact[]>([]);
  const [contact, setContact] = useState<IContact[]>([]);
  const [ready, setReady] = useState(false);

  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  const userId = useSelector((state: IState) => state.auth.data.account.id);

  const verifyPermissionActions = () => {
    if (dataSource.terminado) {
      return setPermission(false);
    }
    if (!user) return setPermission(false);
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    const permission =
      !!DICT.find(key => key === user.toUpperCase()) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      });
    return setPermission(permission);
  };

  useEffect(() => {
    verifyPermissionActions();
  }, []);

  useEffect(() => {
    handleDataSource();
  }, [dataSource]);

  useEffect(() => {
    if (ready) updateStaff();
  }, [architect, engineer, responsible]);

  const handleDataSource = () => {
    if (dataSource) {
      const DICT = ['arquiteto', 'gerente', 'engenheiro'];
      const data = dataSource?.corpo_tecnico || {};
      const keysBodyTech = Object.keys(data);
      const reducedData = DICT.reduce((acum: IBodyTech, curr) => {
        acum[curr] = [];
        const keysFiltered = keysBodyTech.filter(key =>
          new RegExp(curr, 'gi').test(key),
        );
        for (let index = 0; index < keysFiltered.length; index++) {
          const element = data[keysFiltered[index]];
          if (element.id) acum[curr].push(element as IContact);
        }
        return acum;
      }, {});
      const dataDefault = {
        id: null,
        nome: null,
        email: null,
      };
      setArchitect(
        reducedData.arquiteto.length ? reducedData.arquiteto : [dataDefault],
      );
      setEngineer(
        reducedData.engenheiro.length ? reducedData.engenheiro : [dataDefault],
      );
      setResponsible(
        reducedData.gerente.length ? reducedData.gerente : [dataDefault],
      );

      const DICT_CONTACT = ['contato'];
      const dataContact = dataSource || {};
      const keysDataSource = Object.keys(dataContact);
      const reducedDataContact = DICT_CONTACT.reduce(
        (acum: IBodyTech, curr) => {
          acum[curr] = [];
          const keysFiltered = keysDataSource.filter(key =>
            new RegExp(curr, 'gi').test(key),
          );
          for (let index = 0; index < keysFiltered.length; index++) {
            const element = dataContact[keysFiltered[index]];
            const n = keysFiltered[index].replace(/\D/gi, '');
            if (
              element[`email${n}`] ||
              element[`nome${n}`] ||
              element[`telefone${n}`]
            ) {
              acum[curr].push({
                email: element[`email${n}`],
                nome: element[`nome${n}`],
                telefone: element[`telefone${n}`],
              } as IContact);
            }
          }
          return acum;
        },
        {},
      );

      const dataDefaultContact = {
        id: null,
        nome: null,
        email: null,
        telefone: null,
      };

      setContact(
        reducedDataContact.contato.length
          ? reducedDataContact.contato
          : [dataDefaultContact],
      );
      // setReady(true);
    }
  };

  const updateStaff = () => {
    ConstructionsService.updateStaff({
      id: dataSource.corpo_tecnico.id,
      arquitetoId: parseInt(architect[0]?.id || '0', 10) || null,
      engenheiroId: parseInt(engineer[0]?.id || '0', 10) || null,
      gerenteId: parseInt(responsible[0]?.id || '0', 10) || null,
      arquitetoId2: parseInt(architect[1]?.id || '0', 10) || null,
      engenheiroId2: parseInt(engineer[1]?.id || '0', 10) || null,
      gerenteId2: parseInt(responsible[1]?.id || '0', 10) || null,
      arquitetoId3: parseInt(architect[2]?.id || '0', 10) || null,
      engenheiroId3: parseInt(engineer[2]?.id || '0', 10) || null,
      gerenteId3: parseInt(responsible[2]?.id || '0', 10) || null,
      arquitetoId4: parseInt(architect[3]?.id || '0', 10) || null,
      engenheiroId4: parseInt(engineer[3]?.id || '0', 10) || null,
      gerenteId4: parseInt(responsible[3]?.id || '0', 10) || null,
      arquitetoId5: parseInt(architect[4]?.id || '0', 10) || null,
      engenheiroId5: parseInt(engineer[4]?.id || '0', 10) || null,
      gerenteId5: parseInt(responsible[4]?.id || '0', 10) || null,
    });
  };

  const updateContact = () => {
    const filteredData = contact.filter(
      curr => curr.email && isEmailValid(curr.email),
    );
    ConstructionsService.updateContact({
      id: dataSource.id,
      name: filteredData[0]?.nome || null,
      email: filteredData[0]?.email || null,
      phone: filteredData[0]?.telefone || null,
      contatoNome2: filteredData[1]?.nome || null,
      contatoEmail2: filteredData[1]?.email || null,
      contatoTelefone2: filteredData[1]?.telefone || null,
      contatoNome3: filteredData[2]?.nome || null,
      contatoEmail3: filteredData[2]?.email || null,
      contatoTelefone3: filteredData[2]?.telefone || null,
      contatoNome4: filteredData[3]?.nome || null,
      contatoEmail4: filteredData[3]?.email || null,
      contatoTelefone4: filteredData[3]?.telefone || null,
      contatoNome5: filteredData[4]?.nome || null,
      contatoEmail5: filteredData[4]?.email || null,
      contatoTelefone5: filteredData[4]?.telefone || null,
    });
  };

  const handleChangeValue = (value: IContact, key: string, index: number) => {
    if (key === 'architect') {
      const arr = [...architect];
      arr[index] = value;
      setArchitect(arr);
    }
    if (key === 'engineer') {
      const arr = [...engineer];
      arr[index] = value;
      setEngineer(arr);
    }
    if (key === 'responsible') {
      const arr = [...responsible];
      arr[index] = value;
      setResponsible(arr);
    }
    setReady(true);
  };

  const handleChangeContact = (value: string, key: string, index: number) => {
    const arr: any = [...contact];
    arr[index][key] = value;
    setContact(arr);
  };

  const handleClick = (key: string) => {
    setReady(false);
    const data = {
      id: null,
      nome: null,
      email: null,
    };
    if (key === 'architect') {
      setArchitect(previousState => [...previousState, data]);
    }
    if (key === 'engineer') {
      setEngineer(previousState => [...previousState, data]);
    }
    if (key === 'responsible') {
      setResponsible(previousState => [...previousState, data]);
    }
    if (key === 'contact') {
      const dataDefaultContact = {
        id: null,
        nome: null,
        email: null,
        telefone: null,
      };

      setContact(previousState => [...previousState, dataDefaultContact]);
    }

    setReady(true);
  };

  return (
    <ContentWorkManagement>
      <ContentDiv>
        <span>
          {architect.map((curr, index) => (
            <Select
              lista={users}
              disabled={!permission}
              label="Arquiteto Responsável"
              currentValue={`${curr.nome || ''}`}
              onClickList={e => {
                handleChangeValue(
                  {
                    nome: e.nome,
                    id: e.id,
                    email: null,
                  },
                  'architect',
                  index,
                );
              }}
            />
          ))}
          {architect.length < 5 && permission ? (
            <AddEmail
              disabled={architect[architect.length - 1]?.id === null}
              onClick={() => {
                handleClick('architect');
              }}
            >
              <IconAdd />
              <u>Adicionar arquiteto responsável</u>
            </AddEmail>
          ) : null}
        </span>
        <span>
          {engineer.map((curr, index) => (
            <Select
              lista={users}
              disabled={!permission}
              label="Engenheiro Responsável"
              currentValue={`${curr.nome || ''}`}
              onClickList={e => {
                handleChangeValue(
                  {
                    nome: e.nome,
                    id: e.id,
                    email: null,
                  },
                  'engineer',
                  index,
                );
              }}
            />
          ))}

          {engineer.length < 5 && permission ? (
            <AddEmail
              disabled={engineer[engineer.length - 1]?.id === null}
              onClick={() => {
                handleClick('engineer');
              }}
            >
              <IconAdd />
              <u>Adicionar engenheiro responsável</u>
            </AddEmail>
          ) : null}
        </span>
        <span>
          {responsible.map((curr, index) => {
            return (
              <Select
                lista={users}
                disabled={!permission}
                label="Gerenciador Responsável"
                currentValue={`${curr.nome || ''}`}
                onClickList={e => {
                  handleChangeValue(
                    {
                      nome: e.nome,
                      id: e.id,
                      email: null,
                    },
                    'responsible',
                    index,
                  );
                }}
              />
            );
          })}

          {responsible.length < 5 && permission ? (
            <AddEmail
              disabled={responsible[responsible.length - 1]?.id === null}
              onClick={() => {
                handleClick('responsible');
              }}
            >
              <IconAdd />
              <u>Adicionar gerenciador responsável</u>
            </AddEmail>
          ) : null}
        </span>
      </ContentDiv>
      <ContentDiv>
        {contact.map((curr, index) => (
          <Card>
            <span>
              <Input
                label="Nome do Contato da Obra"
                value={curr.nome || ''}
                disabled={!permission}
                onChange={e => {
                  handleChangeContact(e.target.value, 'nome', index);
                }}
                onBlur={updateContact}
              />
            </span>
            <span>
              <Input
                label="Telefone do Contato da Obra"
                disabled={!permission}
                value={curr.telefone || ''}
                maxLenght={15}
                onChange={e => {
                  handleChangeContact(
                    isFormatPhone(e.target.value),
                    'telefone',
                    index,
                  );
                }}
                onBlur={updateContact}
              />
            </span>
            <span>
              <Input
                label="E-mail do Contato da Obra"
                disabled={!permission}
                value={curr.email || ''}
                onChange={e => {
                  handleChangeContact(e.target.value, 'email', index);
                }}
                onBlur={updateContact}
              />
            </span>
          </Card>
        ))}
        {contact.length < 5 && permission ? (
          <AddEmail
            disabled={
              !(
                contact[contact.length - 1]?.nome &&
                contact[contact.length - 1]?.telefone &&
                contact[contact.length - 1]?.email
              )
            }
            onClick={() => {
              handleClick('contact');
            }}
          >
            <IconAdd />
            <u>Adicionar contato responsável</u>
          </AddEmail>
        ) : null}
      </ContentDiv>
    </ContentWorkManagement>
  );
};

export default SwitchWorkManagement;
