import { Reducer } from 'redux';
import { IListCustomerState, ListCustomerTypes } from './types';

export const INITIAL_STATE_LIST_CUSTOMER: IListCustomerState = {
  data: [
    {
      id: 0,
      nome: '',
    },
  ],
  error: false,
  loading: false,
};

const reducer: Reducer<IListCustomerState> = (
  state = INITIAL_STATE_LIST_CUSTOMER,
  action,
) => {
  switch (action.type) {
    case ListCustomerTypes.LISTCUSTOMER_REQUEST:
      return { ...state, loading: true };
    case ListCustomerTypes.LISTCUSTOMER_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case ListCustomerTypes.LISTCUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default reducer;
