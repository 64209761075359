import { api } from './api';

interface ISendEmail {
  subject: string;
  message: string;
  destiny: string;
}

interface IList {
  limit?: number;
  page?: number;
  id?: number;
  descricao?: string;
}

interface IEditTemplate {
  id: number | string;
  texto: string;
}

interface IView {
  ids: number[];
}

interface ICreateTemplate {
  texto: string;
  descricao: string;
}

class NotificationsService {
  async sendEmail({ subject, message, destiny }: ISendEmail): Promise<any> {
    const formData = new FormData();

    formData.append('assunto', subject);
    formData.append('mensagem', message);
    formData.append('destino', destiny);

    const response = await api.post(
      `/notification-api/notification/email`,
      formData,
    );

    return response.data;
  }

  async listTemplate({ limit, page, descricao }: IList): Promise<any> {
    const response = await api.get(`/notification-api/template`, {
      params: { limit, page, descricao },
    });
    return response.data;
  }

  async listNotifications({ limit, page, id }: IList): Promise<any> {
    const response = await api.get(`/notification-api/notification`, {
      params: { limit, page, id },
    });
    return response.data;
  }

  async listNotificationsView({ limit, page, id }: IList): Promise<any> {
    const response = await api.get(`/notification-api/notification`, {
      params: { limit, page, id, status: 0 },
    });
    return response.data;
  }

  async setView({ ids }: IView): Promise<any> {
    const response = await api.patch(`/notification-api/notification`, {
      status: 1,
      ids,
    });
    return response.data;
  }

  async getByIdTemplate(id: number | string): Promise<any> {
    const response = await api.get(`notification-api/template/${id}`);
    return response.data;
  }

  async editByIdTemplate({ id, texto }: IEditTemplate): Promise<any> {
    const response = await api.patch(`notification-api/template/${id}`, {
      texto,
    });
    return response.data;
  }

  async createTemplate({ texto, descricao }: ICreateTemplate): Promise<any> {
    const response = await api.post(`notification-api/template`, {
      texto,
      descricao,
    });
    return response.data;
  }
}

export default new NotificationsService();
