import React from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';

import { Container, Date, Footer, Header } from './stylesZone';

interface IData {
  id: number;
  descricao: string;
  dataCriacao: string;
  dataAtualizacao: string;
}

interface IProps {
  onClick: () => void;
  data: IData;
  date: any;
}

const ZoneJustification: React.FC<IProps> = ({
  onClick,
  data,
  date,
}: IProps) => {
  return (
    <Container>
      <Header>Justificativas para Solicitação de Revisão</Header>
      <Date>
        <b>Último recebido</b>
        <p>{format(date, "dd/MM/yyyy 'às' HH:mm", { locale: pt })}</p>
      </Date>
      <p>{data.descricao}</p>
      <Footer>
        <button type="button" onClick={onClick}>
          Veja as solicitações pendentes
        </button>
      </Footer>
    </Container>
  );
};

export default ZoneJustification;
