/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Loading,
  Modal,
  Page,
  PageHeader,
  TablePage,
} from '~/components';
import {
  generateRequiredInputValues,
  generateDefaultInputValues,
  validateForm,
} from '~/utils/forms';
import { IconSearch } from '~/assets/Icons';

import {
  productStockRequest,
  resetProductStock,
  listProductsRequest,
} from '~/store/modules/stock/actions';
import { IState } from '~/store/modules/types';
import StockService from '~/services/StockService';
import { isEmpty } from '~/utils/validate';
import { IProduct } from '~/store/modules/stock/types';

import { SortOrder } from 'antd/lib/table/interface';
import {
  Container,
  Content,
  RowInput,
  Row,
  ButtonStyle,
  Icon,
  Search,
  InputNumber,
  InputNumberOrder,
} from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string | AnyObject;
  required: boolean;
  error: string;
}

interface IModal extends ITypeModal {
  active: boolean;
  data?: any;
}

interface ITypeModal {
  type?: 'manufacturer' | 'type' | 'subtype' | 'success' | 'error';
}

type typeInput = {
  [key: string]: IPropsInput;
};

interface AnyObject {
  [key: string]: any;
}

const DICT: any = {
  descricao: 'name',
};

const DICT_SAVE: any = {
  name: 'descricao',
};

const SaveSystem: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const formatCurrency = (value: number | null) => {
    return (value || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const [id] = useState(history.location.state?.id);
  const [search, setSearch] = useState('');
  const [modal, setModal] = useState<IModal>({ active: false });
  const [data, setData] = useState<IProduct[]>([]);
  const [processedData, setProcessedData] = useState<IProduct[]>([]);
  const [items, setItems] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputModal, setInputModal] = useState('');
  const [inputState, setInputState] = useState<typeInput>({
    ...generateRequiredInputValues(['name']),
  });

  const columns = [
    {
      title: 'Equipamentos',
      key: 'presentation',
      render: (data: IProduct) => (
        <Row>
          <p
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => handleClickProduct(data.id)}
          >
            {data.descricao}
          </p>
        </Row>
      ),
    },
    {
      title: 'Ordem Produto',
      key: 'numeroOrdemProduto',
      render: (data: IProduct) => (
        <InputNumberOrder>
          <Input
            type="number"
            value={
              data.numeroOrdemProduto && data.quantidadeDeProdutos
                ? data.numeroOrdemProduto
                : ``
            }
            disabled={!data.quantidadeDeProdutos}
            onChange={e =>
              handleChangeQuantityOrder(
                data.id,
                Number(e.target.value),
                `numeroOrdemProduto`,
              )
            }
          />
        </InputNumberOrder>
      ),
    },
    {
      title: 'Qtde. Itens',
      key: 'quantidadeDeProdutos',
      sorter: (a: IProduct, b: IProduct) =>
        (a.quantidadeDeProdutos || 0) - (b.quantidadeDeProdutos || 0),
      defaultSortOrder: `descend` as SortOrder,
      ellipsis: true,
      render: (data: IProduct) => (
        <InputNumber>
          <button
            type="button"
            onClick={() => handleChangeQuantityOrder(data.id, -1)}
          >
            -
          </button>
          <p>{data.quantidadeDeProdutos}</p>
          <button
            type="button"
            onClick={() => handleChangeQuantityOrder(data.id, 1)}
          >
            +
          </button>
        </InputNumber>
      ),
    },
    {
      title: 'Valor unitário',
      dataIndex: 'preco',
      key: 'preco',
      sorter: (a: IProduct, b: IProduct) => a.preco - b.preco,
      ellipsis: true,
      render: formatCurrency,
    },
  ];

  const {
    product,
    loadingProduct,
    products,
    loading: loadingProducts,
  } = useSelector((state: IState) => state.stock);

  useEffect(() => {
    setData(products);
  }, [products]);

  useEffect(() => {
    dispatch(resetProductStock());
    if (id) dispatch(productStockRequest(id));
    dispatch(listProductsRequest());
  }, [id]);

  useEffect(() => {
    handleProduct();
  }, [product]);

  useEffect(() => {
    handleData();
  }, [data]);

  const handleClickProduct = (id?: number) => {
    history.push({
      pathname: '/stock/product',
      ...(id !== undefined && typeof id === 'number' ? { state: { id } } : {}),
    });
  };

  const handleData = () => {
    setProcessedData(
      data.map(curr => {
        const item = (product?.itens || []).find(
          item => item.id === curr.id,
        )?.quantidade;

        const previousItem = processedData.find(
          item => item.id === curr.id,
        )?.quantidadeDeProdutos;

        const changedItem = items.find(
          item => item.id === curr.id,
        )?.quantidadeDeProdutos;

        // numeroOrdemProdutoItem
        const numeroOrdemProdutoItem = (product?.itens || []).find(
          item => item.id === curr.id,
        )?.numeroOrdemProduto;

        const numeroOrdemProdutoPreviousItem = processedData.find(
          item => item.id === curr.id,
        )?.numeroOrdemProduto;

        const numeroOrdemProdutoChangedItem = items.find(
          item => item.id === curr.id,
        )?.numeroOrdemProduto;

        return {
          ...curr,
          quantidadeDeProdutos: changedItem || previousItem || item || 0,
          numeroOrdemProduto:
            numeroOrdemProdutoChangedItem ||
            numeroOrdemProdutoPreviousItem ||
            numeroOrdemProdutoItem ||
            0,
        };
      }),
    );
  };

  const handleChangeQuantityOrder = (
    id: number,
    value: number,
    type: `quantity` | `numeroOrdemProduto` = `quantity`,
  ) => {
    const data = [...processedData];
    const item = data.find(curr => curr.id === id);

    if (item) {
      const newItems = [...items];
      const newItem = newItems.find(curr => curr.id === id);

      if (type === `quantity`) {
        const newQuantity =
          (newItem?.quantidadeDeProdutos || item?.quantidadeDeProdutos || 0) +
          value;
        item.quantidadeDeProdutos = newQuantity < 0 ? 0 : newQuantity;
        item.quantidade = newQuantity < 0 ? 0 : newQuantity;

        if (item.quantidadeDeProdutos === 0 || item.quantidade === 0) {
          item.numeroOrdemProduto = 0;
        }
        if (!newItem) newItems.push(item);
        else newItem.quantidadeDeProdutos = item.quantidadeDeProdutos;
      } else {
        item.numeroOrdemProduto = value;
        if (!newItem) {
          newItems.push(item);
        } else {
          const indexItem = newItems.findIndex(curr => curr.id === item.id);
          newItems[indexItem].numeroOrdemProduto = value;
        }
      }

      setItems(newItems);
    }
    setProcessedData(data);
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState as any);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const handleProduct = () => {
    if (product && id) {
      const keys = Object.keys(product);
      const data = keys.reduce((acum: typeInput, curr) => {
        const valueDict = DICT[curr];

        if (valueDict)
          if (typeof valueDict === 'object') {
            const data = Object.keys(valueDict).reduce(
              (acum: typeInput, dict) => {
                acum[valueDict[dict]] = {
                  ...inputState[valueDict[dict]],
                  value: (product as any)[curr][dict],
                };
                return acum;
              },
              {},
            );
            acum = { ...acum, ...data };
          } else {
            acum[valueDict] = {
              ...inputState[valueDict],
              value: (product as any)[curr],
            };
          }

        return acum;
      }, {});

      setItems(product.itens || []);

      setInputState(previousState => ({
        ...previousState,
        ...data,
      }));
    }
  };

  const handleInputChange = (value: any, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && !value) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleInputBlur = async (value: any, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && !value) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleClickModal = ({ type }: ITypeModal) => {
    setModal({ active: true, type });
  };

  const closeModal = () => {
    setModal({ active: false });
    setInputModal('');
  };

  const handleType = async (value: string, type: string) => {
    const data = await StockService.saveOption({ type, value });
    const DICT: AnyObject = {
      type: 'category',
      subtype: 'subcategory',
      manufacturer: 'producer',
    };
    handleInputChange(data, DICT[type]);
    closeModal();
  };

  const renderModal = useMemo(() => {
    if (!modal.active || !modal.type) return;

    const MODAL_TITLE = {
      manufacturer: 'Fabricante',
      type: 'Categoria',
      subtype: 'SubCategoria',
      success: `Sucesso ao ${product?.id ? 'editar' : 'criar'} sistema`,
      error: `Erro ao ${product?.id ? 'editar' : 'criar'} sistema`,
    };

    return (
      <Modal>
        <Content>
          <h3>{MODAL_TITLE[modal.type]}</h3>
          {!['success', 'error'].includes(modal.type) ? (
            <>
              <Row>
                <Input
                  value={inputModal}
                  onChange={e => setInputModal(e.target.value)}
                />
              </Row>
              <Row>
                <ButtonStyle>
                  <Button onClick={closeModal}>Cancelar</Button>
                </ButtonStyle>
                <Button
                  onClick={() => handleType(inputModal, modal.type || '')}
                >
                  Salvar
                </Button>
              </Row>
            </>
          ) : (
            <ButtonStyle>
              <Button
                onClick={() => {
                  if (modal.type === 'success') {
                    history.push({
                      pathname: '/stock/system',
                    });
                  } else {
                    closeModal();
                  }
                }}
              >
                Entendi,{' '}
                {modal.type === 'success'
                  ? 'voltar para sistemas'
                  : 'tentar novamente'}
              </Button>
            </ButtonStyle>
          )}
        </Content>
      </Modal>
    );
  }, [modal, inputModal, product]);

  const getValue = (name: string, param = 'nome') => {
    const value = inputState[name].value;
    if (!value) return '';
    return typeof value !== 'object' ? value : value[param];
  };

  const saveProduct = async () => {
    setLoading(true);
    const data = Object.keys(inputState).reduce((acum: any, curr) => {
      acum[DICT_SAVE[curr]] = getValue(curr, 'id');
      return acum;
    }, {});
    try {
      const response = await StockService.saveProduct({
        ...data,
        ...(product ? { id: product.id } : {}),
        categoriaId: 2,
      });
      await StockService.handleChangeProduct({
        systemId: response.id,
        previousData: product?.itens || [],
        nextData: items,
      });
      handleClickModal({ type: 'success' });
      setLoading(false);
    } catch (error) {
      handleClickModal({ type: 'error' });
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setData(
      products
        .filter(
          curr =>
            (curr?.descricao || '')
              .toLowerCase()
              .indexOf((search || '').toLowerCase()) !== -1,
        )
        .map(curr => {
          const item = (product?.itens || []).find(
            item => item.id === curr.id,
          )?.quantidade;
          const previousItem = processedData.find(
            item => item.id === curr.id,
          )?.quantidadeDeProdutos;
          const changedItem = items.find(
            item => item.id === curr.id,
          )?.quantidadeDeProdutos;

          // numeroOrdemProdutoItem
          const numeroOrdemProdutoItem = (product?.itens || []).find(
            item => item.id === curr.id,
          )?.numeroOrdemProduto;

          const numeroOrdemProdutoPreviousItem = processedData.find(
            item => item.id === curr.id,
          )?.numeroOrdemProduto;

          const numeroOrdemProdutoChangedItem = items.find(
            item => item.id === curr.id,
          )?.numeroOrdemProduto;

          return {
            ...curr,
            quantidadeDeProdutos: changedItem || previousItem || item || 0,
            numeroOrdemProduto:
              numeroOrdemProdutoChangedItem ||
              numeroOrdemProdutoPreviousItem ||
              numeroOrdemProdutoItem ||
              0,
          };
        }),
    );
  };

  return (
    <Page>
      {renderModal}
      <PageHeader
        title={id ? 'Editar Sistema' : 'Novo Sistema'}
        button="Salvar"
        disabled={loading || !isFormValid()}
        onClick={saveProduct}
        permission={
          id
            ? { module: 'ESTOQUE DE PRODUTOS', permission: 'GERENCIAR' }
            : { module: 'ESTOQUE DE PRODUTOS', permission: 'INCLUIR' }
        }
      />
      {loadingProduct || loading || loadingProducts ? (
        <Loading />
      ) : (
        <Container>
          <RowInput>
            <Input
              label="Nome do sistema:"
              maxLenght={70}
              error={inputState.name.error}
              value={getValue('name')}
              onChange={e => handleInputChange(e.target.value, 'name')}
              onBlur={e => handleInputBlur(e.target.value, 'name')}
            />
          </RowInput>
          <Search>
            <Input
              value={search}
              onChange={e => {
                setSearch(e.target.value);
              }}
              onClick={handleSearch}
              placeholder="Buscar produtos"
              icon={
                <Icon>
                  <IconSearch />
                </Icon>
              }
            />
          </Search>
          <TablePage columns={columns} dataSource={processedData} />
        </Container>
      )}
    </Page>
  );
};

export default SaveSystem;
