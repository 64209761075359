export enum StockTypes {
  LIST_REQUEST = '@stock/LIST_REQUEST',
  LIST_SUCCCES = '@stock/LIST_SUCCCES',
  LIST_FAILURE = '@stock/LIST_FAILURE',
  TOTAL_STOCK_REQUEST = '@stock/TOTAL_STOCK_REQUEST',
  TOTAL_STOCK_SUCCCES = '@stock/TOTAL_STOCK_SUCCCES',
  TOTAL_STOCK_FAILURE = '@stock/TOTAL_STOCK_FAILURE',
  PRODUCT_STOCK_REQUEST = '@stock/PRODUCT_STOCK_REQUEST',
  PRODUCT_STOCK_SUCCCES = '@stock/PRODUCT_STOCK_SUCCCES',
  PRODUCT_STOCK_FAILURE = '@stock/PRODUCT_STOCK_FAILURE',
  RESET_PRODUCT_STOCK = '@stock/RESET_PRODUCT_STOCK',
  SAVE_PRODUCT_STOCK_REQUEST = '@stock/SAVE_PRODUCT_STOCK_REQUEST',
  SAVE_PRODUCT_STOCK_SUCCCES = '@stock/SAVE_PRODUCT_STOCK_SUCCCES',
  SAVE_PRODUCT_STOCK_FAILURE = '@stock/SAVE_PRODUCT_STOCK_FAILURE',
  OPTIONS_REQUEST = '@stock/OPTIONS_REQUEST',
  OPTIONS_SUCCCES = '@stock/OPTIONS_SUCCCES',
  OPTIONS_FAILURE = '@stock/OPTIONS_FAILURE',
  LIST_SYSTEM_REQUEST = '@stock/LIST_SYSTEM_REQUEST',
  LIST_SYSTEM_SUCCCES = '@stock/LIST_SYSTEM_SUCCCES',
  LIST_SYSTEM_FAILURE = '@stock/LIST_SYSTEM_FAILURE',
  LIST_PRODUCTS_REQUEST = '@stock/LIST_PRODUCTS_REQUEST',
  LIST_PRODUCTS_SUCCCES = '@stock/LIST_PRODUCTS_SUCCCES',
  LIST_PRODUCTS_FAILURE = '@stock/LIST_PRODUCTS_FAILURE',
}

export interface IProduct {
  id: number;
  descricao: string;
  descricaoTecnica: string;
  descricaoComercial: string;
  especificacaoTecnica: string;
  preco: number;
  tipo: {
    id: number;
    nome: string;
  };
  subTipo: {
    id: number;
    nome: string;
  };
  categoria: {
    id: number;
    nome: string;
  };
  fabricante: {
    id: number;
    nome: string;
  };
  imagens: any[];
  itens: any[];
  quantidadeDeProdutos?: number;
  quantidade?: number;
  numeroOrdemProduto?: number;
  ultimaAquisicao?: string;
}

export interface ISaveProductRequest {
  id?: number;
  descricao?: string;
  fabricanteId?: number;
  tipoId?: number;
  subTipoId?: number;
  categoriaId?: number;
  preco?: number;
  descricaoTecnica?: string;
  descricaoComercial?: string;
  especificacaoTecnica?: string;
  codigo?: string;
  quantidadeEmEstoque?: number;
  estoqueMinimo?: number;
  file?: string;
}

export interface IChangeProductRequest {
  previousData: any[];
  nextData: any[];
  systemId: number;
}

export interface ISaveVendorRequest {
  nome: string;
  endereco: string;
  cnpj: string;
  telefone: string;
  email: string;
}

export interface IStock {
  id: number;
  produto: {
    descricao: string;
    produtoId: number;
    tipo: string;
    tipoId: number;
    nomeImagem: string;
    idBucket: number;
    urlBucket: string;
  };
  quantidadeEmEstoque: number;
  estoqueMinimo: number;
  valorMedio: number;
  valorTotal: number;
  dataDaUltimaEntrada: string;
}

export interface IInfo {
  quantidadeFaltanteEmEstoque: number;
  valorTotalEmEstoque: number;
}

export interface IStockState {
  readonly stocks: IStock[];
  readonly loading: boolean;

  readonly totalStock: IInfo;
  readonly loadingTotal: boolean;

  readonly product: IProduct;
  readonly loadingProduct: boolean;

  readonly savingProduct: boolean;
  readonly errorSavingProduct: string | null;

  readonly type: IOptions[];
  readonly subtype: IOptions[];
  readonly producer: IOptions[];
  readonly manufacturer: IOptions[];

  readonly systems: IProduct[];
  readonly products: IProduct[];
}

export interface IStocksState {
  stocks: IStock[];
  totalStock: IInfo;
  product: IProduct | null;
  loading: boolean;
  loadingTotal: boolean;
  loadingProduct: boolean;
  savingProduct: boolean;
  errorSavingProduct: string | null;
  type: IOptions[];
  subtype: IOptions[];
  producer: IOptions[];
  manufacturer: IOptions[];
  systems: IProduct[];
  products: IProduct[];
}

export interface IPropsStockRequest {
  type: string;
  payload: {
    id: number;
  };
}
export interface IPropsSaveRequest {
  type: string;
  payload: {
    body: ISaveProductRequest;
  };
}

export interface IPropsOptionsRequest {
  type: string;
  payload: {
    type: string[];
  };
}

export interface IOptions {
  id: number;
  nome: string;
}

export interface IResponseOptions {
  producer?: IOptions;
  type?: IOptions;
  subtype?: IOptions;
}
