/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IconClose, IconDelete } from '~/assets/Icons';
import { Modal, Button, Select, Input, ModalDefault } from '~/components';
import ConstructionsService from '~/services/ConstructionsService';
import UsersService from '~/services/UsersService';
import { generateRequiredInputValues, validateForm } from '~/utils/forms';
import { isEmpty } from '~/utils/validate';

import {
  Header,
  ContainerBox,
  InputBox,
  Display,
  ButtonStyles,
} from './styles';

interface IProps {
  onClick: () => void;
  reload: () => void;
  dataSource: any;
}

interface IPropsInput {
  isValid: boolean;
  value: any;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const ModalCreateSchedule: React.FC<IProps> = ({
  onClick,
  reload,
  dataSource,
}: IProps) => {
  const [assigned, setAssigned] = useState<any>([]);
  const [assignedId, setAssignedId] = useState<any>([]);
  const [category, setCategory] = useState<any>({
    id: 0,
    descricao: 'Selecione',
  });
  const [categoryList, setCategoryList] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);

  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const stateSchemaCreate = {
    ...generateRequiredInputValues([
      'startDate',
      'progress',
      'days',
      'item',
      'category',
    ]),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchemaCreate);

  useEffect(() => {
    const valid = checkForm();
    if (valid !== isValid) setIsValid(valid);
  }, [inputState, assignedId, isValid]);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    const verifyDate = moment(dataSource.inicio).isAfter(value);
    const verifyDateDays = moment(inputState.startDate.value)
      .add(value, 'days')
      .isAfter(dataSource.termino);

    if (inputName === 'days' && verifyDateDays) {
      error = 'Data superior a data de termino';
      isValid = false;
    }

    if (inputName === 'startDate' && verifyDate) {
      error = 'Data inferior a data de início';
      isValid = false;
    }

    if (inputState[inputName].required && value.trim().length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const checkForm = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    if (assignedId.length === 0) hasError = true;

    return isEmpty(inputsWithError) && !hasError;
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;
    Object.entries(inputState).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    if (assignedId.length === 0) hasError = true;

    return isEmpty(inputsWithError) && !hasError;
  };

  const create = () => {
    if (isFormValid()) {
      try {
        ConstructionsService.createSchedule({
          atribuidos: assignedId,
          dias: parseFloat(inputState.days.value),
          progresso: parseFloat(inputState.progress.value),
          inicio: inputState.startDate.value,
          item: inputState.item.value,
          diarioId: dataSource.id,
          categoriaId: category.id,
        });
        onClick();
        reload();
      } catch {
        setIsVisibleModalErro(true);
      }
    }
  };

  useEffect(() => {
    const load = async () => {
      try {
        const response = await ConstructionsService.listCategory();
        setCategoryList(response.result);
        const responseUsers = await UsersService.listUsers({
          limit: 200,
          page: 1,
        });
        setUserList(responseUsers.result);
      } catch {
        setIsVisibleModalErro(true);
      }
    };

    load();
  }, []);

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          onClick={() => setIsVisibleModalErro(false)}
          success={false}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      <Modal width="65vw">
        <>
          <Header>
            <div>
              <b>Cadastrar etapa</b>
            </div>
            <button type="button" onClick={onClick}>
              <IconClose />
            </button>
          </Header>
          <>
            <ContainerBox>
              <InputBox>
                <Input
                  label="Item"
                  requiredLabel
                  onChange={e => handleInput(e.target.value, 'item')}
                  error={inputState.item.error}
                />
                <Input
                  label="Inicio"
                  type="date"
                  requiredLabel
                  onChange={e => handleInput(e.target.value, 'startDate')}
                  error={inputState.startDate.error}
                />
              </InputBox>
              <InputBox>
                <Select
                  lista={categoryList}
                  onClickList={e => {
                    setCategory(e);
                    handleInput(JSON.stringify(e), 'category');
                  }}
                  currentValue={category.descricao}
                  label="Categoria (Obrigatorio)"
                />
                <Input
                  label="Dias"
                  requiredLabel
                  onChange={e => handleInput(e.target.value, 'days')}
                  error={inputState.days.error}
                />
              </InputBox>
            </ContainerBox>
            <ContainerBox>
              <InputBox>
                <Input
                  label="Progresso"
                  requiredLabel
                  value={inputState.progress.value}
                  type="number"
                  onChange={e =>
                    handleInput(
                      e.target.value.replace(/[^\d]+/g, ''),
                      'progress',
                    )
                  }
                  error={inputState.progress.error}
                />
              </InputBox>
              <InputBox>
                <Select
                  label="Atribuir (Obrigatorio)"
                  lista={userList}
                  onClickList={e => {
                    setAssigned((prevState: any) => [...prevState, e]);
                    setAssignedId((prevState: any) => [...prevState, e.id]);
                  }}
                  currentValue={
                    assigned.length < 1
                      ? 'Selecionar'
                      : 'Selecionar mais pessoas'
                  }
                />
              </InputBox>
            </ContainerBox>
            {assigned.map((Element: any) => (
              <Display>
                {Element.nome}
                <button
                  type="button"
                  onClick={() => {
                    setAssigned((prevState: any[]) =>
                      prevState.filter(item => item !== Element),
                    );
                    setAssignedId((prevState: any[]) =>
                      prevState.filter(item => item !== Element.id),
                    );
                  }}
                >
                  <IconDelete />
                </button>
              </Display>
            ))}
          </>
          <ButtonStyles>
            <Button disabled={!isValid} onClick={() => create()}>
              Salvar
            </Button>
          </ButtonStyles>
        </>
      </Modal>
    </>
  );
};

export default ModalCreateSchedule;
