import styled from 'styled-components';

export const Label = styled.b`
  color: #000000;
  margin: 6px 0;
  display: flex;
`;

export const ButtonSelect = styled('button')<{
  currentValue: boolean;
  disabled?: boolean;
}>`
  border: 1px solid #30b4e5;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  width: 100%;

  display: flex;
  ${props => props.currentValue && `justify-content: end;`}
  ${props =>
    !props.currentValue && `justify-content: space-between !important;`}
  align-items: center;
  padding-left: 10px;

  font-size: 12px;
  color: #323232;

  ${props =>
    props.disabled &&
    `background-color: #F1FAFD;
    cursor: not-allowed;
 `}
`;

export const DivList = styled('div')<{ width: number | undefined }>`
  /* margin-top: 45px !important; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  position: absolute;
  top: calc(100% + 10px);
  width: 352px;
  background: #ffffff !important;
  z-index: 900;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  ${props => `width: ${props.width}px !important;`}

  a + a {
    border-top: 1px solid #30b4e5;
  }
`;

export const ListContent = styled.a`
  display: flex;
  padding: 10px;
  color: #323232;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
`;

export const Icon = styled.div`
  margin-top: 10px;
  margin-right: 10px;
  svg {
    transform: rotate(-90deg);
  }
`;

export const ButtonContainer = styled('div')<{ button: boolean }>`
  ${props =>
    props.button &&
    `
  display: flex;


  div {
    width: 90%;
  }
  `}
`;

export const ButtonAdd = styled('button')<{ button: boolean }>`
  ${props => !props.button && `display: none;`}

  margin: 10px 0 0 5px;
`;
