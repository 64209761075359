import React from 'react';

import { CheckModule } from '~/utils/checkPermission';

import jsonMenu from '~/mocks/Menu.json';
import { SwitchIcon } from '~/components';

import { Container, LinkMenu } from './styles';

const ButtonDashboard: React.FC = () => {
  const childSessions = (item: any) => {
    if (item.children.length > 0) {
      return (
        <>
          {item.children.map((child: any) => {
            if (child.children.length > 0) {
              return null;
            }

            return (
              <LinkMenu
                disabled={
                  CheckModule(child.permission)
                    ? false
                    : CheckModule(child.permission)
                }
                to={!CheckModule(child.permission) ? child.link : '/home'}
                key={`${child.id}-linkmenu`}
                className="itensMenu"
              >
                <SwitchIcon name={child.icon} color="#323232" />
                {child.nome}
              </LinkMenu>
            );
          })}
        </>
      );
    }
    return null;
  };

  return (
    <Container key="container-container">
      {jsonMenu.map(item => {
        return childSessions(item);
      })}
    </Container>
  );
};

export default ButtonDashboard;
