import { api } from './api';

interface IListCustomers {
  limit?: number;
  page?: number;
  doc?: 1 | 2;
}

interface IListCustomersFilters {
  doc?: 1 | 2;
  nome?: string;
  cpf?: string;
  cnpj?: string;
  razaoSocial?: string;
  cidade?: string;
  status?: 0 | 1;
}

interface IUpdateCustomerById {
  nome?: string;
  email?: string;
  razaoSocial?: string;
  cpf?: string;
  cnpj?: string;
  logradouro?: string;
  bairro?: string;
  numero?: string;
  estado?: string;
  cidade?: string;
  cep?: string;
  telefone?: string;
  complemento?: string;
  segmento?: number;
  nome_financeiro?: string;
  telefone_financeiro?: string;
  email_financeiro?: string;
}

interface IListsegments {
  limit?: number;
  page?: number;
}

class CustomersService {
  async listCustomers({
    limit = 200,
    page = 1,
    doc,
  }: IListCustomers): Promise<any> {
    const response = await api.get(`/customer-api/customer`, {
      params: {
        limit,
        page,
        doc,
      },
    });

    return response.data;
  }

  async listCustomersFilter({
    doc,
    nome,
    cpf,
    cnpj,
    razaoSocial,
    cidade,
    status,
  }: IListCustomersFilters): Promise<any> {
    const response = await api.get(`/customer-api/customer`, {
      params: {
        limit: 20,
        page: 1,
        doc,
        nome,
        cpf,
        cnpj,
        razaoSocial,
        cidade,
        status,
      },
    });

    return response.data;
  }

  async getCustomerById(id: number): Promise<any> {
    const response = await api.get(`/customer-api/customer/${id}`);
    return response.data;
  }

  async getCep(cep: string): Promise<any> {
    const response = await api.get(`/cep/${cep}`);
    return response.data;
  }

  async updateCustomerById(
    id: number | string,
    customerEdit: IUpdateCustomerById,
  ): Promise<any> {
    const response = await api.put(`/customer-api/customer/${id}`, {
      ...customerEdit,
    });

    return response.data;
  }

  async deleteCustomerById(id: number | string): Promise<any> {
    const response = await api.delete(`/customer-api/customer/${id}`);
    return response.data.message;
  }

  async listSegments({ limit, page }: IListsegments): Promise<any> {
    const response = await api.get(`customer-api/segment`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async createSegment(descricao: string): Promise<any> {
    const response = await api.post(`customer-api/segment`, {
      descricao,
    });

    return response.data;
  }
}

export default new CustomersService();
