export enum ListCustomerTypes {
  LISTCUSTOMER_REQUEST = '@customer/LISTCUSTOMER_REQUEST',
  LISTCUSTOMER_SUCCCES = '@customer/LISTCUSTOMER_SUCCCES',
  LISTCUSTOMER_FAILURE = '@customer/LISTCUSTOMER_FAILURE',
}

export interface IResponse {
  id: number;
  nome: string;
}

export interface IListCustomerState {
  readonly data: IResponse[];
  readonly loading: boolean;
  readonly error: boolean;
}
