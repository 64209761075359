import { combineReducers } from 'redux';

import auth from './auth';
import currency from './currency';
import customer from './customer';
import product from './product';
import budget from './budget';
import notification from './notification';
import construction from './construnction';
import stock from './stock';
import report from './report';

export default combineReducers({
  auth,
  currency,
  customer,
  product,
  budget,
  notification,
  construction,
  stock,
  report,
});
