import styled from 'styled-components';

export const Day = styled.div<{
  day?: boolean;
  disabled?: boolean;
  hideCircles?: boolean;
}>`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: ${({ day, hideCircles }) =>
    !day || hideCircles ? 'center' : 'flex-start'};
  justify-content: ${({ day, hideCircles }) =>
    !day || hideCircles ? 'center' : 'flex-start'};
  cursor: ${({ day }) => (day ? 'pointer' : 'initial')};
  ${({ day }) => (day ? 'border: 1px solid #000;' : '')}
  padding: 5px;
  position: relative;
  ${({ disabled }) =>
    (disabled &&
      `
    cursor: initial;
    opacity: .5;
  `) ||
    ''}
`;

export const Label = styled.p`
  margin: 0;
  position: relative;
  z-index: 1;
`;

export const Selected = styled.div`
  width: 30px;
  height: 30px;
  background: #30b4e5;
  border-radius: 15px;
  position: absolute;
  z-index: 0;
`;

export const Circle = styled.span<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: ${({ color }) => color};

  position: absolute;
  bottom: 5px;
  right: 5px;
`;
