import { parseISO, formatDistance } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';

import { Container, Time, Visible } from './styles';

type ILinkProps = {
  id: number;
  title: string;
  message: string;
  status: boolean;
  created_at: string;
  acaoId: any;
  onClick: () => void;
};

const ElementNotification: React.FC<ILinkProps> = ({
  id,
  title,
  message,
  status,
  created_at,
  acaoId,
  onClick,
}: ILinkProps) => {
  const time = formatDistance(parseISO(created_at), new Date(), {
    addSuffix: true,
    locale: pt,
  });
  return (
    <Container key={id} onClick={() => onClick()}>
      <span>
        <b>{title}</b>
      </span>
      <Visible status={status} />
      <span>{message}</span>
      <span>{acaoId || ''}</span>
      <Time>{time}</Time>
    </Container>
  );
};

export default ElementNotification;
