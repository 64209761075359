export enum ReportTypes {
  LIST_REQUEST = '@schedule/LIST_REQUEST',
  LIST_SUCCCES = '@schedule/LIST_SUCCCES',
  LIST_FAILURE = '@schedule/LIST_FAILURE',
  SELECT_DATE = '@schedule/SELECT_DATE',
  SELECT_MONTH = '@schedule/SELECT_MONTH',
  OPTIONS_REQUEST = '@schedule/OPTIONS_REQUEST',
  OPTIONS_SUCCCES = '@schedule/OPTIONS_SUCCCES',
  OPTIONS_FAILURE = '@schedule/OPTIONS_FAILURE',
  REPORT_REQUEST = '@schedule/REPORT_REQUEST',
  REPORT_SUCCCES = '@schedule/REPORT_SUCCCES',
  REPORT_FAILURE = '@schedule/REPORT_FAILURE',
  RESUME_REQUEST = '@schedule/RESUME_REQUEST',
  RESUME_SUCCCES = '@schedule/RESUME_SUCCCES',
  RESUME_FAILURE = '@schedule/RESUME_FAILURE',
  LOGS_REQUEST = '@schedule/LOGS_REQUEST',
  LOGS_SUCCCES = '@schedule/LOGS_SUCCCES',
  LOGS_FAILURE = '@schedule/LOGS_FAILURE',
}

export interface ISchedule {
  info: IInfo;
  result: IResult;
}

interface IResult {
  resultByDate: IResultByDate[];
  resultByTech: IResultByTech[];
  resultByDateByTechnicians: IResultByDateByTechnicians[];
}

export interface IResultByTech {
  tecnico: ICliente;
  agendamentos: IAgendamentos[];
}

interface IResultByDate {
  date: string;
  key: string;
  color: string;
  events: IEvents[];
}
export interface IResultByDateByTechnicians {
  date: string;
  key: string;
  color: string;
  events: IResultByTech[];
}

export interface IPropsLogRequest {
  type: string;
  payload: {
    id: string | number;
  };
}
export interface IPropsOptionsRequest {
  type: string;
  payload: {
    type: string[];
  };
}

export interface IPropsReportRequest {
  type: string;
  payload: {
    id: number;
  };
}

interface IEvents {
  id: string;
  obra: IObra;
  cliente: ICliente;
  periodo: IPeriodo;
  notificar_cliente: number;
  status: number;
  adversidade: number;
  tecnico: ICliente;
}

export interface IAgendamentos {
  id: string;
  criadorId: number;
  obra: IObra;
  cliente: ICliente;
  periodo: IPeriodo;
  notificar_cliente: number;
  status_relatorio: IObra;
  relatorio_id: number;
  adversidade: number;
  tecnico: ITecnico;
  endereco: IEndereco;
  tipo_manutencao: ITipoManutencao;
}

interface ITipoManutencao {
  id: string;
  descricao: string;
}

interface IEndereco {
  rua: string;
  numero: number;
  bairro: string;
  cidade: string;
  estado: string;
  complemento: string;
  cep: string;
}

interface ITecnico {
  id: number;
  nome: string;
  email: string;
  auxiliares: IAuxiliar[];
}

interface IAuxiliar {
  id: number | null;
  nome: string | null;
  email: string | null;
}

interface IObra {
  id: number;
  descricao: string;
}

interface ICliente {
  id: number;
  nome: string;
}

interface IPeriodo {
  id: string;
  descricao: string;
  data_inicio: string;
  data_termino: string;
  num_ocorrencias: number;
  repetir_em: number;
  repetir_dia: number;
  proximo: string;
}

interface IInfo {
  totalTecnicos: number;
  totalAgendados: number;
  totalAgendadosConcluidos: number;
  diasAgendados: IDiasAgendados[];
  limit: number;
  page: number;
  pages: number;
}

interface IDiasAgendados {
  data: string;
  status: number;
}

export interface IOptions {
  id: number;
  nome: string;
}

export interface IOptions {
  id: number;
  nome: string;
}

export interface IResponseOptions {
  schedule_period?: IOptions;
  resume?: IResume;
  users?: IOptions;
  schedule?: IOptions;
  type_maintenance: IOptions[];
}

interface IResume {
  clientsWithFinishingConstructions: IClient[];
  finishingConstructions: IConstruction[];
  finishedContructionWithoutSchedule: IConstruction[];
}

interface IConstruction {
  id: number;
  descricao: string;
  terminado: number;
  orcamento: number;
  cliente: IClient;
  progresso: number[];
}

export interface IScheduleRequest {
  id?: number;
  diarioId: number;
  criadorId?: number;
  tecnicoResponsavelId: number;
  tecnicoAux1Id?: number;
  tecnicoAux2Id?: number;
  tecnicoAux3Id?: number;
  tecnicoAux4Id?: number;
  periodoId: number;
  tipoManutencaoId: number;
  data_inicio: string;
  data_termino?: string;
  num_ocorrencias: number;
  notificar: number;
  repetir_em: number;
  repetir_dia: number;
  adversidade: number;
}

export interface IAtividade {
  atividadeDescricao: string;
  atividadeId: string;
  condicaoDescricao: string;
  condicaoId: string;
  id: number;
  periodoDescricao: string;
  periodoId: string;
  randomId?: boolean;
}

export interface IAtividadeResponse {
  id: string;
  descricao: string;
}

export interface IEquipamento {
  id: number | string;
  equipamentoId?: number | string;
  descricao: string;
  equipamentoDescricao?: string;
  verificado: number;
}

interface IImagem {
  ID: number;
  TB_RELATORIO_ID: number;
  DS_NOME: string;
  NU_ID_FILE_BUCKET: number;
  DS_CAMINHO_BUCKET: string;
  DS_URL_BUCKET: string;
  DELETADO: number;
  DT_CRIACAO: string;
  DT_ATUALIZACAO: string;
  FL_IMAGEM_PADRAO: number;
}
export interface IReportResponse {
  id: string;
  idAgenda?: string;
  obra: IObra;
  cliente: IOptions;
  tecnico: IOptions;
  adversidade: number;
  ph: string;
  redoux: string;
  cloro: string;
  observacoes: string;
  atividades: IAtividade[];
  equipamentos: IEquipamento[];
  imagens: IImagem[];
}

interface IClient {
  id: number | null;
  nome: string | null;
}

interface IRequestEquipment {
  id: number;
  verificado: number;
}

interface IRequestActivity {
  atividadeId: number;
  condicaoId: number;
  periodoId: number;
}

export interface IRequestSaveReport {
  id?: number;
  agendaId: number;
  ph: string;
  redoux: string;
  cloro: string;
  observacoes: string;
  equipamentos: IRequestEquipment[];
  atividades: IRequestActivity[];
}

export interface IResumeMonth {
  totalAgendadosConcluidos: number | null;
  totalAgendadosIncompletos: number | null;
  totalAgendadosAguardando: number | null;
  totalDiasNaoAgendados: number | null;
}

export interface IReportState {
  readonly schedules: ISchedule | null;
  readonly loading: boolean;
  readonly dateSelected: string;
  readonly monthSelected: string;
  readonly schedule_period: IOptions[];
  readonly resume: IResume;
  readonly users: IOptions[];
  readonly schedule: IOptions[];
  readonly type_maintenance: IOptions[];
  readonly condition: IOptions[];
  readonly period: IOptions[];
  readonly report: IReportResponse[];
  readonly reportOnly: IReportResponse;
  readonly error: boolean;
  readonly resumeMonth: IResumeMonth;
  readonly logs: any;
}

export interface IReportsState {
  schedules: ISchedule | null;
  loading: boolean;
  dateSelected: string;
  monthSelected: string;

  schedule_period: IOptions[];
  resume: IResume;
  users: IOptions[];
  schedule: IOptions[];
  condition: IOptions[];
  period: IOptions[];
  type_maintenance: IOptions[];
  report: IReportResponse[];
  reportOnly: IReportResponse | null;
  error: boolean;
  resumeMonth: IResumeMonth;
  logs: any;
}

export interface IPropsResumeRequest {
  type: string;
  payload: {
    month: string;
  };
}
