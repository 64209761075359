/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';

import { CheckPermission } from '~/utils/checkPermission';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import Calendar from '../Calendar';
import ProjectUnfinished from '../ProjectUnfinished';
import ProjectFinishedWithoutSchedule from '../ProjectFinishedWithoutSchedule';
import Summary from '../Summary';

import { Container } from './styles';

interface IProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  client: number | null;
  setClient: (value: number | null) => void;
}

const ContentLeft: React.FC<IProps> = props => {
  const isTech = CheckPermission({
    module: 'RELATÓRIO DE MANUTENÇÃO',
    permission: 'TECNICODEMANUTENCAO',
  });

  const isManager = CheckPermission({
    module: 'RELATÓRIO DE MANUTENÇÃO',
    permission: 'COORDENADORDEMANUTENCAO',
  });

  return (
    <Container>
      {isTech || isManager ? (
        <>
          <Calendar />
          <Summary />
          {isManager ? (
            <>
              <ProjectUnfinished {...props} />
              <ProjectFinishedWithoutSchedule {...props} />
            </>
          ) : null}
        </>
      ) : null}
    </Container>
  );
};

export default ContentLeft;
