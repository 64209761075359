import styled from 'styled-components';

export const Content = styled('div')<{ state: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 2;
  top: 12vh;
  right: 2vh;
  transition: 0.4s;
  display: flex;
  position: absolute;
  background: ${({ theme }) => theme.Colors.White};
  width: 22vw;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 3px;
  opacity: 1;
  display: flex;
  overflow-y: scroll;
  max-height: 70vh;

  ${props => !props.state && 'display: none; opacity: 0;'}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 20px 14px;
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.Colors.Gray};
`;

export const ViewMore = styled('div')<{ display: boolean }>`
  display: flex;
  justify-content: center;
  padding: 15px;
  border-top: 1px solid ${({ theme }) => theme.Colors.Blue.Main};
  color: ${({ theme }) => theme.Colors.Blue.Main};
  font-weight: 700;

  ${props => !props.display && `display: none;`}
`;
