import { Reducer } from 'redux';
import {
  AddCustomerBudgetTypes,
  AddProductBudgetTypes,
  DeleteProductBudgetTypes,
  IGetBudgetState,
  GetBudgetTypes,
  QuotationBudgetTypes,
  SituationBudgetTypes,
} from './types';

export const INITIAL_STATE_GET: IGetBudgetState = {
  data: {
    id: 0,
    uuid: '',
    descricao: '',
    preco: 0,
    precoTotal: 0,
    valorDesconto: 0,
    valorMaoDeObra: 0,
    desconto: 0,
    maoDeObra: 0,
    observacao: '',
    pagamento: '',
    responsavel: {
      id: 0,
      nome: '',
    },
    criador: {
      id: 0,
      nome: '',
    },
    situacao: {
      id: 0,
      nome: '',
    },
    cliente: {
      id: 0,
      nome: '',
    },
    moeda: {
      id: 0,
      sigla: '',
      codigo: '',
      nome: '',
      cotacao: 0,
    },
    dataCriacao: '',
    dataAtualizacao: '',
    dataValidade: '',
    itens: [
      {
        id: 0,
        descricao: '',
        tipo: '',
        preco: 0,
        precoNovo: 0,
        quantidade: 0,
        categoria: 0,
        imagens: [
          {
            defalut: 0,
            fileUrl: '',
          },
        ],
      },
    ],
    endereco: {
      cep: '',
      logradouro: '',
      numero: 0,
      bairro: '',
      cidade: '',
      estado: '',
      complemento: '',
    },
    emails: '',
    justificativa: [
      {
        id: 0,
        descricao: '',
        dataCriacao: '',
        dataAtualizacao: '',
      },
    ],
  },
  error: false,
  loading: true,
};

const reducer: Reducer<IGetBudgetState> = (
  state = INITIAL_STATE_GET,
  action,
) => {
  switch (action.type) {
    case GetBudgetTypes.GET_BUDGET_REQUEST:
      return { ...state, loading: true };
    case GetBudgetTypes.GET_BUDGET_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case GetBudgetTypes.GET_BUDGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AddProductBudgetTypes.ADD_BUDGET_REQUEST:
      return { ...state };
    case AddProductBudgetTypes.ADD_PRODUCT_BUDGET_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case AddProductBudgetTypes.ADD_PRODUCT_BUDGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case DeleteProductBudgetTypes.DELETE_PRODUCT_BUDGET_REQUEST:
      return { ...state, loading: true };
    case DeleteProductBudgetTypes.DELETE_PRODUCT_BUDGET_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case DeleteProductBudgetTypes.DELETE_PRODUCT_BUDGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case AddCustomerBudgetTypes.ADD_CUSTOMER_BUDGET_REQUEST:
      return { ...state, loading: true };
    case AddCustomerBudgetTypes.ADD_CUSTOMER_BUDGET_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case AddCustomerBudgetTypes.ADD_CUSTOMER_BUDGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case SituationBudgetTypes.SITUATION_BUDGET_REQUEST:
      return { ...state, loading: true };
    case SituationBudgetTypes.SITUATION_BUDGET_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case SituationBudgetTypes.SITUATION_BUDGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case QuotationBudgetTypes.QUOTATION_BUDGET_REQUEST:
      return { ...state, loading: true };
    case QuotationBudgetTypes.QUOTATION_BUDGET_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case QuotationBudgetTypes.QUOTATION_BUDGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
