import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

export const Title = styled.div`
  margin: 3% 3% 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 700;
  font-size: 30px;
`;

export const Container = styled.div`
  min-height: 100vh;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  width: 5vh;
  height: 5vh;
  margin: 2vh;
  font-size: 1vh;
  color: #54565a;
  background: #54565a !important;
  border: 2px solid;
  border-radius: 50%;
`;

export const Table = styled.div`
  @media (max-width: 570px) {
    overflow: scroll;
    max-width: 570px;
  }
`;

export const BtEdit = styled('button')<{
  locked?: boolean;
}>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue.Main};
    ${props => props.locked && 'color: #54565A; cursor: not-allowed;'}
  }
`;

export const BtTrash = styled('button')<{
  disabled?: boolean;
  locked?: boolean;
}>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
    ${props =>
      props.locked && 'color: #54565A !important; cursor: not-allowed;'}
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BtStart = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    height: 25px;
    width: 25px;
    background: #30b4e5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  b {
    font-size: clamp(7px, 1vw + 0.1rem, 10px);
  }
`;

export const BtContainer = styled.div`
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 3% 0 3%;

  > button {
    width: 30%;
    margin-left: 15px;
  }
  .select {
    width: 30%;
    margin-left: 15px;
  }
`;
