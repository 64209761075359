import styled from 'styled-components';

export const Container = styled.div`
  padding: 3%;
  display: flex;
`;

export const ContainerUpload = styled.div`
  width: 200px;
  height: 186px;
  background: #c4c4c4;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;

  p {
    font-size: 10px;
    text-align: center;
  }
`;

export const ContentUpload = styled.div`
  width: 200px;
  height: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 40%;
  }
`;

export const CustomerImg = styled.img`
  width: 200px;
  height: 186px;
  object-fit: cover;
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 40px;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    padding: 15px 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const RowInput = styled.div<{ alignItems?: string }>`
  display: flex;
  flex: 1;
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}

  div + div {
    margin-left: 20px;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonAdd = styled.button.attrs({
  type: 'button',
})`
  height: min-content;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ButtonStyle} + button {
    margin-left: 10px;
  }
`;
