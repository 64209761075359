/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import isValidEmail from '~/utils/validate/isValidEmail';
import isValidCPF from '~/utils/validate/isValidCPF';
import isFormatCPF from '~/utils/format/isFormatCPF';

import {
  Button,
  Input,
  Loading,
  Modal,
  ModalDefault,
  Page,
  PageHeader,
  InputSearch,
  Checkbox,
  InputPhone,
} from '~/components';

import imgDefault from '~/assets/Images/ImgDefault.svg';

import { IconClose, IconVisible } from '~/assets/Icons';
import { isEmpty } from '~/utils/validate/index';
import {
  generateRequiredInputValues,
  generateDefaultInputValues,
  validateForm,
} from '~/utils/forms/index';
import { api } from '~/services/api';
import UsersService from '~/services/UsersService';
import ProfilesService from '~/services/ProfilesService';
import VisibleForPermissions from '~/components/VisibleForPermission';

import {
  CloseModal,
  Container,
  ContainerBox,
  ContainerUpload,
  Content,
  ContentUpload,
  CustomerImg,
  InputBox,
  ListCheckbox,
  SegmentModalContainer,
  TitleCheckbox,
} from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const UserCreate: React.FC = () => {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();
  const [pageTypeEdit, setPageTypeEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [imgArray, setImgArray] = useState([]);
  const [listOffice, setListOffice] = useState([{ id: 0, descricao: '' }]);
  const [listModule, setListModule] = useState([]);
  const [inputOffice, setInputOffice] = useState({
    id: 0,
    descricao: '',
  });
  const [currentInputOffice, setCurrentInputOffice] = useState('');
  const [inputNewOffice, setInputNewOffice] = useState('');
  const [messageModal, setMessageModal] = useState('');
  const [saveUser, setSaveUser] = useState(false);
  const [inputPhone, setInputPhone] = useState<any>(undefined);
  const [showList, setShowList] = useState(false);

  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const [isVisibleModalOffice, setIsVisibleModalOffice] = useState(false);

  const stateSchemaCreate = {
    ...generateRequiredInputValues([
      'name',
      'document',
      'email',
      'phone',
      'password',
      'office',
    ]),
  };
  const stateSchemaEdit = {
    ...generateRequiredInputValues([
      'name',
      'document',
      'email',
      'phone',
      'office',
    ]),
    ...generateDefaultInputValues(['password']),
  };
  const [inputState, setInputState] = useState<typeInput>(stateSchemaCreate);

  const loadPermissionForUser = async () => {
    setLoading(true);
    const response = await UsersService.pemissionForUser(type);
    setListModule(response.result);
    setLoading(false);
  };

  useEffect(() => {
    if (Number.isNaN(parseFloat(type))) {
      const listModule = async () => {
        const response = await UsersService.listModule();
        setListModule(response.result);
      };
      listModule();
      setPageTypeEdit(false);
    } else {
      setInputState(stateSchemaEdit);
      const LoadRequest = async () => {
        setLoading(true);
        setPageTypeEdit(true);
        const response = await UsersService.getUserById(parseFloat(type));
        setInputState((prevState: any) => ({
          ...prevState,
          name: {
            ...prevState.name,
            value: response.nome,
          },
          document: {
            ...prevState.document,
            value: isFormatCPF(response.cpf),
          },
          email: {
            ...prevState.email,
            value: response.email,
          },
          phone: {
            ...prevState.phone,
            value: response.telefone,
          },
          office: {
            ...prevState.office,
            value: response.cargo?.id,
          },
        }));
        setInputPhone(response.telefone);

        setInputOffice(response?.cargo);
        setCurrentInputOffice(response?.cargo?.descricao);
        setImgUrl(response.imagem?.fileUrl);
        setLoading(false);
      };

      loadPermissionForUser();
      LoadRequest();
    }
  }, [type]);

  useEffect(() => {
    const listOffice = async () => {
      const response = await UsersService.listOffice({
        limit: 200,
        page: 1,
      });
      setListOffice(response.result);
    };

    listOffice();
  }, [isVisibleModalOffice]);

  function getBase64(img: any, callback: any) {
    if (img.type === 'image/jpeg' || img.type === 'image/png') {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    if (
      acceptedFiles[0].type === 'image/jpeg' ||
      acceptedFiles[0].type === 'image/png'
    ) {
      setImgArray(acceptedFiles);
      getBase64(acceptedFiles[0], (imageUrl: any) => setImgUrl(imageUrl));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  function content() {
    if (imgUrl) {
      return <CustomerImg src={imgUrl} alt="Foto do cliente" />;
    }
    return (
      <ContentUpload>
        <img src={imgDefault} alt="sem imagem" />
        <p>
          Clique aqui para adicionar uma imagem do seu computador ou arraste-a
          para esse campo.
        </p>
      </ContentUpload>
    );
  }

  const handleInputChange = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (
      inputName === 'document' &&
      value.length < 14 &&
      inputState.document.error
    ) {
      error = 'Campo insuficiente, digite 11 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length < 10) {
      error = 'Campo insuficiente, digite no mínimo 10 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length > 20) {
      error = 'Digite no máximo 20 caracteres ';
      isValid = false;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleInputBlur = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'document') {
      if (value.length < 14) {
        error = 'Campo insuficiente, digite 11 caracteres ';
        isValid = false;
      } else if (!isValidCPF(value)) {
        error = 'CPF inválido';
      }
    }

    if (inputName === 'email' && !isValidEmail(value)) {
      error = 'E-mail inválido';
      isValid = false;
    }

    if (inputName === 'password' && value.length < 6) {
      error = 'Campo insuficiente, digite pelo menos 6 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length < 10) {
      error = 'Campo insuficiente, digite no mínimo 10 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length > 20) {
      error = 'Digite no máximo 20 caracteres ';
      isValid = false;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    Object.entries(inputState).forEach(allInput => {
      handleInputBlur(allInput[1].value, allInput[0]);
    });
    const create = async () => {
      if (isFormValid()) {
        try {
          const request = await UsersService.createUsers({
            nome: inputState.name.value,
            cpf: inputState.document.value.replace(/[^\d]+/g, ''),
            email: inputState.email.value,
            password: inputState.password.value,
            telefone: inputState.phone.value,
            cargo: parseFloat(inputState.office.value),
          });

          if (imgArray.length > 0) {
            const formData = new FormData();
            formData.append('file', imgArray[0]);
            const requestImg = async () => {
              await UsersService.addImgUser({ id: request.id, formData });
            };
            requestImg();
          }
          await ProfilesService.attribute({
            id: request.id,
            object: listModule,
          });
          setIsVisibleModalSuccess(true);
          setSaveUser(true);
          setMessageModal(
            pageTypeEdit
              ? 'Cadastro de usuário editado com sucesso!'
              : 'Cadastro de usuário criado com sucesso!',
          );
          Object.entries(inputState).forEach(allInput => {
            setInputState((prevState: any) => ({
              ...prevState,
              [allInput[0]]: {
                required: allInput[1].required,
                isValid: true,
                error: '',
                value: '',
              },
            }));
          });
          setInputOffice({ id: 0, descricao: '' });
          setCurrentInputOffice('');
        } catch {
          setIsVisibleModalError(true);
          setMessageModal(
            pageTypeEdit
              ? 'Não foi possivel editar o usuário'
              : 'Não foi possivel criar o cadastro de usuário',
          );
        }
      } else {
        setIsVisibleModalError(true);
        setMessageModal(
          pageTypeEdit
            ? 'Não foi possivel editar o usuário'
            : 'Não foi possivel criar o cadastro de usuário',
        );
      }
    };

    const edit: any = async () => {
      try {
        UsersService.updateCustomerById(type, {
          cpf: inputState.document.value.replace(/[^\d]+/g, ''),
          telefone: inputState.phone.value,
          nome: inputState.name.value,
          email: inputState.email.value,
          cargo: parseFloat(inputState.office.value),
        });
        setIsVisibleModalSuccess(true);
        setSaveUser(true);
        setMessageModal(
          pageTypeEdit
            ? 'Cadastro de usuário editado com sucesso!'
            : 'Cadastro de usuário criado com sucesso!',
        );
        if (imgArray.length > 0) {
          const formData = new FormData();
          formData.append('file', imgArray[0]);
          api.post(`user-api/user/${type}/file`, formData);
        }

        // if (attributePermission.length > 1) {
        await ProfilesService.attribute({
          id: type,
          object: listModule,
        });
        // }
        // if (deletePermission.length > 1) {
        //   await ProfilesService.deletePermission({
        //     id: type,
        //     object: deletePermission,
        //   });
        // }
      } catch {
        setIsVisibleModalError(true);
        setMessageModal(
          pageTypeEdit
            ? 'Não foi possivel editar o usuário'
            : 'Não foi possivel criar o cadastro de usuário',
        );
      }

      return null;
    };
    if (pageTypeEdit) {
      edit();
    } else {
      create();
    }
  };

  if (loading) {
    return <Loading />;
  }

  const checkAll = async (checked: boolean, module: any) => {
    if (checked) {
      setListModule(prevState =>
        prevState.filter((e: any) => {
          if (e.modulo === module.modulo) {
            Object.entries(e).forEach((permission: any) => {
              const obj = e;
              if (
                permission[1] !== module.modulo &&
                permission[0] !== 'moduloId'
              ) {
                obj[permission[0]] = 1;
                return obj;
              }
              return obj;
            });
          }
          return e;
        }),
      );
    } else {
      setListModule(prevState =>
        prevState.filter((e: any) => {
          if (e.modulo === module.modulo) {
            Object.entries(e).forEach((permission: any) => {
              const obj = e;
              if (
                permission[1] !== module.modulo &&
                permission[0] !== 'moduloId'
              ) {
                obj[permission[0]] = 0;
                return obj;
              }
              return obj;
            });
          }
          return e;
        }),
      );
    }
  };

  const verifyCheckAll = (module: any) => {
    let state = true;

    Object.entries(module).forEach((permission: any) => {
      if (
        permission[1] !== 1 &&
        permission[1] !== module.modulo &&
        permission[0] !== 'moduloId'
      ) {
        state = false;
      }
    });
    return state;
  };

  return (
    <>
      {isVisibleModalSuccess && (
        <ModalDefault
          success
          onClick={() => {
            setIsVisibleModalSuccess(false);
            if (saveUser) {
              history.push('/users');
              setSaveUser(false);
            }
          }}
        >
          {messageModal}
        </ModalDefault>
      )}
      {isVisibleModalError && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalError(false)}
        >
          {messageModal}
        </ModalDefault>
      )}
      {isVisibleModalOffice && (
        <>
          <Modal>
            <SegmentModalContainer>
              <CloseModal>
                <button
                  type="button"
                  onClick={() => setIsVisibleModalOffice(false)}
                >
                  <IconClose />
                </button>
              </CloseModal>
              <h1>Cadastro de novo Cargo</h1>
              <span>
                <Input
                  label="Título do novo Cargo"
                  onChange={e => setInputNewOffice(e.target.value)}
                  error={
                    inputNewOffice.length < 5
                      ? '*Digite no mínimo 5 caracteres'
                      : undefined
                  }
                  required
                />
              </span>
              <Button
                disabled={inputNewOffice.length < 5}
                onClick={async () => {
                  try {
                    await UsersService.createOffice(inputNewOffice);
                    setMessageModal(
                      'O cadastro de cargo foi concluído com sucesso.',
                    );
                    setIsVisibleModalOffice(false);
                    setIsVisibleModalSuccess(true);
                  } catch {
                    setMessageModal('Ocorreu um erro.');
                    setIsVisibleModalError(true);
                  }
                }}
              >
                Salvar novo cargo
              </Button>
            </SegmentModalContainer>
          </Modal>
        </>
      )}
      <Page>
        <PageHeader
          title={pageTypeEdit ? 'Editar Usuário' : 'Novo Usuário'}
          button="Salvar"
          disabled={!isFormValid()}
          onClick={() => validAll()}
          permission={
            pageTypeEdit
              ? { module: 'USUÁRIOS', permission: 'GERENCIAR' }
              : { module: 'USUÁRIOS', permission: 'INCLUIR' }
          }
        />
        <Container>
          <ContainerUpload>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragReject && (
                <ContentUpload>
                  <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                </ContentUpload>
              )}
              {isDragActive ? (
                <ContentUpload>
                  <p>Solte os arquivos aqui ... </p>
                </ContentUpload>
              ) : (
                content()
              )}
            </div>
          </ContainerUpload>
          <Content>
            <span>
              <b>Dados Gerais</b>
            </span>
            <ContainerBox>
              <InputBox>
                <Input
                  label="Nome"
                  maxLenght={70}
                  requiredLabel
                  error={inputState.name.error}
                  value={inputState.name.value}
                  onChange={e =>
                    handleInputChange(
                      e.target.value.replace(/[^a-zA-Zà-ūÀ-Ū ]/g, ''),
                      'name',
                    )
                  }
                  onBlur={e => handleInputBlur(e.target.value, 'name')}
                />
                <InputPhone
                  label="Telefone"
                  requiredLabel
                  value={inputPhone}
                  error={inputState.phone.error}
                  onChange={e => {
                    setInputPhone(e);
                    if (e !== undefined) {
                      handleInputChange(e, 'phone');
                    }
                  }}
                />
              </InputBox>

              <InputBox>
                <Input
                  label="CPF"
                  requiredLabel
                  value={isFormatCPF(inputState.document.value)}
                  error={inputState.document.error}
                  maxLenght={14}
                  onChange={e => handleInputChange(e.target.value, 'document')}
                  onBlur={e => handleInputBlur(e.target.value, 'document')}
                />
                <Input
                  label="E-mail"
                  requiredLabel
                  value={inputState.email.value}
                  error={inputState.email.error}
                  onChange={e => handleInputChange(e.target.value, 'email')}
                  onBlur={e => handleInputBlur(e.target.value, 'email')}
                />
              </InputBox>
            </ContainerBox>
            <ContainerBox>
              <InputBox>
                <InputSearch
                  type="text"
                  name="office"
                  id="office"
                  lista={listOffice}
                  onChange={e =>
                    setInputOffice({
                      id: inputOffice.id,
                      descricao: e.target.value,
                    })
                  }
                  onClickList={item => {
                    setInputOffice(item);
                    setCurrentInputOffice(item.descricao);
                    handleInputBlur(item.id.toString(), 'office');
                  }}
                  onClick={() => setShowList(!showList)}
                  visibleList={showList}
                  onClickAdd={() => setIsVisibleModalOffice(true)}
                  currentValue={currentInputOffice}
                  valueInput={inputOffice?.descricao}
                  value={inputOffice?.descricao}
                  label="Cargo"
                  requiredLabel
                  maxLenght={15}
                  error={inputState.office.error}
                  setVisibleList={() => setShowList(false)}
                  setCleanInput={() => setInputOffice({ id: 0, descricao: '' })}
                />
              </InputBox>
              <InputBox>
                <Input
                  label="Senha"
                  onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                  type={isVisiblePassword ? 'text' : 'password'}
                  disabled={pageTypeEdit}
                  requiredLabel
                  value={pageTypeEdit ? '******' : inputState.password.value}
                  error={inputState.password.error}
                  onChange={e => handleInputChange(e.target.value, 'password')}
                  onBlur={e => handleInputBlur(e.target.value, 'password')}
                  icon={!pageTypeEdit && <IconVisible />}
                />
              </InputBox>
            </ContainerBox>
            <VisibleForPermissions modules="USUÁRIOS" permissions="GERENCIAR">
              <>
                <span>
                  <b>Permissões</b>
                </span>

                {listModule.map((module: { modulo: string }) => (
                  <>
                    <TitleCheckbox>
                      <b>{module.modulo}</b>
                      <div>
                        <p>Marcar todos</p>
                        <Checkbox
                          checked={verifyCheckAll(module)}
                          onChange={e => checkAll(e.target.checked, module)}
                        />
                      </div>
                    </TitleCheckbox>
                    <ListCheckbox>
                      {Object.entries(module).map((permission: any) => {
                        if (
                          permission[0] !== 'modulo' &&
                          permission[0] !== 'moduloId'
                        ) {
                          return (
                            <div key={permission[0]}>
                              <Checkbox
                                onChange={e => {
                                  if (e.target.checked) {
                                    setListModule(prevState =>
                                      prevState.filter((e: any) => {
                                        if (e.modulo === module.modulo) {
                                          const obj = e;
                                          obj[permission[0]] = 1;

                                          return obj;
                                        }
                                        return e;
                                      }),
                                    );
                                  } else {
                                    setListModule(prevState =>
                                      prevState.filter((e: any) => {
                                        if (e.modulo === module.modulo) {
                                          const obj = e;
                                          obj[permission[0]] = 0;
                                          return obj;
                                        }
                                        return e;
                                      }),
                                    );
                                  }
                                }}
                                checked={permission[1]}
                              />
                              <b>
                                {permission[0] === 'coordenadorDeManutencao'
                                  ? 'Coordenador De Manutenção'
                                  : permission[0] === 'tecnicoDeManutencao'
                                  ? 'Técnico de Manutenção'
                                  : permission[0].charAt(0) +
                                    permission[0]
                                      .slice(1)
                                      .replace(/([A-Z])/g, ' $1')}
                              </b>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </ListCheckbox>
                  </>
                ))}
              </>
            </VisibleForPermissions>
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default UserCreate;
