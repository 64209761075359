/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import { Button } from '~/components';

import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { IResultByTech } from '~/store/modules/report/types';
import { CheckPermission } from '~/utils/checkPermission';

import { Container } from './styles';

export interface ITechnical {
  id: number;
  name: string;
  schedules: ISchedule[];
}

export interface ISchedule {
  id: number;
  client: string;
  project: string;
  start: string;
  next: string;
  status: boolean;
}

export interface IProps {
  onClick: () => void;
  data: IResultByTech[];
}

const TechnicalHeader: React.FC<IProps> = ({ onClick, data }: IProps) => {
  const [values, setValues] = useState({
    techs: 0,
    schedules: 0,
    done: 0,
  });

  const isManager = CheckPermission({
    module: 'RELATÓRIO DE MANUTENÇÃO',
    permission: 'COORDENADORDEMANUTENCAO',
  });

  const { dateSelected } = useSelector((state: IState) => state.report);

  useEffect(() => {
    setValues({
      techs: data.length,
      schedules: data.reduce(
        (acum, curr) => (acum += curr.agendamentos.length),
        0,
      ),
      done: data.reduce(
        (acum, curr) =>
          (acum += curr.agendamentos.filter(
            schedule => schedule.status_relatorio.id === 1,
          ).length),
        0,
      ),
    });
  }, [data]);

  return (
    <Container>
      <div>
        <b>
          {format(new Date(dateSelected), "dd 'de' MMMM 'de' yyyy'", {
            locale: pt,
          })}
        </b>
        <p>
          {values.techs} Técnicos | {values.schedules} Agendamentos |{' '}
          {values.done} Realizados
        </p>
      </div>
      {isManager ? (
        <Button onClick={onClick}>Adicionar agendamento neste dia</Button>
      ) : null}
    </Container>
  );
};

export default TechnicalHeader;
