import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

interface IProps {
  permission: string | null;
  module: string | null;
}

export function CheckPermission({ permission, module }: IProps): boolean {
  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  return userPermissions.find(element => {
    if (element.modulo === module && element.permissao === permission) {
      return true;
    }
    if (module === 'withoutRestriction') {
      return true;
    }
    return false;
  });
}

export function CheckModule({ module }: IProps): boolean {
  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );
  return userPermissions.find(element => {
    if (element.modulo === module || element.modulo === 'withoutRestriction') {
      return true;
    }
    return false;
  });
}
