import styled from 'styled-components';

export const Container = styled.div`
  input {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 700;
    border: 1px solid ${({ theme }) => theme.Colors.Blue.Main};
    border-radius: 5px;
    height: 45px;
    padding: 10px;
    width: 100%;
  }
`;

export const ErroAlert = styled('p')<{ erro: boolean }>`
  margin-top: 4px;
  color: #df0a24 !important;
  font-size: 10px;
  /* min-height: 16px; */

  ${props => !props.erro && `display: none`}
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #000000;
  padding: 6px 0;
  font-weigth: 300;
`;
