/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, FocusEvent } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModalDelete,
  Input,
  Page,
  Summary,
  Button,
  ProductList,
  Loading,
  Avatar,
  ModalDefault,
  Return,
  Select,
} from '~/components';
import { api } from '~/services/api';

import { IconSearch, IconAdd, IconReplace } from '~/assets/Icons';
import { usePageContext } from '~/hooks';
import { useBudgetRequest } from '~/services/request';
import ImgDefault from '~/assets/Images/ImgDefault.svg';
import { IState } from '~/store/modules/types';
// import { listCustomerRequest } from '~/store/modules/customer/actions';
// import { listProductRequest } from '~/store/modules/product/actions';
import {
  addProductBudgetRequest,
  customerBudgetRequest,
  deleteProductBudgetRequest,
  getBudgetRequest,
  getBudgetSuccess,
  quotationCurrencyBudgetRequest,
} from '~/store/modules/budget/actions';
import { listCurrencyRequest } from '~/store/modules/currency/actions';
import CustomersService from '~/services/CustomersService';
import { changeMoney } from '~/utils/format/isFormatMoney';
import CurrenciesServices from '~/services/CurrenciesServices';
import ProductsService from '~/services/ProductsService';
import BudgetsService from '~/services/BudgetsService';
import StockService from '~/services/StockService';
import {
  Title,
  Container,
  Row,
  Buttons,
  Collum,
  SubTitle,
  Value,
  TagA,
  AddDiv,
  ContentTagA,
  ButtonCustomerContent,
  LineSearch,
  ContainerTagA,
  RowCurrency,
  IconCurrancy,
  CollumSmall,
  Label,
  RowProduct,
  BoxTypeProduct,
  BoxInputProduct,
} from './styles';
import ModalAlertCurrecy from './ModalAlertCurrency';
import ModalClient from './ModalAddClient';
import ModalAddCurrency from './ModalAddCurrency';
import ModalErro from './ModalErro';

const OPTIONS = [
  { id: 1, descricao: 'Produto' },
  { id: 2, descricao: 'Sistema' },
];

const Edit: React.FC = () => {
  const { fixed } = usePageContext();
  const { putBudget } = useBudgetRequest();
  const { id } = useParams<{ id: string }>();
  const { handleConfirm } = useBudgetRequest();
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState<any>({});
  const [responseProduct, setResponseProduct] = useState<any[]>([]);
  const [responseCustomer, setResponseCustomer] = useState([]);
  const [responseCurrency, setResponseCurrency] = useState([]);
  const [done, setDone] = useState(false);
  const [produtoEdit, setProdutoEdit] = useState({
    id: 0,
    descricao: '',
  });
  const [pageCustomer, setPageCustomer] = useState(1);
  const [pagesCustomer, setPagesCustomer] = useState(0);
  const [pageCurrency, setPageCurrency] = useState(1);
  const [pagesCurrency, setPagesCurrency] = useState(0);
  const limit = 200;
  const [delet, setDelet] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [addCurrency, setAddCurrency] = useState(false);
  const [modalCurrency, setModalCurrency] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [quotation, setQuotation] = useState(0);
  const [text, setText] = useState('');
  const [textCurrancy, setTextCurrancy] = useState('');
  const [textCustomer, setTextCustomer] = useState('');
  const [clickPosition, setClickPosition] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalAlert, setIsVisibleModalAlert] = useState(false);
  const [searchProductTypeId, setSearchProductTypeId] = useState(OPTIONS[0].id);
  const [searchProductTypeDesciption, setSearchProductTypeDesciption] =
    useState(OPTIONS[0].descricao);

  const deletarProduto = (e: any) => {
    setProdutoEdit(e);
    setDelet(true);
  };

  function cleanActions() {
    setProdutoEdit({
      id: 0,
      descricao: '',
    });
    setDelet(false);
  }

  const data = useSelector((state: IState) => state.budget.data);
  const loadingBudget = useSelector((state: IState) => state.budget.loading);

  useEffect(() => {
    dispatch(getBudgetRequest(id));
    dispatch(listCurrencyRequest());
  }, [id]);

  useEffect(() => setLoading(loadingBudget), [loadingBudget]);

  useEffect(() => {
    setInitialData(data);
  }, []);

  useEffect(() => {
    handleData();
  }, []);

  const getStock = async () => {
    const limit = 200;
    const response = await StockService.listStock({
      limit,
      page: 1,
    });
    const promises = Array.from(
      { length: response.info.pages - 1 },
      (v, k) => k + 2,
    ).reduce((acum: Promise<any>[], curr) => {
      acum.push(
        StockService.listStock({
          limit,
          page: curr,
        }),
      );
      return acum;
    }, []);
    const responsePromise = await Promise.all(promises);

    return [
      ...response.result,
      ...responsePromise.reduce((acum, curr) => {
        acum = [...acum, ...curr.result];
        return acum;
      }, []),
    ];
  };

  const getProducts = async () => {
    const limit = 200;
    const response = await ProductsService.listProducts({
      limit,
      page: 1,
    });
    const promises = Array.from(
      { length: response.info.pages - 1 },
      (v, k) => k + 2,
    ).reduce((acum: Promise<any>[], curr) => {
      acum.push(
        ProductsService.listProducts({
          limit,
          page: curr,
        }),
      );
      return acum;
    }, []);
    await Promise.all(promises);

    return [...response.result];
  };

  const handleData = async () => {
    setLoadingRequest(true);
    const [products, stocks] = await Promise.all([getProducts(), getStock()]);
    const data = products.reduce((acum, curr) => {
      const product = stocks.find(stock => stock.produto.produtoId === curr.id);
      if (product) {
        acum.push(curr);
      }
      return acum;
    }, []);
    setLoadingRequest(false);
    setResponseProduct(data);
  };

  const reloadCustomers = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoadingRequest(true);
    try {
      const res = await CustomersService.listCustomers({
        limit,
        page,
      });
      setResponseCustomer(currencyState => currencyState.concat(res.result));
      setPageCustomer(res.info.page);
      setPagesCustomer(res.info.pages);
    } catch {
      setIsVisibleModalAlert(true);
    }
    setLoadingRequest(false);
  };

  const loadCustomers = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoadingRequest(true);
    try {
      const res = await CustomersService.listCustomers({
        limit,
        page,
      });

      setResponseCustomer(res.result);
      setPageCustomer(res.info.page);
      setPagesCustomer(res.info.pages);
    } catch {
      setIsVisibleModalAlert(true);
    }
    setLoadingRequest(false);
  };

  const reloadCurrency = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoadingRequest(true);
    try {
      const res = await CurrenciesServices.listCurrencies({
        limit,
        page,
      });
      setResponseCurrency(currencyState => currencyState.concat(res.result));
      setPageCurrency(res.info.page);
      setPagesCurrency(res.info.pages);
    } catch {
      setIsVisibleModalAlert(true);
    }
    setLoadingRequest(false);
  };

  const loadCurrency = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    if (!done && addCurrency) {
      setLoadingRequest(true);
      try {
        const res = await CurrenciesServices.listCurrencies({
          limit,
          page,
        });
        setResponseCurrency(res.result);
        setDone(true);
        setPageCurrency(res.info.page);
        setPagesCurrency(res.info.pages);
      } catch {
        setIsVisibleModalAlert(true);
      }
      setLoadingRequest(false);
    }
  };

  const verifyPosition = () => {
    const arr: any = [];

    data.itens.forEach((e: any, index: number) => {
      arr.push({ produtoId: e.id, posicao: index + 1 });
    });

    const request = async () => {
      await BudgetsService.ordenationProduct({
        idBudget: data.id,
        content: arr,
      });
    };

    try {
      request();
    } catch {
      setIsVisibleModalErro(true);
    }
  };

  useEffect(() => {
    loadCustomers({ limit, page: pageCustomer });
  }, []);

  useEffect(() => {
    loadCurrency({ limit, page: pageCurrency });
  }, [addCurrency]);

  useEffect(() => {
    return () => verifyPosition();
  }, [data]);

  async function addproduct(product: any, price: number) {
    await dispatch(addProductBudgetRequest(id, product, data, price));
    setText('');
  }

  const alterProduct = async ({
    quantity,
    price,
    id,
  }: {
    quantity: number;
    price: number;
    id: number;
  }) => {
    await api.put(`/budget-api/budget/${data.id}/item/${id}`, {
      preco: price,
      cotacao: data.moeda.cotacao,
      moedaId: data.moeda.id,
      quantidade: quantity,
    });

    const orcamentoNew = { ...data };

    const indiceAlterProduct = data.itens?.findIndex(
      (item: any) => item.id === id,
    );

    orcamentoNew.itens[indiceAlterProduct].precoNovo = price;
    orcamentoNew.itens[indiceAlterProduct].quantidade = quantity;

    dispatch(
      getBudgetSuccess({
        ...orcamentoNew,
        itens: orcamentoNew.itens,
      }),
    );
  };

  const alterProductOfSystem = async ({
    quantity,
    price,
    id,
    idSystem,
  }: {
    quantity: number;
    price: number;
    id: number;
    idSystem: number;
  }) => {
    await api.put(
      `/budget-api/budget/${data.id}/itemOfSystem/${id}/${idSystem}`,
      {
        preco: price,
        cotacao: data.moeda.cotacao,
        moedaId: data.moeda.id,
        quantidade: quantity,
      },
    );

    const orcamentoNew = { ...data };

    dispatch(
      getBudgetSuccess({
        ...orcamentoNew,
        itens: orcamentoNew.itens,
      }),
    );
  };

  function changePosition(arr: any[], from: number, to: number) {
    setClickPosition(true);
    const newArray = arr;
    newArray.splice(to, 0, arr.splice(from, 1)[0]);
    dispatch(getBudgetSuccess({ ...data, itens: newArray }));
  }

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalAlert && (
        <ModalErro onClick={() => setIsVisibleModalAlert(false)} />
      )}
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {delet && (
        <ModalDelete
          item={`produto ${produtoEdit.descricao}`}
          onClickCancel={cleanActions}
          onClickDel={async () => {
            if (!produtoEdit.id) return;
            dispatch(deleteProductBudgetRequest(data, produtoEdit));
            cleanActions();
          }}
        />
      )}
      {modalCurrency && (
        <ModalAlertCurrecy onClick={() => setModalCurrency(false)} />
      )}
      {addClient && (
        <ModalClient
          pagination={() => {
            if (pageCustomer < pagesCustomer && !loadingRequest) {
              reloadCustomers({ limit, page: pageCustomer + 1 });
              setPageCustomer(pageCustomer + 1);
            }
          }}
          idBudget={id}
          closeButton={() => setAddClient(false)}
          inputValue={textCustomer}
          inputOnChange={e => setTextCustomer(e.target.value)}
          dataCustomer={responseCustomer}
          clientOnClick={customer => {
            dispatch(customerBudgetRequest(data, customer));
            setAddClient(false);
          }}
        />
      )}

      {addCurrency && (
        <ModalAddCurrency
          closeButton={() => setAddCurrency(false)}
          inputSearchValue={textCurrancy}
          inputSearchOnChange={e => setTextCurrancy(e.target.value)}
          dataCurrency={responseCurrency}
          inputQuotationValue={quotation}
          inputQuotationOnChange={(e: FocusEvent<HTMLInputElement>) => {
            setQuotation(changeMoney(e.target.value));
          }}
          currencyOnClick={Currency => {
            if (quotation) {
              dispatch(
                quotationCurrencyBudgetRequest(id, Currency.id, quotation),
              );
              setAddCurrency(false);
            } else {
              setModalCurrency(true);
            }
          }}
          pagination={() => {
            if (pageCurrency < pagesCurrency && !loadingRequest) {
              reloadCurrency({ limit, page: pageCurrency + 1 });
              setPageCurrency(pageCurrency + 1);
            }
          }}
        />
      )}
      <Page>
        <Return />
        <Row>
          <Container>
            <Row>
              <Title>Novo Orçamento</Title>
              <Buttons>
                <Button onClick={() => setAddClient(true)}>
                  {data.cliente.nome ? (
                    <ButtonCustomerContent>
                      <Row aling>
                        <Avatar name={data.cliente.nome} />
                        <Collum>
                          <p>Cliente:</p>
                          <b>{data.cliente.nome}</b>
                        </Collum>
                      </Row>
                      <i>
                        <IconReplace />
                      </i>
                    </ButtonCustomerContent>
                  ) : (
                    '+ Adicionar Cliente'
                  )}
                </Button>
              </Buttons>
            </Row>
            <RowCurrency>
              <div>
                <Input
                  name="titulodoOrçamento"
                  id="inputTituloOrçamento"
                  label="Título do Orçamento"
                  value={data.descricao}
                  onChange={(e: FocusEvent<HTMLInputElement>) => {
                    dispatch(
                      getBudgetSuccess({
                        id: data.id,
                        uuid: data.uuid,
                        descricao: e.target.value,
                        preco: data.preco,
                        precoTotal: data.precoTotal,
                        valorDesconto: data.valorDesconto,
                        valorMaoDeObra: data.valorMaoDeObra,
                        desconto: data.desconto,
                        maoDeObra: data.maoDeObra,
                        observacao: data.observacao,
                        responsavel: data.responsavel,
                        criador: data.criador,
                        situacao: data.situacao,
                        cliente: data.cliente,
                        moeda: data.moeda,
                        dataValidade: data.dataValidade,
                        dataCriacao: data.dataCriacao,
                        dataAtualizacao: data.dataAtualizacao,
                        itens: data.itens,
                        justificativa: data.justificativa,
                        endereco: data.endereco,
                        emails: data.emails,
                        pagamento: data.pagamento,
                      }),
                    );
                  }}
                  onBlur={async () => {
                    putBudget({
                      idBudget: id,
                      budgetDescription: data.descricao,
                    });
                  }}
                />
              </div>
              <Buttons>
                <Button
                  onClick={() => {
                    setAddCurrency(true);
                  }}
                >
                  {data.moeda.id ? (
                    <ButtonCustomerContent>
                      <Row aling>
                        <IconCurrancy>{data.moeda.sigla}</IconCurrancy>
                        <Collum>
                          <p>Moeda:</p>
                          <b>{data.moeda.nome}</b>
                        </Collum>
                      </Row>
                      <CollumSmall>
                        <p>Cotação do dia:</p>
                        <b>R$ {data.moeda.cotacao}</b>
                      </CollumSmall>
                      <i>
                        <IconReplace />
                      </i>
                    </ButtonCustomerContent>
                  ) : (
                    '+ Adicionar Moeda'
                  )}
                </Button>
              </Buttons>
            </RowCurrency>
            <RowProduct>
              <BoxTypeProduct>
                <Label>Tipo</Label>
                <Select
                  lista={OPTIONS}
                  onClickList={item => {
                    setSearchProductTypeId(item.id);
                    setSearchProductTypeDesciption(item.descricao);
                  }}
                  currentValue={searchProductTypeDesciption}
                />
              </BoxTypeProduct>
              <BoxInputProduct>
                <Input
                  name="pesquisarProdutos"
                  id="InputPesquisarProdutos"
                  label="Pesquisar Produtos"
                  icon={<IconSearch />}
                  value={text}
                  onChange={e => {
                    setText(e.target.value);
                  }}
                  disabled={!data.moeda.id}
                />
              </BoxInputProduct>
            </RowProduct>
            {text.length > 2 && !!data.moeda.id && (
              <AddDiv fix={fixed}>
                {responseProduct
                  .filter(
                    (product: any) =>
                      !data.itens.find((e: any) => e.id === product.id),
                  )
                  .filter(
                    (product: any) =>
                      product.categoria.id === searchProductTypeId,
                  )
                  .filter((product: any) => {
                    return (
                      product.descricao
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) > -1
                    );
                  })
                  .map((product: any) => {
                    const price = product.preco;
                    let indexImg = product.imagens.findIndex(
                      (img: any) => img.default === 1,
                    );
                    if (indexImg === -1)
                      indexImg =
                        indexImg === -1 && product.imagens.length
                          ? product.imagens.length - 1
                          : -1;
                    return (
                      <>
                        <TagA
                          onClick={() => {
                            addproduct(product, price);
                          }}
                          key={`${product.id}-${product.tipo.id}-${product.descricao}`}
                        >
                          <LineSearch />
                          <ContainerTagA>
                            <ContentTagA>
                              <img
                                alt=""
                                src={
                                  product.imagens[indexImg]
                                    ? product.imagens[indexImg].fileUrl
                                    : ImgDefault
                                }
                              />
                              <Collum>
                                <span>
                                  <b>{`${product.descricao}`}</b>
                                </span>
                                <Value>
                                  {/* <b>9</b> Produtos em Estoque */}
                                </Value>
                              </Collum>
                            </ContentTagA>
                            <ContentTagA>
                              <Collum>
                                <Value>
                                  <p>
                                    <b>Valor Unitário:</b>
                                  </p>
                                  <p>
                                    <b>
                                      {product.preco
                                        ? `${price.toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL',
                                          })}`
                                        : 'R$ 0,00'}
                                    </b>
                                  </p>
                                </Value>
                              </Collum>
                              <i>
                                <IconAdd />
                              </i>
                            </ContentTagA>
                          </ContainerTagA>
                        </TagA>
                      </>
                    );
                  })}
              </AddDiv>
            )}
            <SubTitle>Produtos Adicionados</SubTitle>
            {data.itens.map((product: any, index: number) => (
              <ProductList
                idCurrency={data.moeda.id}
                idBudget={id}
                quotation={data.moeda.cotacao}
                product={product}
                currency={data.moeda.codigo}
                alterProduct={alterProduct}
                alterProductOfSystem={alterProductOfSystem}
                btTrashOnClick={() => deletarProduto(product)}
                key={product.id}
                position={{ index, length: data?.itens?.length }}
                onChange={e => {
                  changePosition(data.itens, index, e);
                }}
                updateBudget={() => dispatch(getBudgetRequest(id))}
              />
            ))}
          </Container>
          <Summary
            ButtonOnClick={() => {
              handleConfirm(id);
            }}
            Fix={fixed}
            Itens={data.itens}
            Id={id}
            currency={data.moeda.codigo}
            quotation={data.moeda.cotacao}
            disabled={
              !data.moeda.id || !data.cliente.id || data.itens.length === 0
            }
            data={data}
          />
        </Row>
      </Page>
    </>
  );
};

export default Edit;
