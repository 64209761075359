import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 10vh;
  height: 100%;
  max-height: 10vh;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%;
    height: 100%; */
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    span {
      width: 100%;
      padding-top: 100%;
      transform: none !important;
      position: static;
      font-size: 100%;
      p {
        position: absolute;
        top: 0;
        margin: 0;
      }
    }
  }
`;
