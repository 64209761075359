/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';

import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  PageHeader,
  TablePage,
  TableActions,
  Modal,
  Button,
  Loading,
} from '~/components';
import { IState } from '~/store/modules/types';
import { listSystemRequest } from '~/store/modules/stock/actions';
import { IProduct } from '~/store/modules/stock/types';
import StockService from '~/services/StockService';

import { Container, Row, Content, ButtonStyle } from './styles';

interface IModal extends ITypeModal {
  active: boolean;
  data?: IProduct;
  action?: string;
}

interface ITypeModal {
  type?: 'buy' | 'delete' | 'success' | 'error';
}

const System: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const formatCurrency = (value: number | null) => {
    return (value || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const valueStored = sessionStorage.getItem('nameProductSearched') || '';

  const [modal, setModal] = useState<IModal>({ active: false });
  const [data, setData] = useState<IProduct[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [backToModal] = useState(history.location.state?.backToModal || false);
  const [item] = useState(history.location.state?.item || null);
  const [columns] = useState([
    {
      title: 'Assunto',
      key: 'presentation',
      render: (data: IProduct) => (
        <Row justify>
          <TableActions
            functionEdit={() => handleClickProduct(data.id)}
            functionTrash={() => handleClickDelete(data)}
          />
          <div>
            <p>{data.descricao}</p>
            {data.ultimaAquisicao ? (
              <p>
                Ultima aquisição em{' '}
                {format(new Date(data.ultimaAquisicao), 'dd/MM/yyyy', {
                  locale: pt,
                })}
              </p>
            ) : null}
          </div>
        </Row>
      ),
    },
    {
      title: 'Quantidade de produtos',
      dataIndex: 'quantidadeDeProdutos',
      key: 'quantidadeDeProdutos',
      sorter: (a: IProduct, b: IProduct) =>
        (a.quantidadeDeProdutos || 0) - (b.quantidadeDeProdutos || 0),
      ellipsis: true,
      render: (value: number) => `${value || 0} Produtos`,
    },
    {
      title: 'Valor',
      dataIndex: 'preco',
      key: 'preco',
      sorter: (a: IProduct, b: IProduct) => a.preco - b.preco,
      ellipsis: true,
      render: formatCurrency,
    },
  ]);

  const { systems, loading } = useSelector((state: IState) => state.stock);

  useEffect(() => {
    dispatch(listSystemRequest());
  }, []);

  useEffect(() => {
    if (backToModal) {
      handleClickBuy(item);
    }
  }, [backToModal]);

  useEffect(() => {
    setData(systems);
    handleSearch(valueStored);
  }, [systems]);

  const handleClickBuy = (data: IProduct) => {
    setModal({ active: true, data, type: 'buy' });
  };

  const handleClickDelete = (data: IProduct) => {
    setModal({ active: true, data, type: 'delete' });
  };

  const handleAction = (type: 'success' | 'error', action: string) => {
    setModal({ active: true, type, action });
  };

  const closeModal = () => {
    setModal({
      active: false,
    });
  };

  const handleExcludeModal = async (id?: number) => {
    setLoading(true);
    try {
      await StockService.deleteSystem(id || 0);
      closeModal();

      handleAction('success', 'deletar');
      setLoading(false);
    } catch (error) {
      closeModal();
      handleAction('error', 'deletar');
      setLoading(false);
    }
    dispatch(listSystemRequest());
  };

  const handleClickProduct = (id?: number) => {
    history.push({
      pathname: '/stock/system/save',
      ...(id !== undefined && typeof id === 'number' ? { state: { id } } : {}),
    });
  };

  const handleSearch = (value: string) => {
    sessionStorage.setItem('nameProductSearched', value);
    setData(
      systems.filter(
        curr =>
          (curr?.descricao || '')
            .toLowerCase()
            .indexOf((value || '').toLowerCase()) !== -1,
      ),
    );
  };

  const renderModal = useMemo(() => {
    if (
      !modal.active ||
      !modal.type ||
      !['success', 'error'].includes(modal.type)
    )
      return;

    const MODAL_TITLE = {
      success: `Sucesso ao ${modal.action}`,
      error: `Erro ao ${modal.action}`,
    };

    return (
      <Modal>
        <Content>
          <h3>{MODAL_TITLE[modal.type as 'success' | 'error']}</h3>

          <ButtonStyle>
            <Button
              onClick={() => {
                closeModal();
              }}
            >
              Entendido
            </Button>
          </ButtonStyle>
        </Content>
      </Modal>
    );
  }, [modal]);

  return (
    <>
      {modal.active && modal.type === 'delete' ? (
        <Modal>
          <Content>
            <h3>Confirma exclusão?</h3>
            {isLoading ? (
              <Loading />
            ) : (
              <Row>
                <ButtonStyle>
                  <Button onClick={closeModal}>Não</Button>
                </ButtonStyle>
                <Button onClick={() => handleExcludeModal(modal.data?.id)}>
                  Sim
                </Button>
              </Row>
            )}
          </Content>
        </Modal>
      ) : null}
      {renderModal}
      <Page>
        <PageHeader
          textInputSearch={valueStored}
          title="Configuração de Sistemas"
          button="+ Criar novo sistema"
          onClick={handleClickProduct}
          permission={{ module: 'ESTOQUE DE PRODUTOS', permission: 'INCLUIR' }}
          search="Pesquisar em Sistemas"
          onSearch={handleSearch}
        />
        {loading && <Loading />}
        {(!loading && (
          <Container>
            <TablePage columns={columns} dataSource={data} />
          </Container>
        )) ||
          null}
      </Page>
    </>
  );
};

export default System;
