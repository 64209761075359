/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory, Redirect } from 'react-router-dom';

import { loadRequest, loadReset } from '~/store/modules/auth/actions';

import Logo from '~/assets/Logotipo/water-design-logo-grande.png';
import { Button, Input, Modal } from '~/components';
import { IconAlert, IconClose } from '~/assets/Icons';
import { IState } from '~/store/modules/types';
import {
  Container,
  Content,
  Header,
  Title,
  Text,
  Auth,
  ModalAuth,
  Version,
} from './styles';

const Login: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const erro = useSelector((state: IState) => state.auth.error);
  const [fail, setFail] = useState(false);
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const isLogged = useSelector((state: IState) => state.auth.isLogged);
  useEffect(() => setFail(erro), [erro]);
  useEffect(() => {
    if (isLogged) {
      return history.push('/home');
    }
    return null;
  }, [isLogged]);

  async function loger() {
    await dispatch(loadRequest(email, senha));
  }

  function reset() {
    dispatch(loadReset());
  }

  return (
    <>
      {fail && (
        <Modal>
          <ModalAuth>
            <i>
              <IconClose onClick={reset} />
            </i>
            <div>
              <b>
                <IconAlert />
              </b>
              <b>Credenciais Erradas</b>
              <span>
                Por favor, verifique seu e-mail e sua senha e tente novamente!
              </span>
            </div>
            <Button onClick={reset}>Entendi</Button>
          </ModalAuth>
        </Modal>
      )}
      <Header>
        <img src={Logo} alt="Logo-Waterdesing" />
        <hr />
      </Header>
      <Container>
        <Content>
          <Title>Seja bem-vindo!</Title>
          <div>
            <Text>
              Para entrar no sistema da Water Design realize seu login aqui:
            </Text>
            <Auth state={false}>
              <Input
                name="email"
                label="E-mail:"
                id="inputEmail"
                type="email"
                onChange={e => setEmail(e.target.value)}
              />
              <p>*E-mail Inválido</p>
            </Auth>
            <Auth state={false}>
              <Input
                name="senha"
                label="Senha:"
                id="inputSenha"
                type="password"
                onChange={e => setSenha(e.target.value)}
              />
              <p>*Senha Inválido</p>
            </Auth>
            <Button onClick={loger}>Entrar</Button>
          </div>
          <a
            onClick={() => {
              history.push('/recovery');
            }}
          >
            Esqueci minha senha
          </a>
        </Content>
      </Container>
      <Version>{`Versão ${process.env.REACT_APP_VERSION}`}</Version>
    </>
  );
};

export default Login;
