import { createGlobalStyle } from 'styled-components';

// import ComfortaaBold from '~/assets/Fonts/Comfortaa-Bold.ttf';
// import ComfortaaLigth from '~/assets/Fonts/Comfortaa-Light.ttf';
// import ComfortaaRegular from '~/assets/Fonts/Comfortaa-Regular.ttf';

export default createGlobalStyle`
 /* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&display=swap'); */

  :root {
    --cor-principal: red;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Comfortaa', sans-serif;
    font-size: clamp(11px, 1vw + 0.1rem, 16px);
    font-weight: 400;
  }

  body {
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    color: #323232;
  }

  body, input, button {
    font-family: 'Comfortaa', sans-serif;
    font-size: clamp(11px, 1vw + 0.1rem, 16px);
    font-weight: normal;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: none;
  }

  body {
    margin: 0;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }
  
  html {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    overflow-x: hidden;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;
