import { call, put } from 'redux-saga/effects';
import { api } from '~/services/api';
import { IResponse } from './types';
import { getConstructionSuccess, getConstructionFailure } from './actions';

export function* getConstruction(action: {
  type: string;
  payload: { id: string | number };
}): any {
  const { id } = action.payload;

  try {
    const dataCustomer = async () => {
      const request = await api.get(`/construction-api/construction/${id}`);
      return request.data;
    };
    const request: IResponse = yield call(dataCustomer);
    yield put(getConstructionSuccess(request));
  } catch (err) {
    yield put(getConstructionFailure());
  }
}
