import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 75%;
  height: 100%;
  /* background: green; */
  flex-direction: column;

  padding: 5px;
`;
