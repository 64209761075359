/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Loading, ModalDefault, Page, PageHeader } from '~/components';
import ConstructionsService from '~/services/ConstructionsService';
import UsersService from '~/services/UsersService';
import {
  getConstructionRequest,
  getConstructionSuccess,
} from '~/store/modules/construnction/actions';
import { IState } from '~/store/modules/types';
import { IconReturn } from '~/assets/Icons';
import {
  ButtonHeaderContainer,
  Container,
  Content,
  HeaderContainer,
  Header,
  ButtonMain,
} from './styles';
import SwitchWorkManagement from './SwitchWorkManagement';
import SwitchVisualDesing from './SwitchVisualDesign';
import SwitchTechnicalProject from './SwitchTechnicalProject';
import SwitchQuantitative from './SwitchQuantitative';
import SwitchHistoric from './SwitchHistoric';
import SwitchContructionSchedule from './SwitchConstructionSchedule';
import ModalJustification from './ModalJustification';
import ModalConfirmation from './ModalConfirmation';
import DropDown from './components/DropDown';

const DiaryView: React.FC = () => {
  const { type } = useParams<{ type: string }>();
  const dispatch = useDispatch();

  const dataSource = useSelector((state: any) => state.construction.data);
  const loadingConstruction = useSelector(
    (state: any) => state.construction.loading,
  );
  const userId = useSelector((state: IState) => state.auth.data.account.id);

  const [loading, setLoading] = useState(true);
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState<string>('');

  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleDetails, setIsVisibleDetails] = useState(false);

  const [isVisibleModalJustification, setIsVisibleModalJustification] =
    useState(false);
  const [isVisibleModalConfirmation, setIsVisibleModalConfirmation] =
    useState(false);
  const [isVisibleModalConclusion, setIsVisibleModalConclusion] =
    useState(false);
  const [messageModalStartErro, setMessageModalStartErro] = useState<any>({
    type: '',
    message: '',
    messageConfimation: '',
    messageDate: '',
  });

  const [inputTextarea, setInputTextarea] = useState('');
  const [inputDate, setInputDate] = useState('');

  const [historic, setHistoric] = useState(true);
  const [timeline, setTimeline] = useState(false);

  const [workManagement, setWorkManagement] = useState(true);
  const [technicalProject, setTechnicalProject] = useState(false);
  const [visualDesign, setVisualDesign] = useState(false);
  const [quantitative, setQuantitative] = useState(false);

  const [permission, setPermission] = useState(false);

  useEffect(() => {
    dispatch(getConstructionRequest(type));
  }, [type]);

  useEffect(() => {
    if (workManagement) dispatch(getConstructionRequest(type));
  }, [workManagement]);

  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  useEffect(() => {
    const load = async () => {
      try {
        setLoadingPermission(true);
        const responseUsers = await UsersService.listUsers({
          limit: 200,
          page: 1,
        });
        setUsers(responseUsers.result);
        const responseUser = await UsersService.getUserById(userId);
        setUser(responseUser?.cargo?.descricao);
        setLoadingPermission(false);
      } catch {
        setIsVisibleModalErro(true);
      }
    };
    load();
  }, [type, userId]);

  useEffect(() => setLoading(loadingConstruction), [loadingConstruction]);

  const updateRedux = (e: any) => {
    dispatch(
      getConstructionSuccess({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource.projeto_tecnico,
          ...e,
        },
      }),
    );
  };

  const handleStartSchedules = async (dataSource: any) => {
    if (
      !dataSource.projeto_tecnico?.quadro_geral_instalado &&
      !dataSource.projeto_tecnico?.pendencia_quadro_geral?.justificativa
    ) {
      return (
        setMessageModalStartErro({
          type: 'QuadroGeral',
          message:
            'O Quadro Geral ainda não foi instalado completamente, deseja seguir com o ínicio do cronograma?',
          messageConfimation: 'a instalação completa do Quadro Geral',
          messageDate: 'para instalação Quadro Geral',
        }),
        setIsVisibleModalConfirmation(true)
      );
    }
    if (
      !dataSource.projeto_tecnico.equipamentos_recebidos &&
      !dataSource.projeto_tecnico?.pendencia_recebidos?.justificativa
    ) {
      return (
        setMessageModalStartErro({
          type: 'Entrega',
          message:
            'Os produtos vendidos ainda não foram entregues no endereço da Obra. Deseja seguir mesmo assim com o ínicio do cronograma?',
          messageConfimation: 'que os produtos vendidos tenham sido entregues',
          messageDate: 'de entrega dos produtos vendidos',
        }),
        setIsVisibleModalConfirmation(true)
      );
    }
    if (
      !dataSource.projeto_tecnico.tubulacao_hidraulica &&
      !dataSource.projeto_tecnico?.pendencia_tubulacao_hidraulica?.justificativa
    ) {
      setMessageModalStartErro({
        type: 'Tubulacao',
        message: 'A tubulação hidráulica já está preparada?',
        messageConfimation: 'a Tubulação Hidráulica preparada',
        messageDate: 'para preparação da Tubulação Hidráulica:',
      });
      return setIsVisibleModalConclusion(true);
    }
    if (
      !dataSource.projeto_tecnico.casa_de_maquinas &&
      !dataSource.projeto_tecnico?.pendencia_casa_de_maquinas?.justificativa
    ) {
      return (
        setMessageModalStartErro({
          type: 'CasaDeMaquina',
          message: 'A Casa de Máquinas já está finalizada?',
          messageConfimation: 'a Casa de Máquinas estar finalizada',
          messageDate: 'para conclusão da Casa de Máquinas',
        }),
        setIsVisibleModalConclusion(true)
      );
    }
    ConstructionsService.startSchedule(type);
    dispatch(
      getConstructionSuccess({
        ...dataSource,
        iniciado: 1,
      }),
    );
    return null;
  };

  const requestStartSchedules = () => {
    if (messageModalStartErro.type === 'QuadroGeral') {
      ConstructionsService.updateJustification({
        id: type,
        request: {
          justificativaQuadroGeral: inputTextarea,
          dataQuadroGeral: inputDate,
        },
      });
      ConstructionsService.createTimeline({
        id: type,
        stageWorkId: 58,
        tagId: 55,
        text: `Justificativa Quadro Geral - ${inputDate} - ${inputTextarea}`,
      });
      updateRedux({
        pendencia_quadro_geral: {
          justificativa: inputTextarea,
          prazo: inputDate,
        },
      });

      handleStartSchedules({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource.projeto_tecnico,
          pendencia_quadro_geral: {
            justificativa: inputTextarea,
            prazo: inputDate,
          },
        },
      });
      setInputTextarea('');
      setInputDate('');
    }
    if (messageModalStartErro.type === 'Tubulacao') {
      ConstructionsService.updateJustification({
        id: type,
        request: {
          justificativaTubulacao: inputTextarea,
          dataTubulacao: inputDate,
        },
      });
      ConstructionsService.createTimeline({
        id: type,
        stageWorkId: 58,
        tagId: 55,
        text: `Justificativa Tubulação - ${inputDate} - ${inputTextarea}`,
      });
      updateRedux({
        pendencia_tubulacao_hidraulica: {
          justificativa: inputTextarea,
          prazo: inputDate,
        },
      });
      handleStartSchedules({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource.projeto_tecnico,
          pendencia_tubulacao_hidraulica: {
            justificativa: inputTextarea,
            prazo: inputDate,
          },
        },
      });

      setInputTextarea('');
      setInputDate('');
    }
    if (messageModalStartErro.type === 'CasaDeMaquina') {
      ConstructionsService.updateJustification({
        id: type,
        request: {
          justificativaCasaDeMaquina: inputTextarea,
          dataCasaDeMaquina: inputDate,
        },
      });
      ConstructionsService.createTimeline({
        id: type,
        stageWorkId: 58,
        tagId: 55,
        text: `Justificativa Casa de Máquina - ${inputDate} - ${inputTextarea}`,
      });
      updateRedux({
        pendencia_casa_de_maquinas: {
          justificativa: inputTextarea,
          prazo: inputDate,
        },
      });
      handleStartSchedules({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource.projeto_tecnico,
          pendencia_casa_de_maquinas: {
            justificativa: inputTextarea,
            prazo: inputDate,
          },
        },
      });
      setInputTextarea('');
      setInputDate('');
    }
    if (messageModalStartErro.type === 'Entrega') {
      ConstructionsService.updateJustification({
        id: type,
        request: {
          justificativaEntrega: inputTextarea,
          dataEntrega: inputDate,
        },
      });
      ConstructionsService.createTimeline({
        id: type,
        stageWorkId: 58,
        tagId: 55,
        text: `Justificativa Entrega - ${inputDate} - ${inputTextarea}`,
      });
      updateRedux({
        pendencia_recebidos: {
          justificativa: inputTextarea,
          prazo: inputDate,
        },
      });
      handleStartSchedules({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource.projeto_tecnico,
          pendencia_recebidos: {
            justificativa: inputTextarea,
            prazo: inputDate,
          },
        },
      });
      setInputTextarea('');
      setInputDate('');
    }
  };
  const requestStartConfirmSchedules = async () => {
    if (messageModalStartErro.type === 'CasaDeMaquina') {
      try {
        await ConstructionsService.updateTechnicalProject({
          id: dataSource.projeto_tecnico.id,
          machineHouse: 1,
        });
      } catch {
        setIsVisibleModalErro(true);
      }
      updateRedux({
        casa_de_maquinas: 1,
      });
      handleStartSchedules({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource.projeto_tecnico,
          casa_de_maquinas: 1,
          tubulacao_hidraulica: dataSource.projeto_tecnico.tubulacao_hidraulica,
        },
      });
    }
    if (messageModalStartErro.type === 'Tubulacao') {
      try {
        await ConstructionsService.updateTechnicalProject({
          id: dataSource.projeto_tecnico.id,
          hydraulicPipe: 1,
        });
      } catch {
        setIsVisibleModalErro(true);
      }
      updateRedux({
        tubulacao_hidraulica: 1,
      });
      handleStartSchedules({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource.projeto_tecnico,
          tubulacao_hidraulica: 1,
          casa_de_maquinas: dataSource.projeto_tecnico.casa_de_maquinas,
        },
      });
    }
  };

  const handlePermission = () => {
    if (!dataSource?.corpo_tecnico) return false;
    if (!user) return false;
    const bodyTech = dataSource?.corpo_tecnico;
    const keysTechs = Object.keys(bodyTech || {}).filter(curr => curr !== 'id');
    const ids = keysTechs.reduce(
      (acum: number[], curr) => {
        const tech = bodyTech[curr];
        if (tech.id && !acum.find(c => c === tech.id)) acum.push(tech.id);
        return acum;
      },
      [dataSource.responsavel.id],
    );
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    if (
      (!!DICT.find(key => key === user.toUpperCase()) ||
        !!ids.find(id => userId === id)) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      })
    ) {
      return setPermission(true);
    }
    if (dataSource.terminado) {
      setPermission(false);
    }
    setPermission(false);

    return () => setPermission(false);
  };

  useEffect(() => {
    handlePermission();
  }, [dataSource, user]);

  if (loadingPermission) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }
  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }
  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalConfirmation(true)}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModalJustification && (
        <ModalJustification
          onChange={e => setInputTextarea(e.target.value)}
          onChangeDate={e => setInputDate(e.target.value)}
          text={messageModalStartErro.messageConfimation}
          textDate={messageModalStartErro.messageDate}
          onClickConfirm={() => {
            requestStartSchedules();
            setIsVisibleModalJustification(false);
          }}
          onClickCancel={() => setIsVisibleModalJustification(false)}
        />
      )}
      {isVisibleModalConfirmation && (
        <ModalConfirmation
          onClickClose={() => setIsVisibleModalConfirmation(false)}
          text={messageModalStartErro.message}
          onClickConfirm={() => {
            setIsVisibleModalJustification(true);
            setIsVisibleModalConfirmation(false);
          }}
          onClickCancel={() => setIsVisibleModalConfirmation(false)}
        />
      )}
      {isVisibleModalConclusion && (
        <ModalConfirmation
          text={messageModalStartErro.message}
          onClickConfirm={() => {
            requestStartConfirmSchedules();
            setIsVisibleModalConclusion(false);
          }}
          onClickClose={() => setIsVisibleModalConclusion(false)}
          onClickCancel={() => {
            setIsVisibleModalJustification(true);
            setIsVisibleModalConclusion(false);
          }}
        />
      )}

      <Page>
        <Header state={!dataSource.terminado}>
          <PageHeader
            title="Diário de obra"
            button={dataSource.terminado ? 'Obra Finalizada' : 'Não finalizada'}
            onClick
            permission={{ module: 'DIÁRIO DE OBRAS', permission: 'INCLUIR' }}
          />
        </Header>
        <Container>
          <ButtonMain onClick={() => setIsVisibleDetails(!isVisibleDetails)}>
            Informações do Projeto
            <IconReturn />
          </ButtonMain>
          {isVisibleDetails && <DropDown data={dataSource} />}
        </Container>
        <HeaderContainer>
          <ButtonHeaderContainer
            active={workManagement}
            onClick={() => {
              setWorkManagement(true);
              setTechnicalProject(false);
              setVisualDesign(false);
              setQuantitative(false);
            }}
          >
            Gerenciamento da Obra
          </ButtonHeaderContainer>

          <ButtonHeaderContainer
            active={visualDesign}
            onClick={() => {
              setWorkManagement(false);
              setTechnicalProject(false);
              setVisualDesign(true);
              setQuantitative(false);
            }}
          >
            Projeto Visual
          </ButtonHeaderContainer>

          <ButtonHeaderContainer
            active={technicalProject}
            onClick={() => {
              setWorkManagement(false);
              setTechnicalProject(true);
              setVisualDesign(false);
              setQuantitative(false);
            }}
          >
            Projeto Técnico
          </ButtonHeaderContainer>

          <ButtonHeaderContainer
            active={quantitative}
            onClick={() => {
              setWorkManagement(false);
              setTechnicalProject(false);
              setVisualDesign(false);
              setQuantitative(true);
            }}
          >
            Quantitativo
          </ButtonHeaderContainer>
        </HeaderContainer>
        <Content>
          {workManagement && (
            <SwitchWorkManagement
              user={user}
              dataSource={dataSource}
              users={users}
            />
          )}
          {visualDesign && (
            <SwitchVisualDesing user={user} dataSource={dataSource} />
          )}
          {technicalProject && (
            <SwitchTechnicalProject user={user} dataSource={dataSource} />
          )}
          {quantitative && (
            <SwitchQuantitative user={user} dataSource={dataSource} />
          )}
        </Content>
        <Container aling width="40%">
          {!dataSource.iniciado && permission && (
            <Button styles={1} onClick={() => handleStartSchedules(dataSource)}>
              Iniciar Cronograma da Obra
            </Button>
          )}
        </Container>
        <HeaderContainer>
          <ButtonHeaderContainer
            onClick={() => {
              setHistoric(true);
              setTimeline(false);
            }}
            active={historic}
          >
            Histórico da Obra
          </ButtonHeaderContainer>
          {!!dataSource.iniciado && (
            <ButtonHeaderContainer
              onClick={() => {
                setHistoric(false);
                setTimeline(true);
              }}
              active={timeline}
            >
              Cronograma da Obra
            </ButtonHeaderContainer>
          )}
        </HeaderContainer>
        <Content>
          {historic && (
            <SwitchHistoric
              permission={permission}
              user={user}
              dataSource={dataSource}
              stageWorkId={{
                workManagement,
                technicalProject,
                visualDesign,
                quantitative,
              }}
              id={type}
            />
          )}
          {timeline && (
            <>
              <SwitchContructionSchedule
                permission={permission}
                user={user}
                dataSource={dataSource}
              />
            </>
          )}
        </Content>
      </Page>
    </>
  );
};

export default DiaryView;
