import { Reducer } from 'redux';
import { StockTypes, IStocksState } from './types';

const INITIAL_STATE: IStocksState = {
  stocks: [],
  totalStock: {
    quantidadeFaltanteEmEstoque: 0,
    valorTotalEmEstoque: 0,
  },
  product: null,
  loading: false,
  loadingTotal: false,
  loadingProduct: false,
  errorSavingProduct: null,
  savingProduct: false,
  type: [],
  subtype: [],
  producer: [],
  manufacturer: [],
  systems: [],
  products: [],
};

const reducer: Reducer<IStocksState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StockTypes.LIST_REQUEST:
      return { ...state, loading: true };
    case StockTypes.LIST_SUCCCES:
      return {
        ...state,
        stocks: action.payload.stocks,
        loading: false,
      };
    case StockTypes.LIST_FAILURE:
      return {
        ...state,
        stocks: [],
        loading: false,
      };

    case StockTypes.TOTAL_STOCK_REQUEST:
      return { ...state, loadingTotal: true };
    case StockTypes.TOTAL_STOCK_SUCCCES:
      return {
        ...state,
        totalStock: action.payload.totalStock,
        loadingTotal: false,
      };
    case StockTypes.TOTAL_STOCK_FAILURE:
      return {
        ...state,
        totalStock: INITIAL_STATE.totalStock,
        loadingTotal: false,
      };

    case StockTypes.PRODUCT_STOCK_REQUEST:
      return { ...state, loadingProduct: true };
    case StockTypes.PRODUCT_STOCK_SUCCCES:
      return {
        ...state,
        product: action.payload.product,
        loadingProduct: false,
      };
    case StockTypes.PRODUCT_STOCK_FAILURE:
      return {
        ...state,
        product: INITIAL_STATE.product,
        loadingProduct: false,
      };
    case StockTypes.SAVE_PRODUCT_STOCK_REQUEST:
      return { ...state, savingProduct: true, errorSavingProduct: null };
    case StockTypes.SAVE_PRODUCT_STOCK_SUCCCES:
      return {
        ...state,
        savingProduct: false,
      };
    case StockTypes.SAVE_PRODUCT_STOCK_FAILURE:
      return {
        ...state,
        savingProduct: false,
        errorSavingProduct: action.payload.error,
      };
    case StockTypes.OPTIONS_REQUEST:
      return { ...state };
    case StockTypes.OPTIONS_SUCCCES:
      return {
        ...state,
        ...action.payload,
      };
    case StockTypes.OPTIONS_FAILURE:
      return {
        ...state,
      };
    case StockTypes.RESET_PRODUCT_STOCK:
      return {
        ...state,
        product: INITIAL_STATE.product,
      };

    case StockTypes.LIST_SYSTEM_REQUEST:
      return { ...state, loading: true };
    case StockTypes.LIST_SYSTEM_SUCCCES:
      return {
        ...state,
        systems: action.payload.systems,
        loading: false,
      };
    case StockTypes.LIST_SYSTEM_FAILURE:
      return {
        ...state,
        systems: [],
        loading: false,
      };

    case StockTypes.LIST_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case StockTypes.LIST_PRODUCTS_SUCCCES:
      return {
        ...state,
        products: action.payload.products,
        loading: false,
      };
    case StockTypes.LIST_PRODUCTS_FAILURE:
      return {
        ...state,
        products: [],
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
