import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import {
  Page,
  Loading,
  TablePage,
  Modal,
  Button,
  ModalDefault,
  Avatar,
  Input,
  Return,
} from '~/components';

import { IconClose, IconDelete, IconEdit, IconSearch } from '~/assets/Icons';
import { BtEdit } from '~/components/TableActions/styles';
import UsersService from '~/services/UsersService';

import { CheckPermission } from '~/utils/checkPermission';
import {
  BtTrash,
  ContainerTable,
  ContentDelete,
  ContentModal,
  DeletDiv,
  DivList,
  Header,
  ListContent,
  Row,
  Table,
  Title,
  TitleModal,
  UserContainer,
} from './styles';

const Users: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalResponse, setIsVisibleModalResponse] = useState(false);

  const [time, setTime] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [searchList, setSearchList] = useState([]);

  const [messageDelete, setMessageDelete] = useState('');
  const [userEditItem, setUserEditItem] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const loadUsers = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    setLoading(true);
    const res = await UsersService.listUsers({
      limit,
      page,
    });

    setDataSource(res.result);
    setPage(res.info.page);
    setPages(res.info.pages);
    setLoading(false);
  };

  const history = useHistory();

  useEffect(() => {
    loadUsers({ limit, page });
  }, [limit, page]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Cargo',
      dataIndex: 'cargo',
      key: 'cargo',
      render: (e: any) => e.descricao,
    },
    {
      title: 'Ultima Alteração',
      dataIndex: 'ultimaAlteracao',
      key: 'ultimaAlteracao',
      render: (e: any) =>
        `${
          e && format(new Date(e), "dd 'de' MMMM 'às' HH:mm'", { locale: pt })
        }`,
    },
    {
      width: '10vh',
      key: 'avatar',
      render: (e: any) => (
        <UserContainer>
          <Avatar name={e!.nome} />
        </UserContainer>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      width: '10vw',
      render: (e: any) => (
        <>
          <BtTrash
            disabled={
              !CheckPermission({
                module: 'USUÁRIOS',
                permission: 'EXCLUIR',
              })
            }
            locked={
              !CheckPermission({
                module: 'USUÁRIOS',
                permission: 'EXCLUIR',
              })
            }
            onClick={() => {
              setUserEditItem(e.id);
              setIsVisibleModalDelete(true);
            }}
          >
            <IconDelete />
          </BtTrash>
          <BtEdit
            locked={
              !CheckPermission({
                module: 'USUÁRIOS',
                permission: 'VISUALIZAR',
              })
            }
            disabled={
              !CheckPermission({
                module: 'USUÁRIOS',
                permission: 'VISUALIZAR',
              })
            }
            onClick={() => {
              history.push(`/user/view/${e.id}`);
            }}
          >
            <IconEdit />
          </BtEdit>
        </>
      ),
    },
  ];

  const searchRequest = useCallback(async () => {
    const response = await UsersService.listUsers({
      nome: searchInput,
    });
    setSearchList(response.result);
  }, [searchInput]);

  let typingTimer: any = null;
  const doneTypingInterval = 1000;

  const done = () => {
    searchRequest();
  };

  const deleteRequest = async () => {
    try {
      setLoading(true);
      setMessageDelete(await UsersService.deleteUserById(userEditItem));
    } catch {
      setIsVisibleModalErro(true);
    }
    loadUsers({ limit, page });
    setLoading(false);
  };

  if (loading) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModalDelete && (
        <Modal>
          <DeletDiv>
            <TitleModal>
              <button
                type="button"
                onClick={() => setIsVisibleModalDelete(false)}
              >
                <IconClose />
              </button>
            </TitleModal>
            <b>Tem certeza que deseja excluir esse Usuário?</b>
            <ContentDelete>
              <Button styles={1} onClick={() => setIsVisibleModalDelete(false)}>
                Não
              </Button>
              <Button
                onClick={async () => {
                  setIsVisibleModalDelete(false);
                  setIsVisibleModalResponse(true);
                  deleteRequest();
                }}
              >
                Sim
              </Button>
            </ContentDelete>
          </DeletDiv>
        </Modal>
      )}
      {isVisibleModalResponse && (
        <ModalDefault
          success={messageDelete === 'Deletado com sucesso'}
          onClick={() => setIsVisibleModalResponse(false)}
        >
          <ContentModal>{messageDelete}</ContentModal>
        </ModalDefault>
      )}
      <Page>
        <Return />
        <Header>
          <Title>Usuários</Title>
          <Row>
            <Input
              icon={<IconSearch />}
              onChange={e => {
                setSearchInput(e.target.value);
                if (time) clearTimeout(time);
                if (e.target.value) {
                  typingTimer = setTimeout(done, doneTypingInterval);
                  setTime(typingTimer);
                }
              }}
            />
            {searchInput.length > 2 && (
              <DivList>
                {searchList.map((item: any) => (
                  <ListContent
                    key={item.id}
                    onClick={() => {
                      history.push(`/user/view/${item.id}`);
                    }}
                  >
                    {item.nome}
                  </ListContent>
                ))}
              </DivList>
            )}
            <span>
              <Button
                responsiveness
                // locked={
                //   !CheckPermission({
                //     module: 'USUÁRIOS',
                //     permission: 'INCLUSAO',
                //   })
                // }
                // disabled={
                //   !CheckPermission({
                //     module: 'USUÁRIOS',
                //     permission: 'INCLUSAO',
                //   })
                // }
                onClick={() => history.push('/user/create')}
              >
                + Novo Usuário
              </Button>
            </span>
          </Row>
        </Header>
        <ContainerTable>
          <Table>
            <TablePage
              columns={columns}
              dataSource={dataSource}
              pagination={{
                pageSize: limit,
                total: pages * limit,
                current: page,
                onChange: e => {
                  setPage(e);
                },
              }}
              rowKey="id"
            />
          </Table>
        </ContainerTable>
      </Page>
    </>
  );
};

export default Users;
