import { IconClose } from '~/assets/Icons';
import { Modal, Page } from '~/components';
import { IResponseGet } from '~/store/modules/budget/types';
import ListJustification from './list';
import { Container, ContainerList, HeaderContainer } from './stylesModal';

interface IProps {
  onClick: () => void;
  itens: IResponseGet;
}

interface AddPageProps {
  size: number;
  children: React.ReactNode;
}

function AddPage({ size, children }: AddPageProps) {
  if (size > 768) {
    return <>{children}</>;
  }
  return <Page>{children}</Page>;
}

const ModalJustification: React.FC<IProps> = ({ onClick, itens }: IProps) => {
  return (
    <Modal
      width={window.innerWidth > 768 ? '60vw' : '100%'}
      heigth={window.innerWidth > 768 ? '0' : '100%'}
      padding="0"
    >
      <AddPage size={window.innerWidth}>
        <Container>
          <HeaderContainer>
            <span>Justificativas de revisão</span>
            <button type="button" onClick={onClick}>
              <IconClose />
            </button>
          </HeaderContainer>
          <ContainerList>
            {itens.justificativa.map(item => {
              return (
                <ListJustification
                  key={item.id}
                  justification={item}
                  customer={itens.cliente.nome}
                />
              );
            })}
          </ContainerList>
        </Container>
      </AddPage>
    </Modal>
  );
};

export default ModalJustification;
