/* eslint-disable consistent-return */
/* eslint-disable no-console */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

class HttpClient {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_API}`,
      timeout: 30000,
      timeoutErrorMessage: 'Tempo limite da requisição excedido.',
      headers: {
        'api-token':
          process.env.NODE_ENV === 'development' &&
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjUzMiwiaWF0IjoxNjU0Mjg2OTAwMzk1fQ.5yio_Mg2L4cgHX0FQMHytHluGXJAuOO3pz4GwjHZmk0',
        // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjM1NCwiaWF0IjoxNjM5MTk4NTg3MzQyfQ.0G1I0SaVE_7bZpkOvw3JzoFl6EpZuwSGoGzYRLNngOI',
        // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjM0NywiaWF0IjoxNjQxNTk5MzM4ODk2fQ.1SM0b6p9jrSvOWld9H0rNYGUanUo-XyLMDxZQDO27X8',
      },
    });
    this.initInterceptors();
  }

  private initInterceptors() {
    this.api.interceptors.response.use(
      async (response: AxiosResponse) => {
        return response;
      },

      async (error: AxiosError) => {
        const objectError = {
          error: error?.response?.data || error,
          status: error.response?.status,
        };

        if (error && error.response && error.response.status === 401) {
          console.log('TOKEN EXPIRED, ENDING SESSION');

          console.log(objectError);
          // return Promise.reject(objectError);
        }
        if (error.response?.status === 400) return Promise.reject(objectError);
      },
    );
  }
}

export const { api } = new HttpClient();
