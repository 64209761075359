import React from 'react';
import { IconClose } from '~/assets/Icons';
import { Button, Modal } from '~/components';
import { ContainerButtonModal, TitleModal } from './styles';

interface IProps {
  text: string;
  onClickCancel: () => void;
  onClickClose: () => void;
  onClickConfirm: () => void;
}

const ModalConfirmation: React.FC<IProps> = ({
  text,
  onClickCancel,
  onClickClose,
  onClickConfirm,
}: IProps) => {
  return (
    <Modal width="30vw">
      <>
        <TitleModal>
          <b>Tem certeza que deseja Iniciar um Cronograma de Obra?</b>
          <button type="button" onClick={onClickClose}>
            <IconClose />
          </button>
        </TitleModal>
        <p>{text}</p>

        <ContainerButtonModal>
          <Button styles={1} onClick={onClickCancel}>
            Não
          </Button>
          <Button onClick={onClickConfirm}>Sim</Button>
        </ContainerButtonModal>
      </>
    </Modal>
  );
};

export default ModalConfirmation;
