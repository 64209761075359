/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';

import {
  Page,
  BudgetNone,
  TablePage,
  PageHeader,
  ModalDelete,
  Loading,
  Avatar,
  Button,
  Input,
  ModalDefault,
  Select,
} from '~/components';
import { situacaoENUM } from '~/constants/situacao';
import { IconDelete, IconEdit, IconStart } from '~/assets/Icons';
import BudgetsService from '~/services/BudgetsService';
import VisibleForPermissions from '~/components/VisibleForPermission';
import { CheckPermission } from '~/utils/checkPermission';
import { api } from '~/services/api';
import {
  BtContainer,
  BtEdit,
  BtStart,
  BtTrash,
  Content,
  Table,
  UserContainer,
  Row,
} from './styles';

const OPTIONS = [
  { id: 1, param: 'clientCpf', descricao: 'CPF' },
  { id: 2, param: 'clientCnpj', descricao: 'CNPJ' },
  { id: 3, param: 'clientName', descricao: 'Nome do Cliente' },
  { id: 4, param: 'budgetName', descricao: 'Nome do Orçamento' },
];

const Budget: React.FC = () => {
  const [budgetItem, setBudgetItem] = useState({
    id: 0,
    descricao: '',
  });
  const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);

  const [dataSource, setDataSource] = useState<any>([]);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [clientOrder, setClientOrder] = useState<1 | 2 | undefined>(undefined);
  const [updateDate, setUpdateDate] = useState<1 | 2 | undefined>(undefined);
  const [textInput, setTextInput] = useState('');
  const [searchById, setSearchById] = useState(OPTIONS[0].id);
  const [searchBy, setSearchBy] = useState(OPTIONS[0].descricao);
  const [searched, setSearched] = useState(false);

  const loadBudgets = useCallback(
    async ({ limit, page }: { limit: number; page: number }) => {
      setLoading(true);
      const res = await BudgetsService.listBudgets({
        limit,
        page,
        clientOrder,
        updateDate,
      });

      setSearched(false);

      setDataSource(res.result);
      setPage(res.info.page);
      setPages(res.info.pages);
      setLoading(false);
    },
    [clientOrder, updateDate],
  );

  const searchBudget = useCallback(
    async ({ limit, page }: { limit: number; page: number }) => {
      const option = OPTIONS.find(curr => curr.id === searchById);
      setLoading(true);
      const res = await api.get('/budget-api/budgets', {
        params: {
          limit,
          page,
          [option?.param || 'clientCpf']: textInput,
        },
      });

      setSearched(true);

      setDataSource(res.data.result);
      setPage(res.data.info.page);
      setPages(res.data.info.pages);
      setLoading(false);
    },
    [searchById, textInput],
  );

  useEffect(() => {
    if (!searched) loadBudgets({ limit, page });
    else searchBudget({ limit, page });
  }, [limit, page, clientOrder, updateDate, loadBudgets]);

  const history = useHistory();

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Assunto',
      dataIndex: 'descricao',
      key: 'descricao',
      sorter: (a: any, b: any) => a.descricao.length - b.descricao.length,
    },
    {
      title: 'Status',
      dataIndex: 'situacao',
      key: 'situacao',
      render: (e: any) => situacaoENUM(e.nome),
    },
    {
      title: 'Ultima Alteração',
      dataIndex: 'dataAtualizacao',
      key: 'dataAtualizacao',
      render: (e: any) =>
        `${
          e && format(new Date(e), "dd 'de' MMMM 'às' HH:mm'", { locale: pt })
        }`,
      sorter: (a: any, b: any, c: any) => {
        if (c === 'ascend') {
          setUpdateDate(1);
        }
        if (c === 'descend') {
          setUpdateDate(2);
        }
        return a;
      },
    },
    {
      width: '10vh',
      key: 'avatar',
      sorter: (a: any, b: any, c: any) => {
        if (c === 'ascend') {
          setClientOrder(1);
        }
        if (c === 'descend') {
          setClientOrder(2);
        }
        return a;
      },
      render: (e: any) => (
        <UserContainer>
          <Avatar name={e!.cliente?.nome} />
        </UserContainer>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      width: '12vw',
      render: (e: any) => (
        <BtContainer>
          <BtEdit
            disabled={
              !CheckPermission({
                module: 'ORÇAMENTO',
                permission: 'GERENCIAR',
              })
            }
            locked={
              !CheckPermission({
                module: 'ORÇAMENTO',
                permission: 'GERENCIAR',
              })
            }
            onClick={() => {
              // e.situacao.id !== 1
              //   ? history.push(`/budget/finish/${e.id}`)
              history.push(`/budget/edit/${e.id}`);
            }}
          >
            <IconEdit />
          </BtEdit>
          {e.canStartDiary ? (
            <BtStart
              onClick={() =>
                history.push(`/construction/create/?budget=${e.id}`)
              }
            >
              <span>
                <IconStart />
              </span>
              <b>Iniciar obra</b>
            </BtStart>
          ) : (
            <BtTrash
              disabled={
                !CheckPermission({
                  module: 'ORÇAMENTO',
                  permission: 'EXCLUIR',
                })
              }
              locked={
                !CheckPermission({
                  module: 'ORÇAMENTO',
                  permission: 'EXCLUIR',
                })
              }
              onClick={() => {
                setBudgetItem({
                  id: e.id,
                  descricao: e.descricao,
                });
                setIsVisibleModalDelete(true);
              }}
            >
              <IconDelete />
            </BtTrash>
          )}
        </BtContainer>
      ),
    },
  ];

  const createBudget = async () => {
    try {
      const budget = await BudgetsService.createBudget();
      history.push(`/budget/edit/${budget.id}`);
    } catch {
      setIsVisibleModalErro(true);
    }
  };

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          onClick={() => setIsVisibleModalErro(false)}
          success={false}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModalDelete && (
        <ModalDelete
          item={`o orçamento: ${budgetItem.descricao}`}
          onClickCancel={() => {
            setBudgetItem({
              id: 0,
              descricao: '',
            });
            setIsVisibleModalDelete(false);
          }}
          onClickDel={async () => {
            if (!budgetItem.id) return;
            try {
              await BudgetsService.deleteBudgetById(budgetItem.id);
            } catch {
              setIsVisibleModalErro(true);
            }
            setBudgetItem({
              id: 0,
              descricao: '',
            });
            setIsVisibleModalDelete(false);
            loadBudgets({ limit, page });
          }}
        />
      )}
      <Page>
        <VisibleForPermissions modules="ORÇAMENTO" permissions="VISUALIZAR">
          <PageHeader
            title="Orçamentos"
            button="+ NOVO ORÇAMENTO"
            onClick={createBudget}
            permission={{ module: 'ORÇAMENTO', permission: 'INCLUIR' }}
          />
          <Row>
            <Input
              value={textInput}
              onChange={e => setTextInput(e.target.value)}
              placeholder="Buscar"
            />
            <Select
              lista={OPTIONS}
              onClickList={item => {
                setSearchBy(item.descricao);
                setSearchById(item.id);
              }}
              currentValue={searchBy.toString()}
            />
            <Button
              responsiveness
              disabled={!textInput}
              onClick={() => {
                setPage(1);
                searchBudget({ limit: 10, page: 1 });
              }}
            >
              Buscar
            </Button>
            {searched ? (
              <Button
                responsiveness
                disabled={!searched}
                onClick={() => {
                  setTextInput('');
                  setPage(1);
                  loadBudgets({ limit: 10, page: 1 });
                }}
              >
                Limpar filtro
              </Button>
            ) : null}
          </Row>
          {isLoading && <Loading />}
          {dataSource.length === 0 ? (
            <Content>
              <BudgetNone />
            </Content>
          ) : (
            <Table>
              <TablePage
                columns={columns}
                dataSource={dataSource}
                pagination={{
                  current: page,
                  showSizeChanger: false,
                  pageSize: limit,
                  total: pages * limit,
                  onChange: e => {
                    setPage(e);
                  },
                }}
                rowKey="id"
              />
            </Table>
          )}
        </VisibleForPermissions>
      </Page>
    </>
  );
};

export default Budget;
