import { IconEdit, IconDelete, IconMoney } from '~/assets/Icons';
import { BtBuy, BtEdit, BtTrash } from './styles';

interface IProps {
  functionTrash: () => void;
  functionEdit: () => void;
  functionBuy?: () => void;
}

const TableActions: React.FC<IProps> = ({
  functionTrash,
  functionEdit,
  functionBuy,
}: IProps) => {
  return (
    <>
      <BtTrash onClick={functionTrash}>
        <IconDelete />
      </BtTrash>
      <BtEdit onClick={functionEdit}>
        <IconEdit />
      </BtEdit>
      {functionBuy && (
        <BtBuy onClick={functionBuy}>
          <IconMoney />
        </BtBuy>
      )}
    </>
  );
};

TableActions.defaultProps = {
  functionBuy: undefined,
};

export default TableActions;
