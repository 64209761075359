/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react';

import { Checkbox, TablePage, Input } from '~/components';
import { IEquipamento } from '~/store/modules/report/types';

import { Container } from './styles';

interface IProps {
  equipments: IEquipamento[];
  onClick: (id: number, value: any) => any;
}

const Equipaments: React.FC<IProps> = ({ equipments, onClick }) => {
  const [textInput, setTextInput] = useState('');
  const [equipmentsFiltered, setEquipmentsFiltered] = useState<IEquipamento[]>(
    [],
  );

  const normalize = (value: string) => {
    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  };

  const handleFilter = useCallback(() => {
    if (textInput === '') {
      setEquipmentsFiltered(equipments);
    } else {
      setEquipmentsFiltered(
        equipments.filter(equipment =>
          normalize(equipment.descricao).includes(normalize(textInput)),
        ),
      );
    }
  }, [equipments, textInput]);

  useEffect(() => {
    handleFilter();
  }, [equipments, textInput]);

  const columns = [
    {
      title: 'Equipamentos Verificados',
      dataIndex: 'equipamentoDescricao',
      key: 'equipamentoDescricao',
    },
    {
      width: '10vh',
      title: 'Verificado?',
      key: 'verificado',
      render: (data: any) => (
        <Checkbox
          checked={data.verificado}
          onChange={value => onClick(data.id, value)}
        />
      ),
    },
  ];

  return (
    <Container>
      <Input
        value={textInput}
        onChange={e => {
          setTextInput(e.target.value);
        }}
        onClick={handleFilter}
        placeholder="Pesquisar"
      />
      <TablePage dataSource={equipmentsFiltered} columns={columns} />
    </Container>
  );
};

export default Equipaments;
