import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  min-height: 100%;
  flex-direction: column;
  padding: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 0 10px;
`;

export const Card = styled.div`
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
  min-width: 200px;
  width: min-content;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;

  p {
    margin: 0;
    white-space: nowrap;
  }
`;
