export enum NotificationTypes {
  LOAD_REQUEST = '@notification/LOAD_REQUEST',
  LOAD_SUCCCES = '@notification/LOAD_SUCCCES',
  LOAD_FAILURE = '@notification/LOAD_FAILURE',
}

export interface INotification {
  id: number;
  template: {
    id: number;
    descricao: string;
    texto: string;
  };
  usuario: number;
  status: number;
  acao_id: number;
  data: string;
}

export interface ILoadState {
  readonly data: INotification[];
}

export interface INotificationsState {
  notifications: INotification[];
  unread: number;
  loading: boolean;
  pages: number;
}

export interface IPropsNotificationRequest {
  type: string;
  payload: {
    user_id: number;
    page: number;
    state: any[];
  };
}
