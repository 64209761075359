import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  min-height: 100%;
  /* background: blue; */
  flex-direction: row;
`;

// export const ContentLeft = styled.div`
//   width: 30%;
//   height: 100%;

//   background: red;
// `;

// export const ContentRight = styled.div`
//   width: 70%;
//   height: 100%;

//   background: green;
// `;
