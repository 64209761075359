import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.Colors.Gray};
  background: ${({ theme }) => theme.Colors.White};
  padding: 14px;
  border-top: 1px solid rgba(196, 196, 196, 0.5);
  font-size: 12px;

  cursor: pointer;
`;

export const Time = styled.span`
  font-size: 10px;
  align-self: end;
`;

export const Visible = styled('div')<{ status: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #30b4e5;
  align-self: end;

  ${props => props.status && `display: none;`}
`;
