/* eslint-disable no-restricted-globals */
export const changeMoney = (money: string): number => {
  let number = money.replaceAll(/[^a-z0-9]/gm, '').trim();

  if (number.length === 1) number = `0${number}`;

  number = `${number.substr(0, number.length - 2)}.${number.substr(
    number.length - 2,
  )}`;

  const numberFormat: any = parseFloat(number);

  return numberFormat;
};

export const formatDecimal = (money: string): string => {
  let number = money.replaceAll(/[^a-z0-9]/gm, '').trim();

  if (number.length === 1) number = `0${number}`;

  number = `${number.substr(0, number.length - 2)}.${number.substr(
    number.length - 2,
  )}`;

  return number.replace(/^(0+)(\d)/g, '$2');
};

export const isFormatMoney = (e: number): string => {
  let number;

  // eslint-disable-next-line no-unused-expressions
  e
    ? (number = `${e.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })}`)
    : (number = 'R$ 0,00');

  return number.replaceAll(/[R$]/gm, '').trim();
};
