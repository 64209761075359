/* eslint-disable no-param-reassign */
import store from '~/store';
import { optionsRequest } from '~/store/modules/stock/actions';
import {
  IChangeProductRequest,
  ISaveProductRequest,
  ISaveVendorRequest,
} from '~/store/modules/stock/types';
import { api } from './api';

interface IListStock {
  limit?: number;
  page?: number;
  type?: string;
  date?: string | undefined;
}

interface ISaveOption {
  type?: string;
  value: string;
}

interface IBuyStock {
  produtoId?: number;
  quantidadeDeEntrada?: number;
  valorDeEntrada?: number;
  fornecedorId?: number;
}

class StockService {
  async listStock({ limit = 200, page = 1, date }: IListStock): Promise<any> {
    const response = await api.get(`/stock-api/stock`, {
      params: {
        limit,
        page,
        data: date,
      },
    });

    return response.data;
  }

  async listSystem({ limit = 200, page = 1 }: IListStock): Promise<any> {
    const response = await api.get(`/product-api/product`, {
      params: {
        limit,
        page,
        categoria: 2,
      },
    });

    return response.data;
  }

  async deleteStock(id: number): Promise<any> {
    const response = await api.delete(`/stock-api/stock/${id}`);

    return response.data;
  }

  async deleteSystem(id: number): Promise<any> {
    const response = await api.delete(`/product-api/product/${id}`);

    return response.data;
  }

  async buyStock(stock: IBuyStock): Promise<any> {
    const response = await api.post(`/stock-api/stock`, stock);

    return response.data;
  }

  async listOptions({ limit = 200, page = 1, type }: IListStock): Promise<any> {
    if (!type) return null;

    const response = await api.get(`/product-api/${type}`, {
      params: {
        limit,
        page,
      },
    });

    return { [type]: response?.data?.result || [] };
  }

  async saveOption({ type, value }: ISaveOption): Promise<any> {
    if (!type) return null;

    const response = await api.post(`/product-api/${type}`, {
      nome: value,
    });
    store.dispatch(optionsRequest([type]));
    return response.data;
  }

  async totalStock() {
    const response = await api.get('/stock-api/total');

    return response.data;
  }

  async getStockById(id: number): Promise<any> {
    const response = await api.get(`/product-api/product/${id}`);

    return response.data;
  }

  async saveProduct(props: ISaveProductRequest): Promise<any> {
    const id = props?.id ?? '';
    const { file } = props;
    if (file) delete props.file;
    delete props?.id;
    const response = await api[id ? 'put' : 'post'](
      `/product-api/product/${id}`,
      props,
    );
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      await api.post(
        `/product-api/product/${response.data.id}/file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
    }
    return response.data;
  }

  async handleChangeProduct(props: IChangeProductRequest): Promise<any> {
    const promises = props.nextData.reduce((acum, curr) => {
      const item = props.previousData.find(data => data.id === curr.id);
      if ((curr.quantidadeDeProdutos === 0 || curr.quantidade === 0) && item) {
        acum.push(
          api.delete(`product-api/product/${curr.id}/system/${props.systemId}`),
        );
      } else if (curr.quantidadeDeProdutos !== 0 || curr.quantidade !== 0) {
        const payload: any = {
          quantidade: curr.quantidadeDeProdutos || curr.quantidade,
        };
        if (curr.numeroOrdemProduto) {
          payload.numeroOrdemProduto = curr.numeroOrdemProduto || 0;
        }

        acum.push(
          api[item ? 'put' : 'post'](
            `product-api/product/${curr.id}/system/${props.systemId}`,
            payload,
          ),
        );
      }
      return acum;
    }, []);
    return Promise.all(promises);
  }

  async saveVendor(props: ISaveVendorRequest): Promise<any> {
    const response = await api.post(`/product-api/producer`, props);
    return response.data;
  }
}

export default new StockService();
