/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import { call, put } from 'redux-saga/effects';
import ProductsService from '~/services/ProductsService';
import StockService from '~/services/StockService';

import {
  listStockSuccess,
  listStockFailure,
  totalStockSuccess,
  totalStockFailure,
  productStockSuccess,
  productStockFailure,
  saveProductStockSuccess,
  saveProductStockFailure,
  optionsFailure,
  optionsSuccess,
  listSystemSuccess,
  listSystemFailure,
  listProductsSuccess,
  listProductsFailure,
} from './actions';
import {
  IPropsStockRequest,
  IPropsSaveRequest,
  IPropsOptionsRequest,
} from './types';

export function* listStock(action: any): any {
  try {
    const dataStock = async () => {
      const limit = 200;
      const stocks = await StockService.listStock({
        limit,
        page: 1,
        date: action.payload.date,
      });
      const promises = Array.from(
        { length: stocks.info.pages - 1 },
        (v, k) => k + 2,
      ).reduce((acum: Promise<any>[], curr) => {
        acum.push(
          StockService.listStock({
            limit,
            page: curr,
            date: action.payload.date,
          }),
        );
        return acum;
      }, []);
      const response = await Promise.all(promises);

      return [
        ...stocks.result,
        ...response.reduce((acum, curr) => {
          acum = [...acum, ...curr.result];
          return acum;
        }, []),
      ];
    };

    const stocks = yield call(dataStock);
    yield put(listStockSuccess(stocks));
  } catch (err) {
    yield put(listStockFailure());
  }
}

export function* listSystem(): any {
  try {
    const dataSystem = async () => {
      const limit = 200;
      const systems = await StockService.listSystem({
        limit,
        page: 1,
      });
      const promises = Array.from(
        { length: systems.info.pages - 1 },
        (v, k) => k + 2,
      ).reduce((acum: Promise<any>[], curr) => {
        acum.push(
          StockService.listSystem({
            limit,
            page: curr,
          }),
        );
        return acum;
      }, []);
      const response = await Promise.all(promises);

      return [
        ...systems.result,
        ...response.reduce((acum, curr) => {
          acum = [...acum, ...curr.result];
          return acum;
        }, []),
      ];
    };

    const systems = yield call(dataSystem);
    yield put(listSystemSuccess(systems));
  } catch (err) {
    yield put(listSystemFailure());
  }
}

export function* listProducts(): any {
  try {
    const dataSystem = async () => {
      const limit = 200;
      const systems = await ProductsService.listProducts({
        limit,
        page: 1,
      });
      const promises = Array.from(
        { length: systems.info.pages - 1 },
        (v, k) => k + 2,
      ).reduce((acum: Promise<any>[], curr) => {
        acum.push(
          ProductsService.listProducts({
            limit,
            page: curr,
          }),
        );
        return acum;
      }, []);

      await Promise.all(promises);

      return [...systems.result];
    };

    const systems = yield call(dataSystem);
    yield put(listProductsSuccess(systems));
  } catch (err) {
    yield put(listProductsFailure());
  }
}

export function* totalStock(): any {
  try {
    const dataTotalStock = async () => {
      const totalStock = await StockService.totalStock();
      return totalStock;
    };

    const totalStock = yield call(dataTotalStock);
    yield put(totalStockSuccess(totalStock));
  } catch (err) {
    yield put(totalStockFailure());
  }
}

export function* productStock(props: IPropsStockRequest): any {
  try {
    const productStock = async () => {
      const stock = await StockService.getStockById(props?.payload?.id);
      return stock;
    };

    const stock = yield call(productStock);
    yield put(productStockSuccess(stock));
  } catch (err) {
    yield put(productStockFailure());
  }
}

export function* saveProductStock(props: IPropsSaveRequest): any {
  try {
    const productStock = async () => {
      const stock = await StockService.saveProduct(props?.payload?.body);
      return stock;
    };

    yield call(productStock);
    yield put(saveProductStockSuccess());
  } catch (err: any) {
    yield put(saveProductStockFailure(err.response.message || err.message));
  }
}

export function* getOptions(props: IPropsOptionsRequest): any {
  try {
    const types = props?.payload?.type || [];
    const productStock = async (type: string) => {
      const stock = await StockService.listOptions({
        type,
      });
      return stock;
    };
    const promises = types.map(type => productStock(type));

    const data = yield Promise.all(promises);
    const dataReduced = data.reduce((acum: any, curr: any, index: number) => {
      acum[types[index]] = curr[types[index]];
      return acum;
    }, {});
    yield put(optionsSuccess(dataReduced));
  } catch (err) {
    yield put(optionsFailure());
  }
}
