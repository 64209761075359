import { call, put } from 'redux-saga/effects';
// import { api } from '~/services/api';
import ProductsService from '~/services/ProductsService';

import { IResponse } from './types';
import { listProductSuccess, listProductFailure } from './actions';

export async function* listProduct(): any {
  try {
    const dataProduct = async () => {
      const listProduct: any[] = [];
      let page = 1;

      const response: { result: IResponse; info: any } =
        await ProductsService.listProducts({ limit: 1, page });

      const reload = async (res: any) => {
        if (res.info.page === 1) {
          listProduct.push(res.result);
        } else {
          listProduct.concat(res.result);
        }
        if (res.info.pages > page) {
          page += 1;
          dataProduct();
        } else {
          return listProduct;
        }
        return listProduct;
      };
      reload(response);
      return listProduct;
    };
    // const request: IResponse[] = await DataProduct();
    const request: IResponse = yield call(dataProduct);
    yield put(listProductSuccess(request));
  } catch (err) {
    yield put(listProductFailure());
  }
}
