/* eslint-disable react-hooks/exhaustive-deps */
import React, { FocusEvent, useCallback, useEffect, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useBudgetRequest } from '~/services/request';
import {
  Input,
  Loading,
  ModalDefault,
  Page,
  Summary,
  Return,
} from '~/components';
import { usePageContext } from '~/hooks';

import { IState } from '~/store/modules/types';
import {
  getBudgetSuccess,
  situationBudgetRequest,
} from '~/store/modules/budget/actions';
import { api } from '~/services/api';
import CustomersService from '~/services/CustomersService';
import {
  generateRequiredInputValues,
  generateDefaultInputValues,
  validateForm,
} from '~/utils/forms';
import estadosENUM from '~/constants/estados';
import isFormatCEP from '~/utils/format/isFormatCEP';
import { isEmpty } from '~/utils/validate';
import { IconSearch } from '~/assets/Icons';
import BudgetsService from '~/services/BudgetsService';
import { formatDecimal } from '~/utils/format/isFormatMoney';
import {
  Collum,
  Container,
  ContainerBox,
  Content,
  DivList,
  InputBox,
  InputZone,
  ListContent,
  Subtitle,
  Title,
} from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const Confirm: React.FC = () => {
  const { putBudget } = useBudgetRequest();
  const { id } = useParams<{ id?: string }>();
  const { fixed } = usePageContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const [responsible, setResponsible] = useState('');
  const [getCustomer, setGetCustomer] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [messageErro, setMessageErro] = useState('');
  const [labor, setLabor] = useState(0);
  const [listUser, setListUser] = useState([
    {
      id: 0,
      nome: '',
    },
  ]);
  const data = useSelector((state: IState) => state.budget.data);
  const loadingRequest = useSelector((state: IState) => state.budget.loading);
  const [loading, setLoading] = useState(false);
  const stateSchema = {
    ...generateRequiredInputValues([
      'address',
      'state',
      'city',
      'district',
      'numberAddress',
      'cep',
    ]),
    ...generateDefaultInputValues([
      'complement',
      'responsible',
      'labor',
      'notes',
      'payment',
    ]),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchema);

  const updateCep = useCallback((response: any) => {
    dispatch(
      getBudgetSuccess({
        ...data,
        endereco: {
          ...data.endereco,
          cep: response.cep,
          logradouro: response.logradouro,
          numero: 0,
          bairro: response.bairro,
          cidade: response.localidade,
          estado: estadosENUM(response.uf),
        },
      }),
    );
    setInputState((prevState: any) => ({
      ...prevState,
      district: {
        value: response.bairro,
        isValid: inputState.district.isValid,
        required: inputState.district.required,
        error: inputState.district.error,
      },
      address: {
        value: response.logradouro,
        isValid: inputState.address.isValid,
        required: inputState.address.required,
        error: inputState.address.error,
      },
      city: {
        value: response.localidade,
        isValid: inputState.city.isValid,
        required: inputState.city.required,
        error: inputState.city.error,
      },
      state: {
        value: estadosENUM(response.uf),
        isValid: inputState.state.isValid,
        required: inputState.state.required,
        error: inputState.state.error,
      },
    }));
  }, []);

  const requestCep = useCallback(
    async cep => {
      try {
        const response = await CustomersService.getCep(cep);
        updateCep(response);
        await BudgetsService.updateBudgetById(parseFloat(id!), {
          cep: response.cep,
          logradouro: response.logradouro,
          numero: response.numero,
          bairro: response.bairro,
          cidade: response.localidade,
          estado: estadosENUM(response.uf),
          complemento: response.complemento,
        });
        return { isValid: true, error: '' };
      } catch (e) {
        return { isValid: true, error: '' };
      }
    },
    [updateCep],
  );

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'cep' && value.replace(/[^\d]+/g, '').length < 8) {
      error = 'Campo insuficiente, digite 8 caracteres ';
      isValid = false;
    }
    if (inputName === 'cep' && value.replace(/[^\d]+/g, '').length === 8) {
      const cep = value.replace(/[^\d]+/g, '');
      const response = await requestCep(cep);
      error = response.error;
      isValid = response.isValid;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  useEffect(() => {
    const pullData = async () => {
      try {
        const requestUser = await api.get(`/user-api/user`, {
          params: { limit: 200, offset: 0 },
        });
        setListUser(requestUser.data.result);
        const requestCustomer = await CustomersService.getCustomerById(
          data.cliente.id,
        );
        setGetCustomer(requestCustomer.status);
      } catch {
        setMessageErro('Ops, algo deu errado...');
        setIsVisibleModalErro(true);
      }
    };
    pullData();
  }, []);

  useEffect(() => {
    setInputState((prevState: any) => ({
      ...prevState,
      responsible: {
        ...prevState.responsible,
        value: data.responsavel.nome,
      },
      labor: {
        ...prevState.labor,
        value: data.maoDeObra.toString(),
      },
      notes: {
        ...prevState.notes,
        value: data.observacao,
      },
      payment: {
        ...prevState.payment,
        value: data.pagamento,
      },
      address: {
        ...prevState.address,
        value: data.endereco.logradouro,
      },
      complement: {
        ...prevState.complement,
        value: data.endereco.complemento,
      },
      state: {
        ...prevState.state,
        value: data.endereco.estado,
      },
      city: {
        ...prevState.city,
        value: data.endereco.cidade,
      },
      district: {
        ...prevState.district,
        value: data.endereco.bairro,
      },
      numberAddress: {
        ...prevState.numberAddress,
        value: data.endereco.numero,
      },
      cep: {
        ...prevState.cep,
        value: data.endereco.cep,
      },
    }));
    setResponsible(data.responsavel.nome);
  }, []);

  let valorTotal = 0;

  data.itens.forEach((item: any) => {
    valorTotal += item.precoNovo * item.quantidade;
  });

  valorTotal *= 1 + (data.maoDeObra || 0) / 100;

  const valorFinal = data.desconto
    ? valorTotal * (1 - data.desconto / 100)
    : valorTotal;

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  if (loadingRequest || loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          {messageErro}
        </ModalDefault>
      )}
      <Page>
        <Return />
        <Container>
          <Collum>
            <Title>Orçamento {data.cliente.nome}</Title>
            <Content>
              <Input
                label="Vincular ao responsável pela proposta:"
                id="ResponsavelProposta"
                icon={<IconSearch />}
                value={responsible}
                onChange={e => setResponsible(e.target.value)}
              />
              {responsible?.length > 2 &&
                inputState.responsible.value !== responsible && (
                  <DivList>
                    {listUser
                      .filter(
                        (input: any) =>
                          input.nome
                            .toLowerCase()
                            .indexOf(responsible.toLowerCase()) > -1,
                      )
                      .map(user => (
                        <ListContent
                          onClick={() => {
                            putBudget({
                              idBudget: data.id,
                              idResponsible: user.id,
                            });
                            setResponsible(user.nome);

                            setInputState((prevState: any) => ({
                              ...prevState,
                              responsible: {
                                ...prevState.responsible,
                                value: user.nome,
                              },
                            }));
                          }}
                        >
                          {user.nome}
                        </ListContent>
                      ))}
                  </DivList>
                )}
              <Input
                label="% pela mão de obra:"
                id="MaoDeObra"
                value={inputState.labor.value}
                onChange={(e: FocusEvent<HTMLInputElement>) => {
                  handleInput(
                    formatDecimal(e.target.value.replace(/[^\d]+/g, '')),
                    'labor',
                  );
                  setLabor(parseFloat(e.target.value));
                }}
                onBlur={(e: any) => {
                  try {
                    putBudget({
                      idBudget: id,
                      labor: parseFloat(e.target.value),
                    });
                  } catch {
                    setMessageErro('Ops, algo deu errado...');
                    setIsVisibleModalErro(true);
                  }
                }}
              />
              <p>Notas sobre a proposta: </p>
              <InputZone
                value={inputState.notes.value}
                onChange={(e: FocusEvent<HTMLTextAreaElement>) => {
                  handleInput(e.target.value, 'notes');
                }}
                onBlur={(e: any) =>
                  putBudget({ idBudget: id, budgetNote: e.target.value })
                }
              />
              <p>Forma de pagamento: </p>
              <InputZone
                value={inputState.payment.value}
                onChange={(e: FocusEvent<HTMLTextAreaElement>) => {
                  handleInput(e.target.value, 'payment');
                }}
                onBlur={(e: any) =>
                  putBudget({ idBudget: id, payment: e.target.value })
                }
              />
              <Subtitle>Endereço da Obra</Subtitle>
              <ContainerBox>
                <InputBox>
                  <Input
                    label="CEP"
                    requiredLabel
                    maxLenght={9}
                    value={isFormatCEP(inputState.cep.value)}
                    error={inputState.cep.error}
                    onChange={e => {
                      handleInput(e.target.value, 'cep');
                    }}
                  />
                  <Input
                    label="Número"
                    requiredLabel
                    value={inputState.numberAddress.value}
                    error={inputState.numberAddress.error}
                    onChange={e =>
                      handleInput(
                        e.target.value.replace(/[^\d]+/g, ''),
                        'numberAddress',
                      )
                    }
                    onBlur={e => handleInput(e.target.value, 'numberAddress')}
                  />
                </InputBox>
                <InputBox>
                  <Input
                    label="Logradouro"
                    requiredLabel
                    value={inputState.address.value}
                    error={inputState.address.error}
                    onChange={e => handleInput(e.target.value, 'address')}
                    onBlur={e => handleInput(e.target.value, 'address')}
                  />
                  <Input
                    label="Complemento"
                    value={inputState.complement.value}
                    error={inputState.complement.error}
                    onChange={e => handleInput(e.target.value, 'complement')}
                    onBlur={e => handleInput(e.target.value, 'complement')}
                  />
                </InputBox>
              </ContainerBox>
              <ContainerBox>
                <InputBox>
                  <Input
                    label="Bairro"
                    requiredLabel
                    value={inputState.district.value}
                    error={inputState.district.error}
                    onChange={e => handleInput(e.target.value, 'district')}
                    onBlur={e => handleInput(e.target.value, 'district')}
                  />
                  <Input
                    label="Estado"
                    requiredLabel
                    value={inputState.state.value}
                    error={inputState.state.error}
                    onChange={e => handleInput(e.target.value, 'state')}
                    onBlur={e => handleInput(e.target.value, 'state')}
                  />
                </InputBox>
                <InputBox>
                  <Input
                    label="Cidade"
                    requiredLabel
                    value={inputState.city.value}
                    error={inputState.city.error}
                    onChange={e => handleInput(e.target.value, 'city')}
                    onBlur={e => handleInput(e.target.value, 'city')}
                  />
                </InputBox>
              </ContainerBox>
            </Content>
          </Collum>
          <Summary
            ButtonLabel="Finalizar Proposta"
            ButtonOnClick={async () => {
              Object.entries(inputState).forEach(allInput => {
                handleInput(allInput[1].value, allInput[0]);
              });
              if (getCustomer) {
                if (isFormValid()) {
                  setLoading(true);
                  await dispatch(
                    situationBudgetRequest(data, 'finish', valorFinal, {
                      cep: inputState.cep.value,
                      logradouro: inputState.address.value,
                      numero: inputState.numberAddress.value,
                      bairro: inputState.district.value,
                      cidade: inputState.city.value,
                      estado: inputState.state.value,
                      complemento: inputState.complement.value,
                    }),
                  );
                  setTimeout(() => {
                    history.replace(`/budget/finish/${id}`);
                    setLoading(false);
                  }, 2000);
                }
              } else {
                setMessageErro(
                  'Cadastro cliente não está completo, favor finalizar o cadastro do cliente',
                );
                setIsVisibleModalErro(true);
              }
            }}
            Id={id}
            Fix={fixed}
            currency={data.moeda.codigo}
            quotation={data.moeda.cotacao}
            Itens={data.itens}
            data={data}
            labor={labor}
          />
        </Container>
      </Page>
    </>
  );
};

export default Confirm;
