import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;

  flex-direction: column;

  padding: 5px;
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
`;

export const ContentList = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

export const ContentColor = styled('div')<{ color: string }>`
  background-color: ${props => props.color};
  width: 20px;
  height: 20px;
  border-radius: 50px;
  margin-right: 10px;
`;
