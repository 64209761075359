import styled from 'styled-components';

export const Content = styled.div`
width: 98%;
margin: 1%;
box-shadow: 0px 1px 4px 0px #00000047;


background: #fff;
p {
  margin 0;
}
svg {
  height: 30px;
  margin-right: 10px;
}
div{
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`;

export const Alert = styled('div')<{ state: boolean }>`
background-color: rgba(255,153,0,0.25);

padding: 0 10px !important;

display: flex;
align-items: center;
justify-content: space-between;

${props => !props.state && `display: none !important;`}

div {
  display: flex;
  flex-direction: column;

  button {
    max-height: 35px;
    margin 5px;
  }
}
`;

export const Container = styled.div`
  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative;
`;

export const LoadingCircle = styled.i`
  height: 30px;
  width: 30px;

  border: 2px solid #cccccc;
  border-top-color: #3cb4e5;
  border-radius: 50%;

  animation: linear rotation 2s infinite;

  @keyframes rotation {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Img = styled.span`
  position: absolute;
  left: calc(50% - 2vh);
  top: calc(50% - 2vh);

  svg {
    width: 4vh;
    height: auto;
  }
`;
