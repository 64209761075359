import React, { useState } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';

import { BodyList, ContentList, HeaderList } from './stylesModal';

interface IProps {
  justification: any;
  customer: string;
}

const ListJustification: React.FC<IProps> = ({
  justification,
  customer,
}: IProps) => {
  const [content, setContent] = useState(false);
  return (
    <ContentList>
      <HeaderList onClick={() => setContent(!content)}>
        <p>{customer}</p>
        <p>
          {format(
            new Date(justification.dataCriacao),
            "dd'/'MM'/'y 'às' HH:mm'",
            {
              locale: pt,
            },
          )}
        </p>
      </HeaderList>
      <BodyList state={content}>
        <p>{justification.descricao}</p>
      </BodyList>
    </ContentList>
  );
};

export default ListJustification;
