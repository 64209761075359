import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  width: 100%;

  & + & {
    margin-top: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 3px;
  background: #ffffff;

  width: 100%;

  & + & {
    margin-top: 10px;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-left: auto;
  min-width: fit-content;
  height: clamp(100px, 10vw + 0.1rem, 115px);

  p {
    margin-bottom: 5px;
  }
`;

export const BoxContentTitle = styled('div')`
  display: flex;
`;

export const SpanInput = styled('span')<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;

  input {
    margin-left: 16px;
    padding: 5px;
    height: 25px;
    width: 50%;
    text-align: center;

    ${props => props.disabled && 'border: 1px solid #C4C4C4;'}
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;

    input {
      margin: 0;
    }
  }
`;

export const BoxInputNewValue = styled('span')<{ disabled?: boolean }>`
  display: flex;
  white-space: nowrap;

  div {
    input {
      margin-left: 8px;
      padding: 5px;
      height: 25px;
      text-align: center;

      ${props => props.disabled && 'border: 1px solid #C4C4C4;'}
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;

    div {
      input {
        margin: 0;
      }
    }
  }
`;

export const SpanTotal = styled('span')<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;

  margin-top: 20px;
  p {
    color: #30b4e5;
    ${props => props.disabled && 'color: #C4C4C4;'}
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Aling = styled.div`
  display: flex;
  align-items: center;
  padding: 2%;
  img {
    margin-right: 3%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    @media (max-width: 768px) {
      width: 15vw;
      height: 15vw;
    }
  }
`;

export const InputNumber = styled('div')<{
  disabled?: boolean;
}>`
  margin-left: 10px;
  display: flex;
  border: 1px solid #30b4e5;
  border-radius: 3px;
  width: 100px;
  ${props => props.disabled && 'border: 1px solid #c4c4c4;'}
  input {
    margin: 0;
    border: 0;
    padding: 5px;
    height: 25px;
    width: 40px;
    color: #000;
  }
  button {
    align-items: center;
    margin: 0;
    background: #30b4e5;
    color: #fff;
    height: 25px;
    width: 30px;

    ${props => props.disabled && 'background: #C4C4C4;'}
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const BtContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BtTrash = styled('a')<{ disabled?: boolean }>`
  margin: 5px 20px 5px 0px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
  }
`;

export const BtSearch = styled('a')<{ disabled?: boolean; display: boolean }>`
  margin: 5px 20px 5px 0px;
  ${props => !props.display && 'display: none;'}
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue};
    ${props => props.disabled && 'color: #c4c4c4;'}
  }
`;

export const ContainerDropDown = styled.div`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 0px 0px 3px 3px;
  padding: 10px;
  margin-bottom: 15px;
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
  vertical-align: middle;
`;

export const Tr = styled.tr``;

export const Td = styled.td<{ disabled?: boolean }>`
  padding: 5px 0;
  input {
    text-align: center;
  }
  button + input {
    margin: 0;
    border: 0;
    padding: 5px;
    height: 25px;
    width: 40px;
    color: #000;
    border: 1px solid #30b4e5;
    ${props => props.disabled && 'border-color: #C4C4C4;'}
    border-left: none;
    border-right: none;
  }
  button {
    align-items: center;
    margin: 0;
    background: #30b4e5;
    color: #fff;
    height: 25px;
    width: 30px;

    ${props => props.disabled && 'background: #C4C4C4;'}
  }

  img {
    object-fit: cover;
    width: 75px;
    height: 75px;
    border-radius: 8px;
  }
  div {
    white-space: nowrap;
  }
`;

export const Button = styled.button<{ disabled?: boolean }>`
  ${props => props.disabled && 'background: #C4C4C4 !important;'}
`;

export const Th = styled.th``;

export const BtMove = styled('button')<{ rotate: string; display: boolean }>`
  margin: 5px 20px 5px 10px;
  ${props => props.display && 'display: none;'}
  svg {
    width: 10px;
    height: auto;
    transform: ${props => props.rotate && `rotate(${props.rotate}deg)`};
  }
`;

export const ContentCheckBox = styled.div`
  display: flex;
  width: min-content !important;
  align-items: center;
  p {
    margin: 0 0 0 10px;
    white-space: nowrap;
  }
`;
