/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadRequest } from '~/store/modules/notification/actions';
import { IState } from '~/store/modules/types';

export interface IPageContext {
  collapseMenu: boolean;
  setCollapseMenu: React.Dispatch<React.SetStateAction<boolean>>;
  collapseNotification: boolean;
  setCollapseNotification: React.Dispatch<React.SetStateAction<boolean>>;
  fixed: boolean;
  setFixed: React.Dispatch<React.SetStateAction<boolean>>;
  focus: boolean;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const PageContext = createContext<IPageContext>({} as IPageContext);

const PageProvider: React.FC = ({ children }: any) => {
  const dispatch = useDispatch();
  const [collapseMenu, setCollapseMenu] = useState(false);
  const [collapseNotification, setCollapseNotification] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [focus, setFocus] = useState(false);
  const [page, setPage] = useState(1);

  const isLogged = useSelector((state: IState) => state.auth.isLogged);
  const id = useSelector((state: IState) => state.auth.data.account.id);
  const notifications = useSelector(
    (state: IState) => state.notification.notifications,
  );

  const [intervalo, setIntervalo] = useState(0);

  useEffect(() => {
    if (isLogged && id) {
      dispatch(loadRequest(id, page, notifications));
      const int = setInterval(() => {
        dispatch(loadRequest(id, page, notifications));
      }, 60000);
      setIntervalo(parseInt(`${int}`, 10));
    }

    if (!isLogged) {
      clearInterval(intervalo);
    }
  }, [isLogged, id]);

  return (
    <PageContext.Provider
      value={{
        collapseMenu,
        setCollapseMenu,
        collapseNotification,
        setCollapseNotification,
        fixed,
        setFixed,
        focus,
        setFocus,
        page,
        setPage,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
export { PageContext };
