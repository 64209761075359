import styled from 'styled-components';

export const Container = styled.div`
  button {
    width: 100% !important;
  }
`;

export const MenuCustomer = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 3px;
  text-align: center;
  margin-top: 5px;
  width: 100% !important;

  hr {
    border-top: 1px solid #c4c4c4 !important;
    border: transparent;
  }

  button {
    width: 100%;
    height: 40px;
    font-size: 12px;
  }
`;
