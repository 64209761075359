/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import React, { FocusEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Page, Return, Input, Button, Loading, Modal } from '~/components';
import { optionsRequest, reportRequest } from '~/store/modules/report/actions';
import { IState } from '~/store/modules/types';
import {
  IAtividade,
  IEquipamento,
  IRequestSaveReport,
} from '~/store/modules/report/types';
import { generateDefaultInputValues } from '~/utils/forms';
import ReportService from '~/services/ReportService';

import StockService from '~/services/StockService';
import ProductsService from '~/services/ProductsService';
import Activites from './components/Activites';
import Equipaments from './components/Equipaments';
import {
  Container,
  Panel,
  Row,
  ButtonStyle,
  Header,
  ContentModal,
} from './styles';

type typeInput = {
  [key: string]: IPropsInput;
};

interface AnyObject {
  [key: string]: any;
}

interface IPropsInput {
  isValid: boolean;
  value: string | AnyObject;
  required: boolean;
  error: string;
}

interface IModal extends ITypeModal {
  active: boolean;
}

interface ITypeModal {
  type?: 'success' | 'error';
}

const DICT: any = {
  adversidade: 'adversidade',
  ph: 'ph',
  redoux: 'redoux',
  cloro: 'cloro',
  observacoes: 'observacoes',
};

const MaintenanceReportExec: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [id] = useState(history.location.state?.id);
  const [modal, setModal] = useState<IModal>({ active: false });
  const [isSaving, setSaving] = useState(false);
  const [activites, setActivites] = useState<IAtividade[]>([]);
  const [equipment, setEquipment] = useState<IEquipamento[]>([]);
  const [inputState, setInputState] = useState<typeInput>({
    ...generateDefaultInputValues([
      'adversidade',
      'ph',
      'redoux',
      'cloro',
      'observacoes',
    ]),
  });

  const { reportOnly, loading, error } = useSelector(
    (state: IState) => state.report,
  );

  useEffect(() => {
    handleData();
  }, [id]);

  useEffect(() => {
    handleReport();
  }, [reportOnly]);

  const handleData = () => {
    if (id) {
      dispatch(reportRequest(id));
      dispatch(optionsRequest(['condition', 'period']));
    }
  };

  const getStock = async () => {
    const limit = 200;
    const response = await StockService.listStock({
      limit,
      page: 1,
    });
    const promises = Array.from(
      { length: response.info.pages - 1 },
      (v, k) => k + 2,
    ).reduce((acum: Promise<any>[], curr) => {
      acum.push(
        StockService.listStock({
          limit,
          page: curr,
        }),
      );
      return acum;
    }, []);
    const responsePromise = await Promise.all(promises);

    return [
      ...response.result,
      ...responsePromise.reduce((acum, curr) => {
        acum = [...acum, ...curr.result];
        return acum;
      }, []),
    ];
  };

  const getProducts = async () => {
    const limit = 200;
    const response = await ProductsService.listProducts({
      limit,
      page: 1,
    });
    const promises = Array.from(
      { length: response.info.pages - 1 },
      (v, k) => k + 2,
    ).reduce((acum: Promise<any>[], curr) => {
      acum.push(
        ProductsService.listProducts({
          limit,
          page: curr,
        }),
      );
      return acum;
    }, []);
    const responsePromise = await Promise.all(promises);

    return [
      ...response.result,
      ...responsePromise.reduce((acum, curr) => {
        acum = [...acum, ...curr.result];
        return acum;
      }, []),
    ];
  };

  const handleEquipments = async (data: IEquipamento[]) => {
    const [products, stocks] = await Promise.all([getProducts(), getStock()]);
    const equipments = products.reduce((acum, curr) => {
      const product = stocks.find(stock => stock.produto.produtoId === curr.id);
      if (product) {
        acum.push(curr);
      }
      return acum;
    }, []);
    const equipmentsReduced = equipments.reduce(
      (acum: IEquipamento[], curr: IEquipamento) => {
        const product = data.find(p => p.equipamentoId === curr.id);
        acum.push({
          id: curr.id,
          descricao: curr.descricao,
          equipamentoDescricao: curr.descricao,
          verificado: product?.verificado || 0,
        });
        return acum;
      },
      [],
    );
    setEquipment(equipmentsReduced);
  };

  const handleReport = async () => {
    if (reportOnly) {
      setActivites((reportOnly.atividades as any).data as IAtividade[]);
      await handleEquipments(
        (reportOnly.equipamentos as any).data as IEquipamento[],
      );
      const keys = Object.keys(reportOnly);
      const data = keys.reduce((acum: typeInput, curr) => {
        const valueDict = DICT[curr];

        if (valueDict)
          if (typeof valueDict === 'object') {
            const data = Object.keys(valueDict).reduce(
              (acum: typeInput, dict) => {
                acum[valueDict[dict]] = {
                  ...inputState[valueDict[dict]],
                  value: (reportOnly as any)[curr][dict],
                };
                return acum;
              },
              {},
            );
            acum = { ...acum, ...data };
          } else {
            acum[valueDict] = {
              ...inputState[valueDict],
              value: (reportOnly as any)[curr],
            };
          }

        return acum;
      }, {});

      setInputState(previousState => ({
        ...previousState,
        ...data,
      }));
    }
  };

  const getValue = (name: string, param = 'nome') => {
    const { value } = inputState[name];
    if (!value) return '';
    return typeof value !== 'object' ? value : value[param];
  };

  const handleActivity = (
    name: 'period' | 'condition',
    id: number,
    value: any,
  ) => {
    const data = [...activites];
    const activity: any = data.find(curr => curr.id === id);
    if (activity) {
      const PROP = {
        period: 'periodo',
        condition: 'condicao',
      };
      activity[`${PROP[name]}Id`] = value.id;
      activity[`${PROP[name]}Descricao`] = value.descricao;
    }
    setActivites(data);
  };

  const handleInputChange = (value: any, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && !value) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleInputBlur = async (value: any, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && !value) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleEquipment = (id: number, value: FocusEvent<HTMLInputElement>) => {
    const data = [...equipment];
    const equipmentToChange = data.find(curr => curr.id === id);
    if (equipmentToChange) {
      equipmentToChange.verificado = value.target.checked ? 1 : 0;
    }
    setEquipment(data);
  };

  const saveReport = async () => {
    setSaving(true);
    try {
      const payload = Object.keys(inputState).reduce(
        (acum: AnyObject, curr) => {
          acum[curr] = inputState[curr].value || ' ';
          return acum;
        },
        {},
      );
      payload.equipamentos = equipment
        .filter(curr => curr.verificado)
        .map(curr => {
          return { id: curr.id, verificado: curr.verificado };
        });
      payload.atividades = activites.map(curr => {
        return {
          atividadeId: curr.randomId ? curr.atividadeId : curr.id,
          condicaoId: parseInt(curr.condicaoId, 10),
          periodoId: parseInt(curr.periodoId, 10),
        };
      });
      await ReportService.saveReport({
        ...(id ? { id } : {}),
        ...payload,
      } as IRequestSaveReport);
      handleClickModal({ type: 'success' });
    } catch (error) {
      handleClickModal({ type: 'error' });
    }
    setSaving(false);
  };

  const handleClickModal = ({ type }: ITypeModal) => {
    setModal({ active: true, type });
  };

  const closeModal = () => {
    setModal({ active: false });
  };

  const renderModal = useMemo(() => {
    if (!modal.active || !modal.type) return;

    const MODAL_TITLE = {
      success: `Sucesso ${id ? 'editar' : 'criar'} relatório.`,
      error: `Erro ${id ? 'editar' : 'criar'} relatório.`,
    };

    // eslint-disable-next-line consistent-return
    return (
      <Modal>
        <ContentModal>
          <h3>{MODAL_TITLE[modal.type]}</h3>
          <ButtonStyle>
            <Button
              onClick={() => {
                if (modal.type === 'success') {
                  history.push('/maintenance-report');
                } else {
                  closeModal();
                }
              }}
            >
              Entendi,{' '}
              {modal.type === 'success'
                ? 'voltar para relatórios'
                : 'tentar novamente'}
            </Button>
          </ButtonStyle>
        </ContentModal>
      </Modal>
    );
  }, [modal]);

  return (
    <Page>
      {renderModal}
      {loading || isSaving ? (
        <Loading />
      ) : (
        <>
          <Return />
          {error ? (
            <Container>
              <Header>
                <p>Tente novamente mais tarde.</p>
              </Header>
            </Container>
          ) : (
            <Container>
              <Header>
                <div>
                  <p>
                    Relatório de manutenção para
                    <br /> serviços executados
                  </p>
                  <p>Cliente: {reportOnly?.cliente?.nome}</p>
                  <p>{reportOnly?.obra?.descricao}</p>
                </div>
                <Row>
                  <ButtonStyle>
                    <Button onClick={() => history.goBack()}>Cancelar</Button>
                  </ButtonStyle>
                  <Button onClick={saveReport}>Concluir</Button>
                </Row>
              </Header>
              <Panel>
                <Activites activities={activites} onClick={handleActivity} />
                <Equipaments equipments={equipment} onClick={handleEquipment} />
              </Panel>
              <Row>
                <Input
                  onChange={e => handleInputChange(e.target.value, 'ph')}
                  onBlur={e => handleInputBlur(e.target.value, 'ph')}
                  value={getValue('ph')}
                  label="Valores de pH"
                />
                <Input
                  onChange={e => handleInputChange(e.target.value, 'redoux')}
                  onBlur={e => handleInputBlur(e.target.value, 'redoux')}
                  value={getValue('redoux')}
                  label="Redox (mV / mg/L de cloro)"
                />
                <Input
                  onChange={e => handleInputChange(e.target.value, 'cloro')}
                  onBlur={e => handleInputBlur(e.target.value, 'cloro')}
                  value={getValue('cloro')}
                  label="Consumo de Cloro"
                />
                <Input
                  onChange={e =>
                    handleInputChange(e.target.value, 'adversidade')
                  }
                  onBlur={e => handleInputBlur(e.target.value, 'adversidade')}
                  value={getValue('adversidade')}
                  label="Program. de Retro"
                />
              </Row>

              <Input
                onChange={e => handleInputChange(e.target.value, 'observacoes')}
                onBlur={e => handleInputBlur(e.target.value, 'observacoes')}
                value={getValue('observacoes')}
                label="Observações"
              />
            </Container>
          )}
        </>
      )}
    </Page>
  );
};

export default MaintenanceReportExec;
