import styled from 'styled-components';

export const Container = styled('div')<{
  disabled?: boolean;
  aling?: boolean;
  width?: string;
}>`
  margin: 1% 3%;

  ${props => props.disabled && 'display: none;'}
  ${props =>
    props.aling && !props.disabled && 'display: flex; justify-content: end;'}
  ${props => !!props.width && `button{ width: ${props.width};}`}
`;
export const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 3px;
  margin: 3%;
  padding: 3%;

  textArea {
    border-bottom: none;
    border-radius: 5px 5px 0 0;
  }
  section {
    div {
      margin: 0px 5px 0 10px;
      span {
        width: 32px;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

export const HeaderTimeline = styled('div')<{ active?: boolean }>`
  padding: 16px;
  margin: 16px 0px;
  background: #a9abac;
  color: #54565a;

  display: flex;
  justify-content: space-between;

  svg {
    transform: rotate(-90deg);
    color: #000000;
    ${props => props.active && `transform: rotate(90deg);`}
  }
`;

export const InputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  input {
    width: 65%;
  }
  span {
    width: 25%;
    input {
      width: 100%;
    }
  }
`;

export const FooterTextArea = styled('div')<{ disabled: boolean }>`
  background: rgba(231, 231, 231, 0.5);
  border-radius: 0px 0px 5px 5px;
  color: rgba(84, 86, 90, 0.3);
  border: 1px solid #30b4e5;
  border-top: none;
  padding: 6px 0;

  display: flex;
  justify-content: space-between;

  button {
    display: flex;
    align-items: center;
  }
  label {
    cursor: pointer;
    display: flex;
    align-items: center;

    input {
      display: none;
    }
  }

  span {
    background: #30b4e5;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(11px, 1vw + 0.1rem, 16px);
    margin: 0 10px;
  }

  ${props =>
    props.disabled &&
    `cursor: not-allowed;
   label {
    cursor: not-allowed;}
    button {
    cursor:  not-allowed;
    }
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  margin: 30px 0px -3% 4%;
`;

export const ButtonHeaderContainer = styled('button')<{ active: boolean }>`
  box-shadow: 0px -3px 3px rgba(0, 0, 0, 0.08);
  background: #54565a;
  color: #fff;
  border-radius: 3px 3px 0px 0px;
  padding: 8px 10px;
  margin: 0 10px;

  ${props =>
    props.active &&
    `
  background: #ffffff;
  color: #54565A;

  `}
`;

export const Card = styled.div`
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 8px;
  padding: 30px 20px;
  margin-bottom: 20px;
`;

export const ContentWorkManagement = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-size: clamp(11px, 1vw + 0.1rem, 16px);
    width: 80%;
  }
`;

export const ContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  span {
    margin-top: 10px;
    max-width: 80%;
    text-align: center;
  }

  svg {
    path {
      fill: #30b4e5 !important;
    }
  }
`;

export const ContentTechnicalProject = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const BoxContainer = styled('div')<{ width?: boolean }>`
  width: 32%;
  border: 1px solid #30b4e5;
  border-radius: 5px;
  margin: 15px 0;
  max-height: 260px;
  overflow: scroll;

  div {
    display: flex;
    flex-direction: column;
    padding: 6px;

    span {
      font-size: clamp(11px, 1vw + 0.1rem, 14px);
      font-weight: 500;
    }
  }

  ${props => props.width && `width: 65% !important;`}
`;

export const HeaderBox = styled.div`
  background: #f1fafd;
  border-bottom: 1px solid #30b4e5;
  border-radius: 5px;
`;

export const ContainerBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  /* align-items: center; */

  div {
    width: 32%;
    div {
      width: 100%;
    }
  }
`;

export const ListFile = styled('div')<{
  action?: boolean;
  show?: boolean;
  itemFromSystem?: boolean;
}>`
  padding: 12px 8px;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ itemFromSystem }) =>
    itemFromSystem ? `background-color: #E5E5E5;` : ''}

  p {
    color: #c4c4c4;
    font-size: 8px;
  }

  span {
    border-radius: 3px;
    padding: 2px 10px;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2.5px 0;
    ${props =>
      props.action &&
      `color: #fff;
      padding: 2px;
      border-radius: 5px;
      background: #30b4e5;
    `}
    ${props =>
      !props.action &&
      `color: #fff;
       padding: 2px;
       border-radius: 5px;
       background: #F0D443;
    `}
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  ${props => props.show && `display: none !important;`}
`;

export const ContainerTexto = styled.div`
  width: 60% !important;
`;

export const ContainerButton = styled.div`
  width: 40% !important;
  display: flex;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row !important;
  width: max-content !important;
  margin-right: 10px;
  column-gap: 10px;
`;

export const AprroveButton = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: #30b4e5;
  height: 30%;
  margin: 2.5px 0;
`;

export const ReproveButton = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  justify-content: center;
  background: #df0a24;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
  height: 30%;
  margin: 2.5px 0;
`;

export const CheckContent = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;

  p {
    margin: 0;
  }

  div {
    width: fit-content !important;
  }
`;

export const UploadContainer = styled('div')<{
  disabled: boolean;
  show?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px) !important;
  margin: 10px;
  border: 1px dashed rgba(48, 180, 229, 0.46);
  border-radius: 5px;
  cursor: pointer;

  p {
    text-align: center;
    color: #32323278;
  }

  ${props => props.show && `display: none !important;`}

  ${props =>
    props.disabled &&
    ` cursor: not-allowed;
      border: 1px dashed #cccccc;`}
`;

export const ApprovalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  /* padding: 20px 30px 20px 20px !important; */
  /* b {
    margin: 15px 0;
  }
  button {
    margin-top: 15px;
  } */
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  display: block;
`;

// ......................................Modal Justification..............................................

export const TitleModal = styled.div`
  b {
    margin-top: 15px;
    font-size: 20px;
    color: #323232;
  }
  button {
    margin-left: 10px;
    svg {
      color: #30b4e5;
    }
  }
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 15px;
`;

export const ContainerModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  div {
    max-width: 30%;
    display: flex;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }
  }
`;

export const ContainerButtonModal = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 30px;
  button {
    margin: 0 15px;
    max-width: 40%;
  }
`;

export const DeletDiv = styled.div`
  display: flex;
  flex-direction: column;

  color: #df0a24;
`;

export const ContentModal = styled.div`
  margin-top: 3%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  button {
    width: 40%;
  }
`;

export const ListTag = styled.div`
  background: rgba(231, 231, 231, 0.3);
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    justify-content: start;
    padding: 10px 5px;
    border-bottom: 1px solid #c4c4c4;
  }
`;

export const ScrollDiv = styled.div`
  overflow: scroll;
  max-height: 300px;
`;

export const AlertSchedules = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
    path {
      fill: #30b4e5;
    }
  }
  p {
    margin-bottom: 0px;
  }
`;

export const ContentList = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.28);
  border-radius: 3px;
  margin: 2% 0%;
  padding: 2%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 25%;
    height: 30px;
  }
  p {
    margin: 0;
  }
`;

export const TitleColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    width: 16.7%;
    /* text-align: center; */
  }
`;

export const TitleColumnDay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% !important ;
  margin-top: 19px;
  div {
    width: 100% !important ;
    margin: 15px 0px 0px 0px;
    height: 45px;
  }
  p {
    margin: 0;
    height: 100%;
    max-height: 45px;
    max-width: 16ch;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const TitleLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 100%;
    display: flex;
    position: relative;
    /* justify-content: center; */
    align-items: center;
    margin: 15px 0px 0px 0px !important;
  }

  p {
    margin: 0;
    max-height: 45px;
    /* max-width: 16ch; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ProgressBar = styled('div')<{
  time: number | string;
  progress: number | string;
}>`
  border-radius: 5px;
  background: #44b4cd59;
  width: ${props => props.time}% !important;
  height: 45px;
  display: flex;
  margin-right: 15px;
  /* z-index: 3; */
  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: ${props => props.progress}%;
    margin-top: 0;

    background: #44b4cd;
    border-radius: 5px;

    color: #fff;
    weight: 700;
  }
`;

export const TableContainer = styled.div`
  margin-top: 15px;

  display: flex;
  flex-direction: column;

  div {
    margin: 0 0 15px 0;
    padding: 1%;
    width: 100%;
    height: 45px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  span {
    display: block;
    width: 25%;
  }
`;

export const ContainerTimeline = styled('div')<{ width?: boolean }>`
  display: flex;
  ${props => props.width && 'width: 15%!important; flex-direction: column;'}
  div {
    width: 98%;
  }
  button {
    margin: 30px 15px;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    width: 25%;
  }
`;

export const Section = styled('section')<{ widthh: number | string }>`
  width: ${props => props.widthh}%;
`;

export const Span = styled('span')<{ widthh: number | string }>`
  width: ${props => props.widthh}%;
`;

export const Header = styled('div')<{ state: boolean }>`
  button {
    cursor: default;
    background: ${props => {
      if (props.state) {
        return '#DF0A24;';
      }
      return 'rgba(117, 184, 67, 0.9);';
    }};
  }
`;

export const TitleModalClose = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    color: #30b4e5;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -40px;
`;

export const ButtonMain = styled.button`
  border: 1px solid #30b4e5;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  width: 100%;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 10px;
    transform: rotate(-90deg);
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
  width: 100%;
`;

export const BtSearch = styled('a')<{ disabled?: boolean }>`
  svg {
    width: 15px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue};
    ${props => props.disabled && 'color: #c4c4c4;'}
  }
`;

export const AddEmail = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 20px !important;
  svg {
    margin-right: 10px;
  }
  u {
    color: ${({ disabled }) => (disabled ? '#c5c5c5' : '#323232')};
    font-weight: 700;
  }
`;
