import { api } from './api';

interface IUpdateBudgetById {
  desconto?: number;
  descricao?: string;
  maoDeObra?: number;
  observacao?: string;
  responsavel?: number;
  cep?: string;
  logradouro?: string;
  numero?: number;
  bairro?: string;
  cidade?: string;
  estado?: string;
  complemento?: string;
}

interface ILinkCurrencyToBudget {
  budgetId: number;
  currencyId: number;
}

interface ILinkCustomerToBudget {
  budgetId: number;
  customerId: number;
}

interface IAddItemToBudget {
  budgetId: number;
  itemId: number;
  preco: number;
  cotacao: number;
  moedaId: number;
}

interface IUpdateItemToBudget {
  budgetId: number;
  itemId: number;
  preco: number;
  cotacao: number;
  moedaId: number;
  quantidade: number;
}

interface IRemoveItemToBudget {
  budgetId: number;
  itemId: number;
}

interface IListBudgets {
  limit?: number;
  page?: number;
  clientOrder?: 1 | 2 | undefined;
  updateDate?: 1 | 2 | undefined;
  aproved?: 0 | 1;
  cpf?: string | null;
  cnpj?: string | null;
}

interface IOrdenationProducts {
  idBudget: number | string;
  content: any[];
}

interface IAproveToken {
  id: string | undefined;
  token: string | undefined;
}

interface ISendToken {
  id: string | undefined;
  email: string;
}

class BudgetsService {
  async listBudgets({
    limit = 200,
    page = 1,
    clientOrder = undefined,
    updateDate = undefined,
    cpf = null,
    cnpj = null,
  }: IListBudgets): Promise<any> {
    const response = await api.get(`/budget-api/budgets`, {
      params: {
        limit,
        page,
        clientOrder,
        updateDate,
        clientCpf: cpf || null,
        clientCnpj: cnpj || null,
      },
    });

    return response.data;
  }

  async listBudgetsAproved({
    limit = 200,
    page = 1,
    aproved,
  }: IListBudgets): Promise<any> {
    const response = await api.get(`/budget-api/budgets`, {
      params: {
        limit,
        page,
        aproved,
      },
    });

    return response.data;
  }

  async getBudgetById(id: number | string): Promise<any> {
    const response = await api.get(`/budget-api/budget/${id}`);

    return response.data;
  }

  async createBudget(): Promise<any> {
    const response = await api.post(`/budget-api/budget/`, {
      descricao: '',
      situacaoId: 1,
    });

    return response.data;
  }

  async deleteBudgetById(id: number): Promise<any> {
    const response = await api.delete(`/budget-api/budget/${id}`);

    return response.data;
  }

  async updateBudgetById(
    id: number,
    budgetEdit: IUpdateBudgetById,
  ): Promise<any> {
    const response = await api.patch(`/budget-api/budget/${id}`, {
      ...budgetEdit,
    });

    return response.data;
  }

  async linkCurrencyToBudget({
    budgetId,
    currencyId,
  }: ILinkCurrencyToBudget): Promise<any> {
    const response = await api.post(
      `/budget-api/budget/${budgetId}/currency/${currencyId}`,
    );

    return response.data;
  }

  async linkCustomerToBudget({
    budgetId,
    customerId,
  }: ILinkCustomerToBudget): Promise<any> {
    const response = await api.post(
      `/budget-api/budget/${budgetId}/customer/${customerId}`,
    );

    return response.data;
  }

  async generateBudgetPdf(id: number): Promise<any> {
    const response = await api.post(`/budget-api/budget/${id}/pdf`);

    return response.data;
  }

  async sendProposalAsPdfByEmail(uuid: string, email: string): Promise<any> {
    const response = await api.post(
      `/budget-api/budget/proposal/${uuid}/email`,
      {
        email,
      },
    );

    return response.data;
  }

  async changeSituationToFinish(id: number): Promise<any> {
    const response = await api.post(`/budget-api/budget/${id}/finish`);

    return response.data;
  }

  async changeSituationToReading(id: number): Promise<any> {
    const response = await api.post(`/budget-api/budget/${id}/reading`);

    return response.data;
  }

  async changeSituationToReview(id: number, descricao: string): Promise<any> {
    const response = await api.post(`/budget-api/budget/${id}/review`, {
      descricao,
    });

    return response.data;
  }

  async changeSituationToWaiting(id: number): Promise<any> {
    const response = await api.post(`/budget-api/budget/${id}/waiting`);

    return response.data;
  }

  async changeSituationToConstruction(id: number): Promise<any> {
    const response = await api.post(`/budget-api/budget/${id}/construction`);

    return response.data;
  }

  async changeSituationToEdit(id: number): Promise<any> {
    const response = await api.post(`/budget-api/budget/${id}/edit`);

    return response.data;
  }

  async approveToken({ id, token }: IAproveToken): Promise<any> {
    const response = await api.patch(`/budget-api/confirmToken/${id}`, {
      token,
    });
    return response.data;
  }

  async sendToken({ id, email }: ISendToken): Promise<any> {
    const response = await api.post(`/budget-api/proposeToken`, {
      email,
      id,
    });
    return response.data;
  }

  async addItemToBudget({
    budgetId,
    itemId,
    moedaId,
    cotacao,
    preco,
  }: IAddItemToBudget): Promise<any> {
    const response = await api.post(
      `/budget-api/budget/${budgetId}/item/${itemId}`,
      {
        moedaId,
        cotacao,
        preco,
      },
    );

    return response.data;
  }

  async updateItemToBudget({
    budgetId,
    itemId,
    moedaId,
    cotacao,
    preco,
    quantidade,
  }: IUpdateItemToBudget): Promise<any> {
    const response = await api.put(
      `/budget-api/budget/${budgetId}/item/${itemId}`,
      {
        moedaId,
        cotacao,
        preco,
        quantidade,
      },
    );

    return response.data;
  }

  async removeItemToBudget({
    budgetId,
    itemId,
  }: IRemoveItemToBudget): Promise<any> {
    const response = await api.delete(
      `/budget-api/budget/${budgetId}/item/${itemId}`,
    );

    return response.data;
  }

  async ordenationProduct({
    idBudget,
    content,
  }: IOrdenationProducts): Promise<any> {
    const response = await api.post(
      `/budget-api/budget/${idBudget}/ordenation`,
      {
        posicoes: content,
      },
    );

    return response.data;
  }
}

export default new BudgetsService();
