import styled from 'styled-components';

export const ModalMail = styled.div`
  input {
    margin: 15px 0;
  }
  button {
    margin: 10px 0;
  }
  h1 {
    width: 90%;
  }
`;

export const Container = styled('div')<{ margin?: boolean; column?: boolean }>`
  display: flex;
  justify-content: space-between;

  max-width: 70%;
  width: 100%;

  ${props => props.column && 'flex-direction: column; width: 73%; margin: 3%'};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding-bottom: ${props => props.margin && '150px'};
    ${props =>
      props.column && 'flex-direction: column; width: 90%; margin: 5%'};
  }
`;

export const CollumContainer = styled('div')<{ isNot?: boolean }>`
  display: flex;
  flex-direction: column;

  margin: ${props => !props.isNot && '3% 0% 0 3%'};

  width: 100%;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0;
  }
`;

export const Collum = styled.span`
  display: flex;
  flex-direction: column;
`;

export const CollumEmail = styled.span`
  display: flex;
  flex-direction: column;
  width: 95%;
`;

export const ListEmail = styled.p`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 10px;
  }
`;

export const Content = styled.div`
  padding: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  span {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    padding: 6px 0;
    margin: 0;
  }
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  margin: 20px 0;
`;

export const SubTitle = styled.span`
  display: flex;
  align-self: flex-start;
  font-size: 20px;
  font-weight: 700;
  margin: 3% 0;
`;

export const ListInfo = styled('div')<{ alingItens?: boolean }>`
  display: flex;
  font-size: 17px;
  word-break: break-all;

  margin: 20px 0;

  white-space: nowrap;

  svg {
    margin-right: 15px;
    width: 2vh !important;
    height: 2vh !important;
  }
  b {
    margin-left: 10px;
    white-space: normal;
  }
  p {
    margin: 0;
    font-weight: 500;
  }
  p + p {
    margin-top: 10px;
  }
  ${props => props.alingItens && '  align-items: center;'}
`;

export const ContainerOption = styled.div`
  margin: 3% 3% 0 0;
  width: 20%;
  position: absolute;
  right: 0;

  a {
    width: 90%;
    button {
      width: 100%;
    }
  }

  span {
    button {
      width: 100%;
    }
  }

  button {
    margin: 20px 0;
    svg {
      width: 2vh;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;

    button {
      width: 90%;
    }
  }
`;

export const ButtonBlack = styled.span`
  width: 100%;

  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const CreatorBudget = styled.div`
  display: flex;
  align-items: center;
  background: #eaf7fd;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  padding: 20px 15px;
  margin: 10px 0;

  div {
    width: 5vh;
    height: 5vh;
    margin-right: 15px;
  }
  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const StatusBudget = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  padding: 20px 15px;

  span {
    margin-left: 15px;
  }

  p {
    margin: 0;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Responsivenes = styled.span`
  @media (max-width: 768px) {
    position: fixed;
    justify-content: center;
    bottom: 10px;
    width: 90% !important;
    text-align: center;
    z-index: 997;
  }
`;

export const ContainerButton = styled('div')<{
  mobile?: boolean;
  width?: boolean;
}>`
  span {
    display: flex;
    justify-content: space-between;
    ${props => props.mobile && `display: none`}
  }
  div {
    ${props => props.mobile && `display: none`}
  }
  button {
    svg {
      width: 2vh;
    }
  }
  button + button {
    margin-left: 20px;
  }
  margin-bottom: 25px;

  @media (max-width: 768px) {
    span {
      flex-direction: column;
      display: none;
      ${props =>
        props.mobile &&
        `display: flex;
      `}
    }
    div {
      ${props =>
        props.mobile &&
        `display: block;
      `}
    }

    ${props =>
      props.width &&
      `  width: 100% !important;
    `}

    width: 90%;
    margin-top: 15px;
    margin-bottom: 0;
    button {
      margin: 10px 0 !important;
    }
  }
`;

export const TitleModal = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    color: #30b4e5;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
`;

export const AddEmail = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 20px !important;
  svg {
    margin-right: 10px;
  }
  u {
    color: #323232;
    font-weight: 700;
  }
`;
