import styled from 'styled-components';

export const Content = styled.div`
  width: 98%;
  margin: 1%;
  padding: 2%;
  box-shadow: 0px 1px 4px 0px #00000047;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  p {
    margin 0
  }
`;

export const Table = styled('div')<{ state: boolean }>`
  ${props => props.state && `display: none;`}

  width: 98%;
  margin: 1%;

  div {
    padding: 0px;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 40px;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    padding: 15px 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const RowModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ButtonStyle} + button {
    margin-left: 10px;
  }
`;
