import React from 'react';

import { Page } from '~/components';
import ButtonDashboard from '~/components/ButtonDashboard';

import { Container } from './styles';

const Home: React.FC = () => {
  return (
    <Page>
      <Container>
        <h1>Home</h1>
        <ButtonDashboard />
      </Container>
    </Page>
  );
};

export default Home;
