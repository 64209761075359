export enum RepositoriesTypes {
  LOAD_REQUEST = '@repositories/LOAD_REQUEST',
  LOAD_SUCCCES = '@repositories/LOAD_SUCCCES',
  LOAD_FAILURE = '@repositories/LOAD_FAILURE',
  LOAD_RESET = '@repositories/LOAD_RESET',
}
export enum LogoutTypes {
  LOGOUT = '@repositories/LOGOUT',
}

export interface IResponse {
  accessToken: string;
  account: {
    firstName: string;
    lastName: string;
    email: string;
    photo: boolean;
    id: any;
  };
  permissions: any[];
}

export interface IPropsRequest {
  type: string;
  payload: {
    email: string;
    password: string;
  };
}

export interface IPropsSucces {
  data: IResponse[];
  islogged: boolean;
}

export interface IRepositoriesState {
  data: IResponse;
  loading: boolean;
  error: boolean;
  isLogged: boolean;
}
