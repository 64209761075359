/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import { LogoutTypes, RepositoriesTypes, IResponse } from './types';

export const loadRequest = (email: string, password: string) =>
  action(RepositoriesTypes.LOAD_REQUEST, { email, password });

export const loadSuccess = (data: IResponse, islogged: boolean) =>
  action(RepositoriesTypes.LOAD_SUCCCES, { data, islogged });

export const loadFailure = () => action(RepositoriesTypes.LOAD_FAILURE);

export const loadReset = () => action(RepositoriesTypes.LOAD_RESET);

export const logoutRequest = () => action(LogoutTypes.LOGOUT);
