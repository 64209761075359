export const defaultTheme = {
  Colors: {
    Blue: {
      Main: '#30b4e5',
      Secondary: 'rgba(48, 180, 229, 0.07);',
      Tertiary: 'rgba(48, 180, 229, 0.17);',
    },
    Gray: {
      Strong: '#000000',
      Main: '#323232',
      Secondary: '#54565a',
      Tertiary: '#c4c4c4',
    },
    White: '#ffffff',
    Red: '#df0a24',
  },
};
