import styled from 'styled-components';

export const DeletDiv = styled.div`
  button {
    width: 40%;
  }
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
    width: 56% !important;
  }
`;

export const Content = styled.div`
  margin-top: 3%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;
`;
