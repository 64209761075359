/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { IconReturn } from '~/assets/Icons';
import { Checkbox } from '~/components';
import { TitleCheckbox, ListCheckbox } from './styles';

interface IProps {
  module: any;
}

const ListCheckboxPermissions: React.FC<IProps> = ({ module }: IProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <TitleCheckbox onClick={() => setVisible(!visible)}>
        {module.modulo} <IconReturn />
      </TitleCheckbox>

      <ListCheckbox visible={!visible}>
        {Object.entries(module).map((permission: any) => {
          if (permission[0] !== 'modulo' && permission[0] !== 'moduloId') {
            return (
              <div key={permission.moduloId}>
                <Checkbox disabled checked={permission[1]} />
                <b>
                  {permission[0] === 'coordenadorDeManutencao'
                    ? 'Coordenador De Manutenção'
                    : permission[0] === 'tecnicoDeManutencao'
                    ? 'Técnico de Manutenção'
                    : permission[0].charAt(0) +
                      permission[0].slice(1).replace(/([A-Z])/g, ' $1')}
                </b>
              </div>
            );
          }
          return null;
        })}
      </ListCheckbox>
    </>
  );
};

export default ListCheckboxPermissions;
