/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';

import SummaryJson from '~/mocks/SummaryMonthJanuary.json';
import { typeENUM } from '~/constants/types';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import { Container, ContentColor, ContentList } from './styles';

export interface ISummary {
  key: number;
  type: string;
  color: string;
  days: number;
}

const Summary: React.FC = () => {
  const [summary, setSummary] = useState<ISummary[]>([
    {
      key: 1,
      type: 'no-schedule',
      color: '#ccc',
      days: 0,
    },
    {
      key: 2,
      type: 'await-report',
      color: '#2c78e4',
      days: 0,
    },
    {
      key: 3,
      type: 'incomplete',
      color: '#ff9900',
      days: 0,
    },
    {
      key: 4,
      type: 'done',
      color: '#274e13',
      days: 0,
    },
  ]);

  const { resumeMonth, monthSelected } = useSelector(
    (state: IState) => state.report,
  );

  useEffect(() => {
    setSummary([
      {
        key: 1,
        type: 'no-schedule',
        color: '#ccc',
        days: resumeMonth?.totalDiasNaoAgendados || 0,
      },
      {
        key: 2,
        type: 'await-report',
        color: '#2c78e4',
        days: resumeMonth?.totalAgendadosAguardando || 0,
      },
      {
        key: 3,
        type: 'incomplete',
        color: '#ff9900',
        days: resumeMonth?.totalAgendadosIncompletos || 0,
      },
      {
        key: 4,
        type: 'done',
        color: '#274e13',
        days: resumeMonth?.totalAgendadosConcluidos || 0,
      },
    ]);
  }, [resumeMonth]);

  return (
    <Container>
      <h1>
        Resumo de {format(new Date(monthSelected), 'MMMM', { locale: pt })}
      </h1>
      {summary.map((item: ISummary) => (
        <ContentList>
          {/* <ContentColor color={item.color}>.</ContentColor> */}
          <b>{`${item.days} dias ${typeENUM(item.type)}`}</b>
        </ContentList>
      ))}
    </Container>
  );
};

export default Summary;
