/* eslint-disable no-plusplus */
import { api } from './api';

interface IListConstruction {
  limit?: number;
  page?: number;
  cliente?: string;
}

interface ICreateTimeline {
  id: number | string;
  text: string;
  tagId: number;
  stageWorkId: number;
}

interface IUpdateStaff {
  id: number | string;
  arquitetoId?: number | null;
  engenheiroId?: number | null;
  gerenteId?: number | null;
  arquitetoId2?: number | null;
  engenheiroId2?: number | null;
  gerenteId2?: number | null;
  arquitetoId3?: number | null;
  engenheiroId3?: number | null;
  gerenteId3?: number | null;
  arquitetoId4?: number | null;
  engenheiroId4?: number | null;
  gerenteId4?: number | null;
  arquitetoId5?: number | null;
  engenheiroId5?: number | null;
  gerenteId5?: number | null;
}

interface IUpdateContact {
  id: number | string;
  name: string | null;
  phone: string | null;
  email: string | null;
  contatoNome2: string | null;
  contatoEmail2: string | null;
  contatoTelefone2: string | null;
  contatoNome3: string | null;
  contatoEmail3: string | null;
  contatoTelefone3: string | null;
  contatoNome4: string | null;
  contatoEmail4: string | null;
  contatoTelefone4: string | null;
  contatoNome5: string | null;
  contatoEmail5: string | null;
  contatoTelefone5: string | null;
}
interface IUpdateJustification {
  id: number | string;
  request: {
    [key: string]: string | number;
  };
}
interface IUpdateTechnicalProject {
  id: number | string;
  voltage?: string;
  boardInstalled?: 0 | 1;
  boardPowered?: 0 | 1;
  hydraulicPipe?: 0 | 1;
  machineHouse?: 0 | 1;
  uploadId?: number;
  status?: 0 | 1;
  justification?: string;
}

interface IUpdateEquipment {
  id: number | string;
  equipment: number[];
}

interface IUpdateEquipmentSystem {
  id: number | string;
  equipment: number[];
  systemId: number | string;
}

interface IUpdateDesingVisual {
  id: number | string;
  justification?: string;
  status: number;
  uploadId: number | string;
}

interface IUpdateSchedule {
  id: number | string;
  progresso?: string;
}

// interface ICreateConstruction {
//   descricao: string;
//   orcamentod: number;
//   inicio: string;
//   termino: string;
//   contatoNome: string;
//   contatoEmail: string;
//   contatoTelefone: string;
//   contatoCargo: string;
//   contatoEmpresa: string;
//   info: string;
//   cep: string;
//   logradouro: string;
//   numero: number;
//   bairro: string;
//   cidade: string;
//   estado: string;
//   complemento?: string;
// }

interface ICreateSchedule {
  item: string | any;
  diarioId: number | any;
  categoriaId: number | any;
  atribuidos: number[] | any;
  inicio: string | any;
  dias: number | any;
  progresso: number | any;
}

class RecoveryService {
  async listConstruction({
    limit,
    page,
    cliente,
  }: IListConstruction): Promise<any> {
    const response = await api.get(`/construction-api/construction`, {
      params: {
        limit,
        page,
        cliente,
      },
    });

    return response.data;
  }

  async createConstruction(array: any[]): Promise<any> {
    const response = await api.post(`/construction-api/construction`, array);

    return response.data;
  }

  async createSchedule({
    atribuidos,
    categoriaId,
    diarioId,
    dias,
    inicio,
    item,
    progresso,
  }: ICreateSchedule): Promise<any> {
    const response = await api.post(`/construction-api/schedule`, {
      atribuidos,
      categoriaId,
      diarioId,
      dias,
      inicio,
      item,
      progresso,
    });

    return response.data;
  }

  async getConstructionById(id: number | string): Promise<any> {
    const response = await api.get(`/construction-api/construction/${id}`);

    return response.data;
  }

  async getTimelineById(id: number | string): Promise<any> {
    const response = await api.get(
      `/construction-api/timeline/construction/${id}`,
    );

    return response.data;
  }

  async createTimeline({
    id,
    stageWorkId,
    tagId,
    text,
  }: ICreateTimeline): Promise<any> {
    const response = await api.post(
      `/construction-api/timeline/construction/${id}`,
      {
        texto: text,
        tagId,
        etapaDaObraId: stageWorkId,
      },
    );

    return response.data;
  }

  async listTag(): Promise<any> {
    const response = await api.get(`/construction-api/tag`);

    return response.data;
  }

  async uploadFile(formData: any): Promise<any> {
    const response = await api.post(`/construction-api/file`, formData);

    return response.data;
  }

  async updateStaff({
    id,
    arquitetoId,
    engenheiroId,
    gerenteId,
    arquitetoId2,
    engenheiroId2,
    gerenteId2,
    arquitetoId3,
    engenheiroId3,
    gerenteId3,
    arquitetoId4,
    engenheiroId4,
    gerenteId4,
    arquitetoId5,
    engenheiroId5,
    gerenteId5,
  }: IUpdateStaff): Promise<any> {
    const request: any = {
      arquitetoId,
      engenheiroId,
      gerenteId,
      arquitetoId2,
      engenheiroId2,
      gerenteId2,
      arquitetoId3,
      engenheiroId3,
      gerenteId3,
      arquitetoId4,
      engenheiroId4,
      gerenteId4,
      arquitetoId5,
      engenheiroId5,
      gerenteId5,
    };
    const keys = Object.keys(request);
    for (let index = 0; index < keys.length; index++) {
      const element = request[keys[index]];
      if (!element) delete request[keys[index]];
    }
    const response = await api.patch(`/construction-api/staff/${id}`, request);

    return response.data;
  }

  async updateContact({
    id,
    name,
    phone,
    email,
    ...props
  }: IUpdateContact): Promise<any> {
    const request: any = {
      ...props,
    };
    const keys = Object.keys(request);
    for (let index = 0; index < keys.length; index++) {
      const element = request[keys[index]];
      if (!element) delete request[keys[index]];
    }
    const response = await api.patch(`/construction-api/construction/${id}`, {
      contatoNome: name,
      contatoEmail: email,
      contatoTelefone: phone,
      ...request,
    });

    return response.data;
  }

  async updateJustification({
    id,
    request,
  }: IUpdateJustification): Promise<any> {
    const response = await api.patch(
      `/construction-api/construction/${id}`,
      request,
    );

    return response.data;
  }

  async updateTechnicalProject({
    id,
    voltage,
    boardInstalled,
    boardPowered,
    machineHouse,
    hydraulicPipe,
    uploadId,
    status,
    justification,
  }: IUpdateTechnicalProject): Promise<any> {
    const response = await api.patch(
      `/construction-api/construction/technical_project/${id}`,
      {
        correnteTensao: voltage,
        quadroGeralInstalado: boardInstalled,
        quadroGeralAlimentado: boardPowered,
        tubulacaoHidraulica: hydraulicPipe,
        casaDeMaquinas: machineHouse,
        uploadId,
        status,
        justificativa: justification,
      },
    );

    return response.data;
  }

  async updateEquipment({ id, equipment }: IUpdateEquipment): Promise<any> {
    if (equipment.length) {
      const response = await api.put(
        `/construction-api/construction/${id}/products`,
        {
          recebido: 1,
          produtos: equipment,
        },
      );

      return response.data;
    }
    return [];
  }

  async updateEquipmentSystem({
    id,
    equipment,
    systemId,
  }: IUpdateEquipmentSystem): Promise<any> {
    if (systemId) {
      const response = await api.put(
        `/construction-api/construction/${id}/system/${systemId}/products`,
        {
          recebido: 1,
          produtos: equipment,
        },
      );

      return response.data;
    }
    return [];
  }

  async updateProjectVisual({
    id,
    justification,
    status,
    uploadId,
  }: IUpdateDesingVisual): Promise<any> {
    const response = await api.patch(`/construction-api/visual/${id}`, {
      status,
      justificativa: justification,
      uploadId,
    });

    return response.data;
  }

  async updateSchedule({ id, progresso }: IUpdateSchedule): Promise<any> {
    const response = await api.patch(`/construction-api/schedule/${id}`, {
      progresso,
    });

    return response.data;
  }

  async listSchedule(diarioId: number): Promise<any> {
    const response = await api.get(`/construction-api/schedule`, {
      params: {
        diarioId,
      },
    });

    return response.data;
  }

  async startSchedule(id: number | string): Promise<any> {
    const response = await api.post(
      `/construction-api/construction/${id}/schedule`,
    );

    return response.data;
  }

  async listCategory(): Promise<any> {
    const response = await api.get(`/construction-api/schedule-categories`);

    return response.data;
  }
}

export default new RecoveryService();
