import { call, put } from 'redux-saga/effects';
import { api } from '~/services/api';
import { IResponse } from './types';
import { listCustomerSuccess, listCustomerFailure } from './actions';

export function* listCustomer(): any {
  try {
    const dataCustomer = async () => {
      const request = await api.get(`/customer-api/customer?limit=200&page=1`);
      return request.data.result;
    };
    const request: IResponse = yield call(dataCustomer);
    yield put(listCustomerSuccess(request));
  } catch (err) {
    yield put(listCustomerFailure());
  }
}
