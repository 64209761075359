import PhoneInput from 'react-phone-number-input';
import { Container, Label, ErroAlert } from './styles';
import 'react-phone-number-input/style.css';

interface IProps {
  id?: string;
  label?: any;
  value: string | undefined;
  error?: string | null;
  onBlur?: ((e: any) => void) | null;
  onChange: (e: any) => void;
  requiredLabel?: boolean;
}

const defaultProps = {
  id: '',
  label: '',
  error: '',
  onBlur: null,
  requiredLabel: false,
};

const InputPhone: React.FC<IProps> = ({
  id,
  value,
  label,
  error,
  onBlur,
  onChange,
  requiredLabel,
}: IProps) => {
  function addLabel() {
    if (label) {
      return (
        <Label htmlFor={id}>
          {requiredLabel ? (
            <div>
              <b>{label}</b> (Obrigatorio)
            </div>
          ) : (
            <b>{label}</b>
          )}
        </Label>
      );
    }
    return null;
  }

  return (
    <div>
      {addLabel()}
      <Container>
        <PhoneInput
          onChange={e => onChange(e)}
          onBlur={e => {
            if (onBlur) onBlur(e);
          }}
          value={value}
          defaultCountry="BR"
        />
      </Container>
      <ErroAlert erro={!!error}>{error}</ErroAlert>
    </div>
  );
};

InputPhone.defaultProps = defaultProps;

export default InputPhone;
