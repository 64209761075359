import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 1% 3% 3% 3%;

  padding-bottom: 125px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerUpload = styled.div`
  width: 200px;
  height: 186px;
  background: #c4c4c4;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;

  p {
    font-size: 10px;
    text-align: center;
  }
`;

export const CustomerImg = styled.img`
  width: 200px;
  height: 186px;
  object-fit: cover;
  border-radius: 10px;
`;

export const ContentUpload = styled.div`
  width: 200px;
  height: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 40%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 40px;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    padding: 15px 10px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  input,
  label,
  p {
    margin: 0 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InputBox = styled.div`
  width: 100%;
`;

export const DivList = styled.div`
  margin: 0 10px 0 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  position: absolute;
  width: 37%;
  background: #ffffff;
  z-index: 900;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  a + a {
    border-top: 1px solid #30b4e5;
  }
`;

export const ListContent = styled.a`
  display: flex;
  padding: 10px;
  color: #323232;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
`;

export const SegmentContainer = styled.div`
  display: flex;

  button {
    margin-top: 32px;
  }

  div {
    width: 90%;
  }
`;

export const SegmentModalContainer = styled.div`
  h1 {
    margin-top: 15px;
    font-weight: 700;
    color: #323232;
  }

  span {
    display: block;
    margin-bottom: 15px;
  }
`;

export const CloseModal = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    color: #30b4e5;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
`;

export const ContentAddFinancialResponsible = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 0;
`;

export const ButtonAddFinancialResponsible = styled('button')`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  max-height: 45px;
`;

export const ButtonTextAddFinancialResponsible = styled.h1`
  margin: 0px 5px;
  text-decoration: underline;
`;
