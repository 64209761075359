/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMonths,
  getPercentDailyAwaiting,
  getPercentDailyDuration,
  getPercentMothly,
} from '~/utils';
import { IconAlert, IconClose } from '~/assets/Icons';
import { Avatar, Button, Input, Modal, ModalDefault } from '~/components';
import ConstructionsService from '~/services/ConstructionsService';
import {
  getConstructionRequest,
  getConstructionSuccess,
} from '~/store/modules/construnction/actions';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { ModalMail } from '~/pages/Budget/Finish/styles';
import { IState } from '~/store/modules/types';
import ModalCreateSchedule from './components/ModalCreateSchedule';
import {
  AlertSchedules,
  Content,
  ContentList,
  TableContainer,
  TableHeader,
  TitleColumn,
  TitleLine,
  ContainerTimeline,
  ProgressBar,
  Section,
  Span,
  TitleModalClose,
  TitleColumnDay,
} from './styles';

interface IProps {
  dataSource: any;
  user: any;
  permission: boolean;
}

const ConstructionSchedule: React.FC<IProps> = ({
  dataSource,
  user,
  permission,
}: IProps) => {
  const [events, setEvents] = useState([]);
  const [months, setMonths] = useState(['']);
  const [idDelete, setIdDelete] = useState('');
  const [percent, setPercent] = useState('');
  const [isVisibleModalProgress, setIsVisibleModalProgress] = useState(false);
  const [visibleModalCreate, setVisibleModalCreate] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const [messageModalStartErro, setMessageModalStartErro] = useState<any>([]);
  // const [permission, setPermission] = useState(false);
  const userId = useSelector((state: IState) => state.auth.data.account.id);
  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  // const verifyPermission = (): boolean => {
  //   if (dataSource.terminado) {
  //     return false;
  //   }
  //   if (!user) return false;
  //   if (
  //     (user.toUpperCase() === 'DIRETOR' ||
  //       user.toUpperCase() === 'GERENTE DE PROJETOS' ||
  //       user.toUpperCase() === 'GERENTE GERAL' ||
  //       userId === dataSource.corpo_tecnico?.gerente?.id ||
  //       userId === dataSource.corpo_tecnico?.engenheiro?.id ||
  //       userId === dataSource.corpo_tecnico?.arquiteto?.id) &&
  //     userPermissions.find(element => {
  //       if (
  //         element.modulo === 'DIÁRIO DE OBRAS' &&
  //         element.permissao === 'ATUALIZAR'
  //       ) {
  //         return true;
  //       }
  //       return false;
  //     })
  //   ) {
  //     setPermission(true);
  //   }

  //   return false;
  // };

  // useEffect(() => {
  //   verifyPermission();
  // }, []);

  const load = async () => {
    try {
      const response = await ConstructionsService.listSchedule(dataSource.id);
      setEvents(response.result);
    } catch {
      setIsVisibleModalError(false);
    }
  };
  useEffect(() => {
    setMonths(getMonths(dataSource.inicio, dataSource.termino));
    load();
  }, [dataSource.id]);

  const dispatch = useDispatch();

  const updateRedux = () => {
    dispatch(getConstructionRequest(dataSource.id));
  };

  const checkPending = async () => {
    if (!dataSource.projeto_tecnico?.quadro_geral_instalado) {
      setMessageModalStartErro((prevState: any) => [
        ...prevState,
        {
          type: 'QuadroGeral',
          message: 'O Quadro Geral já foi instalado completamente?',
          date: dataSource.projeto_tecnico?.pendencia_quadro_geral?.prazo,
        },
      ]);
    }
    if (!dataSource.projeto_tecnico.equipamentos_recebidos) {
      setMessageModalStartErro((prevState: any) => [
        ...prevState,
        {
          type: 'Entrega',
          message: 'Os produtos vendidos já foram entregues?',
          date: dataSource.projeto_tecnico?.pendencia_recebidos?.prazo,
        },
      ]);
    }
    if (!dataSource.projeto_tecnico.tubulacao_hidraulica) {
      setMessageModalStartErro((prevState: any) => [
        ...prevState,
        {
          type: 'Tubulacao',
          message: 'A tubulação hidráulica já está preparada?',
          date: dataSource.projeto_tecnico?.pendencia_tubulacao_hidraulica
            ?.prazo,
        },
      ]);
    }
    if (!dataSource.projeto_tecnico.casa_de_maquinas) {
      setMessageModalStartErro((prevState: any) => [
        ...prevState,
        {
          type: 'CasaDeMaquina',
          message: 'A Casa de Máquinas já está finalizada?',
          date: dataSource.projeto_tecnico?.pendencia_casa_de_maquinas?.prazo,
        },
      ]);
    }
  };

  const requestStartSchedules = async (type: string) => {
    if (type === 'CasaDeMaquina') {
      try {
        await ConstructionsService.updateTechnicalProject({
          id: dataSource.projeto_tecnico.id,
          machineHouse: 1,
        });
        updateRedux();

        setMessageModalStartErro([]);
        checkPending();
      } catch {
        setIsVisibleModalError(true);
      }
    }
    if (type === 'Tubulacao') {
      try {
        await ConstructionsService.updateTechnicalProject({
          id: dataSource.projeto_tecnico.id,
          hydraulicPipe: 1,
        });
        updateRedux();
        setMessageModalStartErro([]);
        checkPending();
      } catch {
        setIsVisibleModalError(true);
      }
    }
    if (type === 'QuadroGeral') {
      try {
        await ConstructionsService.updateTechnicalProject({
          id: dataSource.projeto_tecnico.id,
          boardInstalled: 1,
          boardPowered: 1,
        });
        updateRedux();
        setMessageModalStartErro([]);
        checkPending();
      } catch {
        setIsVisibleModalError(true);
      }
    }
    if (type === 'Entrega') {
      try {
        const arraySystem: any = {};
        const arrayProduto: any = [];
        dataSource.projeto_tecnico.equipamentos.forEach((element: any) => {
          if (!element.itemFromSystem) {
            return arrayProduto.push(element.id);
          }
          if (element.itemFromSystem && element.sistemaId) {
            if (arraySystem[element.sistemaId]) {
              return arraySystem[element.sistemaId].produtos.push(
                element.produtoId,
              );
            }
            arraySystem[element.sistemaId] = {
              produtos: [element.produtoId],
            };
          }
          return '';
        });

        await Promise.all([
          await ConstructionsService.updateEquipment({
            id: dataSource.id,
            equipment: arrayProduto,
          }),
          ...Object.entries(arraySystem).map(async ([key, value]: any) => {
            return ConstructionsService.updateEquipmentSystem({
              equipment: value.produtos,
              id: dataSource.orcamento_id,
              systemId: key,
            });
          }),
        ]);

        updateRedux();
      } catch {
        setIsVisibleModalError(true);
      }
    }
  };

  useEffect(() => {
    checkPending();
  }, []);

  return (
    <>
      {visibleModalCreate && (
        <ModalCreateSchedule
          onClick={() => setVisibleModalCreate(false)}
          dataSource={dataSource}
          reload={() => load()}
        />
      )}
      {isVisibleModalError && (
        <ModalDefault
          success={false}
          onClick={() => setIsVisibleModalError(false)}
        >
          Ops, algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModalProgress && (
        <Modal width={window.innerWidth > 800 ? '35vw' : '95%'}>
          <ModalMail>
            <TitleModalClose>
              <button
                type="button"
                onClick={() => setIsVisibleModalProgress(false)}
              >
                <IconClose />
              </button>
            </TitleModalClose>
            <h1>Insira o novo progresso.</h1>
            <Input
              required
              placeholder="Ex.: 69%"
              onChange={e => setPercent(e.target.value)}
            />
            <Button
              onClick={async () => {
                try {
                  await ConstructionsService.updateSchedule({
                    id: idDelete,
                    progresso: percent.replace(/[^\d]+/g, ''),
                  });
                  load();
                } catch {
                  setIsVisibleModalError(true);
                }
                setPercent('');
                setIdDelete('');

                setIsVisibleModalProgress(false);
              }}
            >
              Atualizar
            </Button>
          </ModalMail>
        </Modal>
      )}
      {messageModalStartErro.length > 0 && (
        <>
          <AlertSchedules>
            <IconAlert />
            <p>
              Essa obra possui etapas críticas que ainda não foram concluídas,
              por isso nenhuma data de início do cronograma não pode ser
              inferior à previsão de conclusão das seguintes etapas:
            </p>
          </AlertSchedules>
          {messageModalStartErro.map((element: any) => (
            <ContentList key={element.type}>
              <b>{element.message}</b>
              <div>
                {element.date && (
                  <>
                    <p>Previsão de entrega:</p>
                    <p>
                      {format(new Date(element.date), "dd'/'LL'/'yyyy", {
                        locale: pt,
                      })}
                    </p>
                  </>
                )}
              </div>
              <Button
                disabled={!permission}
                onClick={() => requestStartSchedules(element.type)}
              >
                Concluir
              </Button>
            </ContentList>
          ))}
        </>
      )}
      <span>
        <b>Timeline de Progresso</b>
        <ContainerTimeline>
          <Button
            disabled={!permission}
            onClick={() => setVisibleModalCreate(true)}
          >
            Adicionar
          </Button>
          {!dataSource.terminado && (
            <Button
              disabled={!permission}
              onClick={() => {
                try {
                  ConstructionsService.updateJustification({
                    id: dataSource.id,
                    request: { terminado: 1 },
                  });
                  dispatch(
                    getConstructionSuccess({
                      ...dataSource,
                      terminado: 1,
                    }),
                  );
                } catch {
                  setIsVisibleModalError(true);
                }
              }}
              styles={1}
            >
              Informar a conclusão da obra
            </Button>
          )}
        </ContainerTimeline>
        <ContainerTimeline>
          <div>
            <TableHeader>
              <span>Início</span>
              <span>Dias</span>
              <span>Categoria</span>
              <span>Atribuído</span>
            </TableHeader>
            <TableContainer>
              {events.map((element: any) => (
                <Content>
                  <span>{moment(element.inicio).format('DD/MM/YYYY')}</span>
                  <span>{element.dias}</span>
                  <span
                    style={{
                      background: element.categoria.cor,
                      padding: '1px',
                      color: '#ffffff',
                      textAlign: 'center',
                      borderRadius: '3px',
                    }}
                  >
                    {element.categoria.nome}
                  </span>
                  <span>
                    {element.atribuidos.length > 1 ? (
                      <section>
                        <Avatar
                          peoples={element.atribuidos.map(
                            (atribuido: { nome: string; imagem: string }) => {
                              return {
                                nome: atribuido.nome,
                                url: atribuido.imagem,
                              };
                            },
                          )}
                        />
                      </section>
                    ) : (
                      <section>
                        <Avatar name={element.atribuidos[0].nome} />

                        {/* <Avatar name={element.atribuidos[0].nome} /> +
                        {element.atribuidos.lenght - 1} */}
                      </section>
                    )}
                  </span>
                </Content>
              ))}
            </TableContainer>
          </div>
          <div>
            <TitleColumn>
              {months.map(month => {
                const spaceContent = getPercentMothly(
                  dataSource.inicio,
                  dataSource.termino,
                );
                return <Span widthh={spaceContent}>{month}</Span>;
              })}
            </TitleColumn>
            <TitleLine>
              {events.map((element: any) => {
                const spaceStart = getPercentDailyAwaiting(
                  dataSource.inicio,
                  dataSource.termino,
                  element.inicio,
                );

                const spaceContent = getPercentDailyDuration(
                  dataSource.inicio,
                  dataSource.termino,
                  element.dias,
                );
                return (
                  <button
                    type="button"
                    disabled={!permission}
                    onClick={() => {
                      setIdDelete(element.id);
                      setIsVisibleModalProgress(true);
                    }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        width: '100%',
                      }}
                    >
                      <b>{element.item}</b>
                      <p>{element.progresso}%</p>
                    </span>
                    <Section widthh={spaceStart} />
                    <ProgressBar
                      time={spaceContent}
                      progress={element.progresso}
                    >
                      <span />
                    </ProgressBar>
                  </button>
                );
              })}
            </TitleLine>
          </div>
          <ContainerTimeline width>
            <TitleColumnDay>
              {events.map((element: any) => (
                <Tooltip
                  placement="top"
                  title={`Finaliza dia
                      ${moment(element.inicio)
                        .add(element.dias, 'days')
                        .format(' DD/MM/YYYY')} `}
                >
                  <div>
                    <p>
                      Finaliza
                      {moment(element.inicio)
                        .add(element.dias, 'days')
                        .format(' DD/MM/YYYY')}
                    </p>
                  </div>
                </Tooltip>
              ))}
            </TitleColumnDay>
          </ContainerTimeline>
        </ContainerTimeline>
      </span>
    </>
  );
};

export default ConstructionSchedule;
