import React, { useState } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';

import { BodyList, ContentList, HeaderList, Button } from './styles';

interface IProps {
  element: {
    usuario: {
      nome: string;
    };
    tag: {
      descricao: string;
    };
    upload: {
      path: string;
    };
    texto: string;
    data: string;
  };
}

const ListJustification: React.FC<IProps> = ({ element }: IProps) => {
  const [content, setContent] = useState(false);
  return (
    <ContentList state={element.tag.descricao}>
      <HeaderList
        state={element.tag.descricao}
        onClick={() => setContent(!content)}
      >
        <p>{element.usuario.nome}</p>
        <div>
          <span>{element.tag.descricao}</span>
          <p>
            {format(new Date(element.data), "dd'/'MM'/'y 'às' HH:mm'", {
              locale: pt,
            })}
          </p>
        </div>
      </HeaderList>
      <BodyList state={content}>
        <p>{element.texto}</p>
        {element?.upload?.path ? (
          <Button target="_blank" href={element.upload.path} download="arquivo">
            Ver item
          </Button>
        ) : null}
      </BodyList>
    </ContentList>
  );
};

export default ListJustification;
