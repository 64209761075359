import styled from 'styled-components';

interface ILabelProps {
  color?: string;
}

export const Container = styled.div`
  padding: 3% 0;
  display: flex;
  flex-direction: column;
`;

export const ButtonAdd = styled.button.attrs({
  type: 'button',
})``;

export const RowInput = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;

  p + div {
    margin-left: 5px;
  }

  p + button {
    margin-left: 5px;
  }

  button {
    width: 100% !important;
  }

  ${ButtonAdd} {
    width: min-content !important;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  ${RowInput} + ${RowInput} {
    margin-left: 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
  }
  span + button {
    margin-left: 5px;
  }
  ${Row} + ${RowInput} {
    margin-top: 10px;
  }
  ${RowInput} + ${Row} {
    margin-top: 10px;
  }
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const Label = styled.p<ILabelProps>`
  ${({ color }) => (color ? `color: ${color};` : '')};
`;
export const BoxImage = styled.div`
  img {
    width: 60px;
    height: 60px;
    border: 1px solid #30b4e5;
    object-fit: cover;
    border-radius: 3px;
    margin: 0 10px 0 0;
  }
`;

export const TitleModal = styled.div`
  svg {
    color: #30b4e5;
  }
  button {
    width: fit-content !important;
  }
  display: flex;
  justify-content: right;
  margin: -10px -10px 0 0;
`;

export const ContentModal = styled.div`
  text-align: center;
`;

export const HeaderModalFilter = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: -30px;
  font-size: 20px;

  p {
    margin: 0 0 10px 0;
    font-weight: 300;
  }
`;

export const ContentModalFilter = styled.div`
  span {
    button {
      margin-top: 15px;
    }
  }
`;

export const CleanFilter = styled.button`
  border: 1px solid #30b4e5;
  border-radius: 7px;
  color: #30b4e5;
  font-size: clamp(8px, 1vw + 0.1rem, 13px);
  padding: 5px;
  margin-top: 40px;
  height: fit-content;
`;
