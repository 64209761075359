import styled from 'styled-components';

export const Container = styled('div')<{ disable?: boolean }>`
  input:checked:after {
    background-color: #30b4e5;
  }
  input {
    outline: 1px solid #1e5180
    border: 1px solid #30b4e5;
    border-color: #30b4e5;
    border-radius: 3px;
    ${props => props.disable && `cursor: default !important;`}
  }
  input[disabled] {
    background: #323232;
    border: 1px solid #323232;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    border: 1px solid #30b4e5;
    position: relative;
    display: block;
  }


`;
