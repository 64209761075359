import { all, takeLatest } from 'redux-saga/effects';

import { RepositoriesTypes } from './auth/types';
import { load } from './auth/sagas';

import { ListTypes } from './currency/types';
import { listCurrency } from './currency/sagas';

import { ListCustomerTypes } from './customer/types';
import { listCustomer } from './customer/sagas';

import { ListProductTypes } from './product/types';
import { listProduct } from './product/sagas';

import { NotificationTypes } from './notification/types';
import { listNotifications } from './notification/sagas';

import { GetConstructionTypes } from './construnction/types';
import { getConstruction } from './construnction/sagas';

import { StockTypes } from './stock/types';
import {
  listStock,
  listSystem,
  totalStock,
  productStock,
  saveProductStock,
  getOptions,
  listProducts,
} from './stock/sagas';

import { ReportTypes } from './report/types';
import {
  listSchedule,
  getOptionsReport,
  getReport,
  getResume,
  getLogs,
} from './report/sagas';

import {
  AddCustomerBudgetTypes,
  AddProductBudgetTypes,
  DeleteProductBudgetTypes,
  GetBudgetTypes,
  ListBudgetTypes,
  QuotationBudgetTypes,
  SituationBudgetTypes,
} from './budget/types';
import {
  addCustomerBudget,
  addProductBudget,
  deleteProduct,
  getBudget,
  listBudget,
  quotationBudgetCurrency,
  situationBudget,
} from './budget/sagas';

export default function* rootSaga(): any {
  return yield all([
    takeLatest(RepositoriesTypes.LOAD_REQUEST, load),
    takeLatest(ListTypes.LIST_REQUEST, listCurrency),
    takeLatest(ListCustomerTypes.LISTCUSTOMER_REQUEST, listCustomer),
    takeLatest(ListProductTypes.LISTPRODUCT_REQUEST, listProduct),
    takeLatest(ListBudgetTypes.LIST_BUDGET_REQUEST, listBudget),
    takeLatest(GetBudgetTypes.GET_BUDGET_REQUEST, getBudget),
    takeLatest(AddProductBudgetTypes.ADD_BUDGET_REQUEST, addProductBudget),
    takeLatest(
      DeleteProductBudgetTypes.DELETE_PRODUCT_BUDGET_REQUEST,
      deleteProduct,
    ),
    takeLatest(
      QuotationBudgetTypes.QUOTATION_BUDGET_REQUEST,
      quotationBudgetCurrency,
    ),
    takeLatest(SituationBudgetTypes.SITUATION_BUDGET_REQUEST, situationBudget),
    takeLatest(
      AddCustomerBudgetTypes.ADD_CUSTOMER_BUDGET_REQUEST,
      addCustomerBudget,
    ),
    takeLatest(GetConstructionTypes.GETCONSTRUCTION_REQUEST, getConstruction),
    takeLatest(NotificationTypes.LOAD_REQUEST, listNotifications),
    takeLatest(StockTypes.LIST_REQUEST, listStock),
    takeLatest(StockTypes.LIST_SYSTEM_REQUEST, listSystem),
    takeLatest(StockTypes.LIST_PRODUCTS_REQUEST, listProducts),
    takeLatest(StockTypes.TOTAL_STOCK_REQUEST, totalStock),
    takeLatest(StockTypes.PRODUCT_STOCK_REQUEST, productStock),
    takeLatest(StockTypes.SAVE_PRODUCT_STOCK_REQUEST, saveProductStock),
    takeLatest(StockTypes.OPTIONS_REQUEST, getOptions),
    takeLatest(ReportTypes.LIST_REQUEST, listSchedule),
    takeLatest(ReportTypes.OPTIONS_REQUEST, getOptionsReport),
    takeLatest(ReportTypes.REPORT_REQUEST, getReport),
    takeLatest(ReportTypes.RESUME_REQUEST, getResume),
    takeLatest(ReportTypes.LOGS_REQUEST, getLogs),
  ]);
}
