/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState } from 'react';
import { IconSearch } from '~/assets/Icons';

import { BtSearch, ListFile, Row } from './styles';

interface IItem {
  id: number;
  descricao: string;
  recebido: number | null;
  deletado: number;
  itemFromSystem: boolean;
  items?: IItems[];
}

interface IItems {
  id: number;
  produtoId?: number;
  sistemaId?: number;
  orcamentoId?: number;
  descricao: string;
  recebido: number | null;
  deletado: number;
  itemFromSystem: boolean;
}

interface IProps {
  product: IItem;
  permission: boolean;
  handleClick: (product: IItems | IItem) => void;
}

const Equipment: React.FC<IProps> = ({ product, permission, handleClick }) => {
  const [toggle, setToggle] = useState(false);

  const renderProducts = useMemo(() => {
    if (!toggle || !product.items?.length) return null;
    return product.items?.map(curr => (
      <ListFile itemFromSystem show={false} action={!!curr.recebido}>
        <Row>
          {curr.descricao}{' '}
          <span
            onClick={() => {
              if (!curr.recebido && permission) {
                handleClick(curr);
              }
            }}
          >
            {(curr.recebido === 1 && 'Recebido') ||
              (curr.recebido === 2 && 'Parcialmente') ||
              'A receber'}
          </span>
        </Row>
      </ListFile>
    ));
  }, [toggle, product, permission, handleClick]);

  return (
    <>
      <ListFile show={false} action={!!product.recebido}>
        <Row>
          {product.descricao}{' '}
          <span
            onClick={() => {
              if ([null, 2, 0].includes(product.recebido) && permission) {
                handleClick(product);
              }
            }}
          >
            {(product.recebido === 1 && 'Recebido') ||
              (product.recebido === 2 && 'Parcialmente') ||
              'A receber'}
          </span>
        </Row>
        {product.itemFromSystem ? (
          <BtSearch onClick={() => setToggle(previousState => !previousState)}>
            <IconSearch />
          </BtSearch>
        ) : null}
      </ListFile>
      {renderProducts}
    </>
  );
};

export default Equipment;
