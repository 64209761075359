/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';
import { Input, Loading, Page, PageHeader, Select } from '~/components';
import { generateRequiredInputValues, validateForm } from '~/utils/forms';

import imgDefault from '~/assets/Images/ImgDefault.svg';

import StockService from '~/services/StockService';
import { isEmpty } from '~/utils/validate';

import {
  Container,
  ContainerUpload,
  ContentUpload,
  CustomerImg,
  Content,
  RowInput,
} from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const DICT_SAVE: any = {
  cnpj: 'cnpj',
  name: 'nome',
  address: 'endereco',
  mail: 'email',
  phone: 'telefone',
};

const Vendor: React.FC = () => {
  const history = useHistory();
  const [imgUrl, setImgUrl] = useState('');
  const [buy] = useState(history.location.state?.buy);
  const [item] = useState(history.location.state?.item);
  const [newVendor] = useState(history.location.state?.newVendor || false);

  const [loading, setLoading] = useState(false);

  const [inputState, setInputState] = useState<typeInput>({
    ...generateRequiredInputValues([
      'cnpj',
      'name',
      'address',
      'mail',
      'phone',
    ]),
  });

  const onDrop = useCallback(acceptedFiles => {
    if (
      acceptedFiles[0].type === 'image/jpeg' ||
      acceptedFiles[0].type === 'image/png'
    ) {
      // setImgArray(acceptedFiles);
      getBase64(acceptedFiles[0], (imageUrl: any) => {
        setImgUrl(imageUrl);
      });
    }
  }, []);

  const getBase64 = (img: any, callback: any) => {
    if (img.type === 'image/jpeg' || img.type === 'image/png') {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  const content = () => {
    if (imgUrl) {
      return <CustomerImg src={imgUrl} alt="Foto do cliente" />;
    }
    return (
      <ContentUpload>
        <img src={imgDefault} alt="sem imagem" />
        <p>
          Clique aqui para adicionar uma imagem do seu computador ou arraste-a
          para esse campo.
        </p>
      </ContentUpload>
    );
  };

  const handleInputChange = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleInputBlur = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const saveVendor = async () => {
    setLoading(true);
    const data = Object.keys(inputState).reduce((acum: any, curr) => {
      acum[DICT_SAVE[curr]] = inputState[curr].value;
      return acum;
    }, {});
    try {
      const response = await StockService.saveVendor({
        ...data,
      });
      setLoading(false);
      history.push({
        pathname: '/stock',
        ...(newVendor
          ? {
              state: {
                backToModal: true,
                buy: { ...buy, vendor: response },
                item,
              },
            }
          : {}),
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState as any);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  return (
    <Page>
      <PageHeader
        title="Novo Fornecedor"
        button="Salvar"
        disabled={loading || !isFormValid()}
        onClick={saveVendor}
        permission={{ module: 'ESTOQUE DE PRODUTOS', permission: 'INCLUIR' }}
      />
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <ContainerUpload>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragReject && (
                <ContentUpload>
                  <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                </ContentUpload>
              )}
              {isDragActive ? (
                <ContentUpload>
                  <p>Solte os arquivos aqui ... </p>
                </ContentUpload>
              ) : (
                content()
              )}
            </div>
          </ContainerUpload>
          <Content>
            <RowInput>
              <Input
                label="CNPJ"
                maxLenght={70}
                error={inputState.cnpj.error}
                value={inputState.cnpj.value}
                onChange={e => handleInputChange(e.target.value, 'cnpj')}
                onBlur={e => handleInputBlur(e.target.value, 'cnpj')}
              />
              <Input
                label="Nome"
                maxLenght={70}
                error={inputState.name.error}
                value={inputState.name.value}
                onChange={e =>
                  handleInputChange(
                    e.target.value.replace(/[^a-zA-Zà-ūÀ-Ū ]/g, ''),
                    'name',
                  )
                }
                onBlur={e => handleInputBlur(e.target.value, 'name')}
              />
            </RowInput>
            <RowInput>
              <Input
                label="Endereço"
                maxLenght={70}
                error={inputState.address.error}
                value={inputState.address.value}
                onChange={e => handleInputChange(e.target.value, 'address')}
                onBlur={e => handleInputBlur(e.target.value, 'address')}
              />
            </RowInput>
            <RowInput>
              <Input
                label="Email"
                maxLenght={70}
                error={inputState.mail.error}
                value={inputState.mail.value}
                onChange={e => handleInputChange(e.target.value, 'mail')}
                onBlur={e => handleInputBlur(e.target.value, 'mail')}
              />
              <Input
                label="Telefone"
                maxLenght={70}
                error={inputState.phone.error}
                value={inputState.phone.value}
                onChange={e => handleInputChange(e.target.value, 'phone')}
                onBlur={e => handleInputBlur(e.target.value, 'phone')}
              />
            </RowInput>
          </Content>
        </Container>
      )}
    </Page>
  );
};

export default Vendor;
