import styled from 'styled-components';

export const Header = styled.div`
  margin: 3% 3% 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 30%;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const Search = styled.div`
  width: 30%;
`;

export const Icon = styled.span`
  position: absolute;
  right: 10px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  div + button {
    margin-left: 5px;
  }

  button + button {
    margin-left: 5px;
  }
`;
