import styled from 'styled-components';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

export const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 45px);
`;

export const Month = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.p`
  margin: 0;
  text-transform: capitalize;
`;

export const Day = styled.div<{ day?: boolean }>`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: ${({ day }) => (!day ? 'center' : 'flex-start')};
  justify-content: ${({ day }) => (!day ? 'center' : 'flex-start')};
  cursor: ${({ day }) => (day ? 'pointer' : 'initial')};
  ${({ day }) => (day ? 'border: 1px solid #000;' : '')}
  padding: 5px;
  position: relative;
`;

export const Left = styled(MdKeyboardArrowLeft)`
  cursor: pointer;
`;
export const Right = styled(MdKeyboardArrowRight)`
  cursor: pointer;
`;
