/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from 'typesafe-actions';
import {
  ListBudgetTypes,
  GetBudgetTypes,
  IResponseGet,
  IResponseList,
  AddProductBudgetTypes,
  DeleteProductBudgetTypes,
  AddCustomerBudgetTypes,
  SituationBudgetTypes,
  QuotationBudgetTypes,
} from './types';

export const getBudgetRequest = (id: string | undefined) =>
  action(GetBudgetTypes.GET_BUDGET_REQUEST, { id });
export const getBudgetSuccess = (data: IResponseGet) =>
  action(GetBudgetTypes.GET_BUDGET_SUCCCES, { data });
export const getBudgetFailure = () => action(GetBudgetTypes.GET_BUDGET_FAILURE);

export const listBudgetRequest = () =>
  action(ListBudgetTypes.LIST_BUDGET_REQUEST);
export const listBudgetSuccess = (data: IResponseList) =>
  action(ListBudgetTypes.LIST_BUDGET_SUCCCES, { data });
export const listBudgetFailure = () =>
  action(ListBudgetTypes.LIST_BUDGET_FAILURE);

export const addProductBudgetRequest = (
  id: string | undefined,
  product: any | undefined,
  data: IResponseGet | undefined,
  price: number | undefined,
) =>
  action(AddProductBudgetTypes.ADD_BUDGET_REQUEST, {
    id,
    product,
    data,
    price,
  });

export const addProductBudgetSuccess = (data: any) =>
  action(AddProductBudgetTypes.ADD_PRODUCT_BUDGET_SUCCCES, { data });
export const addProductBudgetFailure = () =>
  action(AddProductBudgetTypes.ADD_PRODUCT_BUDGET_FAILURE);

export const deleteProductBudgetRequest = (
  data: any | undefined,
  product: any | undefined,
) =>
  action(DeleteProductBudgetTypes.DELETE_PRODUCT_BUDGET_REQUEST, {
    data,
    product,
  });
export const deleteProductBudgetSuccess = (data: IResponseGet) =>
  action(DeleteProductBudgetTypes.DELETE_PRODUCT_BUDGET_SUCCCES, { data });
export const deleteProductBudgetFailure = () =>
  action(DeleteProductBudgetTypes.DELETE_PRODUCT_BUDGET_FAILURE);

export const customerBudgetRequest = (
  data: any | undefined,
  customer: any | undefined,
) =>
  action(AddCustomerBudgetTypes.ADD_CUSTOMER_BUDGET_REQUEST, {
    data,
    customer,
  });
export const customerBudgetSuccess = (data: IResponseGet) =>
  action(AddCustomerBudgetTypes.ADD_CUSTOMER_BUDGET_SUCCCES, { data });
export const customerBudgetFailure = () =>
  action(AddCustomerBudgetTypes.ADD_CUSTOMER_BUDGET_FAILURE);

export const situationBudgetRequest = (
  data: IResponseGet,
  situation: string,
  precoTotal?: number,
  adress?: object,
) =>
  action(SituationBudgetTypes.SITUATION_BUDGET_REQUEST, {
    data,
    situacao: situation,
    precoTotal,
    adress,
  });
export const situationBudgetSuccess = (data: IResponseGet) =>
  action(SituationBudgetTypes.SITUATION_BUDGET_SUCCCES, { data });
export const situationBudgetFailure = () =>
  action(SituationBudgetTypes.SITUATION_BUDGET_FAILURE);

export const quotationCurrencyBudgetRequest = (
  id: string | undefined,
  idCurrency: number | undefined,
  quotation: number | undefined,
) =>
  action(QuotationBudgetTypes.QUOTATION_BUDGET_REQUEST, {
    id,
    idCurrency,
    quotation,
  });
export const quotationBudgetSuccess = (data: IResponseGet) =>
  action(QuotationBudgetTypes.QUOTATION_BUDGET_SUCCCES, { data });
export const quotationBudgetFailure = () =>
  action(QuotationBudgetTypes.QUOTATION_BUDGET_FAILURE);
