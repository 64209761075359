/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';

import { Tooltip } from 'antd';
import { IconEdit, IconSearch } from '~/assets/Icons';
import { Button, Input, Page, Return, TablePage } from '~/components';
import ConstructionsService from '~/services/ConstructionsService';
import { CheckPermission } from '~/utils/checkPermission';
import { BtEdit } from '~/components/TableActions/styles';

import {
  DivList,
  Header,
  ListContent,
  Row,
  StatusContainer,
  StatusContent,
  Title,
} from './styles';

const ConstructionDiary: React.FC = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [permission, setPermission] = useState(
    !CheckPermission({
      permission: 'INCLUIR',
      module: 'DIÁRIO DE OBRAS',
    }),
  );

  const searchRequest = useCallback(async () => {
    const response = await ConstructionsService.listConstruction({
      cliente: searchInput,
    });
    setSearchList(response.result);
  }, [searchInput]);

  useEffect(() => {
    const loadList = async () => {
      setLoading(true);
      const response = await ConstructionsService.listConstruction({
        limit,
        page,
      });

      setDataSource(response.result);
      setPages(response.info.pages);
      setLoading(false);
    };

    loadList();
  }, [page, limit]);

  let typingTimer: any = null;
  const doneTypingInterval = 1000;

  const done = () => {
    searchRequest();
    setLoading(false);
  };

  const columns = [
    {
      title: 'Último RDO',
      dataIndex: 'data_atualizacao',
      key: 'data_atualizacao',
      render: (e: any) => {
        if (e === null) {
          return '';
        }
        return `${e && format(new Date(e), 'dd/MM/yyyy', { locale: pt })}`;
      },
    },
    {
      title: 'Última att. RDO',
      dataIndex: 'data_atualizacao',
      key: 'data_atualizacao',
      render: (e: any) => {
        if (e === null) {
          return '';
        }
        const time = formatDistance(parseISO(e), new Date(), {
          addSuffix: true,
          locale: pt,
        });
        return time;
      },
    },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      key: 'Customer',
      render: (e: { nome: string }) => e?.nome,
    },
    {
      title: 'Projeto',
      dataIndex: 'descricao',
      key: 'Project',
    },
    {
      title: 'Status',
      dataIndex: 'progressoTabs',
      key: 'progressoTabs',
      render: (e: any) => {
        return (
          <StatusContainer>
            <Tooltip placement="top" title="Gerenciamento da obra">
              <StatusContent state={!!e.gerenciamentoDaObra} />
            </Tooltip>
            <Tooltip placement="top" title="Projeto visual">
              <StatusContent state={!!e.projetoVisual} />
            </Tooltip>
            <Tooltip placement="top" title="Projeto técnico">
              <StatusContent state={!!e.projetoTecnico} />
            </Tooltip>
            <Tooltip placement="top" title="Quantitativo">
              <StatusContent state={!!e.quantitativo} />
            </Tooltip>
          </StatusContainer>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      width: '8vw',
      render: (e: any) => (
        <>
          <BtEdit
            locked={
              !CheckPermission({
                module: 'DIÁRIO DE OBRAS',
                permission: 'VISUALIZAR',
              })
            }
            disabled={
              !CheckPermission({
                module: 'DIÁRIO DE OBRAS',
                permission: 'VISUALIZAR',
              })
            }
            onClick={() => {
              history.push(`/construction/view/${e.id}`);
            }}
          >
            <IconEdit />
          </BtEdit>
        </>
      ),
    },
  ];

  return (
    <Page>
      <Return />
      <Header>
        <Title>Diário de Obras</Title>
        <Row>
          <Input
            icon={<IconSearch />}
            onChange={e => {
              setSearchInput(e.target.value);
              if (time) clearTimeout(time);
              if (e.target.value) {
                typingTimer = setTimeout(done, doneTypingInterval);
                setTime(typingTimer);
              }
            }}
          />
          {searchInput.length > 2 && (
            <DivList>
              {searchList.map((item: any) => (
                <ListContent
                  key={item.id}
                  disabled={permission}
                  onClick={() => {
                    history.push(`/construction/view/${item.id}`);
                  }}
                >
                  {item.cliente.nome}
                </ListContent>
              ))}
            </DivList>
          )}
          <Button
            locked={
              !CheckPermission({
                permission: 'INCLUIR',
                module: 'DIÁRIO DE OBRAS',
              })
            }
            disabled={
              !CheckPermission({
                permission: 'INCLUIR',
                module: 'DIÁRIO DE OBRAS',
              })
            }
            onClick={() => history.push('/construction/create')}
          >
            + Criar Diário de Obra
          </Button>
        </Row>
      </Header>

      <TablePage
        columns={columns}
        dataSource={dataSource}
        pagination={{
          showSizeChanger: false,
          pageSize: limit,
          total: pages * limit,
          onChange: e => {
            setPage(e);
          },
        }}
        rowKey="id"
      />
    </Page>
  );
};

export default ConstructionDiary;
