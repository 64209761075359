import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #30b4e5;
  border-radius: 6px;
  margin: 15px 0;
  width: 100%;
  p {
    margin: 10px;
    font-size: 10px;
    word-wrap: break-word;
    max-height: 2ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Header = styled.div`
  background: rgba(48, 180, 229, 0.07);
  color: #323232;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Date = styled.div`
  background: #c4c4c4;
  color: rgba(50, 50, 50, 0.6);
  margin: 10px 0;
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  padding: 6px;

  p {
    margin: 0;
  }
`;

export const Footer = styled.div`
  display: flex !important;
  justify-content: flex-end;
  margin: 10px 10px 0;
  button {
    text-decoration-line: underline;
    color: #30b4e5;
    font-size: 10px;
  }
`;
