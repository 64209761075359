import { api } from './api';

interface IListProducts {
  limit?: number;
  page?: number;
}

class ProductsService {
  async listProducts({ limit = 200, page = 1 }: IListProducts): Promise<any> {
    const response = await api.get(`/product-api/product`, {
      params: {
        limit,
        page,
      },
    });

    return response.data;
  }

  async getProductById(id: number): Promise<any> {
    const response = await api.get(`/product-api/product/${id}`);

    return response.data;
  }

  async getProductBySystem(idBudget: number, idSystem: number): Promise<any> {
    const response = await api.get(`/budget-api/budget/${idBudget}/items`, {
      params: { systemId: idSystem },
    });

    return response.data;
  }

  async updateProductSystem(
    idBudget: number,
    idProduct: number,
    idSystem: number,
    data: any,
  ): Promise<any> {
    const response = await api.put(
      `/budget-api/budget/${idBudget}/itemOfSystem/${idProduct}/${idSystem}`,
      data,
    );

    return response.data;
  }
}

export default new ProductsService();
