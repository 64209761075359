/* eslint-disable no-console */
/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import { useEffect, useState, useCallback } from 'react';

import { Input, Button as Btn, ModalAlertDefault } from '~/components';
import { IconDelete, IconReturn, IconSearch } from '~/assets/Icons';
import ImgDefault from '~/assets/Images/ImgDefault.svg';
import { changeMoney } from '~/utils/format/isFormatMoney';

import ProductsService from '~/services/ProductsService';
import {
  Container,
  Content,
  SpanInput,
  SpanTotal,
  Aling,
  InputNumber,
  BtContainer,
  BtTrash,
  BtSearch,
  Flex,
  ContainerDropDown,
  BtMove,
  Table,
  Tr,
  Td,
  Th,
  Button,
  BoxContentTitle,
  BoxInputNewValue,
} from './styles';

interface IProps {
  idBudget: any;
  product: any;
  disabled?: boolean;
  alterProduct?: any;
  alterProductOfSystem?: any;
  btTrashOnClick?: any;
  currency?: any;
  quotation?: any;
  position?: { index: number; length: number } | undefined;
  onChange?: null | ((e: number) => void);
  updateBudget?: () => void;
  idCurrency?: any;
}

const defaultProps = {
  disabled: false,
  alterProduct: '',
  alterProductOfSystem: () => ``,
  btTrashOnClick: '',
  currency: 'BRL',
  quotation: 1,
  position: undefined,
  onChange: null,
  idCurrency: null,
  updateBudget: undefined,
};

const ProductList: React.FC<IProps> = ({
  idBudget,
  product,
  disabled,
  btTrashOnClick,
  alterProduct,
  alterProductOfSystem,
  currency,
  quotation,
  position,
  onChange,
  idCurrency,
  updateBudget,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [unitaryValue, setUnitaryValue] = useState(0);
  const [valueNew, setValueNew] = useState(0);
  const [valueTotal, setValueTotal] = useState(0);
  const [dropDown, setDropDown] = useState(false);
  const [productSystem, setProductSystem] = useState<any[]>([]);
  const [productSystemDefault, setProductSystemDefault] = useState<any[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [disabledValueNew, setDisabledValueNew] = useState(true);
  const [isVisibleModalAlert, setIsVisibleModalAlert] = useState(false);

  useEffect(() => {
    setQuantity(product.quantidade);
    setUnitaryValue(product.preco);
    setValueNew(product.precoNovo);
  }, [product]);

  useEffect(() => {
    setValueTotal((quantity * valueNew) / quotation);
  }, [quantity, valueNew]);

  useEffect(() => {
    handleChange();
  }, [productSystem]);

  const calcTotalAmount = useCallback(() => {
    let totalAmount = 0;
    productSystem.forEach(item => {
      if (item.preco && item.quantidade) {
        totalAmount += item.quantidade * item.preco;
      }
    });
    setValueNew(totalAmount);
    return totalAmount;
  }, [productSystem]);

  const compareData = (): boolean => {
    let hasChanges = false;

    for (let index = 0; index < productSystem.length; index++) {
      const product = productSystem[index];
      const productDefault = productSystemDefault[index];

      const dataKeys = Object.keys(product);

      for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        if (product[key] !== productDefault[key] && key !== 'updatable') {
          hasChanges = true;
          break;
        }
      }

      if (hasChanges) break;
    }
    return hasChanges;
  };

  const getChangedProducts = (): number[] => {
    const ids = [];

    for (let index = 0; index < productSystem.length; index++) {
      const product = productSystem[index];
      const productDefault = productSystemDefault[index];

      const dataKeys = Object.keys(product);

      for (let index = 0; index < dataKeys.length; index++) {
        const key = dataKeys[index];
        if (product[key] !== productDefault[key] && key !== 'updatable') {
          ids.push(product.id);
          break;
        }
      }
    }
    return ids;
  };

  const handleChange = () => {
    if (hasChanges) {
      setHasChanges(compareData());
    }
  };

  const numberFormat = (e: number | null) => {
    const number = `${(e || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: currency || 'BRL',
    })}`;

    return number;
  };

  const alterQuantity = (e: any) => {
    switch (e) {
      case '+':
        setQuantity(currentValue => currentValue + 1);
        alterProduct({
          quantity: quantity + 1,
          price: valueNew,
          id: product.id,
        });
        break;
      case '-':
        if (quantity !== 1) {
          setQuantity(currentValue => currentValue - 1);
          alterProduct({
            quantity: quantity - 1,
            price: valueNew,
            id: product.id,
          });
        }
        break;

      default:
        break;
    }
  };

  const alterPrice = (e: number, blur: boolean) => {
    if (!Number.isNaN(e)) {
      setValueNew(e);
    }

    if (blur) alterProduct({ quantity, price: e, id: product.id });
  };

  let indexImg = product?.imagens.findIndex((img: any) => img.default === 1);
  if (indexImg === -1)
    indexImg =
      indexImg === -1 && product?.imagens.length
        ? product?.imagens.length - 1
        : -1;

  const SearchSystem = async () => {
    setDropDown(!dropDown);
    const response = await ProductsService.getProductBySystem(
      idBudget,
      product.id,
    );
    const productSystem = [...response.items].map((curr: any) => ({
      ...curr,
      updatable: false,
    }));
    const productSystemDefault = [...response.items].map((curr: any) => ({
      ...curr,
    }));
    setProductSystem([...productSystem]);
    setProductSystemDefault([...productSystemDefault]);
  };

  const handleChangeValue = (
    id: number,
    value: any,
    param: string,
    price = 0,
  ) => {
    const data = [...productSystem];
    const productBudget = data.find((curr: any) => curr.id === id);
    if (productBudget) {
      if (param === 'updatable' && !value) {
        const dataDefault = [...productSystemDefault];
        const productBudgetDefault = dataDefault.find(
          (curr: any) => curr.id === id,
        );
        Object.keys(productBudgetDefault).forEach(curr => {
          productBudget[curr] = productBudgetDefault[curr];
        }, {});
        productBudget[param] = value;
      } else {
        productBudget[param] = value;
      }
    }

    if (param === 'quantidade') {
      setDisabledValueNew(true);
      alterProductOfSystem({
        quantity: value,
        price: price / quotation,
        id: productBudget.id,
        idSystem: product.id,
      });
    }
    setProductSystem(data);
    if (param !== 'updatable') calcTotalAmount();
    if (!hasChanges && param !== 'updatable') setHasChanges(true);
  };

  const saveChanges = async () => {
    setLoading(true);
    try {
      const changedProducts = getChangedProducts();
      const promises = productSystem
        .filter(curr => changedProducts.includes(curr.id))
        .map(curr => {
          return ProductsService.updateProductSystem(
            idBudget,
            curr.id,
            product.id,
            {
              preco: curr.preco,
              cotacao: quotation,
              moedaId: idCurrency,
              quantidade: curr.quantidade,
            },
          );
        });
      await Promise.all(promises);
      setHasChanges(false);
      const data = [...productSystem].slice();
      setProductSystemDefault(data);
      if (updateBudget) updateBudget();
    } catch (error) {
      console.log({ error });
    }
    setLoading(false);
  };

  return (
    <>
      {isVisibleModalAlert && (
        <ModalAlertDefault
          title="Atenção!"
          content="Ao customizar este valor, você ignora a soma dos produtos abaixo!"
          onClick={() => setIsVisibleModalAlert(false)}
        />
      )}
      <Flex key={product.id}>
        <Container>
          {position !== undefined && (
            <BtContainer>
              <BtMove
                display={position!.index === 0}
                rotate="90"
                onClick={() => {
                  if (onChange) onChange(position!.index! - 1);
                }}
              >
                <IconReturn />
              </BtMove>

              <BtMove
                display={position!.index === position!.length! - 1}
                rotate="270"
                onClick={() => {
                  if (onChange) onChange(position!.index! + 1);
                }}
              >
                <IconReturn />
              </BtMove>
            </BtContainer>
          )}
          <Aling>
            <img
              alt="Imagem do produto"
              src={
                product?.imagens[indexImg]
                  ? product?.imagens[indexImg].fileUrl
                  : ImgDefault
              }
            />
            <Content>
              <BoxContentTitle>
                <p>
                  <strong>{product.descricao}</strong>
                </p>
              </BoxContentTitle>
              <BoxContentTitle>
                <p>
                  <strong>Valor unitário: </strong>
                  {unitaryValue.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
              </BoxContentTitle>
              <BoxInputNewValue
                disabled={false}
                onClick={() => {
                  if (
                    disabledValueNew &&
                    (product?.categoria?.id === 2 || product?.categoria === 2)
                  ) {
                    setIsVisibleModalAlert(true);
                    setDisabledValueNew(false);
                  }
                }}
              >
                <div>
                  <p>
                    <strong>Novo valor:</strong>
                  </p>
                </div>
                <div>
                  <Input
                    value={valueNew.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                    disabled={
                      disabledValueNew &&
                      (product?.categoria?.id === 2 || product?.categoria === 2)
                    }
                    onChange={e => {
                      alterPrice(changeMoney(e.target.value), false);
                    }}
                    onBlur={e => {
                      alterPrice(changeMoney(e.target.value), true);
                    }}
                  />
                </div>
              </BoxInputNewValue>
              {currency !== `BRL` && (
                <BoxContentTitle>
                  <p>
                    <strong> Valor com cotação do dia: </strong>
                    {numberFormat(valueNew / quotation)}
                  </p>
                </BoxContentTitle>
              )}
            </Content>
          </Aling>
          <Content>
            <SpanInput disabled={disabled}>
              Quantidade
              <InputNumber disabled={disabled}>
                <button
                  type="button"
                  onClick={() => alterQuantity('-')}
                  disabled={disabled}
                >
                  -
                </button>
                <input
                  value={quantity}
                  onChange={e =>
                    setQuantity(
                      parseFloat(e.target.value.replace(/[^\d]+/g, '')),
                    )
                  }
                  onBlur={e =>
                    alterProduct({
                      quantity: e.target.value,
                      price: valueNew,
                      id: product.id,
                    })
                  }
                />
                <button
                  type="button"
                  onClick={() => alterQuantity('+')}
                  disabled={disabled}
                >
                  +
                </button>
              </InputNumber>
            </SpanInput>
            <SpanTotal disabled={disabled}>
              Total
              <p>{numberFormat(valueTotal)}</p>
            </SpanTotal>
          </Content>
          <BtContainer>
            <BtTrash disabled={disabled} onClick={btTrashOnClick}>
              <IconDelete />
            </BtTrash>
            <BtSearch
              display={product?.categoria?.id === 2 || product?.categoria === 2}
              onClick={() => SearchSystem()}
            >
              <IconSearch />
            </BtSearch>
          </BtContainer>
        </Container>
      </Flex>
      {dropDown && (
        <ContainerDropDown>
          <Table>
            <Tr>
              <Th>
                <></>
              </Th>
              <Th>Descrição</Th>
              <Th>Quantidade</Th>
              {updateBudget ? <Th>Preço do produto</Th> : null}
              <Th>Preço com cotação</Th>
              {updateBudget ? <Th>Preço customizado</Th> : null}
            </Tr>
            {productSystem.map((product: any) => {
              let indexImg = product?.imagens.findIndex(
                (img: any) => img.default === 1,
              );
              if (indexImg === -1)
                indexImg =
                  indexImg === -1 && product?.imagens.length
                    ? product?.imagens.length - 1
                    : -1;
              return (
                <Tr key={product.id}>
                  <Td>
                    <img
                      alt=" "
                      src={product?.imagens?.[indexImg]?.fileUrl || ImgDefault}
                    />
                  </Td>
                  <Td>{product?.descricao}</Td>
                  <Td disabled={!product.updatable}>
                    {updateBudget ? (
                      <div>
                        <Button
                          type="button"
                          onClick={() =>
                            handleChangeValue(
                              product.id,
                              product.quantidade - 1,
                              'quantidade',
                              product.preco,
                            )
                          }
                          disabled={
                            !product.updatable || product.quantidade === 0
                          }
                        >
                          -
                        </Button>
                        <input
                          value={product?.quantidade}
                          onChange={e =>
                            handleChangeValue(
                              product.id,
                              parseFloat(e.target.value),
                              'quantidade',
                            )
                          }
                          onBlur={e =>
                            alterProduct({
                              quantity: parseFloat(e.target.value),
                              price: valueNew,
                              id: product.id,
                            })
                          }
                          style={{ textAlign: 'center' }}
                        />
                        <Button
                          type="button"
                          onClick={() =>
                            handleChangeValue(
                              product.id,
                              product.quantidade + 1,
                              'quantidade',
                              product.preco,
                            )
                          }
                          disabled={
                            !product.updatable
                            // ||
                            // product?.estoque?.estoqueAtual === product.quantidade
                          }
                        >
                          +
                        </Button>
                      </div>
                    ) : (
                      product?.quantidade
                    )}
                  </Td>
                  {updateBudget ? (
                    <Td>
                      <input
                        value={product?.preco.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        disabled={!product.updatable}
                        onChange={e => {
                          handleChangeValue(
                            product.id,
                            changeMoney(e.target.value),
                            'preco',
                          );
                        }}
                        onBlur={e => {
                          handleChangeValue(
                            product.id,
                            changeMoney(e.target.value),
                            'preco',
                          );
                        }}
                      />
                    </Td>
                  ) : null}
                  <Td>{numberFormat(product?.preco / quotation)}</Td>
                  {updateBudget ? (
                    <Td>
                      <input
                        type="checkbox"
                        checked={product.updatable}
                        onChange={event =>
                          handleChangeValue(
                            product.id,
                            event.target.checked,
                            'updatable',
                          )
                        }
                      />
                    </Td>
                  ) : null}
                </Tr>
              );
            })}
          </Table>
          <Btn
            disabled={
              !hasChanges ||
              !productSystem.find((curr: any) => curr.updatable) ||
              loading
            }
            onClick={saveChanges}
          >
            {loading ? 'Salvando alterações' : 'Confirmar alterações'}
          </Btn>
        </ContainerDropDown>
      )}
    </>
  );
};

ProductList.defaultProps = defaultProps;

export default ProductList;
