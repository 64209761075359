/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { IconClose } from '~/assets/Icons';
import { Modal } from '~/components';
import { IState } from '~/store/modules/types';
import { useSelector } from 'react-redux';

import { Header } from './styles';
import Item from './Item';

interface IProps {
  onClick: () => void;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  client: number | null;
  setClient: (value: number | null) => void;
}

const ModalScheduling: React.FC<IProps> = ({ onClick, ...props }: IProps) => {
  const { resume } = useSelector((state: IState) => state.report);

  return (
    <Modal width="60vw">
      <div>
        <Header>
          <div />
          <button type="button" onClick={onClick}>
            <IconClose />
          </button>
        </Header>
        <div style={{ flex: 1, maxHeight: '80vh', overflow: 'scroll' }}>
          {resume.finishingConstructions.map(item => (
            <Item
              id={item.id}
              cliente={item.cliente}
              descricao={item.descricao}
              onClick={onClick}
              {...props}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ModalScheduling;
