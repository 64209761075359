import { action } from 'typesafe-actions';
import { GetConstructionTypes, IResponse } from './types';

export const getConstructionRequest = (id: string | number): any =>
  action(GetConstructionTypes.GETCONSTRUCTION_REQUEST, { id });

export const getConstructionSuccess = (data: IResponse): any =>
  action(GetConstructionTypes.GETCONSTRUCTION_SUCCCES, { data });

export const getConstructionFailure = (): any =>
  action(GetConstructionTypes.GETCONSTRUCTION_FAILURE);
