/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useSelector } from 'react-redux';

import { Select, TablePage } from '~/components';
import { IAtividade } from '~/store/modules/report/types';

import { IState } from '~/store/modules/types';
import { Container } from './styles';

interface IProps {
  activities: IAtividade[];
  onClick: (name: 'period' | 'condition', id: number, value: any) => any;
}

const Activites: React.FC<IProps> = ({ activities, onClick }) => {
  const { condition, period } = useSelector((state: IState) => state.report);

  const columns = [
    {
      title: '',
      key: 'id',
      dataIndex: 'atividadeId',
    },
    {
      title: 'Descrição da Atividade',
      dataIndex: 'atividadeDescricao',
      key: 'atividadeDescricao',
    },
    {
      title: 'Período',
      key: 'periodoDescricao',
      render: (data: any) => (
        <Select
          lista={period}
          currentValue={data.periodoDescricao}
          onClickList={value => {
            onClick('period', data.id, value);
          }}
        />
      ),
    },
    {
      title: 'Condição',
      key: 'condicaoDescricao',
      render: (data: any) => (
        <Select
          lista={condition}
          currentValue={data.condicaoDescricao}
          onClickList={value => {
            onClick('condition', data.id, value);
          }}
        />
      ),
    },
  ];

  return (
    <Container>
      <TablePage dataSource={activities} columns={columns} />
    </Container>
  );
};

export default Activites;
