import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 25px 20px;

  span {
    margin-top: 26px;
    font-size: 24px;
    font-weight: 700;
    color: #323232;
  }

  button {
    height: min-content;
    color: #30b4e5;
    margin: 5px 15px;
  }
`;
export const Container = styled.div`
  margin: 10px 0px 45px 0px;
`;

export const ContainerList = styled.div`
  max-height: 45vh;
  overflow: scroll;
`;

export const ContentList = styled.div`
  border: 1px solid #30b4e5;
  background: #fff;
  border-radius: 5px;
  margin: 10px 12px;
`;

export const BodyList = styled('div')<{ state: boolean }>`
  display: none;
  padding: 15px 10px;
  font-size: 11px;
  color: #898a8d;
  /* width: 100px; */
  p {
    width: 100%;
    margin: 0;
    word-wrap: break-word;
  }
  ${props => props.state && 'display: flex'}
`;

export const HeaderList = styled.div`
  background: rgba(48, 180, 229, 0.17);
  cursor: pointer;
  /* border: solid #30b4e5; */
  border-radius: 5px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0;
    font-weight: 700;
  }
`;
