/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconAdd } from '~/assets/Icons';

import {
  Input,
  InputSearch,
  Loading,
  ModalDefault,
  Page,
  PageHeader,
} from '~/components';

import BudgetsService from '~/services/BudgetsService';
import ConstructionsService from '~/services/ConstructionsService';
import { useUrlParams } from '~/utils';
import Form from './components/form';
import {
  ButtonAdd,
  Container,
  ContainerBox,
  Content,
  DivAdd,
  InputBox,
} from './styles';

const DiaryCreate: React.FC = () => {
  const history = useHistory();
  const queryParams = useUrlParams();
  const [listBudget, setListBudget] = useState([]);
  const [inputBudget, setInputBudget] = useState('');
  const [currentBudget, setCurrentBudget] = useState<any>({
    id: 0,
    descricao: '',
    cliente: { nome: '' },
    endereco: {
      cep: '',
      logradouro: '',
      numero: 0,
      bairro: '',
      cidade: '',
      estado: '',
      complemento: '',
    },
  });
  // const [customerType, setCustomerType] = useState(true);
  const [pageTypeEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isVisibleList, setIsVisibleList] = useState(false);
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);

  const [nameProject, setNameProject] = useState('');
  const [erroProject, setErroProject] = useState('');
  const [inputState, setInputState] = useState<any>([]);
  const [formValid, setFormValid] = useState(false);
  const [formValue] = useState<any>([]);
  const [formErro] = useState<any>([]);

  useEffect(() => {
    const loadBudget = async () => {
      if (queryParams.budget) {
        try {
          const response = await BudgetsService.getBudgetById(
            queryParams.budget,
          );
          setInputState([response]);
        } catch {
          console.log('erro');
        }
      }
    };
    loadBudget();
  }, []);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const response = await BudgetsService.listBudgetsAproved({
        limit: 200,
        page: 1,
        aproved: 1,
      });
      setListBudget(response.result);
      setLoading(false);
    };
    load();
  }, []);

  const isFormValid = () => {
    setFormValid(formErro.every((element: boolean) => element === true));
  };

  const toSave = async () => {
    try {
      setLoading(true);
      await formValue.forEach((element: any, index: any) => {
        Object.entries(element).forEach(allInput => {
          if (allInput[0] === 'descricao') {
            formValue[index][allInput[0]] = nameProject;
          }
        });
      });
      await ConstructionsService.createConstruction(formValue);
      setLoading(false);

      setIsVisibleModalSuccess(true);
    } catch {
      setLoading(false);

      setIsVisibleModalError(true);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalSuccess && (
        <ModalDefault
          success
          onClick={() => {
            setIsVisibleModalSuccess(false);
            history.push('/construction');
          }}
        >
          {pageTypeEdit
            ? 'Cadastro de obra editado com sucesso!'
            : 'Cadastro de obra criado com sucesso!'}
        </ModalDefault>
      )}
      {isVisibleModalError && (
        <ModalDefault
          success={false}
          onClick={() => {
            setIsVisibleModalError(false);
          }}
        >
          {pageTypeEdit
            ? 'Não foi possivel editar o cadastro de obra'
            : 'Não foi possivel criar o cadastro de obra'}
        </ModalDefault>
      )}
      <Page>
        <PageHeader
          title="Diário de Obras"
          button="Continuar"
          disabled={!formValid}
          onClick={() => {
            toSave();
          }}
          permission={{ module: 'DIÁRIO DE OBRAS', permission: 'INCLUIR' }}
        />
        <Container>
          <Content>
            <ContainerBox>
              <InputBox>
                <InputSearch
                  currentValue={currentBudget.descricao}
                  lista={listBudget.filter((list: any) => {
                    if (inputState[0]) {
                      return !inputState.find((e: any) => e.id === list.id);
                    }
                    return list;
                  })}
                  onClickList={(e: any) => {
                    setIsVisibleList(false);
                    setCurrentBudget(e);
                    setInputBudget(e.id);
                  }}
                  label="Número do Orçamento"
                  requiredLabel
                  valueInput={inputBudget}
                  value={inputBudget}
                  onChange={e => setInputBudget(e.target.value)}
                  // error={inputState.budget.error}
                  // onBlur={e => handleInput(e.target.value, 'budget')}
                  nameObject="id"
                  lengthShow={0}
                  onClick={() => setIsVisibleList(!isVisibleList)}
                  visibleList={isVisibleList}
                  setVisibleList={() => setIsVisibleList(false)}
                  setCleanInput={() => {
                    // if (
                    //   inputState.budget.value.toString() !==
                    //   currentBudget.id.toString()
                    // ) {
                    //   // handleInput('', 'budget');
                    // }
                  }}
                />
              </InputBox>
              <DivAdd>
                <ButtonAdd
                  onClick={() => {
                    if (currentBudget.id !== 0) {
                      setInputState((prevState: any) => {
                        return [...prevState, currentBudget];
                      });
                      setInputBudget('');
                      setCurrentBudget({ id: 0 });
                      if (inputState.length === 0) {
                        setNameProject(currentBudget.descricao);
                      }
                    }
                  }}
                >
                  <IconAdd />
                  <u>Adicionar orçamento</u>
                </ButtonAdd>
              </DivAdd>
            </ContainerBox>
            <ContainerBox>
              <InputBox>
                <Input
                  label="Nome do Projeto"
                  requiredLabel
                  value={nameProject}
                  onChange={e => {
                    setNameProject(e.target.value);
                    if (e.target.value.length < 1) {
                      setErroProject('Campo obrigatório');
                    }
                    if (e.target.value.length > 0) {
                      setErroProject('');
                    }
                  }}
                  error={erroProject}
                />
              </InputBox>
              <InputBox />
            </ContainerBox>
            {inputState.map((element: any, index: number) => {
              return (
                <Form
                  description={nameProject}
                  key={element.id}
                  budget={element}
                  setValue={(e, erro) => {
                    formValue.push(e);
                    formErro.push(erro);
                  }}
                  onChange={(e, erro) => {
                    formValue[index] = e;
                    formErro[index] = erro;
                    isFormValid();
                  }}
                />
              );
            })}
          </Content>
        </Container>
      </Page>
    </>
  );
};

export default DiaryCreate;
