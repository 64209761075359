import { api } from './api';

interface ISendEmail {
  email: string;
}

interface IRecoveryPassword {
  token: string;
  password: string;
  passwordConfirmation: string;
}

interface IRedefinePassword {
  lastPassword: string;
  password: string;
  passwordConfirmation: string;
}

class RecoveryService {
  async sendTokenForEmail({ email }: ISendEmail): Promise<any> {
    const response = await api.post(`/user-api/user/sendRecoveryEmail`, {
      email,
    });

    return response.data;
  }

  async recoveryPassword({
    token,
    password,
    passwordConfirmation,
  }: IRecoveryPassword): Promise<any> {
    const response = await api.post(`/user-api/user/resetPassword`, {
      token,
      password,
      passwordConfirmation,
    });

    return response.data;
  }

  async redefinePassword({
    lastPassword,
    password,
    passwordConfirmation,
  }: IRedefinePassword): Promise<any> {
    const response = await api.post(`/user-api/user/changePassword`, {
      lastPassword,
      password,
      passwordConfirmation,
    });

    return response.data;
  }
}

export default new RecoveryService();
