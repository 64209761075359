/* eslint-disable no-console */
import React from 'react';
import { Page, TablePage, PageHeader, TableActions } from '~/components';
import VisibleForPermissions from '~/components/VisibleForPermission';
import RevisorJson from '~/mocks/Revisor.json';
import { Content } from './styles';

const Revisores: React.FC = () => {
  const columns = [
    {
      title: 'Remetente',
      dataIndex: 'remetente',
    },
    {
      title: 'Assunto',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.length - b.name.length,
      ellipsis: true,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
    },
    {
      title: 'Ultima Alteração',
      dataIndex: 'time',
    },
    {
      title: 'Ações',
      key: 'action',
      render: () => (
        <TableActions
          functionEdit={() => console.log(1)}
          functionTrash={() => console.log(1)}
        />
      ),
    },
  ];

  return (
    <Page>
      <PageHeader
        title="Revisores"
        button="adicionar revisor"
        onClick
        permission={{ module: 'REVISORES', permission: 'INCLUSAO' }}
      />
      <VisibleForPermissions modules="REVISORES" permissions="VISUALIZACAO">
        <Content>
          <TablePage columns={columns} dataSource={RevisorJson} />
        </Content>
      </VisibleForPermissions>
    </Page>
  );
};

export default Revisores;
