import { IconLogoWD } from '~/assets/Icons';
import { Container, Img, LoadingCircle } from './styles';

const Loading: React.FC = () => {
  return (
    <Container>
      <LoadingCircle />
      <Img>
        <IconLogoWD />
      </Img>
    </Container>
  );
};

export default Loading;
