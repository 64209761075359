import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Collum = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 3%;

  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 3%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  p {
    font-weight: 700;
    color: #000000;
    padding: 6px 0;
    margin: 0;
  }
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  margin: 20px 0;
`;

export const InputZone = styled.textarea`
  width: 100%;
  border: 1px solid #30b4e5;
  border-radius: 5px;
  padding: 5px;
`;

export const ListContent = styled.a`
  display: flex;
  padding: 10px;
  color: #323232;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
`;

export const DivList = styled.div`
  margin-top: 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  position: absolute;
  width: 65%;
  background: #ffffff;
  z-index: 900;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  a + a {
    border-top: 1px solid #30b4e5;
  }
`;

export const Row = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 30%;
    min-width: 120px;
  }
`;

export const Subtitle = styled.div`
  font-weight: 700;
  font-size: 20px;
  padding-top: 16px;
`;

export const ContainerBox = styled.div`
  display: flex;
  input,
  label,
  p {
    margin: 0 10px;
  }

  margin: 0 -10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InputBox = styled.div`
  width: 100%;
`;
