import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import Logo from '~/assets/Logotipo/water-design-logo-grande.png';
import { Button, Input, ModalDefault } from '~/components';
import { generateRequiredInputValues, validateForm } from '~/utils/forms';
import { isEmpty } from '~/utils/validate';
import RecoveryService from '~/services/RecoveryService';
import { Container, Content, Header, Title, Text } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const RecoveryPassword: React.FC = () => {
  const stateSchema = {
    ...generateRequiredInputValues(['token', 'password', 'confirmPassword']),
  };
  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const history = useHistory();

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'password' && value.length < 6) {
      error = '*Digite no mínimo 6 caracteres';
      isValid = false;
    }

    if (
      inputName === 'confirmPassword' &&
      inputState.password.value !== value
    ) {
      error = '*Senhas diferentes';
      isValid = false;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = '*Campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const loger = async () => {
    await RecoveryService.recoveryPassword({
      token: inputState.token.value,
      password: inputState.password.value,
      passwordConfirmation: inputState.confirmPassword.value,
    });
    setIsVisibleModalSuccess(true);
  };

  return (
    <>
      {isVisibleModalSuccess && (
        <ModalDefault success onClick={() => history.push('/')}>
          Senha trocada com sucesso!
        </ModalDefault>
      )}
      <Header>
        <img src={Logo} alt="Logo-Waterdesing" />
        <hr />
      </Header>
      <Container>
        <Content>
          <Title>Redefinição de Senha</Title>
          <div>
            <Text>Insira nos campos abaixo sua nova senha.</Text>
            {/* <Auth state={false}> */}
            <Input
              name="token"
              label="Token:"
              id="inputToken"
              onChange={e => handleInput(e.target.value.trim(), 'token')}
              error={inputState.token.error}
            />
            <Input
              name="password"
              label="Senha:"
              id="inputPassword"
              type="password"
              onChange={e => handleInput(e.target.value, 'password')}
              error={inputState.password.error}
            />
            <Input
              name="ConfirmPassword"
              label="Confirmação de Senha:"
              id="inputConfirmPassword"
              type="password"
              onChange={e => handleInput(e.target.value, 'confirmPassword')}
              error={inputState.confirmPassword.error}
            />
            {/* </Auth> */}
            <Button disabled={!isFormValid()} onClick={() => loger()}>
              Salvar
            </Button>
          </div>
        </Content>
      </Container>
    </>
  );
};

export default RecoveryPassword;
