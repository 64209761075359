import { action } from 'typesafe-actions';
import { ListProductTypes, IResponse } from './types';

export const listProductRequest = (): any =>
  action(ListProductTypes.LISTPRODUCT_REQUEST);

export const listProductSuccess = (data: IResponse): any =>
  action(ListProductTypes.LISTPRODUCT_SUCCCES, { data });

export const listProductFailure = (): any =>
  action(ListProductTypes.LISTPRODUCT_FAILURE);
