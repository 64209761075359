import React from 'react';
import { useBudgetRequest } from '~/services/request';
import { IconBudget } from '~/assets/Icons';
import { Button } from '~/components';
import { Container } from './styles';

const BudgetNone: React.FC = () => {
  const { createBudget } = useBudgetRequest();
  return (
    <Container>
      <p>
        Opa, parece que você ainda não possui nenhuma proposta de orçamento!
      </p>
      <IconBudget />
      <p>
        Clique aqui para criar um <b>novo orçamento:</b>
      </p>
      <Button onClick={createBudget}>Criar Orçamento</Button>
    </Container>
  );
};

export default BudgetNone;
