import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 25%;
  height: 100%;
  flex-direction: column;

  box-shadow: 0px 1px 4px 0px #00000047;

  padding: 5px;
`;

export const Content = styled.div`
  width: 98%;
  margin: 1%;
  padding: 2%;
  box-shadow: 0px 1px 4px 0px #00000047;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  p {
    margin 0
  }
  svg {
    height: 20px;
    margin-right: 10px;
  }
  div{
    display: flex;
    align-items: center;
  }
`;

export const Header = styled.div`
  /* height: 0; */
  display: flex;
  justify-content: space-between;

  svg {
    width: 20px;
    color: #30b4e5;
  }
  button {
    height: fit-content;
  }
`;

export const Scroll = styled.div`
  height: calc(100% - 50px);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
