/* eslint-disable no-plusplus */
import { call, put } from 'redux-saga/effects';
import NotificationsService from '~/services/NotificationsService';

import { loadSuccess, loadFailure } from './actions';
import { INotification, IPropsNotificationRequest } from './types';

export function* listNotifications(action: IPropsNotificationRequest): any {
  try {
    const dataNotification = async () => {
      const notifications = await NotificationsService.listNotifications({
        id: action.payload.user_id,
        limit: 4,
        page: action.payload.page,
      });
      return notifications;
    };

    let unread = 0;

    const response = yield call(dataNotification);

    response.resut.forEach((notification: INotification) => {
      if (!notification.status) ++unread;
    });

    const notifications = action.payload.state.concat(response.resut);

    yield put(loadSuccess(notifications, unread, response.info.pages));
  } catch (err) {
    yield put(loadFailure());
  }
}
