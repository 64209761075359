import { Reducer } from 'redux';
import { NotificationTypes, INotificationsState } from './types';

const INITIAL_STATE: INotificationsState = {
  notifications: [],
  unread: 0,
  loading: false,
  pages: 1,
};

const reducer: Reducer<INotificationsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case NotificationTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case NotificationTypes.LOAD_SUCCCES:
      return {
        ...state,
        notifications: action.payload.notifications,
        unread: action.payload.unread,
        pages: action.payload.pages,
      };
    case NotificationTypes.LOAD_FAILURE:
      return {
        ...state,
        notifications: [],
        unred: 0,
      };

    default:
      return state;
  }
};

export default reducer;
