import styled from 'styled-components';

export const Title = styled.span`
  display: flex;
  align-items: center;
  font-size: clamp(20px, 2vw + 0.1rem, 30px);
  font-weight: 700;
`;

export const SubTitle = styled.span`
  padding-top: 20px;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
`;

export const Container = styled.div`
  width: 80%;
  margin: 3%;
  h1 {
    margin: 20px 0;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    margin-bottom: 80px;
    width: 100%;
  }
`;

export const Row = styled('span')<{ aling?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${props => props.aling && 'align-items: center;'}
`;

export const ButtonCustomerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: 12px;
  p {
    margin: 0;
    align-self: flex-start;
  }
  b {
    font-weight: 700;
    overflow: hidden;
    max-width: 16ch;
    text-overflow: ellipsis;
  }
  div {
    margin-right: 10px;
    /* max-height: 80%;
    height: 3vh;
    width: 3vh; */
  }
  svg {
    width: 80%;
    height: auto;
  }
`;

export const Content = styled.div`
  margin-top: 3%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  b {
    font-size: 20px;
  }

  svg {
    color: #30b4e5;
  }

  span {
    width: 75%;
    font-size: 12px;
    color: #323232;
  }
  p {
    text-align: right;
    width: 25%;
    font-size: 12px;
    color: #274f13;
  }
`;

export const ModalAdd = styled.div`
  padding: 20px;

  button {
    width: min-content;
    height: 45px;
  }

  svg {
    color: ${({ theme }) => theme.Colors.Gray};
    margin-right: 2%;
  }
`;

export const ScrollDiv = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  overflow: scroll;
  max-height: 200px;
  margin: 2vh 0;

  @media (max-width: 480px) {
    max-height: 400px;
  }
`;

export const Buttons = styled.span`
  width: 30%;
  min-width: 170px;

  button {
    padding: 25px 15px;
  }

  @media (max-width: 768px) {
    width: 40%;
  }
`;

export const Client = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 31%;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  padding: 10px 15px;
  margin: 1%;
  cursor: pointer;
  font-size: 12px;

  svg {
    color: #30b4e5;
  }

  div {
    min-height: 5vh;
    height: 5vh;
    min-width: 5vh;
    width: 5vh;
    margin-right: 15px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
  p {
    margin-bottom: 0px;
    font-size: 10px;
  }
`;

export const RowCurrency = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  button {
    margin-top: clamp(26px, 1vw + 0.1rem + 15px, 31px);
    padding: 22.5px 0;
    border-radius: 5px;
    background-color: #54565a;

    div {
      width: 100%;
      padding: 5px 15px;

      div {
        padding: 0;
      }
    }
  }

  div {
    width: 65%;
  }

  b {
    align-self: flex-start;
  }
`;
export const IconCurrancy = styled.span`
  font-weight: 700;
  font-size: 16px;
  margin-right: 1vw;
`;

export const CollumSmall = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 9px;
`;

export const TitleModal = styled('div')<{ right?: boolean; marginB?: boolean }>`
  b {
    font-size: 20px;
  }

  svg {
    color: #30b4e5;
  }
  display: flex;
  ${props => props.right && 'justify-content: right; margin-bottom: -20px;'}
  ${props =>
    props.marginB && 'margin-bottom: clamp(27px, 1vw + 0.1rem + 15px, 31px);'}
`;
export const Collum = styled.span`
  display: flex;
  flex-direction: column;

  margin: 0 5px;
`;

export const ModalCurrencyContainer = styled.div`
  display: flex;
  flex-direction: column;

  i {
    margin-top: 25px;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: -20px;
    color: ${({ theme }) => theme.Colors.Red};

    b {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  button {
    align-self: center;
    margin-top: 15px;
    width: 80%;
  }
`;

export const Line = styled.hr`
  margin-top: 3%;
  border-top-color: transparent !important;
  border: 1px solid rgba(196, 196, 196, 0.5);
`;

export const AlingBaseLine = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1vh;

  span {
    width: 60%;
    font-size: 12px;
    color: #323232;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;

    span {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }
`;

export const CloseButton = styled.button`
  @media (max-width: 480px) {
    display: none;
  }
`;

export const ReturnButton = styled.button`
  @media (min-width: 480px) {
    display: none;
  }
`;

export const Value = styled.span`
  b {
    color: #cf2a28;
  }
  p {
    b {
      color: #274f13;
    }
    margin: 0;
  }
`;

export const TagA = styled.a`
  span {
    font-size: 12px;
    color: #323232;
  }

  img {
    width: 34px;
    height: 34px;
    border: 1px solid #30b4e5;
    object-fit: cover;
    border-radius: 3px;
  }

  & + & {
    hr {
      display: flex;
    }
  }
`;

export const ContainerTagA = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1vh 0 1vh 0;
  width: 100%;
  background: #ffffff;
`;

export const AddDiv = styled('div')<{ fix?: boolean }>`
  margin-top: 10px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  position: absolute;
  width: 70.5%;
  background: #ffffff;
  z-index: 900;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  overflow: scroll;
  max-height: 200px;

  ${props => props.fix && 'width: 56%'}
`;

export const ContentTagA = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 34px;
    height: 34px;
    border: 1px solid #30b4e5;
    object-fit: cover;
    border-radius: 3px;
    margin: 0 15px;
  }
  i {
    margin: 0 20px 0 15px;
  }
`;

export const LineSearch = styled.hr`
  display: none;
  border-top-color: transparent !important;
  border: 1px solid #30b4e5;
`;

export const ContainerModal = styled.div`
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  svg {
    margin-bottom: 20px;
  }
`;
export const ContentModal = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
  button {
    width: 40%;
  }
`;

export const RowProduct = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const BoxTypeProduct = styled.div`
  flex: 0.1;
`;

export const BoxInputProduct = styled.div`
  flex: 0.9;
`;

export const Label = styled.label`
  display: flex;
  color: #000000;
  padding: 6px 0;
  font-weight: 700;
`;
