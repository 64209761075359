import { call, put } from 'redux-saga/effects';
import { api } from '~/services/api';
import BudgetsService from '~/services/BudgetsService';

import {
  IPropsAddProductRequest,
  IPropsCustomerRequest,
  IPropsDelProductRequest,
  IPropsGetRequest,
  IPropsQuotationRequest,
  IPropsSituationRequest,
  // IPropsSituationRequest,
  IResponseGet,
  IResponseList,
} from './types';
import {
  listBudgetSuccess,
  listBudgetFailure,
  getBudgetSuccess,
  addProductBudgetSuccess,
  addProductBudgetFailure,
  deleteProductBudgetFailure,
  quotationBudgetSuccess,
  quotationBudgetFailure,
  situationBudgetSuccess,
  situationBudgetFailure,
  deleteProductBudgetSuccess,
} from './actions';

export function* getBudget(action: IPropsGetRequest): any {
  try {
    const dataBudget = async (id: any) => {
      const request = await api.get(`/budget-api/budget/${id}`);
      return request.data;
    };
    const validationBudget = async (id: any) => {
      const data = await dataBudget(id);
      if (
        window.location.pathname === `/budget/edit/${id}` &&
        (data.situacao.id !== 1 || 0)
      ) {
        window.location.href = `/budget/finish/${id}`;
      }
    };
    validationBudget(action.payload.id);
    const request: IResponseGet = yield call(dataBudget, action.payload.id);
    yield put(getBudgetSuccess(request));
  } catch (err) {
    window.history.back();
    yield put(listBudgetFailure());
  }
}

export function* listBudget(): any {
  try {
    const listBudget = async () => {
      const list = await BudgetsService.listBudgets({});
      return list.result;
    };
    const request: IResponseList = yield call(listBudget);
    yield put(listBudgetSuccess(request));
  } catch (err) {
    yield put(listBudgetFailure());
  }
}

export function* addProductBudget(action: IPropsAddProductRequest): any {
  const { product } = action.payload;
  const { price } = action.payload;
  const { data } = action.payload;
  const { id } = action.payload;

  async function add() {
    await api.post(`/budget-api/budget/${id}/item/${product.id}`, {
      preco: price,
      cotacao: data?.moeda.cotacao,
      moedaId: data?.moeda.id,
      quantidade: 1,
    });
  }
  try {
    add();
    const newProduct = {
      id: product.id,
      descricao: product.descricao,
      tipo: product.tipo,
      preco: price,
      precoNovo: price,
      quantidade: 1,
      imagens: product.imagens,
      categoria: product.categoria,
    };

    data?.itens.push(newProduct);

    yield put(addProductBudgetSuccess(data));
  } catch (err) {
    yield put(addProductBudgetFailure());
  }
}

export function* deleteProduct(action: IPropsDelProductRequest): any {
  const { product } = action.payload;
  const { data } = action.payload;

  async function del() {
    await api.delete(`/budget-api/budget/${data.id}/item/${product.id}`);
  }
  try {
    del();
    const oldData = { ...data };
    const filter = oldData.itens.filter(
      (item: any) => !(item?.id === product.id),
    );

    const newData: IResponseGet = {
      ...oldData,
      itens: filter,
    };

    yield put(deleteProductBudgetSuccess(newData));
  } catch (err) {
    yield put(deleteProductBudgetFailure());
  }
}

export function* addCustomerBudget(action: IPropsCustomerRequest): any {
  const { customer } = action.payload;
  const { data } = action.payload;
  const { id } = action.payload.data;

  async function addCustomer() {
    await api.post(`budget-api/budget/${id}/customer/${customer.id}`);
  }
  try {
    addCustomer();
    const oldData = { ...data };
    const newData = {
      ...oldData,
      cliente: {
        id: customer.id,
        nome: customer.nome,
      },
    };

    yield put(addProductBudgetSuccess(newData));
  } catch (err) {
    yield put(addProductBudgetFailure());
  }
}

export function* situationBudget(action: IPropsSituationRequest): any {
  const { data, situacao, precoTotal, adress } = action.payload;
  const { id } = data;
  try {
    const situation = async () => {
      let request;
      if (situacao === 'finish') {
        request = await api.post(`/budget-api/budget/${id}/${situacao}`, {
          precoTotal,
          ...adress,
        });
        await api.post(`/budget-api/budget/${id}/pdf`);
      } else {
        request = await api.post(`/budget-api/budget/${id}/${situacao}`);
      }
      const oldData = { ...data };
      const newData = {
        ...oldData,
        situacao: {
          id: request.data.result.situacao.id,
          nome: request.data.result.situacao.nome,
        },
      };
      return newData;
    };
    const request: IResponseGet = yield call(situation);
    yield put(situationBudgetSuccess(request));
  } catch (err) {
    yield put(situationBudgetFailure());
  }
}

export function* quotationBudgetCurrency(action: IPropsQuotationRequest): any {
  try {
    const quotation = async () => {
      await api.post(
        `/budget-api/budget/${action.payload.id}/currency/${action.payload.idCurrency}`,
        {
          cotacao: action.payload.quotation,
        },
      );
      const request = await BudgetsService.getBudgetById(action.payload.id!);
      return request;
    };
    const request: IResponseGet = yield call(quotation);
    yield put(quotationBudgetSuccess(request));
  } catch (err) {
    yield put(quotationBudgetFailure());
  }
}
