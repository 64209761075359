import { Reducer } from 'redux';
import { IListState, ListTypes } from './types';

const INITIAL_STATE: IListState = {
  data: [
    {
      id: 0,
      moeda: '',
      codigo: '',
      sigla: '',
      dataCriacao: '',
      dataAtualizacao: '',
    },
  ],
  error: false,
  loading: false,
};

const reducer: Reducer<IListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ListTypes.LIST_REQUEST:
      return { ...state, loading: true };
    case ListTypes.LIST_SUCCCES:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data,
      };
    case ListTypes.LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default reducer;
