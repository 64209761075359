import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  width: 100%;
  box-shadow: 0px 1px 4px 0px #00000047;

  padding: 5px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  width: 50%;
  padding: 5px;

  p {
    margin: 0px;
  }

  span {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
  }
`;
