import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 10px;
  border-top: 1px solid #e5e5e5;
`;

export const Value = styled.p`
  font-weight: bold;
  font-size: 30px;
  margin: 0 10px 0 0;
`;

export const Label = styled.p`
  font-size: 15px;
  line-height: 15px;
  margin: 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
