/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';

import { format } from 'date-fns';
import { IResultByTech } from '~/store/modules/report/types';

import TechnicalHeader from '../TechnicalHeader';
import TechnicalList from '../TechnicalList';
import ModalSchedule from '../ModalSchedule';

import { Container } from './styles';

export interface ITechnical {
  id: number;
  name: string;
  schedules: ISchedule[];
}

export interface ISchedule {
  id: number;
  client: string;
  project: string;
  start: string;
  next: string;
  status: boolean;
}

interface IProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  client: number | null;
  setClient: (value: number | null) => void;
}

const ContentRight: React.FC<IProps> = ({
  openModal,
  setOpenModal,
  ...props
}) => {
  const [data, setData] = useState<IResultByTech[]>([]);
  const [id, setId] = useState<number | null>(null);

  const { schedules, dateSelected } = useSelector(
    (state: IState) => state.report,
  );

  useEffect(() => {
    if (schedules) {
      setData([]);
      const data = schedules.result.resultByDateByTechnicians
        .filter(
          curr =>
            format(
              new Date(curr.date.replace(/T.*$/gi, 'T05:00:00.000Z')).setHours(
                0,
                0,
                0,
                0,
              ),
              'dd/MM/yyyy',
            ) ===
            format(new Date(dateSelected).setHours(0, 0, 0, 0), 'dd/MM/yyyy'),
        )
        .reduce((acum: IResultByTech[], curr) => [...acum, ...curr.events], [])
        .reduce((acum: IResultByTech[], curr) => {
          const data = acum.find(data => data.tecnico.id === curr.tecnico.id);
          if (data) {
            data.agendamentos = [...curr.agendamentos, ...data.agendamentos];
          } else {
            acum.push(data || curr);
          }
          return acum;
        }, []);

      setData(data);
    }
  }, [schedules, dateSelected]);

  return (
    <>
      {openModal && (
        <ModalSchedule
          id={id}
          setId={setId}
          onClick={() => setOpenModal(false)}
          {...props}
        />
      )}
      <Container>
        <TechnicalHeader data={data} onClick={() => setOpenModal(true)} />
        {data.map(technical => {
          return (
            <TechnicalList
              setId={value => {
                setId(value);
                setOpenModal(true);
              }}
              technical={technical}
            />
          );
        })}
      </Container>
    </>
  );
};

export default ContentRight;
