import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const BtStatus = styled.a`
  margin: 0 5px;
`;

export const BtTrash = styled('a')<{ disabled?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Status = styled.div`
  border-radius: 50%;
  background-color: red !important;
  width: 10px;
  height: 10px;
`;
