export enum GetBudgetTypes {
  GET_BUDGET_REQUEST = '@budget/GET_BUDGET_REQUEST',
  GET_BUDGET_SUCCCES = '@budget/GET_BUDGET_SUCCCES',
  GET_BUDGET_FAILURE = '@budget/GET_BUDGET_FAILURE',
}

export enum ListBudgetTypes {
  LIST_BUDGET_REQUEST = '@budget/LIST_BUDGET_REQUEST',
  LIST_BUDGET_SUCCCES = '@budget/LIST_BUDGET_SUCCCES',
  LIST_BUDGET_FAILURE = '@budget/LIST_BUDGET_FAILURE',
}

export enum AddProductBudgetTypes {
  ADD_BUDGET_REQUEST = '@budget/ADD_BUDGET_REQUEST',
  ADD_PRODUCT_BUDGET_SUCCCES = '@budget/ADD_BUDGET_SUCCCES',
  ADD_PRODUCT_BUDGET_FAILURE = '@budget/ADD_BUDGET_FAILURE',
}

export enum DeleteProductBudgetTypes {
  DELETE_PRODUCT_BUDGET_REQUEST = '@budget/DELETE_PRODUCT_BUDGET_REQUEST',
  DELETE_PRODUCT_BUDGET_SUCCCES = '@budget/DELETE_PRODUCT_BUDGET_SUCCCES',
  DELETE_PRODUCT_BUDGET_FAILURE = '@budget/DELETE_PRODUCT_BUDGET_FAILURE',
}

export enum AddCustomerBudgetTypes {
  ADD_CUSTOMER_BUDGET_REQUEST = '@budget/ADD_CUSTOMER_BUDGET_REQUEST',
  ADD_CUSTOMER_BUDGET_SUCCCES = '@budget/ADD_CUSTOMER_BUDGET_SUCCCES',
  ADD_CUSTOMER_BUDGET_FAILURE = '@budget/ADD_CUSTOMER_BUDGET_FAILURE',
}

export enum SituationBudgetTypes {
  SITUATION_BUDGET_REQUEST = '@budget/SITUATION_BUDGET_REQUEST',
  SITUATION_BUDGET_SUCCCES = '@budget/SITUATION_BUDGET_SUCCCES',
  SITUATION_BUDGET_FAILURE = '@budget/SITUATION_BUDGET_FAILURE',
}

export enum QuotationBudgetTypes {
  QUOTATION_BUDGET_REQUEST = '@budget/QUOTATION_BUDGET_REQUEST',
  QUOTATION_BUDGET_SUCCCES = '@budget/QUOTATION_BUDGET_SUCCCES',
  QUOTATION_BUDGET_FAILURE = '@budget/QUOTATION_BUDGET_FAILURE',
}
export interface IResponseGet {
  id: number;
  uuid: string;
  descricao: string;
  preco: number;
  precoTotal: number;
  desconto: number;
  valorDesconto: number;
  maoDeObra: number;
  valorMaoDeObra: number;
  observacao: string;
  pagamento: string;
  responsavel: {
    id: number;
    nome: string;
  };
  criador: {
    id: number;
    nome: string;
  };
  situacao: {
    id: number;
    nome: string;
  };
  cliente: {
    id: number;
    nome: string;
  };
  moeda: {
    id: number;
    sigla: string;
    codigo: string;
    nome: string;
    cotacao: number;
  };
  dataValidade: string | null;
  dataCriacao: string;
  dataAtualizacao: string;
  itens: any;
  endereco: {
    cep: string;
    logradouro: string;
    numero: number;
    bairro: string;
    cidade: string;
    estado: string;
    complemento: string;
  };
  emails: string;
  justificativa: [
    {
      id: number;
      descricao: string;
      dataCriacao: string;
      dataAtualizacao: string;
    },
  ];
}

export interface IResponseList {
  id: number;
  descricao: string;
  preco: number;
  precoNovo: number;
  desconto: number;
  situacao: {
    id: number;
    nome: string;
  };
  cliente: {
    id: number;
    nome: string;
  };
  dataValidade: string | null;
  dataCriacao: string;
  dataAtualizacao: string;
}

export interface IPropsGetRequest {
  type: string;
  payload: {
    id: string;
  };
}

export interface IPropsAddProductRequest {
  type: string;
  payload: {
    id: string | undefined;
    product: any | undefined;
    data: IResponseGet | undefined;
    price: number;
  };
}

export interface IPropsDelProductRequest {
  type: string;
  payload: {
    data: IResponseGet;
    product: any | undefined;
  };
}

export interface IPropsCustomerRequest {
  type: string;
  payload: {
    data: IResponseGet;
    customer: any | undefined;
  };
}

export interface IPropsSituationRequest {
  type: string;
  payload: {
    data: IResponseGet;
    situacao: string;
    precoTotal: number;
    adress: object;
  };
}

export interface IPropsQuotationRequest {
  type: string;
  payload: {
    id: string | undefined;
    idCurrency: number | undefined;
    quotation: number | undefined;
  };
}

export interface IGetBudgetState {
  readonly data: IResponseGet;
  readonly loading: boolean;
  readonly error: boolean;
}

export interface IListBudgetState {
  readonly data: IResponseList[];
  readonly loading: boolean;
  readonly error: boolean;
}
