/* eslint-disable no-param-reassign */
import {
  IRequestSaveReport,
  IScheduleRequest,
} from '~/store/modules/report/types';
import { api } from './api';

interface IList {
  limit?: number;
  page?: number;
  type?: string;
  mes?: string;
}

class ReportService {
  async listSchedule({ limit = 200, page = 1 }: IList): Promise<any> {
    const response = await api.get(`/report-api/schedule`, {
      params: {
        limit,
        page,
      },
    });
    return response.data;
  }

  async getResume({ mes }: IList): Promise<any> {
    const response = await api.get(`/report-api/schedule`, {
      params: {
        limit: 1000,
        page: 1,
        mes,
      },
    });

    return response.data;
  }

  async getScheduleById(id: number): Promise<any> {
    const response = await api.get(`/report-api/schedule/${id}`);
    return response.data;
  }

  async listOptions({ limit = 200, page = 1, type }: IList): Promise<any> {
    if (!type) return null;

    const response = await api.get(`/report-api/${type}`, {
      params: {
        limit,
        page,
      },
    });

    return {
      [type]:
        response?.data?.data || response?.data?.result || response?.data || [],
    };
  }

  async fetchReport(id: number): Promise<any> {
    const response = await api.get(`/report-api/report/${id}`);
    return response.data;
  }

  async fetchEquipment(id: number): Promise<any> {
    const response = await api.get(`/report-api/equipments/contruction/${id}`);
    return response.data;
  }

  async fetchActivity({ limit = 200, page = 1 }: IList): Promise<any> {
    const response = await api.get(`/report-api/activit`, {
      params: {
        limit,
        page,
      },
    });
    return response.data;
  }

  async saveSchedule(props: IScheduleRequest): Promise<any> {
    const id = props.id ?? '';
    delete props.id;
    if (id) {
      delete props.criadorId;
    }
    const response = await api[id ? 'patch' : 'post'](
      `/report-api/schedule/${id}`,
      props,
    );
    return response.data;
  }

  async deleteSchedule(id: number): Promise<any> {
    const response = await api.delete(`/report-api/schedule/${id}`);
    return response.data;
  }

  async completeSchedule(id: number | string): Promise<any> {
    const response = await api.patch(`/report-api/schedule/${id}/complete`);
    return response.data;
  }

  async saveReport(props: IRequestSaveReport): Promise<any> {
    const id = props?.id ?? '';
    const PROPS = {
      patch: ['ph', 'redoux', 'cloro', 'observacoes'],
      post: [
        'agendaId',
        'ph',
        'redoux',
        'cloro',
        'observacoes',
        'equipamentos',
        'atividades',
      ],
    };
    const method = id ? 'patch' : 'post';
    const payload = PROPS[method].reduce((acum: any, curr) => {
      acum[curr] = (props as any)[curr];
      return acum;
    }, {});
    const response = await api[method](
      `/report-api/report/${id || ''}`,
      payload,
    );
    if (props.id) {
      await Promise.all([
        api.post(`/report-api/report/${props.id}/equipments`, {
          equipamentos: props.equipamentos,
        }),
        api.post(`/report-api/report/${props.id}/activities`, {
          atividades: props.atividades,
        }),
      ]);
    }
    return response.data;
  }

  async getOffClient(id: number): Promise<any> {
    const response = await api.patch(`/construction-api/construction/${id}`, {
      relatorioAcompanhar: 0,
    });

    return response.data;
  }

  async getLogs(id: number | string): Promise<any> {
    const response = await api.get(`/report-api/logs?relatorioId=${id}`);

    return response.data;
  }
}

export default new ReportService();
