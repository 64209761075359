/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { format } from 'date-fns';
import { IconAdd, IconAlert, IconLogoWD } from '~/assets/Icons';
import pt from 'date-fns/esm/locale/pt/index.js';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Checkbox, Input, Select } from '~/components';
import ReportService from '~/services/ReportService';
import {
  listSchedulesRequest,
  optionsRequest,
} from '~/store/modules/report/actions';
import { IState } from '~/store/modules/types';
import { generateRequiredInputValues } from '~/utils/forms';

import Calendar from '../Calendar';

import * as St from './styles';

const DICT_SAVE: any = {
  client: 'clienteId',
  project: 'diarioId',
  tech: 'tecnicoResponsavelId',
  tech1: 'tecnicoAux1Id',
  tech2: 'tecnicoAux2Id',
  tech3: 'tecnicoAux3Id',
  tech4: 'tecnicoAux4Id',
  period: 'periodoId',
  tipoManutencao: 'tipoManutencaoId',
};

interface IPropsInput {
  isValid: boolean;
  value: string | AnyObject;
  required: boolean;
  error: string;
}

interface AnyObject {
  [key: string]: any;
}

interface IProps {
  onClick: () => void;
  client: number | null;
  setClient: (value: number | null) => void;
  id: number | null;
  setId: (value: number | null) => void;
}

interface IModal extends ITypeModal {
  active: boolean;
}

interface ITypeModal {
  type?: 'success' | 'error';
}

interface IClient {
  id: number | null;
  nome: string | null;
  projects: {
    id: number;
    nome: string;
  }[];
}

type typeInput = {
  [key: string]: IPropsInput;
};

const ModalSchedule: React.FC<IProps> = ({
  onClick,
  setClient,
  client,
  id,
  setId,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState<IModal>({ active: false });
  const [date, setDate] = useState(moment().toISOString());
  const [repeatWeek, setRepeatWeek] = useState(0);
  const [customRepeat, setCustomRepeat] = useState(false);
  const [notify, setNotify] = useState(false);
  const [days] = useState([
    { id: 0, label: 'D' },
    { id: 1, label: 'S' },
    { id: 2, label: 'T' },
    { id: 3, label: 'Q' },
    { id: 4, label: 'Q' },
    { id: 5, label: 'S' },
    { id: 6, label: 'S' },
  ]);
  const [selectedWeekDay, setSelectedWeekDay] = useState(0);

  const [endAt, setEndAt] = useState(0);
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [endAfter, setEndAfter] = useState(0);

  const [clients, setClients] = useState<IClient[]>([]);
  const [techsValue, setTechsValue] = useState(0);
  const [error, setError] = useState('');

  const userId = useSelector((state: IState) => state.auth.data.account.id);

  const [inputState, setInputState] = useState<typeInput>({
    ...generateRequiredInputValues([
      'client',
      'project',
      'tech',
      'period',
      'tipoManutencao',
      'tech1',
      'tech2',
      'tech3',
      'tech4',
    ]),
  });

  const [options] = useState([
    { id: 0, label: 'Nunca' },
    {
      id: 1,
      label: 'Em',
      component: 'date',
    },
    {
      id: 2,
      label: 'Após',
      component: 'after',
    },
  ]);

  const { dateSelected, schedule_period, resume, users, type_maintenance } =
    useSelector((state: IState) => state.report);

  useEffect(() => {
    dispatch(
      optionsRequest([
        'schedule_period',
        'resume',
        'users',
        'schedule',
        'type_maintenance',
      ]),
    );
  }, []);

  useEffect(() => {
    handleResume();
  }, [resume]);

  useEffect(() => {
    setDate(dateSelected);
  }, [dateSelected]);

  useEffect(() => {
    handleClient();
  }, [clients, client]);

  useEffect(() => {
    handleId();
  }, [id]);

  const handleId = async () => {
    if (id) {
      setLoading(true);
      try {
        const data = await ReportService.getScheduleById(id);
        handleInputChange(
          {
            id: data.cliente.id,
            nome: data.cliente.nome,
          },
          'client',
        );
        handleInputChange(
          {
            id: data.obra.id,
            nome: data.obra.descricao,
          },
          'project',
        );
        handleInputChange(
          {
            id: data.tecnico.id,
            nome: data.tecnico.nome,
          },
          'tech',
        );
        handleInputChange(
          {
            id: Number(data.periodo.id),
            descricao: data.periodo.descricao,
          },
          'period',
        );
        handleInputChange(
          {
            id: Number(data.tipo_manutencao.id),
            descricao: data.tipo_manutencao.descricao,
          },
          'tipoManutencao',
        );
        if (data.tecnico.auxiliares.aux1_id) {
          handleInputChange(
            {
              id: data.tecnico.auxiliares.aux1_id,
              nome: data.tecnico.auxiliares.aux1_nome,
            },
            'tech1',
          );
          setTechsValue(1);
        }
        if (data.tecnico.auxiliares.aux2_id) {
          handleInputChange(
            {
              id: data.tecnico.auxiliares.aux2_id,
              nome: data.tecnico.auxiliares.aux2_nome,
            },
            'tech2',
          );
          setTechsValue(2);
        }
        if (data.tecnico.auxiliares.aux3_id) {
          handleInputChange(
            {
              id: data.tecnico.auxiliares.aux3_id,
              nome: data.tecnico.auxiliares.aux3_nome,
            },
            'tech3',
          );
          setTechsValue(3);
        }
        if (data.tecnico.auxiliares.aux4_id) {
          handleInputChange(
            {
              id: data.tecnico.auxiliares.aux4_id,
              nome: data.tecnico.auxiliares.aux4_nome,
            },
            'tech4',
          );
          setTechsValue(4);
        }
        setNotify(!!data.notificar_cliente);
        setSelectedWeekDay(data.periodo.repetir_dia);
        setRepeatWeek(data.periodo.repetir_em);
        if (
          moment(data.periodo.data_inicio).format('DD/MM/YY') !==
          moment(data.periodo.data_termino).format('DD/MM/YY')
        ) {
          setEndDate(data.periodo.data_termino);
        }
        setEndAfter(data.periodo.num_ocorrencias);
        setLoading(false);
        setCustomRepeat(true);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleClient = () => {
    if (clients && client) {
      const clientFinded = clients.find(data => data.id === client);
      handleInputChange(clientFinded, 'client');
    }
  };

  const handleResume = () => {
    const clients = [
      ...resume.finishedContructionWithoutSchedule,
      ...resume.finishingConstructions,
    ].reduce((acum: IClient[], curr) => {
      const client = acum.find(client => curr.cliente.id === client.id);
      if (!client) {
        acum.push({
          id: curr.cliente.id,
          nome: curr.cliente.nome || 'Cliente sem nome',
          projects: [{ id: curr.id, nome: curr.descricao }],
        });
      } else {
        const project = client.projects.find(project => project.id === curr.id);
        if (!project)
          client.projects = [
            ...client.projects,
            { id: curr.id, nome: curr.descricao },
          ];
      }
      return acum;
    }, []);
    setClients(clients);
  };

  const handleInputChange = (value: any, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && !value) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const handleClick = (day: Moment) => {
    setDate(day.toISOString());
  };

  const handleTech = (value: number) => {
    setTechsValue(previousState => previousState + value);
  };

  const getValue = (name: string, param = 'nome') => {
    const { value } = inputState[name];
    if (!value) return '';
    return typeof value !== 'object' ? value : value[param];
  };

  const RenderDate = () => (
    <St.InputContainer width="200px" margin="0 0 0 10px">
      <Input
        onChange={event => setEndDate(event.target.value)}
        value={endDate}
        type="date"
      />
    </St.InputContainer>
  );

  const RenderAfter = () => (
    <>
      <St.InputContainer width="50px" margin="0 0 0 10px">
        <Input
          onChange={event =>
            setEndAfter(parseInt(event.target.value.replace(/\D/gi, ''), 10))
          }
          value={endAfter}
        />
      </St.InputContainer>
      <St.Label>ocorrências</St.Label>
    </>
  );

  const renderComponent = (component: string) => {
    return {
      date: <RenderDate />,
      after: <RenderAfter />,
    }[component];
  };

  const getProjects = () => {
    const projects = getValue('client', 'projects');
    if (!projects) return [];
    return projects;
  };

  const handleClickModal = ({ type }: ITypeModal) => {
    setModal({ active: true, type });
  };

  const closeModal = () => {
    setModal({ active: false });
  };

  const renderModal = useMemo(() => {
    if (!modal.active || !modal.type) return;

    const MODAL_TITLE = {
      success: `Sucesso ao ${id ? 'editar' : 'criar'} agendamento`,
      error: `Erro ao ${id ? 'editar' : 'criar'} agendamento`,
    };

    // eslint-disable-next-line consistent-return
    return (
      <Modal>
        <St.ContentModal>
          <h3>{MODAL_TITLE[modal.type]}</h3>
          <St.ButtonStyle>
            <Button
              onClick={() => {
                if (modal.type === 'success') {
                  dispatch(listSchedulesRequest());
                  dispatch(optionsRequest(['report', 'resume']));
                  setClient(null);
                  setId(null);
                  onClick();
                } else {
                  closeModal();
                }
              }}
            >
              Entendi,{' '}
              {modal.type === 'success'
                ? 'voltar para relatórios'
                : 'tentar novamente'}
            </Button>
          </St.ButtonStyle>
        </St.ContentModal>
      </Modal>
    );
  }, [modal]);

  const saveSchedule = async () => {
    setLoading(true);
    const request = Object.keys(inputState).reduce((acum: any, curr) => {
      const value = getValue(curr, 'id');
      if (value)
        acum[DICT_SAVE[curr]] =
          curr === 'tipoManutencao' || curr === 'period'
            ? Number(value)
            : value;
      return acum;
    }, {});

    try {
      await ReportService.saveSchedule({
        ...request,
        ...(id ? { id } : {}),
        criadorId: userId,
        data_inicio: moment(date).format('YYYY-MM-DDT05:00:00Z'),
        data_termino: moment(date).format('MM-DD-YYYY'),
        notificar: notify ? 1 : 0,
        ...(customRepeat
          ? {
            repetir_em: repeatWeek || 0,
            repetir_dia: selectedWeekDay,
            ...(endAt !== 0
              ? {
                ...(endAt === 1
                  ? { data_termino: moment(endDate).format('MM-DD-YYYY') }
                  : {}),
                ...(endAt === 2 ? { num_ocorrencias: endAfter } : {}),
              }
              : {}),
          }
          : {
            repetir_em: 0,
            repetir_dia: 0,
          }),
      });
      handleClickModal({ type: 'success' });
    } catch (error: any) {
      if (error.status === 400) {
        setError(
          'O técnico já tem agendamento para essa data e período informados.',
        );
      } else {
        handleClickModal({ type: 'error' });
      }
    }
    setLoading(false);
  };

  return (
    <Modal width="80vw">
      {loading ? (
        <St.LoadingContainer>
          <St.LoadingCircle />
          <St.Img>
            <IconLogoWD />
          </St.Img>
        </St.LoadingContainer>
      ) : (
        <div style={{ flex: 1, maxHeight: '80vh', overflow: 'scroll' }}>
          {renderModal}
          <St.Container>
            <St.Calendar>
              <Calendar hideCircles onClick={handleClick} selectedDate={date} />
            </St.Calendar>
            <St.Content>
              <St.Label>
                {format(new Date(date), "dd 'de' MMMM 'de' yyyy'", {
                  locale: pt,
                })}
              </St.Label>
              <St.Row>
                <Checkbox
                  checked={customRepeat}
                  onChange={e => setCustomRepeat(e.target.checked)}
                />
                <St.Label>
                  Esse agendamento terá recorrência personalizada a partir dessa
                  data
                </St.Label>
              </St.Row>
              {customRepeat ? (
                <>
                  <St.Row>
                    <St.Label>Repetir a cada</St.Label>
                    <St.InputContainer width="50px" margin="0 0 0 10px">
                      <Input
                        value={repeatWeek}
                        onChange={event =>
                          setRepeatWeek(
                            parseInt(
                              event.target.value.replace(/\D/gi, ''),
                              10,
                            ) || 0,
                          )
                        }
                      />
                    </St.InputContainer>
                    <St.Label>semana</St.Label>
                  </St.Row>
                  <St.Row>
                    <St.Label>Repetir</St.Label>
                    <St.Days>
                      {days.map(curr => (
                        <St.Day
                          onClick={() => setSelectedWeekDay(curr.id)}
                          active={selectedWeekDay === curr.id}
                          key={`${curr.label}-${curr.id}`}
                        >
                          {curr.label}
                        </St.Day>
                      ))}
                    </St.Days>
                  </St.Row>
                  <St.Row dontAlign>
                    <St.Label>Termina em</St.Label>
                    <div>
                      {options.map(curr => (
                        <St.Row margin="0 0 10px 0">
                          <St.RadioButton
                            active={curr.id === endAt}
                            onClick={() => setEndAt(curr.id)}
                          />
                          <St.Label>{curr.label}</St.Label>
                          {curr.component
                            ? renderComponent(curr.component)
                            : null}
                        </St.Row>
                      ))}
                    </div>
                  </St.Row>
                </>
              ) : null}
              <St.Row>
                <St.ContainerSelect>
                  <Select
                    label="Cliente"
                    onClickList={e => handleInputChange(e, 'client')}
                    currentValue={getValue('client')}
                    lista={clients as any}
                  />
                </St.ContainerSelect>
                <St.ContainerSelect>
                  <Select
                    label="Projeto"
                    onClickList={e => handleInputChange(e, 'project')}
                    currentValue={getValue('project')}
                    lista={getProjects()}
                  />
                </St.ContainerSelect>
              </St.Row>
              <St.Row dontAlign>
                <St.ContainerSelect>
                  <Select
                    label="Técnico"
                    onClickList={e => handleInputChange(e, 'tech')}
                    currentValue={getValue('tech')}
                    lista={users.sort((prev, next) =>
                      prev.nome?.localeCompare(next.nome),
                    )}
                  />
                  {Array.from({ length: techsValue }, (v, k) => ({
                    id: k + 1,
                  })).map(curr => (
                    <Select
                      label="Técnico auxiliar"
                      onClickList={e => handleInputChange(e, `tech${curr.id}`)}
                      currentValue={getValue(`tech${curr.id}`)}
                      lista={users.sort((prev, next) =>
                        prev.nome?.localeCompare(next.nome),
                      )}
                    />
                  ))}
                  {techsValue < 4 ? (
                    <St.Row button onClick={() => handleTech(1)}>
                      <IconAdd />
                      <St.Label margin="0 0 5px 10px">
                        Adicionar técnico auxiliar
                      </St.Label>
                    </St.Row>
                  ) : null}
                </St.ContainerSelect>
                <St.ContainerSelect>
                  <Select
                    label="Período"
                    onClickList={e => handleInputChange(e, 'period')}
                    currentValue={getValue('period', 'descricao')}
                    lista={schedule_period}
                  />
                </St.ContainerSelect>
                <St.ContainerSelect>
                  <Select
                    label="Tipo de Manutenção"
                    onClickList={e => handleInputChange(e, 'tipoManutencao')}
                    currentValue={getValue('tipoManutencao', 'descricao')}
                    lista={type_maintenance}
                  />
                </St.ContainerSelect>
              </St.Row>
              <St.Row>
                <Checkbox
                  checked={notify}
                  onChange={e => setNotify(e.target.checked)}
                />
                <St.Label>
                  Notificar cliente que o técnico estará a caminho
                </St.Label>
              </St.Row>
              {error ? (
                <St.Row>
                  <IconAlert />
                  <St.Label>{error}</St.Label>
                </St.Row>
              ) : null}
            </St.Content>
          </St.Container>
          <St.Footer>
            <Button
              onClick={() => {
                onClick();
                setClient(null);
                setId(null);
              }}
              styles={1}
            >
              Cancelar
            </Button>
            <Button onClick={saveSchedule}>Confirmar</Button>
          </St.Footer>
        </div>
      )}
    </Modal>
  );
};

export default ModalSchedule;
