/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Page, Loading } from '~/components';
import {
  listSchedulesRequest,
  optionsRequest,
} from '~/store/modules/report/actions';
import { IState } from '~/store/modules/types';
import ContentLeft from './components/ContentLeft';
import ContentRight from './components/ContentRight';
import { Container } from './styles';

const MaintenanceReport: React.FC = () => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [client, setClient] = useState<number | null>(null);

  const { loading } = useSelector((state: IState) => state.report);

  useEffect(() => {
    dispatch(listSchedulesRequest());
    dispatch(optionsRequest(['report', 'resume']));
  }, []);

  return (
    <Page>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container>
            <ContentLeft
              client={client}
              setClient={setClient}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
            <ContentRight
              client={client}
              setClient={setClient}
              openModal={openModal}
              setOpenModal={setOpenModal}
            />
          </Container>
        </>
      )}
    </Page>
  );
};

export default MaintenanceReport;
