import { Badge } from 'antd';
import React, { useState, FocusEvent } from 'react';
import { IconFilter, IconSearch } from '~/assets/Icons';
import { Button, Input, Return } from '~/components';
import { CheckPermission } from '~/utils/checkPermission';
import { Header, Title, Search, Icon, Row } from './styles';

interface IProps {
  textInputSearch?: string;
  button: any;
  title: any;
  onClick: any;
  disabled?: boolean;
  permission: { module: string; permission: string };
  search?: string;
  ref?: any;
  onSearch?: (value: string) => void;
  onChange?: (value: string) => void;
  buttonSecond?: any;
  onClickSecond?: any;
  onClickFilter?: (() => void) | undefined;
  stateFilter?: boolean | undefined;
}

const defaultProps = {
  textInputSearch: ``,
  disabled: false,
  search: undefined,
  onSearch: undefined,
  onChange: undefined,
  buttonSecond: undefined,
  onClickSecond: undefined,
  onClickFilter: undefined,
  stateFilter: undefined,
  ref: null,
};

const PageHeader: React.FC<IProps> = ({
  textInputSearch = '',
  button,
  title,
  onClick,
  disabled,
  permission,
  search,
  onSearch,
  onChange,
  ref,
  buttonSecond,
  onClickSecond,
  onClickFilter,
  stateFilter,
}: IProps) => {
  const [text, setText] = useState(textInputSearch);

  const handleChangeSearch = (event: FocusEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  return (
    <>
      <Return />
      <Header>
        <Title>{title}</Title>
        <Row>
          {search && (
            <Search>
              <Input
                ref={ref}
                value={text}
                onChange={e => {
                  if (onChange) {
                    onChange(e.target.value);
                    handleChangeSearch(e);
                  } else {
                    handleChangeSearch(e);
                  }
                }}
                onClick={() => onSearch && onSearch(text)}
                placeholder={search}
                icon={
                  <Icon>
                    <IconSearch />
                  </Icon>
                }
              />
            </Search>
          )}
          {onClickFilter && (
            <Badge dot={stateFilter} color="#30B4E5">
              <button type="button" onClick={onClickFilter}>
                <IconFilter />
              </button>
            </Badge>
          )}
          {onClickSecond && (
            <Button
              responsiveness
              locked={!CheckPermission(permission)}
              disabled={
                CheckPermission(permission)
                  ? disabled
                  : !CheckPermission(permission)
              }
              onClick={onClickSecond}
            >
              {buttonSecond}
            </Button>
          )}
          {onClick && (
            <Button
              responsiveness
              locked={!CheckPermission(permission)}
              disabled={
                CheckPermission(permission)
                  ? disabled
                  : !CheckPermission(permission)
              }
              onClick={onClick}
            >
              {button}
            </Button>
          )}
        </Row>
      </Header>
    </>
  );
};

export default PageHeader;

PageHeader.defaultProps = defaultProps;
