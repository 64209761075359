/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import isValidEmail from '~/utils/validate/isValidEmail';
import { Input, InputPhone } from '~/components';
import estadosENUM from '~/constants/estados';
import CustomersService from '~/services/CustomersService';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  validateForm,
} from '~/utils/forms/index';
import TextArea from '~/components/TextArea';
import isFormatCEP from '~/utils/format/isFormatCEP';
import { IconReturn } from '~/assets/Icons';
import { isEmpty } from '~/utils/validate';
import { Container, ContainerBox, Content, InputBox, Header } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};
interface IPropsForm {
  budget?: any;
  description: string;
  onChange: (e: any, erro: boolean) => void;
  setValue: (e: any, erro: boolean) => void;
}

const defaultProps = {
  budget: null,
};

const Form: React.FC<IPropsForm> = ({
  budget,
  description,
  onChange,
  setValue,
}: IPropsForm) => {
  // const [currentBudget, setCurrentBudget] = useState({
  //   id: 0,
  //   descricao: '',
  //   cliente: { nome: '' },
  //   endereco: {
  //     cep: '',
  //     logradouro: '',
  //     numero: 0,
  //     bairro: '',
  //     cidade: '',
  //     estado: '',
  //     complemento: '',
  //   },
  // });

  const [inputPhone, setInputPhone] = useState<any>(undefined);
  const [showBudget, setShowBudget] = useState(false);

  const stateSchema = {
    ...generateRequiredInputValues([
      'dateStart',
      'dateEnd',
      'contact',
      'office',
      'phone',
      'email',
      'company',
      'information',
      'cep',
      'address',
      'state',
      'city',
      'district',
      'numberAddress',
    ]),
    ...generateDefaultInputValues(['complement']),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchema);

  useEffect(() => {
    const loadBudget = async () => {
      setInputState((prevState: any) => ({
        ...prevState,
        cep: {
          value: budget.endereco.cep,
          isValid: inputState.cep.isValid,
          required: inputState.cep.required,
          error: inputState.cep.error,
        },
        address: {
          value: budget.endereco.logradouro,
          isValid: inputState.address.isValid,
          required: inputState.address.required,
          error: inputState.address.error,
        },
        state: {
          value: budget.endereco.estado,
          isValid: inputState.state.isValid,
          required: inputState.state.required,
          error: inputState.state.error,
        },
        city: {
          value: budget.endereco.cidade,
          isValid: inputState.city.isValid,
          required: inputState.city.required,
          error: inputState.city.error,
        },
        district: {
          value: budget.endereco.bairro,
          isValid: inputState.district.isValid,
          required: inputState.district.required,
          error: inputState.district.error,
        },
        numberAddress: {
          value: budget.endereco.numero,
          isValid: inputState.numberAddress.isValid,
          required: inputState.numberAddress.required,
          error: inputState.numberAddress.error,
        },
      }));
    };
    if (budget !== null) {
      loadBudget();
    }
  }, []);

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  useEffect(() => {
    if (isFormValid()) {
      setValue(
        {
          descricao: description,
          orcamento_id: budget.id,
          inicio: inputState.dateStart.value,
          termino: inputState.dateEnd.value,
          contatoNome: inputState.contact.value,
          contatoEmail: inputState.email.value,
          contatoTelefone: inputState.phone.value,
          contatoCargo: inputState.office.value,
          contatoEmpresa: inputState.company.value,
          info: inputState.information.value,
          cep: inputState.cep.value,
          logradouro: inputState.address.value,
          numero: parseFloat(inputState.numberAddress.value),
          bairro: inputState.district.value,
          cidade: inputState.city.value,
          estado: inputState.state.value,
          complemento: inputState.complement.value,
        },
        isFormValid(),
      );
    }
  }, []);

  const updateCep = (data: any) => {
    setInputState((prevState: any) => ({
      ...prevState,
      district: {
        value: data.bairro,
        isValid: inputState.district.isValid,
        required: inputState.district.required,
        error: inputState.district.error,
      },
      address: {
        value: data.logradouro,
        isValid: inputState.address.isValid,
        required: inputState.address.required,
        error: inputState.address.error,
      },
      city: {
        value: data.localidade,
        isValid: inputState.city.isValid,
        required: inputState.city.required,
        error: inputState.city.error,
      },
      state: {
        value: estadosENUM(data.uf),
        isValid: inputState.state.isValid,
        required: inputState.state.required,
        error: inputState.state.error,
      },
    }));
  };

  const requestCep = useCallback(async cep => {
    try {
      const response = await CustomersService.getCep(cep);
      updateCep(response);
    } catch (e) {
      setInputState(prevState => ({
        ...prevState,
        [cep]: {
          isValid: false,
          value: cep,
          required: inputState[cep].required,
          error: 'Cep não encontrado',
        },
      }));
    }
  }, []);

  const handleInputBlur = async (value: string, inputName: string) => {
    if (inputName === 'cep' && value.replace(/[^\d]+/g, '').length === 8) {
      const cep = value.replace(/[^\d]+/g, '');
      await requestCep(cep);
    }
  };

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    const verifyDate = moment(inputState.dateStart.value).isAfter(value);

    if (inputName === 'dateEnd' && verifyDate) {
      error = 'Data inferior a data de início';
      isValid = false;
    }

    if (inputName === 'email' && !isValidEmail(value)) {
      error = 'E-mail inválido';
      isValid = false;
    }

    if (inputName === 'cep' && value.replace(/[^\d]+/g, '').length < 8) {
      error = 'Campo insuficiente, digite 8 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length < 10) {
      error = 'Campo insuficiente, digite no mínimo 10 caracteres ';
      isValid = false;
    }

    if (inputName === 'phone' && value.length > 20) {
      error = 'Campo insuficiente, digite no máximo 20 caracteres ';
      isValid = false;
    }

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));

    onChange(
      {
        descricao: description,
        orcamento_id: budget.id,
        inicio: inputState.dateStart.value,
        termino: inputState.dateEnd.value,
        contatoNome: inputState.contact.value,
        contatoEmail: inputState.email.value,
        contatoTelefone: inputState.phone.value,
        contatoCargo: inputState.office.value,
        contatoEmpresa: inputState.company.value,
        info: inputState.information.value,
        cep: inputState.cep.value,
        logradouro: inputState.address.value,
        numero: parseFloat(inputState.numberAddress.value),
        bairro: inputState.district.value,
        cidade: inputState.city.value,
        estado: inputState.state.value,
        complemento: inputState.complement.value,
      },
      isFormValid(),
    );
  };

  return (
    <Container state={budget === null}>
      {budget !== null && (
        <Header onClick={() => setShowBudget(!showBudget)}>
          <b>Orçamento {budget.id}</b>
          <IconReturn />
        </Header>
      )}
      <Content show={showBudget}>
        <ContainerBox>
          <InputBox>
            <Input
              label="Cliente"
              requiredLabel
              disabled
              value={budget?.cliente?.nome}
            />
          </InputBox>
        </ContainerBox>
        <ContainerBox>
          <InputBox>
            <Input
              label="CEP"
              requiredLabel
              maxLenght={9}
              value={isFormatCEP(inputState.cep.value)}
              error={inputState.cep.error}
              onChange={e => handleInput(e.target.value, 'cep')}
              onBlur={async e => {
                handleInputBlur(e.target.value, 'cep');
              }}
            />
            <Input
              label="Número"
              requiredLabel
              value={inputState.numberAddress.value}
              error={inputState.numberAddress.error}
              onChange={e =>
                handleInput(
                  e.target.value.replace(/[^\d]+/g, ''),
                  'numberAddress',
                )
              }
              onBlur={e => handleInput(e.target.value, 'numberAddress')}
            />
          </InputBox>
          <InputBox>
            <Input
              label="Logradouro"
              requiredLabel
              value={inputState.address.value}
              error={inputState.address.error}
              onChange={e => handleInput(e.target.value, 'address')}
              onBlur={e => handleInput(e.target.value, 'address')}
            />
            <Input
              label="Complemento"
              value={inputState.complement.value}
              error={inputState.complement.error}
              onChange={e => handleInput(e.target.value, 'complement')}
              onBlur={e => handleInput(e.target.value, 'complement')}
            />
          </InputBox>
        </ContainerBox>
        <ContainerBox>
          <InputBox>
            <Input
              label="Bairro"
              requiredLabel
              value={inputState.district.value}
              error={inputState.district.error}
              onChange={e => handleInput(e.target.value, 'district')}
              onBlur={e => handleInput(e.target.value, 'district')}
            />
            <Input
              label="Estado"
              requiredLabel
              value={inputState.state.value}
              error={inputState.state.error}
              onChange={e => handleInput(e.target.value, 'state')}
              onBlur={e => handleInput(e.target.value, 'state')}
            />
          </InputBox>
          <InputBox>
            <Input
              label="Cidade"
              requiredLabel
              value={inputState.city.value}
              error={inputState.city.error}
              onChange={e => handleInput(e.target.value, 'city')}
              onBlur={e => handleInput(e.target.value, 'city')}
            />
          </InputBox>
        </ContainerBox>
        <ContainerBox>
          <InputBox>
            <Input
              label="Data de Início"
              requiredLabel
              type="date"
              value={inputState.dateStart.value}
              error={inputState.dateStart.error}
              onChange={e => handleInput(e.target.value, 'dateStart')}
              onBlur={e => handleInput(e.target.value, 'dateStart')}
            />
            <Input
              label="Nome do Contato da Obra"
              requiredLabel
              value={inputState.contact.value}
              error={inputState.contact.error}
              onChange={e => handleInput(e.target.value, 'contact')}
              onBlur={e => handleInput(e.target.value, 'contact')}
            />
          </InputBox>
          <InputBox>
            <Input
              label="Data de Término"
              requiredLabel
              type="date"
              value={inputState.dateEnd.value}
              error={inputState.dateEnd.error}
              onChange={e => handleInput(e.target.value, 'dateEnd')}
              onBlur={e => handleInput(e.target.value, 'dateEnd')}
            />
            <Input
              label="Cargo"
              requiredLabel
              value={inputState.office.value}
              error={inputState.office.error}
              onChange={e => handleInput(e.target.value, 'office')}
              onBlur={e => handleInput(e.target.value, 'office')}
            />
          </InputBox>
        </ContainerBox>
        <ContainerBox>
          <InputBox>
            <InputPhone
              label="Telefone"
              requiredLabel
              value={inputPhone}
              error={inputState.phone.error}
              onChange={e => {
                setInputPhone(e);
                if (e !== undefined) {
                  handleInput(e, 'phone');
                }
              }}
            />
            <Input
              label="Empresa"
              requiredLabel
              value={inputState.company.value}
              error={inputState.company.error}
              onChange={e => handleInput(e.target.value, 'company')}
              onBlur={e => handleInput(e.target.value, 'company')}
            />
          </InputBox>
          <InputBox>
            <Input
              label="Email"
              requiredLabel
              type="email"
              value={inputState.email.value}
              error={inputState.email.error}
              onChange={e => handleInput(e.target.value, 'email')}
              onBlur={e => handleInput(e.target.value, 'email')}
            />
          </InputBox>
        </ContainerBox>
        <ContainerBox>
          <TextArea
            label="Informações Gerais"
            requiredLabel
            placeholder="Insira seu texto aqui..."
            value={inputState.information.value}
            error={inputState.information.error}
            onChange={e => handleInput(e.target.value, 'information')}
            onBlur={e => handleInput(e.target.value, 'information')}
          />
        </ContainerBox>
      </Content>
    </Container>
  );
};

Form.defaultProps = defaultProps;

export default Form;
