import styled from 'styled-components';

export const Container = styled.div`
  padding: 3%;

  div {
    padding: 0;
  }
`;

export const ContainerUpload = styled.div`
  width: 200px;
  height: 186px;
  background: #c4c4c4;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;

  p {
    font-size: 10px;
    text-align: center;
  }
`;

export const ContentUpload = styled.div`
  width: 200px;
  height: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 40%;
  }
`;

export const CustomerImg = styled.img`
  width: 200px;
  height: 186px;
  object-fit: cover;
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 40px 0 40px;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    padding: 15px 0px;
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const RowInput = styled.div<{ alignItems?: string }>`
  display: flex;
  flex: 1;
  ${({ alignItems }) => (alignItems ? `align-items: ${alignItems};` : '')}

  div + div {
    margin-left: 20px;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonAdd = styled.button.attrs({
  type: 'button',
})`
  height: min-content;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const ButtonStyle = styled.span`
  width: 100%;
  display: inline-block;
  button {
    border: 2px solid #30b4e5;
    background: #fff;
    color: #30b4e5;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ButtonStyle} + button {
    margin-left: 10px;
  }
`;

export const Search = styled.div`
  max-width: 500px;
  margin-left: auto;
  margin-top: 10px;
`;

export const Icon = styled.span`
  position: absolute;
  right: 10px;
`;

export const InputNumberOrder = styled.div`
  display: flex;
  justify-content: center;
  div {
    padding-left: 16px;
  }

  input {
    margin: 0;
    padding: 5px;
    height: 25px;
    width: 70px;
    color: #000;
  }
`;

export const InputNumber = styled('div')<{ disabled?: boolean }>`
  margin-left: 10px;
  display: flex;
  border: 1px solid #30b4e5;
  border-radius: 3px;
  width: 100px;
  ${props => props.disabled && 'border: 1px solid #c4c4c4;'}
  input {
    margin: 0;
    border: 0;
    padding: 5px;
    height: 25px;
    width: 40px;
    color: #000;
  }
  button {
    align-items: center;
    margin: 0;
    background: #30b4e5;
    color: #fff;
    height: 25px;
    width: 30px;

    ${props => props.disabled && 'background: #C4C4C4;'}
  }
  p {
    margin: 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    align-text: center;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`;
