import { IconAlert } from '~/assets/Icons';
import { Button, Modal } from '~/components';
import { ModalCurrencyContainer } from './styles';

interface IProps {
  onClick: () => void;
}

const ModalAlertCurrecy: React.FC<IProps> = ({ onClick }: IProps) => {
  return (
    <Modal>
      <ModalCurrencyContainer>
        <div>
          <i>
            <IconAlert />
          </i>

          <b>Está faltando algo!</b>
          <span>Por favor, insira o valor da cotação e tente novamente!</span>
        </div>
        <Button onClick={onClick}>Entendi!</Button>
      </ModalCurrencyContainer>
    </Modal>
  );
};

export default ModalAlertCurrecy;
