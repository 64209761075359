/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Page, Return, Loading } from '~/components';
import { logsRequest } from '~/store/modules/report/actions';
import { IState } from '~/store/modules/types';

import * as St from './styles';

const MaintenanceReportExec: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, logs } = useSelector((state: IState) => state.report);

  const [id] = useState(history.location.state?.id);
  const [report] = useState(history.location.state?.report);

  useEffect(() => {
    dispatch(logsRequest(id));
  }, []);

  const handleDescription = (value: string) => {
    switch (value) {
      case 'AGENDAMENTO VISITA TECNICA':
        return 'AGENDAMENTO VISITA TÉCNICA';
      case 'REALIZACAO DE VISITA TECNICA':
        return 'REALIZAÇÃO DE VISITA TÉCNICA';
      case 'INSERCAO DE IMAGEM NO RELATORIO':
        return 'INSERÇÃO DE IMAGEM NO RELATÓRIO';
      case 'INFORMAR CONDICAO ADVERSA':
        return 'INFORMAR CONDIÇÃO ADVERSA';
      case 'DEIXAR DE ACOMPANHAR OBRA':
        return 'DEIXAR DE ACOMPANHAR OBRA';
      default:
        return value;
    }
  };

  return (
    <Page>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Return />
          <St.Container>
            <St.Header>
              <p>Logs relatório: {report}</p>
            </St.Header>
            <St.Container>
              {logs?.data?.length === 0 ? (
                <St.Card>
                  <p>Não existem logs para esse relatório</p>
                </St.Card>
              ) : null}
              {logs?.data?.map((curr: any) => (
                <St.Card>
                  <p>{handleDescription(curr.acaoNome)}</p>
                  <p>
                    {curr.data
                      ? moment(curr.data).format('DD/MM/YYYY HH:mm:ss')
                      : null}
                  </p>
                </St.Card>
              ))}
            </St.Container>
          </St.Container>
        </>
      )}
    </Page>
  );
};

export default MaintenanceReportExec;
