import { call, put } from 'redux-saga/effects';
import { api } from '~/services/api';

import { listCurrencySuccess, listCurrencyFailure } from './actions';

export function* listCurrency(): any {
  try {
    const dataCurrency = async () => {
      const request = await api.get(`/budget-api/currency?limit=200&page=1`);
      return request.data.result;
    };
    const request: any[] = yield call(dataCurrency);
    yield put(listCurrencySuccess(request));
  } catch (err) {
    yield put(listCurrencyFailure());
  }
}
