import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16vh;
  width: 100%;
  background: #ffffff;
  padding-right: 20px;
  z-index: 999;

  img {
    height: 8vh;
    width: auto;
    padding: 0 60px;
  }

  a {
    width: 100%;
    margin: 0px 15px;

    button {
      margin: 0px;
      width: 100%;
    }
  }
  button {
    margin: 0px 15px;

    width: 30%;
  }

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    height: 14vh;
    padding: 0;

    img {
      padding: 0;
    }

    div {
      width: 0;
      display: none;
    }

    button {
      display: none;
    }
  }
`;

export const ToShare = styled.button`
  display: none;

  @media (max-width: 768px) {
    display: flex !important;
    justify-content: flex-end;
    position: absolute;
    width: 5% !important;
    right: 10px;
  }
`;

export const MenuToShare = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: inline;
    position: absolute;
    right: 20px;
    top: 10vh;
    z-index: 3;
    background-color: #ffffff;
    padding: 15px 20px;
    box-shadow: 0px 2px 9px 0px #00000040;
    border-radius: 3px 3px 0px 0px;
    button {
      margin: 10px 0;
    }
  }
`;

export const Content = styled.div`
  background: #3232324d;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 24vh;
  div {
    max-width: 100%;
    max-height: 100%;

    display: flex;
    justify-content: center;

    canvas {
      max-width: 90%;
      width: 90%;
      height: auto !important;
    }
  }

  @media (max-width: 768px) {
    padding-top: 18vh;
  }
`;

export const ButtonContainer = styled('div')<{ state?: boolean }>`
  width: 30%;
  margin: 0px 15px;
  button {
    display: flex;
    margin: 0;
    width: 100%;
    ${props => props.state && 'display: none'}
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export const JustificationContainer = styled.div`
  background-color: #ffffff;
  padding: 24px;
  position: absolute;
  z-index: 100;
  width: 100%;
  span {
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }

  p {
    margin-top: 0;
    font-size: 11px;
  }

  textarea {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #30b4e5;
    border-radius: 10px;

    max-height: 40vh;
  }
  div {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }
  button {
    width: 30%;
    align-self: flex-end;
  }
  button + button {
    display: none;
  }

  @media (max-width: 768px) {
    height: 100%;

    span {
      font-size: 20px;
    }
    div {
      justify-content: center;
      flex-direction: column;
      button {
        width: 100%;
        margin: 10px 0;
      }

      button + button {
        display: flex;
      }
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2000;
  position: relative;
  i {
    margin-top: 25px;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: -20px;
    color: ${({ theme }) => theme.Colors.Red};

    b {
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  button {
    align-self: center;
    margin-top: 15px;
    width: 80%;
  }
`;

export const TitleModal = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    color: #30b4e5;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -40px;
`;
