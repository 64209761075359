import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1% 3% 3% 3%;

  padding-bottom: 125px;
  width: 80%;
`;

export const TextArea = styled.textarea`
  border: 1px solid #30b4e5;
  border-radius: 5px;
  padding: 15px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
`;

export const ErroAlert = styled('p')<{ erro: boolean }>`
  margin-top: 4px;
  color: #df0a24 !important;
  font-size: 10px;

  ${props => !props.erro && `display: none`}
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #000000;
  padding: 6px 0;
  font-weigth: 300;
`;
