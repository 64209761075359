import React, { useState } from 'react';
import { IconClose } from '~/assets/Icons';
import { Button, Modal, TextArea } from '~/components';
import { generateRequiredInputValues, validateForm } from '~/utils/forms';
import { isEmpty } from '~/utils/validate';
import { ContainerButtonModal, TitleModal } from './styles';

interface IProps {
  typeProject: string;
  onClickCancel: () => void;
  onClickConfirm: () => void;
  onChange: (e: any) => void;
}

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const ModalApproval: React.FC<IProps> = ({
  typeProject = `Visual`,
  onClickCancel,
  onClickConfirm,
  onChange,
}: IProps) => {
  const stateSchema = {
    ...generateRequiredInputValues(['text']),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchema);

  const handleInput = async (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputState[inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    setInputState(prevState => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    return isEmpty(inputsWithError) && !hasError;
  };

  const toSave = () => {
    Object.entries(inputState).forEach(allInput => {
      handleInput(allInput[1].value, allInput[0]);
    });
    if (isFormValid()) {
      onClickConfirm();
    }
  };

  return (
    <Modal width="40vw">
      <>
        <TitleModal>
          <b>
            Informe a justificativa para a reprovação do Projeto {typeProject}:
          </b>
          <button type="button" onClick={onClickCancel}>
            <IconClose />
          </button>
        </TitleModal>
        <TextArea
          error={inputState.text.error}
          onChange={e => {
            handleInput(e.target.value, 'text');
            onChange(e.target.value);
          }}
        />
        <ContainerButtonModal>
          <Button styles={1} onClick={onClickCancel}>
            Cancelar
          </Button>
          <Button onClick={toSave} disabled={!isFormValid()}>
            Confirmar
          </Button>
        </ContainerButtonModal>
      </>
    </Modal>
  );
};

export default ModalApproval;
