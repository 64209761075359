export enum ListTypes {
  LIST_REQUEST = '@currency/LIST_REQUEST',
  LIST_SUCCCES = '@currency/LIST_SUCCCES',
  LIST_FAILURE = '@currency/LIST_FAILURE',
  // LOAD_RESET = '@currency/LOAD_RESET',
}

export interface IResponse {
  id: number;
  moeda: string;
  codigo: string;
  sigla: string;
  dataCriacao: string;
  dataAtualizacao: string;
}

/**
 * State type
 */
export interface IListState {
  readonly data: IResponse[];
  readonly loading: boolean;
  readonly error: boolean;
}
