export enum GetConstructionTypes {
  GETCONSTRUCTION_REQUEST = '@construction/GETCONSTRUCTION_REQUEST',
  GETCONSTRUCTION_SUCCCES = '@construction/GETCONSTRUCTION_SUCCCES',
  GETCONSTRUCTION_FAILURE = '@construction/GETCONSTRUCTION_FAILURE',
}

interface IFile {
  id: number;
  nome: string;
  path: string;
  status: string | null;
  data_atualizacao: string;
  status_responsavel: number;
  justificativa: string | null;
}

interface IEquipment {
  id: number;
  descricao: string;
  recebido: boolean | null;
  deletado: boolean | null;
}

export interface IResponse {
  id: number;
  descricao: string;
  iniciado: number;
  inicio: string;
  termino: string;
  orcamento_id: number;
  endereco: {
    cep: string;
    logradouro: string;
    numero: number;
    bairro: string;
    cidade: string;
    estado: string;
    complemento: string;
  };
  responsavel: {
    id: number;
    nome: string;
  };
  cliente: {
    id: number;
    nome: string;
  };
  info: string | null;
  contato: {
    id: number;
    nome: string;
    email: string;
    telefone: string;
    cargo: string;
    empresa: string;
  };
  corpo_tecnico: {
    id: number;
    arquiteto: {
      id: number;
      nome: string;
      email: string;
    };
    engenheiro: {
      id: number;
      nome: string;
      email: string;
    };
    gerente: {
      id: number;
      nome: string;
      email: string;
    };
  };
  projeto_visual: {
    id: number;
  };
  projeto_tecnico: {
    id: number;
    corrente_tensao: number;
    quadro_geral_instalado: number;
    quadro_geral_alimentado: number;
    file: IFile;
    equipamentos: IEquipment[];
  };
  quantitativo: {
    id: number;
    files: IFile[];
  };
}

export interface IGetConstructionState {
  readonly data: IResponse[];
  readonly loading: boolean;
  readonly error: boolean;
}

export const initialState = {
  id: 0,
  descricao: '',
  iniciado: 0,
  inicio: '',
  termino: '',
  orcamento_id: 0,
  endereco: {
    cep: '',
    logradouro: '',
    numero: 0,
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
  },
  responsavel: {
    id: 0,
    nome: '',
  },
  cliente: {
    id: 0,
    nome: '',
  },
  info: null,
  contato: {
    id: 0,
    nome: '',
    email: '',
    telefone: '',
    cargo: '',
    empresa: '',
  },
  corpo_tecnico: {
    id: 0,
    arquiteto: {
      id: 0,
      nome: '',
      email: '',
    },
    engenheiro: {
      id: 0,
      nome: '',
      email: '',
    },
    gerente: {
      id: 0,
      nome: '',
      email: '',
    },
  },
  projeto_visual: {
    id: 0,
  },
  projeto_tecnico: {
    id: 0,
    corrente_tensao: 0,
    quadro_geral_instalado: 0,
    quadro_geral_alimentado: 0,
    file: {
      id: 0,
      nome: '',
      path: '',
      status: null,
      data_atualizacao: '',
      status_responsavel: 0,
      justificativa: null,
    },
    equipamentos: [{ id: 0, descricao: '', recebido: null, deletado: null }],
  },
  quantitativo: {
    id: 0,
    files: [
      {
        id: 0,
        nome: '',
        path: '',
        status: null,
        data_atualizacao: '',
        status_responsavel: 0,
        justificativa: null,
      },
    ],
  },
};
