/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from '~/store/modules/types';
import ModalScheduling from '../ModalScheduling';
import * as St from './styles';

interface IProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  client: number | null;
  setClient: (value: number | null) => void;
}

const ProjectUnfinished: FC<IProps> = props => {
  const [modal, setModal] = useState(false);
  const { resume } = useSelector((state: IState) => state.report);

  return resume?.finishedContructionWithoutSchedule?.length ? (
    <St.Container>
      {modal ? (
        <ModalScheduling onClick={() => setModal(false)} {...props} />
      ) : null}
      <St.Row>
        <St.Value>
          {(resume?.finishedContructionWithoutSchedule?.length)
            .toString()
            .padStart(2, '0')}
        </St.Value>
        <St.Label>
          obras finalizadas que não possuem agendamento de manutenção
        </St.Label>
      </St.Row>
    </St.Container>
  ) : null;
};

export default ProjectUnfinished;
