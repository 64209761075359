export const situacaoENUM = (situacao: string): string => {
  switch (situacao) {
    case 'RASCUNHO':
      return 'Rascunho';
    case 'ANALISE':
      return 'Em análise';
    case 'REVISAO':
      return 'Aguardando Revisão';
    case 'ANALISE_AGUARDANDO':
      return 'Enviado para Análise do Cliente';
    case 'OBRA_AGUARDANDO':
      return 'Aguardando Início da Obra';
    case 'DIARIO_AGUARDANDO':
      return 'Aguardando Início do Diário da Obra';
    case 'DIARIO_INICIADO':
      return 'Obra Iniciada';
    case 'OBRA_INICIADA':
      return 'Obra Iniciada';
    default:
      return '-';
  }
};
