/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Radio } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import {
  IconAlert,
  IconDownload2,
  IconUpload,
  IconClose,
  IconSuccess,
} from '~/assets/Icons';
import { Button, Checkbox, Modal, ModalDefault } from '~/components';
import ConstructionsService from '~/services/ConstructionsService';
import {
  getConstructionRequest,
  getConstructionSuccess,
} from '~/store/modules/construnction/actions';
import { listSystemRequest } from '~/store/modules/stock/actions';
import { IState } from '~/store/modules/types';
import {
  AprroveButton,
  BoxContainer,
  CheckContent,
  ContainerBox,
  ContainerTexto,
  ContentModal,
  DeletDiv,
  HeaderBox,
  ListFile,
  NoData,
  ReproveButton,
  TitleModal,
  UploadContainer,
  Actions,
  ApprovalContainer,
} from './styles';
import Equipment from './Equipment';
import ModalApproval from './ModalApproval ';

interface IProps {
  dataSource: any;
  user: any;
}

interface IItem {
  id: number;
  descricao: string;
  recebido: number | null;
  deletado: number;
  itemFromSystem: boolean;
  items?: IItems[];
  sistemaId?: number;
}

interface IItems {
  id: number;
  produtoId?: number;
  sistemaId?: number;
  orcamentoId?: number;
  descricao: string;
  recebido: number | null;
  deletado: number;
  itemFromSystem: boolean;
}

interface IUpdateTechnicalProject {
  voltage?: string;
  boardInstalled?: boolean;
  boardPowered?: boolean;
}

interface IUpdateEquipment {
  id: number;
  description: string;
  system?: boolean;
  systemId?: number;
}

interface IUpdateEquipmentSystem {
  id: number[];
  description: string;
}
const SwitchTechnicalProject: React.FC<IProps> = ({
  dataSource,
  user,
}: IProps) => {
  const { type } = useParams<{ type: string }>();
  const [valueVoltage, setValueVoltage] = useState<string>(
    `${dataSource?.projeto_tecnico?.corrente_tensao}`,
  );
  const [valueBoardInstalled, setValueBoardInstalled] = useState(
    dataSource?.projeto_tecnico?.quadro_geral_instalado,
  );
  const [valueBoardPowered, setValueBoardPowered] = useState(
    dataSource?.projeto_tecnico?.quadro_geral_alimentado,
  );
  const [perimission, setPerimission] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleModalSystem, setIsVisibleModalSystem] = useState(false);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [equipmentInfo, setEquipmentInfo] = useState<IUpdateEquipment>({
    id: 0,
    description: '',
  });
  const [equipmentsInfo, setEquipmentsInfo] = useState<IUpdateEquipment[]>([]);
  const [equipments, setEquipments] = useState<IItem[]>([]);
  const [imgArray, setImgArray] = useState(dataSource?.projeto_tecnico?.files);

  const [isVisibleModalApproval, setIsVisibleModalApproval] = useState(false);
  const [justification, setJustification] = useState('');
  const [handleImg, setHandleImg] = useState(0);

  const userId = useSelector((state: IState) => state.auth.data.account.id);

  const dispatch = useDispatch();

  const { systems } = useSelector((state: IState) => state.stock);

  useEffect(() => {
    dispatch(listSystemRequest());
  }, []);

  useEffect(() => {
    handleEquipments();
  }, [systems, dataSource?.projeto_tecnico?.equipamentos]);

  const handleEquipments = () => {
    if (systems?.length && dataSource?.projeto_tecnico?.equipamentos?.length) {
      const equipments = [...dataSource?.projeto_tecnico?.equipamentos];
      const systemReduced = equipments
        .reduce((acum: IItem[], curr: IItems) => {
          if (curr.itemFromSystem) {
            const system = systems.find(system => system.id === curr.sistemaId);
            const exist = acum.find(curr => curr.id === system?.id);
            if (system) {
              if (!exist) {
                acum.push({
                  id: system?.id,
                  deletado: 0,
                  descricao: system?.descricao,
                  itemFromSystem: true,
                  recebido: 0,
                  items: [curr],
                });
              } else {
                exist.items?.push(curr);
              }
            }
          } else {
            acum.push(curr);
          }
          return acum;
        }, [])
        .map((curr: IItem) => {
          if (curr.items) {
            const len = curr.items.length;
            const lenFinished = curr.items.filter(item => item.recebido).length;
            return {
              ...curr,
              recebido:
                (len === lenFinished && 1) || (lenFinished > 0 && 2) || 0,
            };
          }
          return curr;
        });
      setEquipments(systemReduced);
    }
  };

  const updateTechnicalProject = async ({
    voltage,
    boardInstalled,
    boardPowered,
  }: IUpdateTechnicalProject) => {
    try {
      await ConstructionsService.updateTechnicalProject({
        id: dataSource.projeto_tecnico.id,
        voltage: voltage || valueVoltage,
        boardInstalled: boardInstalled ? 1 : 0,
        boardPowered: boardPowered ? 1 : 0,
      });
      setValueBoardInstalled(
        boardInstalled === undefined ? valueBoardInstalled : boardInstalled,
      );
      setValueBoardPowered(
        boardPowered === undefined ? valueBoardPowered : boardPowered,
      );
      dispatch(
        getConstructionSuccess({
          ...dataSource,
          projeto_tecnico: {
            ...dataSource.projeto_tecnico,
            corrente_tensao: voltage || valueVoltage,
            quadro_geral_instalado:
              boardInstalled === undefined
                ? valueBoardInstalled
                : boardInstalled,
            quadro_geral_alimentado:
              boardPowered === undefined ? valueBoardPowered : boardPowered,
          },
        }),
      );
    } catch {
      setIsVisibleModalErro(true);
    }
  };

  const checkProductsDelivered = (dataSource: any) => {
    let received = true;
    dataSource?.projeto_tecnico?.equipamentos?.forEach((element: any) => {
      if (!element.recebido) {
        received = false;
      }
    });

    if (received) {
      dispatch(
        getConstructionSuccess({
          ...dataSource,
          projeto_tecnico: {
            ...dataSource?.projeto_tecnico,
            equipamentos_recebidos: 1,
          },
        }),
      );
    }
  };

  const updateEquipment = async ({ id, description }: IUpdateEquipment) => {
    const contentEquipament = dataSource?.projeto_tecnico?.equipamentos?.filter(
      (e: any) => e.id !== id,
    );
    const equipment = dataSource?.projeto_tecnico?.equipamentos?.find(
      (e: any) => e.id === id,
    );
    try {
      await ConstructionsService.updateEquipment({
        id: dataSource.id,
        equipment: [id],
      });
      checkProductsDelivered({
        ...dataSource,
        projeto_tecnico: {
          ...dataSource?.projeto_tecnico,
          equipamentos: [
            ...contentEquipament,
            {
              ...equipment,
              id,
              descricao: description,
              recebido: 1,
              deletado: 0,
            },
          ],
        },
      });
      dispatch(getConstructionRequest(type));
    } catch {
      setIsVisibleModalErro(true);
    }
  };

  const updateEquipmentSystem = async ({ id }: IUpdateEquipmentSystem) => {
    const equipment = dataSource?.projeto_tecnico?.equipamentos?.find(
      (e: any) => e.id === id[0],
    );
    try {
      await ConstructionsService.updateEquipmentSystem({
        id: dataSource.orcamento_id,
        equipment: id,
        systemId: `${equipmentsInfo[0].systemId}`,
      });

      dispatch(getConstructionRequest(type));
    } catch (error) {
      setIsVisibleModalErro(true);
    }
  };

  const approveFile = () => {
    dispatch(getConstructionRequest(dataSource.id));
    setImgArray(dataSource?.projeto_visual?.files);
  };

  const userPermissions = useSelector(
    (state: IState) => state.auth.data.permissions,
  );

  const verifyPermissionActions = (): boolean => {
    if (dataSource.terminado) {
      return false;
    }
    if (!user) return false;
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    return (
      !!DICT.find(key => key === user.toUpperCase()) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      })
    );
  };

  const verifyPermission = (): boolean => {
    if (dataSource.terminado) {
      return false;
    }
    if (!user) return false;
    const bodyTech = dataSource.corpo_tecnico;
    const keysTechs = Object.keys(bodyTech || {}).filter(curr => curr !== 'id');
    const ids = keysTechs.reduce(
      (acum: number[], curr) => {
        const tech = bodyTech[curr];
        if (tech.id && !acum.find(c => c === tech.id)) acum.push(tech.id);
        return acum;
      },
      [dataSource.responsavel.id],
    );
    const DICT = ['DIRETOR', 'GERENTE DE PROJETOS', 'GERENTE GERAL'];
    if (
      (!!DICT.find(key => key === user.toUpperCase()) ||
        !!ids.find(id => userId === id)) &&
      userPermissions.find(element => {
        if (
          element.modulo === 'DIÁRIO DE OBRAS' &&
          element.permissao === 'ATUALIZAR'
        ) {
          return true;
        }
        return false;
      })
    ) {
      setPerimission(true);
    }

    return false;
  };

  useEffect(() => {
    verifyPermission();
  }, []);

  const uploadFile = async (file: any[]) => {
    const formData = new FormData();
    formData.append('tab', '56');
    formData.append('obra', dataSource.id);
    formData.append('file', file[0]);
    try {
      const response = await ConstructionsService.uploadFile(formData);

      setImgArray((prevState: any) => {
        return [
          ...prevState,
          {
            status: null,
            ...response.upload,
            path: response.upload.url,
            data_atualizacao: new Date(),
            justificativa: '',
          },
        ];
      });

      dispatch(
        getConstructionSuccess({
          ...dataSource,
          projeto_tecnico: {
            ...dataSource?.projeto_tecnico,
            files: [
              ...dataSource?.projeto_tecnico?.files,
              {
                ...response.upload,
                path: response.upload.url,
                status: null,
                data_atualizacao: new Date(),
                justificativa: '',
              },
            ],
          },
        }),
      );
    } catch {
      setIsVisibleModalErro(true);
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    uploadFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  const handleClick = (product: IItem) => {
    if (product?.items && product?.items.length > 0) {
      setIsVisibleModalSystem(true);
      setEquipmentsInfo(
        product.items.map(item => ({
          id: item.id,
          systemId: item.sistemaId,
          description: item.descricao,
        })),
      );
    } else if (product.itemFromSystem) {
      setIsVisibleModalSystem(true);
      setEquipmentsInfo([
        {
          id: product.id,
          systemId: product.sistemaId,
          description: product.descricao,
        },
      ]);
    } else {
      setIsVisibleModal(true);
      setEquipmentInfo({
        id: product.id,
        description: product.descricao,
        system: !!product.itemFromSystem,
      });
    }
  };

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefault
          onClick={() => setIsVisibleModalErro(false)}
          success={false}
        >
          Algo deu errado...
        </ModalDefault>
      )}
      {isVisibleModal && (
        <Modal>
          <DeletDiv>
            <TitleModal>
              <b>Tem certeza que deseja receber o produto na obra?</b>
              <button type="button" onClick={() => setIsVisibleModal(false)}>
                <IconClose />
              </button>
            </TitleModal>
            <ContentModal>
              <Button styles={1} onClick={() => setIsVisibleModal(false)}>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  if (equipmentInfo.system)
                    updateEquipmentSystem({
                      id: [equipmentInfo.id],
                      description: equipmentInfo.description,
                    });
                  else updateEquipment(equipmentInfo);
                  setIsVisibleModal(false);
                }}
              >
                Receber
              </Button>
            </ContentModal>
          </DeletDiv>
        </Modal>
      )}
      {isVisibleModalSystem && (
        <Modal>
          <DeletDiv>
            <TitleModal>
              <b>Tem certeza que deseja receber o sistema na obra?</b>
              <button
                type="button"
                onClick={() => setIsVisibleModalSystem(false)}
              >
                <IconClose />
              </button>
            </TitleModal>
            <ContentModal>
              <Button styles={1} onClick={() => setIsVisibleModalSystem(false)}>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  const ids = equipmentsInfo.map(curr => curr.id);
                  updateEquipmentSystem({
                    id: ids,
                    description: equipmentInfo.description,
                  });
                  setIsVisibleModalSystem(false);
                }}
              >
                Receber
              </Button>
            </ContentModal>
          </DeletDiv>
        </Modal>
      )}

      {isVisibleModalApproval && (
        <ModalApproval
          typeProject="Técnico"
          onChange={(e: any) => setJustification(e)}
          onClickConfirm={async () => {
            try {
              await ConstructionsService.updateTechnicalProject({
                id: dataSource.projeto_tecnico.id,
                uploadId: handleImg,
                justification,
                status: 0,
              });
              approveFile();
              dispatch(getConstructionRequest(type));
            } catch {
              setIsVisibleModalErro(true);
            }
            setIsVisibleModalApproval(false);
          }}
          onClickCancel={() => setIsVisibleModalApproval(false)}
        />
      )}
      <ContainerBox>
        <BoxContainer>
          <HeaderBox>Equipamento</HeaderBox>
          {equipments.map(product => (
            <Equipment
              handleClick={handleClick}
              product={product}
              permission={perimission}
            />
          ))}
        </BoxContainer>
        <div>
          <BoxContainer>
            <HeaderBox>Corrente de Tensão</HeaderBox>
            <Radio.Group
              onChange={e => {
                setValueVoltage(e.target.value);
                updateTechnicalProject({ voltage: e.target.value });
              }}
              value={valueVoltage}
            >
              <Radio disabled={!perimission} value="1">
                380 tri
              </Radio>
              <Radio disabled={!perimission} value="2">
                220 tri
              </Radio>
              <Radio disabled={!perimission} value="3">
                220 mono
              </Radio>
            </Radio.Group>
          </BoxContainer>
          <BoxContainer>
            <CheckContent>
              <Checkbox
                disabled={!perimission}
                checked={valueBoardInstalled}
                onChange={e => {
                  if (
                    valueVoltage === '1' ||
                    valueVoltage === '2' ||
                    valueVoltage === '3'
                  ) {
                    updateTechnicalProject({
                      boardInstalled: e.target.checked,
                      boardPowered: valueBoardPowered,
                    });
                  }
                }}
              />
              <p>Quadro Geral Instalado</p>
            </CheckContent>
            <CheckContent>
              <Checkbox
                disabled={!perimission}
                checked={valueBoardPowered}
                onChange={e => {
                  if (
                    valueVoltage === '1' ||
                    valueVoltage === '2' ||
                    valueVoltage === '3'
                  ) {
                    updateTechnicalProject({
                      boardPowered: e.target.checked,
                      boardInstalled: valueBoardInstalled,
                    });
                  }
                }}
              />
              <p>Quadro Geral Alimentado</p>
            </CheckContent>
          </BoxContainer>
        </div>
        <div>
          <BoxContainer>
            <HeaderBox>Upload de Arquivos</HeaderBox>
            <>
              <UploadContainer
                {...getRootProps()}
                show={!perimission}
                disabled={!perimission}
              >
                <>
                  <input {...getInputProps()} />
                  {isDragReject && (
                    <p>Arquivo não suportado, tente um arquivo .jpg ou .png</p>
                  )}
                  {isDragActive ? (
                    <p>Solte os arquivos aqui ... </p>
                  ) : (
                    <>
                      <IconUpload />
                      <p>
                        Arraste seu arquivo aqui ou escolha selecionar dentro do
                        seu dispositivo.
                      </p>
                    </>
                  )}
                </>
              </UploadContainer>

              <UploadContainer show={perimission} disabled={!perimission}>
                <IconUpload />
                <p>
                  Arraste seu arquivo aqui ou escolha selecionar dentro do seu
                  dispositivo.
                </p>
              </UploadContainer>
            </>
          </BoxContainer>
          {imgArray.length > 0 ? (
            <BoxContainer>
              <HeaderBox>Arquivos</HeaderBox>
              {imgArray.map((element: any) => (
                <ListFile action>
                  <ContainerTexto>
                    <b>
                      <u>{element.nome}</u>
                    </b>
                    <p>
                      Publicado
                      {format(
                        new Date(element.data_atualizacao),
                        ` dd/MM/yyyy 'às' HH:mm`,
                        {
                          locale: pt,
                        },
                      )}
                    </p>
                  </ContainerTexto>
                  <Actions>
                    {element.status === null ? (
                      verifyPermissionActions() ? (
                        <>
                          <AprroveButton
                            onClick={async () => {
                              try {
                                await ConstructionsService.updateTechnicalProject(
                                  {
                                    id: dataSource.projeto_tecnico.id,
                                    uploadId: element.id,
                                    justification: 'aprovado',
                                    status: 1,
                                  },
                                );

                                dispatch(getConstructionRequest(type));
                              } catch {
                                setIsVisibleModalErro(true);
                              }
                            }}
                          >
                            Aprovar
                          </AprroveButton>
                          <ReproveButton
                            onClick={() => {
                              setIsVisibleModalApproval(true);
                              setHandleImg(element.id);
                            }}
                          >
                            Reprovar
                          </ReproveButton>
                        </>
                      ) : null
                    ) : element.status === 1 ? (
                      <ApprovalContainer>
                        <IconSuccess />
                        Aprovado
                      </ApprovalContainer>
                    ) : (
                      <ApprovalContainer>
                        <IconAlert />
                        <b>Reprovado</b>
                      </ApprovalContainer>
                    )}

                    <span>
                      <a target="_blank" href={element?.path} rel="noreferrer">
                        <IconDownload2 />
                      </a>
                    </span>
                  </Actions>
                </ListFile>
              ))}
            </BoxContainer>
          ) : (
            <BoxContainer>
              <NoData>
                <IconAlert />
                <span>
                  <b>Esse projeto ainda não possui nenhuma revisão anexada!</b>
                </span>
              </NoData>
            </BoxContainer>
          )}
        </div>
      </ContainerBox>
    </>
  );
};

export default SwitchTechnicalProject;
