import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 20px;
    font-weight: normal;
  }
`;

export const ButtonHeader = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 15px;
    margin-right: 15px;
  }
`;

export const Container = styled.div`
  display: flex;
  margin: 30px 30px 0;
`;

export const Content = styled.div`
  padding: 16px 20px;
  margin: 0 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.17);
  border-radius: 3px;
  width: 100%;
  height: fit-content;
  font-size: 17px;

  span {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }
  b {
    margin-left: 4px;
    font-weight: 700;
  }
  p {
    font-weight: 300;
  }
`;

export const Img = styled.img`
  border: 1px solid #000000;
  margin: 0 10px;

  border-radius: 6px;
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentAddFinancialResponsible = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* margin: auto 20vh 1vh; */
`;

export const ButtonAddFinancialResponsible = styled('button')`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  max-height: 45px;
`;

export const ButtonTextAddFinancialResponsible = styled.h1`
  margin: 0px 5px;
  text-decoration: underline;
`;

export const BtTrash = styled('button')<{
  disabled?: boolean;
  locked?: boolean;
}>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Red};
    ${props => props.disabled && 'color: #c4c4c4;'}
    ${props =>
      props.locked && 'color: #54565A !important; cursor: not-allowed;'}
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
