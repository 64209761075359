import { action } from 'typesafe-actions';
import { ListCustomerTypes, IResponse } from './types';

export const listCustomerRequest = (): any =>
  action(ListCustomerTypes.LISTCUSTOMER_REQUEST);

export const listCustomerSuccess = (data: IResponse): any =>
  action(ListCustomerTypes.LISTCUSTOMER_SUCCCES, { data });

export const listCustomerFailure = (): any =>
  action(ListCustomerTypes.LISTCUSTOMER_FAILURE);
