import styled from 'styled-components';

export const Header = styled.div`
  margin: 3%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin: 0 10px;
    width: 50%;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const DivList = styled.div`
  margin-top: 50px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  position: absolute;
  width: 22%;
  background: #ffffff;
  z-index: 900;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.17);
  border-radius: 3px;

  a + a {
    border-top: 1px solid #30b4e5;
  }
`;

export const ListContent = styled.a`
  display: flex;
  padding: 10px;
  color: #323232;
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
`;

export const Content = styled.div`
  display: flex;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 30px;
  text-transform: capitalize;
  margin: 3% 3% 0;
`;

export const BtEdit = styled('a')<{ locked?: boolean }>`
  margin: 0 5px;
  svg {
    width: 25px;
    height: auto;
    color: ${({ theme }) => theme.Colors.Blue.Main};
    ${props => props.locked && 'color: #54565A;'}
  }
`;
