export enum ListProductTypes {
  LISTPRODUCT_REQUEST = '@product/LISTPRODUCT_REQUEST',
  LISTPRODUCT_SUCCCES = '@product/LISTPRODUCT_SUCCCES',
  LISTPRODUCT_FAILURE = '@product/LISTPRODUCT_FAILURE',
}

export interface IResponse {
  id: 0;
  descricao: '';
  descricaoTecnica: '';
  descricaoComercial: '';
  especificacaoTecnica: '';
  preco: 0;
  imagens: [
    {
      default: 0;
      fileUrl: '';
    },
  ];
}

export interface IListProductState {
  readonly data: IResponse[];
  readonly loading: boolean;
  readonly error: boolean;
}
