import React, { useState } from 'react';

import { MdKeyboardArrowDown } from 'react-icons/md';
import jsonMenu from '~/mocks/Menu.json';
import { SwitchIcon } from '~/components';

import {
  Container,
  DropDown,
  ButtonMenu,
  SessionTitle,
  LinkMenu,
  ButtonMenuContent,
} from './styles';

const ItemMenu: React.FC = () => {
  const [toggle, setToggle] = useState(false);

  const children = (item: any) => {
    const multiChild = (child: any) => {
      if (child.children.length > 0) {
        return <div key={child.id}>{children(child)}</div>;
      }
      return (
        <LinkMenu to={child.link} key={`${child.id}-children`}>
          {child.nome}
        </LinkMenu>
      );
    };

    return (
      <>
        <ButtonMenu key={item.id} onClick={() => setToggle(!toggle)}>
          <SwitchIcon name={item.icon} color="#323232" />
          <ButtonMenuContent>
            {item.nome}
            <MdKeyboardArrowDown />
          </ButtonMenuContent>
        </ButtonMenu>
        {item.children.map((sub: any) => {
          return (
            <div>
              <DropDown
                key={`${sub.id}-dropdown`}
                className={toggle ? '' : 'false'}
              >
                {multiChild(sub)}
              </DropDown>
            </div>
          );
        })}
      </>
    );
  };

  const childSessions = (item: any) => {
    if (item.children.length > 0) {
      return (
        <div key={`${item.id}-asdhiah`}>
          {item.children.map((child: any) => {
            if (child.children.length > 0) {
              return (
                <div key={`${child.id}-childSessions`}>{children(child)}</div>
              );
            }
            return (
              <LinkMenu
                to={child.link}
                key={`${child.id}-linkmenu`}
                className="itensMenu"
              >
                <SwitchIcon name={child.icon} color="#323232" />
                {child.nome}
              </LinkMenu>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const sessions = (item: any) => {
    if (item.session) {
      return (
        <div key={`${item.nome}-sessionat`}>
          <SessionTitle key={`${item.nome}-session`}>{item.nome}</SessionTitle>
        </div>
      );
    }
    return null;
  };

  return (
    <div key="container-container">
      {jsonMenu.map(item => {
        return (
          <Container key={`${item.id}-container`}>
            {sessions(item)}
            {childSessions(item)}
          </Container>
        );
      })}
    </div>
  );
};

export default ItemMenu;
